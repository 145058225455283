import {
  Ref,
  useState,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useRef
} from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure
} from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom'
import { dbDeleteForm } from 'controllers/forms'

export interface IRemoveFormModal {
  open: (formId: string) => void
}

type Props = {}

const RemoveFormModal: ForwardRefRenderFunction<IRemoveFormModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [formId, setFormId] = useState<string>()
  const navigate = useNavigate()
  const cancelRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: (deleteFormId: string) => {
      setFormId(deleteFormId)
      onOpen()
    }
  }))

  const onSubmit = async (): Promise<void> => {
    if (formId) {
      dbDeleteForm(formId)
      onClose()
      navigate('/responses')
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete video form
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete the video form?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant='ghost' ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={onSubmit} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default forwardRef(RemoveFormModal)
