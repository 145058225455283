import { RootState } from 'model/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { DictT, UserProfileT } from 'shared/types/model'
import _ from 'lodash'

export const getUser = (state: RootState) => state.user
export const getAccount = (state: RootState) => state.account
export const getAccountProfile = (state: RootState) => state.accountProfile
export const getQuestions = (state: RootState) => state.questions
export const getQuestionsLibrary = (state: RootState) => state.questionsLibrary
export const getTemplates = (state: RootState) => state.templates
export const getTemplateSections = (state: RootState) => state.templatesSections
export const getResponses = (state: RootState) => state.responses
export const getForms = (state: RootState) => state.forms
export const getProfiles = (state: RootState) =>
  state.profiles as DictT<UserProfileT>
export const getProducts = (state: RootState) => state.products
export const getPrices = (state: RootState) => state.prices
export const getSubscription = (state: RootState) => state.subscription

export const getAppLoaded = createSelector(
  [getUser, getAccount, getAccountProfile, getResponses],
  (user, account, accountProfile, responses) => {
    const lst = [user, account, accountProfile, responses]
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
