import { FC } from 'react'
import {
  VStack,
  GridItem,
  Grid,
  Heading,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  HStack,
  Button,
  Text,
  Menu,
  MenuButton,
  Divider,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { QA, ResponseT } from 'shared/types/model'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { generateId } from 'controllers/db'
import { useSelector } from 'model/hooks'
import { getFormLabel } from 'shared/utils/form'
import { dbFetchAnswers } from 'controllers/responses'
import { getAllQuestions } from 'model/selectors/questions'

type Props = {
  qas: QA[]
  setQas: (qas: QA[]) => void
  hideAIAnalysis?: boolean
}

const QuestionsAnswersTestCase: FC<Props> = ({
  qas,
  setQas,
  hideAIAnalysis
}) => {
  const responses = useSelector(state => state.responses)
  const forms = useSelector(state => state.forms)
  const questions = useSelector(getAllQuestions)

  const onEditQ = (qaId: string, nextValue: string) => {
    console.log('onEditQ', qaId, nextValue)
    const newQas = _.map(qas, qa => {
      if (qa.id === qaId) {
        return { ...qa, q: nextValue }
      } else {
        return qa
      }
    })
    setQas(newQas)
  }

  const onEditA = (qaId: string, nextValue: string) => {
    console.log('onEditA', qaId, nextValue)
    const newQas = _.map(qas, qa => {
      if (qa.id === qaId) {
        return { ...qa, a: nextValue }
      } else {
        return qa
      }
    })
    setQas(newQas)
  }

  const deleteQA = (qaId: string) => {
    const newQas = _.filter(qas, qa => qa.id !== qaId)
    setQas(newQas)
  }

  const onAddRow = () => {
    const id = generateId()
    const qa = {
      id,
      q: '',
      a: '',
      ai: ''
    }
    setQas([...qas, qa])
  }

  const renderRow = (qa: QA) => {
    return [
      <GridItem
        key={'q_' + qa.id}
        p={2}
        borderTopWidth={1}
        borderColor={'gray.100'}
      >
        <Editable
          placeholder='enter question'
          fontSize='sm'
          w={'full'}
          defaultValue={qa.q}
          onSubmit={(nextValue: string) => onEditQ(qa.id, nextValue)}
        >
          <EditablePreview w='full' />
          <EditableInput w='full' />
        </Editable>
      </GridItem>,
      <GridItem
        key={'a_' + qa.id}
        p={2}
        borderTopWidth={1}
        borderColor={'gray.100'}
      >
        <HStack w='full' justify={'space-between'}>
          <Editable
            placeholder='enter answer'
            fontSize='sm'
            w={'full'}
            defaultValue={qa.a}
            onSubmit={(nextValue: string) => onEditA(qa.id, nextValue)}
          >
            <EditablePreview w='full' />
            <EditableInput w='full' />
          </Editable>
        </HStack>
      </GridItem>,
      !hideAIAnalysis ? (
        <GridItem
          key={'ai_' + qa.id}
          borderTopWidth={1}
          borderColor={'gray.100'}
        >
          <Text fontSize='sm'>{qa.ai}</Text>
        </GridItem>
      ) : null,
      <GridItem
        key={'actuions_' + qa.id}
        borderTopWidth={1}
        borderColor={'gray.100'}
      >
        <HStack h='full' align={'center'} justify={'flex-end'} w='full' px={4}>
          <IconButton
            size='sm'
            aria-label='delete qa'
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            onClick={() => deleteQA(qa.id)}
          />
        </HStack>
      </GridItem>
    ]
  }

  const onImportResponse = async (r: ResponseT) => {
    console.log('onImportResponse', r)
    const dbAnswers = await dbFetchAnswers(r.id)
    console.log('dbAnswers', dbAnswers)
    const newQas: QA[] = []
    _.forEach(r.answersIds, aId => {
      const a = _.get(dbAnswers, aId)
      if (a) {
        const q = _.get(questions, a.questionId)
        if (q) {
          newQas.push({
            id: a.id,
            a: a.transcription || '',
            q: q.text,
            ai: ''
          })
        }
      }
    })
    console.log('newQas', newQas)
    setQas(newQas)
  }

  const renderResponseImport = () => {
    return (
      <Menu>
        <MenuButton as={Button} colorScheme='blue' variant='outline'>
          Import response
        </MenuButton>
        <MenuList maxH={'sm'} overflowY={'auto'}>
          {_.map(responses, r => {
            const form = _.get(forms, r.formId)
            return (
              <MenuItem
                key={r.id}
                value={r.id}
                onClick={() => onImportResponse(r)}
              >
                <VStack w='full' spacing={0} align='flex-start'>
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    {form ? getFormLabel(form) : ''}
                  </Text>
                  <Text fontSize={'xs'}>{r.name}</Text>
                  <Divider />
                </VStack>
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    )
  }

  return (
    <VStack w='full'>
      <HStack w='full' justify={'space-between'}>
        <Heading>Test case</Heading>
        {renderResponseImport()}
      </HStack>
      <Grid
        w='full'
        gridTemplateColumns={
          hideAIAnalysis ? '1fr 1fr 100px' : '1fr 1fr 1fr 100px'
        }
        borderWidth={1}
        bg='white'
      >
        <GridItem p={2}>
          <Heading size='sm'>Question</Heading>
        </GridItem>
        <GridItem p={2}>
          <Heading size='sm'>Answer</Heading>
        </GridItem>
        {!hideAIAnalysis && (
          <GridItem p={2}>
            <Heading size='sm'>AI analysis</Heading>
          </GridItem>
        )}
        <GridItem p={2}>
          <Heading size='sm'></Heading>
        </GridItem>
        {_.map(qas, renderRow)}
      </Grid>
      <HStack justify={'flex-end'} w='full'>
        <Button
          size='sm'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onAddRow}
          variant={'outline'}
          colorScheme='blue'
        >
          Add row
        </Button>
      </HStack>
    </VStack>
  )
}

export default QuestionsAnswersTestCase
