import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Select,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import { getAllQuestions } from 'model/selectors/questions'
import { DictT, QuestionT } from 'shared/types/model'

export interface IAddQuestionModal {
  open: (qIds: string[]) => void
  update: (qIds: string[]) => void
}

type Props = {
  onSelect: (v: string[]) => void
  openCreateQuestionModal: () => void
}

const AddQuestionModal: ForwardRefRenderFunction<IAddQuestionModal, Props> = (
  {
    onSelect,
    openCreateQuestionModal
  },
  ref: Ref<unknown>
) => {
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<string>('all')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const allQuestions = useSelector(getAllQuestions)
  const templates = useSelector(state => state.templates)

  useImperativeHandle(ref, () => ({
    open: (qIds: string[]) => {
      setSelectedQuestions(qIds)
      onOpen()
    },
    update: (qIds: string[]) => {
      setSelectedQuestions([...selectedQuestions, ...qIds])
    }
  }))

  useEffect(() => {
    setSelectedQuestions([])
  }, [selectedTemplate])

  const handleSelect = (v: string[]) => {
    onSelect(v)
    handleClose()
  }

  const handleSelectAll = () => {
    if (_.isEqual(_.size(questions), _.size(selectedQuestions))) {
      setSelectedQuestions([])
    } else {
      setSelectedQuestions(_.map(questions, q => q.id))
    }
  }

  const handleClose = () => {
    setSelectedTemplate('all')
    setSelectedQuestions([])
    onClose()
  }

  const handleQuestionClick = (qId: string) => {
    const checked = _.includes(selectedQuestions, qId)
    if (checked) {
      setSelectedQuestions([..._.pull(selectedQuestions, qId)])
    } else {
      setSelectedQuestions([...selectedQuestions, qId])
    }
  }

  const questions: DictT<QuestionT> = useMemo(() => {
    if (selectedTemplate === 'all') {
      return allQuestions
    } else {
      const template = _.get(templates, selectedTemplate)
      return _.reduce(template?.questions, (res, qId) => {
        _.set(res, qId, _.get(allQuestions, qId))
        return res
      }, {})
    }
  }, [selectedTemplate, allQuestions])

  const sortedQuestions = useMemo(
    () => _.sortBy(questions, q => !_.includes(selectedQuestions, q.id)),
    [selectedQuestions, questions]
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <HStack justify='space-between' w='full' p='6'>
          <ModalHeader
            p={0}
            fontSize='md'
            fontWeight='normal'
            color='black'
            flexShrink={0}
            flex={1}
          >
            <HStack justify='space-between' align='center'>
              <VStack align='start' justify='center'>
                <Text fontSize='lg' color='gray.900'>
                  Add question
                </Text>
              </VStack>
              <HStack>
                <Select
                  value={selectedTemplate}
                  onChange={e => setSelectedTemplate(e.target.value)}
                >
                  <option value='all'>All questions</option>
                  {_.map(templates, t => {
                    if (!_.isEmpty(t.questions)) {
                      return (
                        <option key={t.id} value={t.id}>
                          {t.title}
                        </option>
                      )
                    }
                  })}
                </Select>
              </HStack>
            </HStack>
          </ModalHeader>
        </HStack>

        <ModalBody pt='0' px={4}>
          <Box h='md' overflowY='auto'>
            {!_.isEmpty(questions) && (
              <Checkbox
                px='8'
                py='4'
                isChecked={_.isEqual(
                  _.size(questions),
                  _.size(selectedQuestions)
                )}
                isIndeterminate={
                  !_.isEmpty(selectedQuestions) &&
                  !_.isEqual(_.size(questions), _.size(selectedQuestions))
                }
                onChange={handleSelectAll}
              />
            )}
            {_.map(sortedQuestions, (q, i) => {
              return (
                <HStack
                  key={`${i}_${q.id}`}
                  w='full'
                  px='8'
                  py='4'
                  borderBottom='1px'
                  borderColor='gray.100'
                >
                  <Checkbox
                    isChecked={_.includes(selectedQuestions, q.id)}
                    onChange={() => handleQuestionClick(q.id)}
                    spacing='8'
                  >
                    <Text fontSize='md' fontWeight='medium' cursor='default'>
                      {q.text}
                    </Text>
                  </Checkbox>
                </HStack>
              )
            })}
          </Box>
        </ModalBody>
        <ModalFooter justifyContent='flex-start' pt={8}>
          <HStack>
            <Button onClick={openCreateQuestionModal}>
              Add custom question
            </Button>
          </HStack>
          <HStack justify='flex-end' w='full'>
            <Button
              variant='ghost'
              fontSize='md'
              lineHeight='6'
              fontWeight='semibold'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme='teal'
              onClick={() => handleSelect(selectedQuestions)}
              disabled={_.isEmpty(selectedQuestions)}
            >
              Add question{_.size(selectedQuestions) > 1 ? 's' : ''}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddQuestionModal)
