import { AnyAction } from '@reduxjs/toolkit'
import * as types from 'model/actionTypes'
import { QuestionT, DictT } from 'shared/types/model'

const initialState: DictT<QuestionT> = {}

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_QUESTIONS_LIBRARY:
      return action.questions
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
