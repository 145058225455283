import {
  TypedUseSelectorHook,
  useDispatch as storeUseDispatch,
  useSelector as storeUseSelector
} from 'react-redux'

import type { RootState, AppDispatch } from 'model/store'

export const useDispatch = () => storeUseDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = storeUseSelector
