import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import {
  Textarea,
  Text,
  useClipboard,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  VStack,
  Button,
  TabList,
  Tab
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTrashCan,
  faArrowTurnDownLeft,
  faAddressBook
} from '@fortawesome/pro-light-svg-icons'
import {
  faEllipsisH,
  faLink,
  faInputText,
  faArrowUpRightFromSquare,
  faInboxIn,
  faPersonCircleQuestion
} from '@fortawesome/pro-regular-svg-icons'
import { faShare } from '@fortawesome/pro-solid-svg-icons'
import { FormT } from 'shared/types/model'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

type Props = {
  questionsAmount: number
  form?: FormT
  onBrowseTemplatesClick: () => void
  onAddQuestionClick: () => void
  onPlaceContactForm: () => void
  onDeleteForm: () => void
  formId: string
  label?: string
  setLabel: (label: string) => void
  isEdit: boolean
  responsesAmount: number
}

const EditorNavbarMobile: FC<Props> = ({
  questionsAmount,
  form,
  onAddQuestionClick,
  onPlaceContactForm,
  onDeleteForm,
  formId,
  label = '',
  setLabel,
  isEdit,
  responsesAmount
}) => {
  const { onCopy, setValue } = useClipboard(
    `${process.env.REACT_APP_VIEW_FORM_URL}/${formId}`
  )
  const [_label, _setLabel] = useState<string>(label)
  const labelRef = useRef<HTMLTextAreaElement>(null)
  const [error, setError] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    _setLabel(label)
  }, [label])

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.style.height = 'auto'
      labelRef.current.style.height = labelRef.current.scrollHeight + 'px'
    }
  }, [_label, label])

  useEffect(() => {
    setValue(`${process.env.REACT_APP_VIEW_FORM_URL}/${formId}`)
  }, [formId])

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if (error) setError(false)
    _setLabel(e.target.value)
  }

  const saveLabel = () => {
    setLabel(_label)
  }

  const onPreviewClick = () => {
    window.open(
      `${process.env.REACT_APP_VIEW_FORM_URL}/${formId}?preview`,
      '_blank'
    )
  }

  const menuItemShare = (
    <MenuItem
      color={'gray.500'}
      icon={
        <FontAwesomeIcon icon={faArrowTurnDownLeft} fixedWidth rotation={180} />
      }
      onClick={() => navigate(`/form/${formId}/share`)}
      isDisabled={questionsAmount === 0}
    >
      <Text color='gray.700' fontWeight={'semibold'}>
        Share
      </Text>
    </MenuItem>
  )

  const menuItemCopyLink = (
    <MenuItem
      color={'gray.500'}
      icon={<FontAwesomeIcon icon={faLink} fixedWidth />}
      onClick={onCopy}
      isDisabled={questionsAmount === 0}
    >
      <Text color='gray.700' fontWeight={'semibold'}>
        Copy link
      </Text>
    </MenuItem>
  )

  const menuItemDelete = (
    <MenuItem
      color='red.500'
      onClick={onDeleteForm}
      icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
    >
      <Text fontWeight={'semibold'}>Delete</Text>
    </MenuItem>
  )

  const menuItemRename = (
    <MenuItem
      color={'gray.500'}
      icon={<FontAwesomeIcon icon={faInputText} fixedWidth />}
      onClick={() => labelRef.current?.focus()}
    >
      <Text color='gray.700' fontWeight={'semibold'}>
        Rename
      </Text>
    </MenuItem>
  )

  const menuItemPreview = (
    <MenuItem
      color={'gray.500'}
      icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />}
      onClick={onPreviewClick}
    >
      <Text color='gray.700' fontWeight={'semibold'}>
        Preview
      </Text>
    </MenuItem>
  )

  const menuList = () => {
    if (isEdit) {
      return (
        <MenuList zIndex={100}>
          {menuItemShare}
          {menuItemCopyLink}
          {menuItemRename}
          <MenuItem
            color={'gray.500'}
            icon={<FontAwesomeIcon icon={faPlus} fixedWidth />}
            onClick={() => onAddQuestionClick()}
          >
            <Text color='gray.700' fontWeight={'semibold'}>
              Add question
            </Text>
          </MenuItem>

          <MenuItem
            onClick={onPlaceContactForm}
            color={'blue.500'}
            icon={<FontAwesomeIcon icon={faAddressBook} fixedWidth />}
            isDisabled={form && !_.isNil(form.contactFormQuestionId)}
          >
            <Text color='blue.700' fontWeight={'semibold'}>
              Add contact form
            </Text>
          </MenuItem>

          {menuItemDelete}
        </MenuList>
      )
    } else {
      return (
        <MenuList zIndex='101'>
          {menuItemShare}
          {menuItemCopyLink}
          {menuItemPreview}
          {menuItemRename}
          {menuItemDelete}
        </MenuList>
      )
    }
  }

  const renderMenu = () => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisH} />}
          variant={'ghost'}
          size='sm'
        />
        {menuList()}
      </Menu>
    )
  }

  // const renderSyncStatus = () => {
  //   if (form && !_.isEmpty(form.syncRequests)) {
  //     return (
  //       <Text color={'gray.300'}>
  //         <i>{'Updating...'}</i>
  //       </Text>
  //     )
  //   } else if (form && form.vaPreviewSyncedAt) {
  //     return (
  //       <Text color={'gray.300'} fontSize='xs'>
  //         Updated {moment(form.vaPreviewSyncedAt).fromNow()}
  //       </Text>
  //     )
  //   }
  // }

  // const copyLinkToClipboard = () => {
  //   logFormLinkCopied(formId)
  //   onCopy()
  // }

  // const renderButtons = () => {
  //   return (
  //     <HStack flexShrink={0} spacing={4}>
  //       <Box>{renderSyncStatus()}</Box>
  //       {/* <Button
  //           variant='outline'
  //           size='md'
  //           leftIcon={<FontAwesomeIcon icon={faExternalLink} />}
  //           onClick={onPreviewClick}
  //           isDisabled={
  //             !_.has(form, 'previewUrl') ||
  //             (form && !_.isEmpty(form.syncRequests))
  //           }
  //           isLoading={form && !_.isEmpty(form.syncRequests)}
  //         >
  //           Preview
  //         </Button> */}
  //       <Tooltip
  //         label='Invite candidates to this form'
  //         shouldWrapChildren
  //         hasArrow
  //         closeDelay={1000}
  //       >
  //         <ShareModal formId={formId} />
  //       </Tooltip>
  //       <Tooltip
  //         label={hasCopied ? 'Link copied!' : 'Copy link'}
  //         shouldWrapChildren
  //         hasArrow
  //         closeDelay={1000}
  //         closeOnClick={false}
  //       >
  //         <Button
  //           size='lg'
  //           variant='ghost'
  //           colorScheme='gray'
  //           onClick={copyLinkToClipboard}
  //           className='copy-link-button'
  //           isDisabled={questionsAmount === 0}
  //         >
  //           <FontAwesomeIcon icon={faLink} />
  //         </Button>
  //       </Tooltip>
  //       {renderMenu()}
  //     </HStack>
  //   )
  // }

  const renderModeButton = () => {
    return (
      <TabList borderBottomWidth={0}>
        <Tab
          color='gray.500'
          isDisabled={responsesAmount === 0 && questionsAmount === 0}
          className='responses-tab'
          px={2}
        >
          <HStack fontSize='xs'>
            <FontAwesomeIcon icon={faInboxIn} />
            <Text fontWeight={'medium'}>Responses</Text>
            <Text fontWeight={'normal'}>{responsesAmount}</Text>
          </HStack>
        </Tab>
        <Tab color='gray.500' className='questions-tab' px={2}>
          <HStack fontSize='xs'>
            <FontAwesomeIcon icon={faPersonCircleQuestion} />
            <Text fontWeight={'medium'}>Questions</Text>
            <Text fontWeight={'normal'}>{questionsAmount}</Text>
          </HStack>
        </Tab>
      </TabList>
    )
    // if (isEdit) {
    //   return (
    //     <Button
    //       variant='outline'
    //       color='gray.600'
    //       fontWeight='medium'
    //       onClick={() => navigate(`/form/${formId}`)}
    //     >
    //       <HStack spacing={2}>
    //         <Text>Responses</Text>
    //         <Text as='span' color='gray.400'>
    //           {responsesAmount}
    //         </Text>
    //       </HStack>
    //     </Button>
    //   )
    // } else {
    //   return (
    //     <Button
    //       variant='outline'
    //       color='gray.600'
    //       fontWeight='medium'
    //       onClick={() => navigate(`/form/${formId}/edit`)}
    //     >
    //       <HStack spacing={2}>
    //         <Text>Questions</Text>
    //         <Text as='span' color='gray.400'>
    //           {questionsAmount}
    //         </Text>
    //       </HStack>
    //     </Button>
    //   )
    // }
  }

  return (
    <HStack px='4' w='full' align='center' justify='space-between'>
      <VStack spacing={0} align='flex-start' w='full' pt={0}>
        <HStack w='full' justify={'space-between'} spacing={6}>
          <Textarea
            name='formname'
            role='presentation'
            // type='text'
            ref={labelRef}
            variant='unstyled'
            autoComplete='off'
            value={_label}
            onChange={onChange}
            placeholder='Name your video form'
            fontSize='lg'
            fontWeight={'bold'}
            w='100%'
            rows={1}
            resize='none'
            // maxLength={46}
            wrap='hard'
            h='auto'
            color='gray.800'
            _placeholder={{
              color: error ? 'red.300' : 'gray.300',
              fontSize: 'lg'
            }}
            isInvalid={error}
            onBlur={saveLabel}
            onFocus={() => setError(false)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault()
                labelRef.current?.blur()
              }
            }}
            // noOfLines={2}
          />

          <Button
            onClick={() => navigate(`/form/${formId}/share`)}
            variant='solid'
            size='sm'
            colorScheme='blue'
            leftIcon={<FontAwesomeIcon icon={faShare} size='xs' />}
            flexShrink={0}
          >
            Share
          </Button>
        </HStack>
        <HStack w='full' justify='space-between'>
          {renderModeButton()}
          {renderMenu()}
        </HStack>
      </VStack>
    </HStack>
  )
}

export default EditorNavbarMobile
