import {
  HStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

type OptionT = {
  value: string
  title: string
}

type Props = {
  title: string
  options: OptionT[]
  value?: string
  onChange: (v: string) => void
  placeholder?: string
}

const SelectOption: FC<Props> = ({
  title,
  value,
  onChange,
  options,
  placeholder
}) => {
  const activeOption = useMemo(() => {
    return _.find(options, opt => opt.value === value)
  }, [options, value])

  return (
    <HStack w='full' justify={'space-between'}>
      <Box flex={1} w='full'>
        <Text fontSize={'lg'} color='gray.700'>
          {title}
        </Text>
      </Box>
      <Box>
        <Menu>
          <MenuButton>
            <HStack color='gray.400'>
              <Text fontSize={'lg'} color='gray.400'>
                {activeOption ? activeOption.title : placeholder || '-'}
              </Text>
              <FontAwesomeIcon icon={faChevronRight} />
            </HStack>
          </MenuButton>
          <MenuList>
            {_.map(options, ({ title, value }) => (
              <MenuItem key={value} onClick={() => onChange(value)}>
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  )
}

export default SelectOption
