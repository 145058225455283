import { useMemo, useEffect, useState } from 'react'
import { HStack, VStack, Text, Button } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faTrash,
  faArrowRight
} from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import Title from 'modals/questionsAndCFQ/Title'
import { DictT, CFQT, AtLeast, QuestionT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import AutoCompleteComponent from 'components/AutoComplete'
import { getAllQuestions } from 'model/selectors/questions'
import { fetchAIConf } from 'controllers/settings'
import { callOpenaiChat } from 'controllers/admin'

const CFQs = ({
  questions,
  cfqs,
  addCfq,
  removeCfq,
  updateCfq
}: {
  questions: QuestionT[],
  cfqs: DictT<AtLeast<CFQT, 'id'>>
  addCfq: () => void
  removeCfq: (id?: string) => void
  updateCfq: (id: string, cfq: Partial<CFQT>) => void
}) => {
  const dbCfqs = useSelector(state => state.cfqs)
  const dbQuestions = useSelector(getAllQuestions)
  const sortedCfqs = useMemo(() => {
    return _.sortBy(_.map(cfqs, (c, cId) => ({ ...c, id: c.id || cId })), 'createdAt')
  }, [cfqs])
  const sortedCfqsDesc = useMemo(() => {
    return _.orderBy(dbCfqs, 'createdAt', 'desc')
  }, [dbCfqs])
  const recentlyUsed = _.slice(sortedCfqsDesc, 0, 5)
  const aiConf = useSelector(state => state.aiConf)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchAIConf()
  }, [])

  const getCfqsSuggestions = async (qs: QuestionT[]) => {
    try {
      const r: string[] = []
      _.forEach(qs, (q, i) => {
        r.push(`Question ${i + 1}: ${q.text}`)
      })
      const interview = _.join(r, '/n')
      const message = [
        { role: 'user', content: _.replace(_.get(aiConf, 'CFQSuggestions.prompt'), '$INTERVIEW', interview) }
      ]
      const resApi = await callOpenaiChat(
        message,
        'gpt-3.5-turbo',
        0,
        1024
      )
      const resM = _.get(resApi, ['choices', 0, 'message', 'content'])
      const res = JSON.parse(resM)
      return res
    } catch (e) {
      console.log('getCfqsSuggestions error', e)
      return null
    }
  }

  useEffect(() => {
    const run = async () => {
      setLoading(true)
      const v = await getCfqsSuggestions(questions)
      if (!_.isNil(v)) {
        setSuggestions(v)
      }
      setLoading(false)
    }
    if (_.size(questions) > 0 && !loading && !_.isNil(aiConf)) {
      run()
    }
  }, [questions, aiConf])

  const onSelectCondition = (id: string, value: string) => {
    const cfq = _.find(dbCfqs, v => v.condition === value)
    const upd: { [x: string]: string | undefined } = { condition: value }
    if (!_.get(cfqs, [id, 'question'])) {
      upd.question = _.get(cfq, 'question', '')
      upd.questionId = _.get(cfq, 'questionId')
    }
    updateCfq(id, { ..._.get(cfqs, id), ...upd })
  }

  const onSelectQuestion = (id: string, value: string) => {
    const question = _.find(dbQuestions, v => v.text === value)
    const upd: { [x: string]: string | undefined } = {
      question: value,
      questionId: _.get(question, 'id')
    }
    updateCfq(id, { ..._.get(cfqs, id), ...upd })
  }

  const prepDataForAutoComplete = (field: string): { [key: string]: string[] } | null => {
    if (field === 'condition') {
      return {
        'recently used': _.uniq(_.map(recentlyUsed, v => _.get(v, field, ''))),
        suggested: suggestions
      }
    } else if (field === 'question') {
      return {
        questions: _.uniq(_.map(dbQuestions, q => q.text || ''))
      }
    } else {
      return null
    }
  }

  return (
    <>
      <Title
        title='Conditional followup questions'
        description='These logic based questions will only be asked if they are not covered by the candidates answers to the questions above'
        onAdd={addCfq}
      />
      <VStack justify='start' align='center' w='full' position='relative'>
        {_.map(sortedCfqs, cfq => {
          return (
            <HStack
              key={cfq.id}
              w='full'
              px='8'
              py='4'
              borderBottom='1px'
              borderColor='gray.100'
              align='center'
              justify='space-between'
              sx={{
                '> button': {
                  color: 'transparent'
                },
                ':hover': {
                  '> button': {
                    color: 'black'
                  }
                }
              }}
            >
              <HStack
                w='full'
                justify='space-between'
                align='center'
                spacing='10'
              >
                <AutoCompleteComponent
                  autocompleteData={prepDataForAutoComplete('condition')}
                  value={cfq.condition || ''}
                  onChange={e => {
                    e.preventDefault()
                    onSelectCondition(cfq.id, e.target.value)
                  }}
                  disabled={_.has(dbCfqs, cfq.id)}
                  onSelect={v => {
                    onSelectCondition(cfq.id, v)
                  }}
                  hideOnType
                  loading={loading}
                />
                <HStack>
                  <Text whiteSpace='nowrap' color='gray.300' fontSize='sm'>
                    if not answered, ask
                  </Text>
                  <FontAwesomeIcon icon={faArrowRight} color='#CBD5E0' />
                </HStack>
                <AutoCompleteComponent
                  autocompleteData={prepDataForAutoComplete('question')}
                  value={cfq.question || ''}
                  onChange={e => {
                    e.preventDefault()
                    onSelectQuestion(cfq.id, e.target.value)
                  }}
                  disabled={_.has(dbCfqs, cfq.id)}
                  onSelect={v => {
                    onSelectQuestion(cfq.id, v)
                  }}
                />
              </HStack>
              <Button variant='ghost' onClick={() => removeCfq(cfq.id)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </HStack>
          )
        })}
        <HStack w='full' py='2'>
          <Button
            variant='ghost'
            size='sm'
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={addCfq}
          >
            Add conditional followup
          </Button>
        </HStack>
      </VStack>
    </>
  )
}

export default CFQs
