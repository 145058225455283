import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState,
  useEffect,
  useMemo
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  HStack,
  ModalFooter,
  Box,
  Button,
  Flex,
  VStack
} from '@chakra-ui/react'
import TemplatesSideBar from 'components/templates/TemplatesSideBar'
import TemplateQuestions from 'components/templates/TemplateQuestions'
import { useSelector } from 'model/hooks'
import TemplateQuestionPreview from 'components/templates/TemplateQuestionPreview'
import { getTemplatesBySection } from 'model/selectors/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBorderAll, faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { TemplateT, QuestionT, DictT } from 'shared/types/model'

export interface ITemplatesModal {
  open: (formId?: string) => void
}

type Props = {
  toStartFromScratch: () => void
  onUseTemplate: (template: TemplateT) => void
}

const TemplatesModal: ForwardRefRenderFunction<ITemplatesModal, Props> = (
  { toStartFromScratch, onUseTemplate }: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentTemplateId, setCurrentTemplateId] = useState<string>()
  const [currentQuestionId, setCurrentQuestionId] = useState<string>()
  const [formId, setFormId] = useState<string>()
  const templates = useSelector(state => state.templates)
  const templatesSections = useSelector(state => state.templatesSections)
  const templatesBySectionId = useSelector(getTemplatesBySection)
  const questionsDict: DictT<QuestionT> = useSelector(
    state => state.questionsLibrary
  )

  useImperativeHandle(ref, () => ({
    open: (formId: string) => {
      setFormId(formId)
      onOpen()
    }
  }))

  const currentQuestion = useMemo(
    () => (currentQuestionId ? questionsDict[currentQuestionId] : undefined),
    [questionsDict, currentQuestionId]
  )

  const t: TemplateT | undefined = useMemo(() => {
    if (templates && currentTemplateId) {
      return templates[currentTemplateId]
    }
  }, [templates, currentTemplateId])

  useEffect(() => {
    const needSwitchTemplate = _.isEmpty(currentTemplateId) || !t
    const needResetQuestion =
      currentQuestionId && t && !_.includes(t.questions, currentQuestionId)
    if (needResetQuestion) {
      setCurrentQuestionId(undefined)
    }
    if (needSwitchTemplate) {
      _.forEach(templatesSections.order, sectionId => {
        const templates = templatesBySectionId[sectionId]
        if (templates) {
          setCurrentTemplateId(templates[0].id)
          setCurrentQuestionId(undefined)
          return false
        }
      })
    }
  }, [t, currentTemplateId, currentQuestionId, templatesSections, templates])

  const onTemplateClick = (templateId: string) => {
    if (t) {
      setCurrentQuestionId(_.get(t, ['questoins', 0]))
      setCurrentTemplateId(templateId)
    }
  }

  const onQuestionClick = (questionId: string) => {
    setCurrentQuestionId(questionId)
  }

  const onDone = () => {
    console.log('onDone')
    if (templates && currentTemplateId && formId) {
      const t = templates[currentTemplateId]
      if (t && t.questions) {
        onUseTemplate(t)
        onClose()
      }
    }
    console.warn(
      'something went wrong',
      templates && currentTemplateId && formId
    )
  }

  const onStartFromScratchClick = () => {
    onClose()
    toStartFromScratch()
  }

  const openTemplatePreview = () => {
    if (t && t.previewUrl) {
      window.open(
        `${process.env.REACT_APP_VIEW_FORM_URL}/${t.id}?preview`,
        '_blank'
      )
    }
  }

  const footer = (
    <ModalFooter
      borderTop='1px'
      borderColor='gray.100'
      justifyContent='space-between'
    >
      <Box>
        <Button
          variant='ghost'
          leftIcon={<FontAwesomeIcon icon={faExternalLink} />}
          color='gray.700'
          isDisabled={!t || !t.previewUrl}
          onClick={openTemplatePreview}
        >
          Preview
        </Button>
      </Box>
      <Flex gap='4' align='center'>
        <Button
          variant='outline'
          onClick={onStartFromScratchClick}
          leftIcon={<FontAwesomeIcon icon={faBorderAll} />}
          color='gray.700'
          fontSize='md'
          lineHeight='6'
          fontWeight='semibold'
        >
          Start from Scratch
        </Button>
        <Button
          size='lg'
          lineHeight='7'
          fontWeight='semibold'
          colorScheme='blue'
          mr={5}
          onClick={onDone}
        >
          Use template
        </Button>
      </Flex>
    </ModalFooter>
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent h='80%' maxH='800px'>
        <ModalBody w='full' h='full' p='0' overflow={'hidden'}>
          <HStack spacing={0} w='full' h='full' overflow={'hidden'}>
            <TemplatesSideBar
              onTemplateClick={onTemplateClick}
              currentTemplateId={currentTemplateId}
            />
            <TemplateQuestions
              templateId={currentTemplateId}
              onQuestionClick={onQuestionClick}
            />
            <VStack
              flex={1}
              bg='gray.50'
              h='full'
              borderTopRightRadius={'md'}
              flexShrink={0}
              flexGrow={1}
            >
              <TemplateQuestionPreview
                text={currentQuestion?.text}
                mediaUrl={currentQuestion?.mediaUrl}
                hideAlert
              />
            </VStack>
          </HStack>
        </ModalBody>
        {footer}
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(TemplatesModal)
