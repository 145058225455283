import * as types from 'model/actionTypes'
import { DictT, UserT } from 'shared/types/model'

const initialState: DictT<UserT> | null = null

export default function userAccounts (
  state = initialState,
  action: {
    type: string
    userAccounts: typeof initialState
  }): typeof initialState | UserT {
  switch (action.type) {
    case types.RECEIVE_USER_ACCOUNTS:
      return action.userAccounts
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
