import { useState, useMemo, FC, useEffect } from 'react'
import {
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  VStack,
  HStack,
  Box,
  Wrap,
  WrapItem,
  IconButton,
  Divider
} from '@chakra-ui/react'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'
import { FormT, QuestionT } from 'shared/types/model'
import SingleSelect, { OptionT } from 'components/SingleSelect'
import { dbAddQuestion, dbCreateForm, dbUpdateForm } from 'controllers/forms'
import { getAllQuestions } from 'model/selectors/questions'
import { arrayUnion } from 'firebase/firestore'
import VideoPlayer from 'components/VideoPlayer'
import Card from 'components/card/Card'
import {
  faArrowDown,
  faArrowUp,
  faPlus
} from '@fortawesome/pro-regular-svg-icons'
import QuestionsFilterTags from 'components/QuestionsFilterTags'
import {
  logFormAddQuestion,
  logFormQuestionRemoved
} from 'controllers/analytics'
import { dbUpdateUser } from 'controllers/user'

type Props = {
  onClose: () => void
  formId: string
  toCreateQuestion: () => void
  replacedQuestionId?: string
  handleContactFormClick: () => void
}

const SelectQuestion: FC<Props> = ({
  onClose,
  formId,
  toCreateQuestion,
  replacedQuestionId = '',
  handleContactFormClick
}) => {
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(
    null
  )
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const allQuestions = useSelector(getAllQuestions)
  const [sortByField, setSortByField] = useState('createdAt')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const form: FormT | undefined = useSelector(state =>
    formId && state.forms ? state.forms[formId] : undefined
  )
  const templates = useSelector(state => state.templates)
  const user = useSelector(state => state.user)

  useEffect(() => {
    if (form && form.templateId && templates) {
      const t = templates[form.templateId]
      if (t) {
        const tags = t.tags || []
        setSelectedTags(tags)
      }
    }
  }, [form, templates])

  const questions = useMemo(() => {
    let res = _.filter(allQuestions, (q: QuestionT) => {
      const filtersPassed =
        _.isEmpty(selectedTags) ||
        (!_.isEmpty(q.tags) &&
          _.size(_.intersection(q.tags, selectedTags)) === _.size(selectedTags))
      return !q.deleted && filtersPassed
    })
    if (form && form.questions && !_.isEmpty(form.questions)) {
      res = _.filter(res, q => !_.includes(form.questions, q.id))
    }
    return _.orderBy(res, [sortByField], [order as 'asc' | 'desc'])
  }, [form, allQuestions, sortByField, order, selectedTags])

  const handleClick = (id: string | null) => {
    if (id === currentQuestionId) {
      setCurrentQuestionId(null)
    } else {
      setCurrentQuestionId(id)
    }
  }

  const handleCreateQuestion = () => {
    handleHeapAction()
    toCreateQuestion()
  }

  const handleHeapAction = async () => {
    const isLocalHost =
    location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    if (!isLocalHost) {
      const userId = _.get(user, 'id')
      const heap = _.get(window, 'heap')
      const createQuestionAttempts = _.get(user, 'heap.createQuestionAttempts', 0)
      const newCreateQuestionAttempts = createQuestionAttempts + 1

      if (!_.isNil(userId)) {
        await dbUpdateUser(userId, {
          heap: {
            ..._.get(user, 'heap'),
            createQuestionAttempts: newCreateQuestionAttempts
          }
        })
      }
      if (!_.isNil(heap)) {
        heap.addUserProperties({
          createQuestionAttempts: newCreateQuestionAttempts
        })
      }
    }
  }

  const currentQuestion = currentQuestionId
    ? _.get(allQuestions, currentQuestionId, null)
    : null

  const onDone = async () => {
    if (replacedQuestionId && currentQuestionId && form) {
      const newQuestions = form?.questions ?? []
      const index = newQuestions.indexOf(replacedQuestionId)
      newQuestions.splice(index, 1, currentQuestionId)
      onClose()

      const upd: Partial<FormT> = {
        questions: newQuestions,
        syncRequests: arrayUnion(_.now())
      }

      if (
        form &&
        form.contactFormQuestionId &&
        form.contactFormQuestionId === replacedQuestionId
      ) {
        upd.contactFormQuestionId = currentQuestionId
      }
      logFormQuestionRemoved(replacedQuestionId, formId, form?.templateId)
      logFormAddQuestion(currentQuestionId, formId, form?.templateId)
      return dbUpdateForm(form.id, upd)
    } else if (currentQuestionId) {
      onClose()
      if (_.isEmpty(form)) {
        await dbCreateForm(formId)
      }
      await dbAddQuestion(currentQuestionId, formId)
      logFormAddQuestion(currentQuestionId, formId, form?.templateId)
    }
  }

  const changeOrder = () => {
    setOrder(order => (order === 'asc' ? 'desc' : 'asc'))
  }

  const renderHeader = () => {
    const sortByOptions: OptionT[] = [
      { value: 'createdAt', label: 'Date created' },
      { value: 'lastUsedAt', label: 'Last used' },
      { value: 'length', label: 'Length' },
      { value: 'createdBy', label: 'Created by' }
    ]
    return (
      <HStack pr='6' w='full' justify='space-between' flexGrow={0}>
        <HStack align='center' spacing={0} justify='flex-start' pl={2}>
          <IconButton
            variant={'unstyled'}
            color='gray.400'
            aria-label='sorting order'
            icon={
              <FontAwesomeIcon
                icon={order === 'asc' ? faArrowUp : faArrowDown}
                onClick={changeOrder}
              />
            }
          />
          <Box w={40}>
            <SingleSelect
              size='sm'
              options={sortByOptions}
              value={sortByField}
              onSelect={v => setSortByField(v)}
              chakraStyles={{
                singleValue: provided => ({
                  ...provided,
                  color: 'blackAlpha.600'
                }),
                downChevron: provided => ({
                  ...provided,
                  color: 'gray.400'
                }),
                control: provided => ({
                  ...provided,
                  borderColor: 'gray.200',
                  rounded: 'md'
                })
              }}
            />
          </Box>
          <HStack pl={4} spacing={4} zIndex={102}>
            <Divider h={6} orientation='vertical' color={'gray.50'} />
            <QuestionsFilterTags
              selectedTags={selectedTags}
              setSelectedTags={tagsIds => setSelectedTags(tagsIds)}
            />
          </HStack>
        </HStack>
        <Button
          variant='outline'
          size='lg'
          fontSize='lg'
          lineHeight='7'
          fontWeight='semibold'
          color='gray.500'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          colorScheme='gray'
          onClick={handleCreateQuestion}
          className='create-question-button'
        >
          Create question
        </Button>
      </HStack>
    )
  }

  const renderQuestions = () => {
    return (
      <Wrap
        w='100%'
        maxH='682px'
        overflowY='auto'
        pt={4}
        px='5'
        spacing='6'
        sx={{
          '::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {_.map(questions, (q: QuestionT, index: number) => {
          return (
            <WrapItem
              key={q.id}
              display='flex'
              borderRadius='lg'
              borderWidth={3}
              borderColor={
                q.id === currentQuestionId ? 'blue.400' : 'transparent'
              }
              className={`modal-question-${index}`}
            >
              <Card
                onPlay={() => handleClick(q.id)}
                name={q.text}
                thumbnail={q.thumbnail}
                hideButtons
                isSelected={q.id === currentQuestionId}
                canSelect
                tags={q.tags}
              />
            </WrapItem>
          )
        })}
      </Wrap>
    )
  }

  const onAddContactFromClick = () => {
    handleContactFormClick()
    onClose()
  }

  const footer = (
    <ModalFooter
      px='19'
      py='16px'
      bg='white'
      justifyContent='space-between'
      borderTop='2px solid'
      borderColor='gray.100'
      borderBottomRadius={'md'}
    >
      <Button
        fontSize='md'
        lineHeight='6'
        fontWeight='semibold'
        color='gray.700'
        variant='outline'
        visibility={
          form && !_.isNil(form.contactFormQuestionId) ? 'hidden' : 'visible'
        }
        leftIcon={<FontAwesomeIcon icon={faUserPlus} size='sm' />}
        isDisabled={form && !_.isNil(form.contactFormQuestionId)}
        onClick={onAddContactFromClick}
      >
        Place Contact Form
      </Button>
      <Flex gap='4' alignItems='center'>
        <Button
          fontSize='md'
          lineHeight='6'
          fontWeight='semibold'
          color='gray.700'
          variant='ghost'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          fontSize='lg'
          size='lg'
          lineHeight='7'
          fontWeight='semibold'
          colorScheme='blue'
          disabled={_.isNil(currentQuestionId)}
          onClick={onDone}
          className='select-question-done'
        >
          {replacedQuestionId ? 'Replace question' : 'Add question'}
        </Button>
      </Flex>
    </ModalFooter>
  )

  const renderMedaiPreview = () => {
    if (currentQuestion && currentQuestion.mediaUrl) {
      return (
        <Box w='full' h='full' position={'relative'}>
          <VideoPlayer
            mediaUrl={currentQuestion.mediaUrl}
            key={currentQuestion.mediaUrl}
          />
        </Box>
      )
    } else {
      return (
        <Box
          w='full'
          h='full'
          border='1px solid'
          borderStyle='dashed'
          borderRadius='10'
          borderColor='gray.300'
        />
      )
    }
  }

  return (
    <>
      <ModalBody p='0' w='full' h='full%'>
        <Flex direction='row' w='full' h='full' borderRadius={'md'}>
          <VStack
            bg='white'
            w='full'
            h='full'
            pt='4'
            borderRight='2px solid'
            borderColor='gray.100'
            borderTopLeftRadius={'md'}
          >
            {renderHeader()}
            {renderQuestions()}
          </VStack>
          <Flex
            bg='gray.50'
            w={'48%'}
            h='full'
            px={10}
            pt={90}
            pb={90}
            borderTopRightRadius={'md'}
          >
            {renderMedaiPreview()}
          </Flex>
        </Flex>
      </ModalBody>
      {footer}
    </>
  )
}

export default SelectQuestion
