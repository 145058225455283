import { VStack, HStack } from '@chakra-ui/react'
import NavBarMobile from 'components/NavBarMobile'
import ShareModalContent from 'modals/shareModal/ShareModalContent'
import { useNavigate, useParams } from 'react-router-dom'
import BackButtonMobile from 'components/BackButtonMobile'

const ShareForm = () => {
  const navigate = useNavigate()
  const { formId } = useParams()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <VStack align={'flex-start'} spacing={0} w='full' h='full' bg='white'>
      <NavBarMobile />
      {formId && (
        <VStack
          w='full'
          h='full'
          bg='gray.50'
          spacing={0}
          overflowX='hidden'
          overflowY={'auto'}
        >
          <HStack w='full' p={4}>
            <BackButtonMobile onClick={goBack} />
          </HStack>
          <ShareModalContent onClose={goBack} formId={formId} />
        </VStack>
      )}
    </VStack>
  )
}

export default ShareForm
