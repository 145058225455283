import { useRef } from 'react'
import { VStack, HStack, Heading, Box, Text } from '@chakra-ui/react'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import { getTemplatesBySection } from 'model/selectors/templates'
import {
  TemplateSectionsT,
  TemplateSectionT,
  TemplateT,
  FormT
} from 'shared/types/model'
import TemplateRow from 'pages/form/selectTemplateMobile/TemplateRow'
import ViewTemplateMobileModal, {
  IViewTemplateMobileModal
} from 'modals/ViewTemplateMobileModal'
import { arrayUnion } from 'firebase/firestore'
import { dbUpdateForm } from 'controllers/forms'
import { logFormTemplateSelected } from 'controllers/analytics'
import BackButtonMobile from 'components/BackButtonMobile'

const SelectTemplateMobile = () => {
  const { formId } = useParams() as { formId: string }
  const navigate = useNavigate()
  const templatesBySectionId = useSelector(getTemplatesBySection)
  const templatesSections: TemplateSectionsT = useSelector(
    state => state.templatesSections
  )
  const allTemplates = useSelector(state => state.templates)
  const canEdit = false
  const viewTemplateRef = useRef<IViewTemplateMobileModal>(null)
  const form: FormT | undefined = useSelector(state =>
    _.get(state, ['forms', formId as string])
  )

  const onUseTemplate = async (t: TemplateT) => {
    const upd: Partial<FormT> = {
      questions: t.questions,
      syncRequests: arrayUnion(_.now()),
      templateId: t.id
    }
    if (t.contactFormQuestionId) {
      upd.contactFormQuestionId = t.contactFormQuestionId
    }
    const currentLabel = form?.label
    if (_.isEmpty(currentLabel)) {
      upd.label = t.title
    }
    await dbUpdateForm(formId, upd)
    logFormTemplateSelected(t.id)
    navigate(`/form/${formId}/edit`)
  }

  const renderTopBar = () => {
    return (
      <HStack py={4} justify='space-between' w='full' px={4}>
        <Box flex={1}>
          <BackButtonMobile onClick={() => navigate(`/form/${formId}/edit`)} />
        </Box>
        <Heading size='sm' color='gray.800'>
          Select a template
        </Heading>
        <Box flex={1} />
      </HStack>
    )
  }

  const renderSection = (sectionId: string) => {
    const section: TemplateSectionT = templatesSections.sections[sectionId]
    if (!allTemplates || !section) return null
    return (
      <VStack
        key={sectionId}
        mt='0'
        align={'flex-start'}
        justify={'flex-start'}
        w='full'
        spacing={2}
      >
        <Text
          fontSize='xs'
          color='gray.500'
          fontWeight='medium'
          letterSpacing='wider'
          textAlign={'left'}
          pl={2}
          pb={2}
        >
          {_.upperCase(section.label || '')}
        </Text>
        {_.map(section.templatesOrder, (tId: string) => {
          const t = allTemplates[tId]
          if (!t.enabled && !canEdit) return null
          return (
            <TemplateRow
              t={t}
              section={section}
              onPlayClick={(tId, index) =>
                viewTemplateRef.current?.open(tId, index)
              }
              onUseTemplateClick={() => onUseTemplate(t)}
            />
          )
        })}
      </VStack>
    )
  }

  const renderTemplates = () => {
    // const amount = _.size(templatesSections.order)
    return (
      <VStack
        w='full'
        px={2}
        h='full'
        overflowX={'hidden'}
        overflowY='auto'
        spacing={4}
      >
        {_.map(templatesSections.order, sectionId => {
          const templates = _.get(templatesBySectionId, sectionId, [])
          if (!canEdit && _.isEmpty(templates)) {
            return null
          } else {
            // return renderSection(sectionId, index, amount)
            return renderSection(sectionId)
          }
        })}
      </VStack>
    )
  }

  return (
    <VStack h='full' w='full' bg='gray.50' overflow={'hidden'} pb={4}>
      {renderTopBar()}
      {renderTemplates()}
      <ViewTemplateMobileModal ref={viewTemplateRef} />
    </VStack>
  )
}

export default SelectTemplateMobile
