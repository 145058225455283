import { FC, useContext, useEffect, useMemo } from 'react'
import {
  Flex,
  Text,
  Image,
  Button,
  // HStack,
  VStack,
  // Box,
  // Hide,
  Tooltip,
  useBreakpointValue,
  Show,
  Skeleton,
  StackDivider
} from '@chakra-ui/react'
import _ from 'lodash'
import { faList, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import ResponsesPanel from 'pages/form/ResponsesPanel'
// import Response from 'pages/form/Response'
import NoResponsesImage from 'assets/no_responses.png'
// import ResponsesNavBarMobile from 'pages/responses/ResponsesNavBarMobile'
import { generateId } from 'controllers/db'
import { logNewFormClicked } from 'controllers/analytics'
import { useNavigate, useParams } from 'react-router-dom'
import { ResponseT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import { getAllResponses } from 'model/selectors/responses'
import { arrayUnion } from 'firebase/firestore'
import { dbCreateForm } from 'controllers/forms'
// import { dbDeleteResponse } from 'controllers/responses'
// import ContactFilter from 'pages/responses/filters/ContactFilter'
// import FormFilter from 'pages/responses/filters/FormFilter'
import { getBlockedResponses } from 'model/selectors/paymentPlans'
// import NavBarMobile from 'components/NavBarMobile'
import DrawerContext from 'contexts/DrawerContext'
import ResponsesTableHeader from 'components/ResponsesTableHeader'
import ResponseRow from 'components/ResponseRow'

const Responses: FC<{}> = () => {
  const navigate = useNavigate()
  const { responseId } = useParams()
  const { openDrawer } = useContext(DrawerContext)
  const user = useSelector(state => state.user)
  const forms = useSelector(state => state.forms)
  const blockedResponses = useSelector(getBlockedResponses)
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const templates = useSelector(state => state.templates)
  const formsNames = useSelector(state => _.map(state.forms, f => f.label))
  const responses: ResponseT[] = useSelector(getAllResponses)
  const allResponses = useSelector(state => state.responses)
  // const [selectedContacts, setSelectedContacts] = useState<
  //   Record<string, boolean>
  // >({})
  // const [selectedForms, setSelectedForms] = useState<Record<string, boolean>>(
  //   {}
  // )
  // const [filterVisible, setFilterVisible] = useState<
  //   'forms' | 'contacts' | null
  // >(null)

  useEffect(() => {
    if (isDesktop) {
      const responseIsNotSelected = responses && _.isNil(responseId)
      const responseIsBlocked =
        responses && !_.isNil(responseId) && _.has(blockedResponses, responseId)
      const responseDoesNotExist =
        responses && !_.isNil(responseId) && !_.has(allResponses, responseId)
      if (responseIsNotSelected || responseDoesNotExist || responseIsBlocked) {
        const r = _.find(responses, r => !_.has(blockedResponses, r.id))
        if (r) {
          navigate(`/responses/${r.id}`)
        } else {
          navigate('/responses')
        }
      }
    }
  }, [responses, responseId, isDesktop, blockedResponses])

  const showSkeleton = useMemo(() => {
    return (
      _.isEmpty(allResponses) &&
      _.has(user, 'onboarding.contactId') &&
      !isDesktop
    )
  }, [user, allResponses, isDesktop])

  // const showFirstFormBanner = useMemo(() => {
  //   const onlyOnboardingForm =
  //     _.has(user, 'onboarding.industry.templateId') &&
  //     _.size(forms) === 1 &&
  //     _.get(_.first(_.values(forms)), 'templateId') ===
  //       _.get(user, 'onboarding.industry.templateId')
  //   return !isDesktop && (onlyOnboardingForm || _.isEmpty(forms))
  // }, [forms, user, isDesktop])

  // const filteredResponses = useMemo(() => {
  //   if (_.isEmpty(selectedContacts) && _.isEmpty(selectedForms)) {
  //     return responses
  //   } else {
  //     return _.filter(responses, r => {
  //       const matchesContactsFilter =
  //         !_.isEmpty(selectedContacts) && _.has(selectedContacts, r.email)
  //       const matchesFormsFilter =
  //         !_.isEmpty(selectedForms) && _.has(selectedForms, r.formId)
  //       return matchesContactsFilter || matchesFormsFilter
  //     })
  //   }
  // }, [responses, selectedContacts, selectedForms])

  useEffect(() => {
    window.addEventListener('message', onStonlyAction)

    return () => {
      window.removeEventListener('message', onStonlyAction)
    }
  }, [])

  const templatesIdsByLabel = useMemo(() => {
    const res = {}
    _.forEach(templates, t => {
      _.set(res, _.kebabCase(t.title), t.id)
    })
    return res
  }, [templates])

  // FIXME:
  const onStonlyAction = (event: any) => {
    const v = event.data.value

    if (_.has(templatesIdsByLabel, v)) {
      const templateId = _.get(templatesIdsByLabel, v)
      const template = _.get(templates, templateId)
      const existed = _.includes(formsNames, _.get(template, 'title'))
      if (!existed) {
        const id = generateId()
        const form = {
          label: _.get(template, 'title'),
          questions: _.get(template, 'questions'),
          syncRequests: arrayUnion(_.now())
        }
        dbCreateForm(id, form)
      }
    }
  }

  // const onDeleteResponse = (responseId: string) => {
  //   console.log('onDeleteResponse', responseId)
  //   dbDeleteResponse(responseId)
  // }

  // const onViewContact = (responseId: string) => {
  //   if (allResponses) {
  //     const r = allResponses[responseId]
  //     if (r && !_.isEmpty(r.email)) {
  //       navigate(`/contacts/${r.email}`)
  //     }
  //   }
  // }

  const onResponseClick = (rId: string) => {
    console.log('onResponseClick', rId)
    if (responseId !== rId) {
      navigate(`/responses/${rId}`)
    }
  }

  const onNewVideoFormClick = () => {
    const formId = generateId()
    logNewFormClicked(formId)
    navigate(`/form/${formId}/edit`)
  }

  // const renderResponsesList = () => {
  //   if (isDesktop || _.isNil(responseId)) {
  //     switch (filterVisible) {
  //       case 'contacts': {
  //         return (
  //           <ContactFilter
  //             selectedContacts={selectedContacts}
  //             setSelectedContacts={setSelectedContacts}
  //             onClose={() => setFilterVisible(null)}
  //           />
  //         )
  //       }
  //       case 'forms': {
  //         return (
  //           <FormFilter
  //             selectedForms={selectedForms}
  //             setSelectedForms={setSelectedForms}
  //             onClose={() => setFilterVisible(null)}
  //           />
  //         )
  //       }
  //       default: {
  //         return (
  //           <VStack
  //             w={{ base: 'full', lg: 'sm' }}
  //             align='flex-start'
  //             spacing={0}
  //             h='full'
  //             flexShrink={0}
  //           >
  //             <Show above='lg'>
  //               <Box pl='4' pt={4}>
  //                 <Text
  //                   fontWeight='semibold'
  //                   fontSize='xs'
  //                   textTransform='uppercase'
  //                   lineHeight='16px'
  //                   color='gray.500'
  //                 >
  //                   All interactions
  //                 </Text>
  //               </Box>
  //             </Show>
  //             <Flex
  //               flex={1}
  //               h='full'
  //               w={{ base: 'full', lg: 'sm' }}
  //               overflow={'hidden'}
  //             >
  //               <ResponsesPanel
  //                 responses={filteredResponses}
  //                 onClick={onResponseClick}
  //                 selectedResponseId={responseId}
  //                 showFormName
  //                 onDeleteResponse={onDeleteResponse}
  //                 onViewContact={onViewContact}
  //               />
  //             </Flex>
  //           </VStack>
  //         )
  //       }
  //     }
  //   }
  // }

  // const renderResponses = () => (
  //   <HStack
  //     align={'flex-start'}
  //     w='full'
  //     h='full'
  //     spacing={0}
  //     overflow='hidden'
  //   >
  //     {renderResponsesList()}
  //     {(isDesktop || !_.isNil(responseId)) && (
  //       <Flex w='full' h='full' flexGrow='1'>
  //         {responseId && <Response responseId={responseId} />}
  //       </Flex>
  //     )}
  //   </HStack>
  // )

  const renderResponses = () => {
    return (
      <VStack w='full' h='full' overflow={'hidden'} px={6} py={6}>
        <VStack
          divider={<StackDivider color='gray.200' />}
          w='full'
          spacing={0}
          overflow={'hidden'}
        >
          <ResponsesTableHeader />
          <VStack
            spacing={0}
            w='full'
            overflowY={'auto'}
            divider={<StackDivider color='gray.200' />}
          >
            {_.map(responses, r => (
              <ResponseRow key={r.id} r={r} onClick={onResponseClick} />
            ))}
          </VStack>
        </VStack>
      </VStack>
    )
  }

  const emptyState = (
    <Flex
      justifyContent={{ base: 'flex-start', lg: 'center' }}
      alignItems='center'
      w='full'
      h='full'
      direction='column'
      gap='6'
      p={{ base: '16', lg: 0 }}
      position='relative'
    >
      <Image src={NoResponsesImage} alt='Empty responses' boxSize={44} />
      <Text
        maxWidth='96'
        textAlign='center'
        fontWeight='normal'
        fontSize='md'
        lineHeight='24px'
        color='gray.600'
      >
        No responses. Create and share a video form to get started.
      </Text>

      <Show below='lg'>
        {!_.isEmpty(forms) && (
          <Button
            w='56'
            h='12'
            fontWeight='semibold'
            variant='solid'
            colorScheme='teal'
            fontSize='lg'
            lineHeight='7'
            leftIcon={<FontAwesomeIcon icon={faList} />}
            onClick={openDrawer}
          >
            View forms
          </Button>
        )}
      </Show>

      <Tooltip
        label='Create a new video form'
        shouldWrapChildren
        hasArrow
        closeDelay={1000}
      >
        <Button
          w='56'
          h='12'
          fontWeight='semibold'
          variant='outline'
          color='gray.700'
          fontSize='lg'
          lineHeight='7'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onNewVideoFormClick}
        >
          Create new
        </Button>
      </Tooltip>
    </Flex>
  )

  const renderContent = () => {
    if (_.size(responses) > 0) {
      return renderResponses()
    } else if (showSkeleton) {
      return <Skeleton w='full' h='20' />
    } else {
      return emptyState
    }
  }

  // const renderFirstFormAlert = () => {
  //   if (showFirstFormBanner) {
  //     return (
  //       <HStack bg='blue.400' px={4} py={4} w='full' justify={'space-between'}>
  //         <Text color='white' fontSize={'xs'}>
  //           Create your first form in a few taps
  //         </Text>
  //         <Button
  //           variant={'solid'}
  //           bg='white'
  //           size='xs'
  //           color='blue.500'
  //           rounded='sm'
  //           onClick={onNewVideoFormClick}
  //         >
  //           Create new
  //         </Button>
  //       </HStack>
  //     )
  //   }
  // }

  return (
    <VStack
      w='full'
      h='full'
      overflow={'hidden'}
      bg='white'
      // bg={{ base: 'white', lg: 'gray.100' }}
      spacing={0}
    >
      {/* <Hide above='lg'>
        {_.isNil(responseId) && (
          <VStack w='full' spacing={0}>
            <NavBarMobile />
            {renderFirstFormAlert()}
            {!_.isEmpty(responses) && (
              <ResponsesNavBarMobile
                currentFilter={filterVisible}
                selectedEmails={selectedContacts}
                selectedFormIds={selectedForms}
                handleFilter={setFilterVisible}
              />
            )}
          </VStack>
        )}
      </Hide> */}
      {renderContent()}
    </VStack>
  )
}

export default Responses
