import {
  Ref,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  useEffect
} from 'react'
import _ from 'lodash'
import {
  HStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Button
} from '@chakra-ui/react'
import WelcomePagePreview from 'pages/formEdit/WelcomePagePreview'
import WelcomePageForm from 'modals/customizeWelcomePageModal/WelcomePageForm'
import { FormT, WelcomeT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import { dbUpdateForm } from 'controllers/forms'

export interface ICustomizeWelcomePageModal {
  open: (formId: string) => void
}

type Props = {}

const defaultExplanation =
  'You are invited to a one-way video interview. To submit your interview response, you will need to view/listen to pre-recorded questions and record your video answers.'

const CustomizeWelcomePageModal: ForwardRefRenderFunction<
  ICustomizeWelcomePageModal,
  Props
> = (props, ref: Ref<unknown>) => {
  const [welcome, setWelcome] = useState<WelcomeT>({})
  const accountProfile = useSelector(state => state.accountProfile)
  const [formId, setFormId] = useState<string>('')
  const form: FormT | undefined = useSelector(state =>
    _.get(state, ['forms', formId])
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: (fId: string) => {
      onOpen()
      const w = _.get(form, 'welcome', {} as WelcomeT)
      if (!_.has(w, 'explanation')) {
        w.explanation = defaultExplanation
      }
      setWelcome(w)
      setFormId(fId)
    }
  }))

  useEffect(() => {
    if (form && form.welcome) {
      setWelcome(form.welcome)
    }
  }, [form])

  const saveWelcome = async () => {
    const newWelcome = { ...welcome, companyName: accountProfile.name }
    const w = _.omitBy(newWelcome, _.isUndefined)
    dbUpdateForm(formId, { welcome: w })
    onClose()
  }

  const renderContent = () => {
    return (
      <HStack h='full' w='full' spacing={0} align='stretch'>
        <Flex flexDirection={'column'} h='full' w='full' flex={2}>
          <WelcomePageForm
            formId={formId}
            welcome={welcome}
            update={setWelcome}
          />
        </Flex>
        <Flex flex={1.2}>
          <WelcomePagePreview welcome={welcome} />
        </Flex>
      </HStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>{renderContent()}</ModalBody>
        <ModalFooter borderTopWidth={1}>
          <HStack>
            <Button onClick={onClose} variant={'ghost'} size='md'>
              Cancel
            </Button>
            <Button colorScheme='teal' onClick={saveWelcome}>
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CustomizeWelcomePageModal)
