import { FC, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { useSelector } from 'model/hooks'
import ResponsesPanel from 'pages/form/ResponsesPanel'
import ContactHeader from 'pages/contact/ContactHeader'
import {
  getContactsByEmail,
  getResponsesByEmail
} from 'model/selectors/responses'
import Response from 'pages/form/Response'
import { dbDeleteResponse } from 'controllers/responses'
import { getBlockedResponses } from 'model/selectors/paymentPlans'

const Contact: FC<{}> = () => {
  const blockedResponses = useSelector(getBlockedResponses)
  const contactsByEmail = useSelector(getContactsByEmail)
  const responsesByEmail = useSelector(getResponsesByEmail)
  const allResponses = useSelector(state => state.responses)
  const { email } = useParams()
  const [selected, setSelected] = useState<string | null>(null)
  const contact = email ? _.get(contactsByEmail, email) : undefined
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  useEffect(() => {
    if (email && isDesktop) {
      const responses = _.get(responsesByEmail, email)
      const responseIsNotSelected = responses && _.isNil(selected)
      const responseIsBlocked =
        responses && !_.isNil(selected) && _.has(blockedResponses, selected)
      const responseDoesNotExist =
        responses && !_.isNil(selected) && !_.has(allResponses, selected)
      if (responseIsNotSelected || responseDoesNotExist || responseIsBlocked) {
        const r = _.find(responses, r => !_.has(blockedResponses, r.id))
        if (r) {
          setSelected(r.id)
        } else {
          setSelected(null)
        }
      }
    }
  }, [selected, responsesByEmail, allResponses, blockedResponses, isDesktop])

  const responses = email ? _.get(responsesByEmail, email) : []

  const onDeleteResponse = (responseId: string) => {
    console.log('onDeleteResponse', responseId)
    dbDeleteResponse(responseId)
  }

  const onViewContact = (responseId: string) => {
    console.log('onViewContact', responseId)
  }

  const renderInteractions = () => {
    if (isDesktop || _.isNil(selected)) {
      return (
        <VStack w='sm' align='flex-start' spacing={0}>
          <Box px='4' pt={4}>
            <Text
              textTransform='uppercase'
              color='gray.500'
              fontWeight={600}
              fontSize='xs'
              lineHeight='16px'
            >
              VIEW ALL INTERACTIONS
            </Text>
          </Box>
          <Flex
            h='full'
            w={{ base: 'full', lg: 'sm' }}
            overflow={'hidden'}
            pr={{ base: 4, lg: 0 }}
            flexShrink={0}
          >
            <ResponsesPanel
              responses={responses}
              onClick={v => setSelected(v)}
              selectedResponseId={!_.isNil(selected) ? selected : undefined}
              onDeleteResponse={onDeleteResponse}
              onViewContact={onViewContact}
              hideMenu
            />
          </Flex>
        </VStack>
      )
    }
  }

  if (_.isNil(contact)) {
    return null
  } else {
    return (
      <VStack w='full' h='full' spacing={0}>
        {(isDesktop || !selected) && <ContactHeader contact={contact} />}
        <HStack align={'flex-start'} w='full' h='full' spacing={0}>
          {renderInteractions()}
          {!_.isNil(selected) && (
            <Flex w='full' h='full' flexGrow='1'>
              <Response
                responseId={selected}
                onBack={() => setSelected(null)}
              />
            </Flex>
          )}
        </HStack>
      </VStack>
    )
  }
}

export default Contact
