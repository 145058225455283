import { FC } from 'react'
import {
  Text,
  Center,
  VStack,
  Box,
  Spinner,
  HStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLoader } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import { QuestionT } from 'shared/types/model'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'

type Props = {
  questions: QuestionT[]
  questionsInProgress: string[]
}

const FormGenerating: FC<Props> = ({ questions, questionsInProgress }) => {
  return (
    <Center w='full' h='full' pb={{ base: 12, lg: 0 }} bg='white'>
      <VStack spacing={6} h='full' justify='center'>
        <Box color='black'>
          <Spinner color='teal.400' />
        </Box>
        <Text
          color='black'
          fontWeight='semibold'
          fontSize='xl'
        >
          Generating questions...
        </Text>
        <Text
          color='gray.600'
          fontWeight='normal'
          fontSize='md'
        >
          It can take up to 5 minutes to create new video questions.
        </Text>
        <VStack overflowY='auto' maxH='40vh' align='start' justify='start' w='2xl' pr='8'>
          {_.map(questions, q => (
            <HStack
              key={q.id}
              flexShrink={0}
              borderBottom='1px'
              borderColor='gray.100'
              w='full'
              justify='space-between'
            >
              <Text textAlign='left' py='4'>
                {_.truncate(q.text, { length: 70 })}
              </Text>
              {_.includes(questionsInProgress, q.id)
                ? <FontAwesomeIcon icon={faLoader} className='fa-spin' color='#A0AEC0' />
                : <FontAwesomeIcon icon={faCircleCheck} color='#48BB78' />}
            </HStack>
          ))}
        </VStack>
      </VStack>
    </Center>
  )
}

export default FormGenerating
