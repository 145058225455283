import * as types from 'model/actionTypes'
import { DictT, CFQT } from 'shared/types/model'

interface formsActionT {
  type: string
  cfqs: DictT<CFQT> | null
}

export function receiveCFQs (cfqs: DictT<CFQT> | null): formsActionT {
  return {
    type: types.RECEIVE_CFQS,
    cfqs
  }
}
