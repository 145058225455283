import { initializeApp } from 'firebase/app'
import {
  doc,
  collection,
  initializeFirestore,
  deleteField
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { mapObject } from 'shared/utils/object'

import config from 'shared/config'
import { DictT } from 'shared/types/model'

const firebaseApp = initializeApp(config)

export const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true
})
export const auth = getAuth()
export const storage = getStorage(firebaseApp)
export const storageRef = (refer: string) => ref(storage, refer)
export const analytics = getAnalytics(firebaseApp)

export const generateId = (): string => {
  return doc(collection(db, 'tmp')).id
}

export const dbOmit = (data: DictT<any>) => {
  return mapObject(data, value => (value === undefined ? deleteField() : value))
}
