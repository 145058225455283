import { Select } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  model: string
  setModel: (model: string) => void
}

const AIModelSelect: FC<Props> = ({ model, setModel }) => {
  return (
    <Select value={model} onChange={e => setModel(e.target.value)}>
      <option value='gpt-4'>gpt-4</option>
      <option value='gpt-4-0314'>gpt-4-0314</option>
      <option value='gpt-4-32k'>gpt-4-32k</option>
      <option value='gpt-4-32k-0314'>gpt-4-32k-0314</option>
      <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
      <option value='gpt-3.5-turbo-0301'>gpt-3.5-turbo-0301</option>
    </Select>
  )
}

export default AIModelSelect
