import {
  doc,
  collection,
  updateDoc
} from 'firebase/firestore'
import { db } from 'controllers/db'
import * as Sentry from '@sentry/react'

import { UserT } from 'shared/types/model'

export const dbUpdateUser = async (
  userId: string,
  upd: Partial<UserT>
) => {
  try {
    const ref = doc(collection(db, 'users'), userId)
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}
