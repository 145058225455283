import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { AccountT } from 'shared/types/model'

const initialState: AccountT | null = null

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_ACCOUNT:
      return action.account
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
