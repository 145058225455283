import { FC, useState, useRef } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  InputRightElement,
  InputGroup,
  IconButton,
  Box,
  FormErrorMessage
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import validator from 'validator'

import { dbSignIn } from 'controllers/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import _ from 'lodash'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import GoogleAuthButton from 'pages/auth/GoogleAuthButton'
import AuthDivider from 'pages/auth/AuthDivider'
import AuthGeneralError from 'pages/auth/AuthGeneralError'
import AuthHeader from 'pages/auth/AuthHeader'

const SignIn: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>(_.get(location, 'state.email', ''))
  const [show, setShow] = useState(false)
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [generalError, setGeneralError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)

  const submit = async (): Promise<void> => {
    setGeneralError(null)
    let eError = null
    let pError = null
    if (_.isEmpty(email)) {
      eError = 'Please enter your email'
    } else if (!validator.isEmail(email)) {
      eError =
        'Please enter a valid email address using the following format: name@example.com'
    }
    if (_.isEmpty(_.toString(password))) {
      pError = 'Please enter your password'
    }

    if (_.isNil(eError) && _.isNil(pError)) {
      setLoading(true)
      const errorCode = await dbSignIn(email, password)
      if (!_.isNil(errorCode)) {
        switch (errorCode) {
          case 'auth/wrong-password':
            pError =
              'The email and password you entered did not match our records. Please double-check and try again.'
            break
          case 'auth/user-not-found':
            eError =
              'An account with this email does not exist. To create a new account please sign up.'
            break
          default:
            setGeneralError(authErrorToString(errorCode))
        }
      }
    }
    setEmailError(eError)
    setPasswordError(pError)
    setLoading(false)
  }

  const resetErrors = () => {
    setEmailError(null)
    setPasswordError(null)
  }

  const pageHeader = (
    <AuthHeader
      title='Log in to your account'
      description='FaceFile the easiest way to conduct one-way video interviews'
    />
  )

  const renderGeneralError = () => {
    if (!_.isNil(generalError)) {
      return (
        <AuthGeneralError
          errorMessage={generalError}
          onReset={() => setGeneralError(null)}
        />
      )
    }
  }

  const onForgotPasswordClick = async () => {
    navigate('/auth/reset_password', { state: { email } })
  }

  const togglePasswordVisibility = () => setShow(show => !show)

  const renderInputs = () => {
    return (
      <VStack spacing={5} w='full'>
        <FormControl isRequired isInvalid={!_.isNil(emailError)}>
          <FormLabel htmlFor='email-signin'>Email</FormLabel>
          <Input
            id='email-signin'
            type='email'
            value={email}
            color={_.isNil(emailError) ? undefined : 'red.500'}
            onFocus={resetErrors}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                passwordInputRef.current?.focus()
              }
            }}
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!_.isNil(passwordError)}>
          <HStack align='flex-start' justify={'space-between'}>
            <FormLabel htmlFor='password'>Password</FormLabel>
            <Button
              variant='link'
              color={'blue.600'}
              fontWeight='medium'
              size='sm'
              onClick={onForgotPasswordClick}
            >
              Forgot password?
            </Button>
          </HStack>
          <InputGroup size='md'>
            <Input
              id='password'
              type={show ? 'text' : 'password'}
              value={password}
              onFocus={resetErrors}
              color={_.isNil(passwordError) ? undefined : 'red.500'}
              onChange={e => setPassword(e.target.value)}
              ref={passwordInputRef}
              onKeyPress={async e => {
                if (e.key === 'Enter') {
                  submit()
                }
              }}
            />
            <InputRightElement>
              <IconButton
                aria-label='show/hide password'
                variant={'unstyled'}
                icon={<FontAwesomeIcon icon={show ? faEyeSlash : faEye} />}
                onClick={togglePasswordVisibility}
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{passwordError}</FormErrorMessage>
        </FormControl>
      </VStack>
    )
  }

  const renderCTA = () => {
    return (
      <Box w='full' pt={4}>
        <Button
          isLoading={loading}
          variant='solid'
          colorScheme={'teal'}
          onClick={submit}
          w='full'
          size='lg'
        >
          Log in
        </Button>
      </Box>
    )
  }

  const renderChangeMode = () => {
    return (
      <HStack spacing='2' justify='center' align='center' pt={4}>
        <Text color='gray.600'>New to FaceFile?</Text>
        <Button
          variant='link'
          color='blue.600'
          onClick={() => navigate('/auth/signup')}
        >
          Sign up
        </Button>
      </HStack>
    )
  }

  return (
    <VStack spacing='2' w='full'>
      {pageHeader}
      <VStack
        bgColor='white'
        p='10'
        w='full'
        maxW='2xl'
        rounded='lg'
        boxShadow='sm'
      >
        {renderGeneralError()}
        {renderInputs()}
        {renderCTA()}
        {renderChangeMode()}
        <AuthDivider />
        <GoogleAuthButton />
      </VStack>
    </VStack>
  )
}

export default SignIn
