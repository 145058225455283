import * as types from 'model/actionTypes'
import { TemplateSectionsT } from 'shared/types/model'

interface TemplatesSectionsActionT {
  type: string
  sections: TemplateSectionsT
}

export function receiveTemplatesSections (
  sections: TemplateSectionsT
): TemplatesSectionsActionT {
  return {
    type: types.RECEIVE_TEMPLATES_SECTIONS,
    sections
  }
}
