import { FC, useRef, LegacyRef, useEffect } from 'react'
import { WrapItem, WrapItemProps } from '@chakra-ui/react'
import { useUpdateEffect } from 'react-use'

export interface Props extends WrapItemProps {
  onBoudingRect: (rect: DOMRect) => void
}

const DynamicWrap: FC<Props> = ({ onBoudingRect, ...props }) => {
  const ref = useRef(null)
  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect()
    if (rect) {
      onBoudingRect(rect)
    }
  }, [])

  return (
    <WrapItem ref={ref as LegacyRef<HTMLLIElement>} {...props}>
      {props.children}
    </WrapItem>
  )
}

export default DynamicWrap
