import {
  Ref,
  useState,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  HStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Switch,
  VStack
} from '@chakra-ui/react'
import { ContactFormOrderT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { dbUpdateForm } from 'controllers/forms'
// import _ from 'lodash'

export interface IContactFormSettingsModal {
  open: (formId: string) => void
}

type Props = {}

const ContactFormSettingsModal: ForwardRefRenderFunction<
  IContactFormSettingsModal,
  Props
> = (_props, ref: Ref<unknown>) => {
  const [formId, setFormId] = useState<string>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [when, setWhen] = useState(ContactFormOrderT.END)
  const [name, setName] = useState(true)
  const [email, setEmail] = useState(true)
  const [phone, setPhone] = useState(false)
  const form = useSelector(state => formId && _.get(state.forms, formId))

  useEffect(() => {
    if (form) {
      setWhen(_.get(form.contactForm, 'when', ContactFormOrderT.END))
      setName(_.get(form.contactForm, 'name', true))
      setEmail(_.get(form.contactForm, 'email', true))
      setPhone(_.get(form.contactForm, 'phone', false))
    }
  }, [form])

  useImperativeHandle(ref, () => ({
    open: (fId: string) => {
      setFormId(fId)
      onOpen()
    }
  }))

  const onSubmit = async (): Promise<void> => {
    if (formId) {
      const cf = {
        when,
        name,
        email,
        phone
      }
      dbUpdateForm(formId, { contactForm: cf })
    }
    onClose()
  }

  const renderWhen = () => {
    let buttonTitle = 'Never'
    if (when === ContactFormOrderT.START) {
      buttonTitle = 'At the beginning'
    } else if (when === ContactFormOrderT.END) {
      buttonTitle = 'At the end'
    }
    return (
      <HStack w='full' justify={'space-between'} pb={6}>
        <Text color='gray.700' fontSize={'lg'}>
          When to ask
        </Text>
        <Popover closeOnBlur>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  variant={'unstyled'}
                  size='lg'
                  color='gray.400'
                  fontWeight={'normal'}
                  rightIcon={
                    <FontAwesomeIcon icon={faChevronRight} fontSize={'0.8em'} />
                  }
                >
                  {buttonTitle}
                </Button>
              </PopoverTrigger>
              <PopoverContent w='32' p={0}>
                <PopoverArrow />
                <PopoverBody
                  // w='52'
                  color='gray.400'
                  fontSize={'md'}
                  fontWeight={'semibold'}
                >
                  <Flex
                    w='full'
                    as='button'
                    onClick={() => {
                      setWhen(ContactFormOrderT.END)
                      onClose()
                    }}
                    _hover={{ bg: 'gray.50' }}
                    p={2}
                  >
                    End
                  </Flex>
                  <Flex
                    w='full'
                    as='button'
                    onClick={() => {
                      setWhen(ContactFormOrderT.START)
                      onClose()
                    }}
                    _hover={{ bg: 'gray.50' }}
                    p={2}
                  >
                    Beginning
                  </Flex>
                  <Flex
                    w='full'
                    as='button'
                    onClick={() => {
                      setWhen(ContactFormOrderT.NEVER)
                      onClose()
                    }}
                    _hover={{ bg: 'gray.50' }}
                    p={2}
                  >
                    Never
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </HStack>
    )
  }

  const renderName = () => {
    return (
      <HStack w='full' justify={'space-between'}>
        <Text color='gray.500' fontWeight={'medium'}>
          Name
        </Text>
        <Switch
          colorScheme='teal'
          isChecked={name}
          onChange={e => setName(e.target.checked)}
        />
      </HStack>
    )
  }

  const renderEmail = () => {
    return (
      <HStack w='full' justify={'space-between'}>
        <Text color='gray.500' fontWeight={'medium'}>
          Email
        </Text>
        <Switch
          colorScheme='teal'
          isChecked={email}
          onChange={e => setEmail(e.target.checked)}
        />
      </HStack>
    )
  }

  const renderPhone = () => {
    return (
      <HStack w='full' justify={'space-between'}>
        <Text color='gray.500' fontWeight={'medium'}>
          Phone
        </Text>
        <Switch
          colorScheme='teal'
          isChecked={phone}
          onChange={e => setPhone(e.target.checked)}
        />
      </HStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md' isCentered>
      <ModalOverlay />
      <ModalContent px={4} py={3}>
        <ModalHeader
          pt='4'
          // pb='7'
          px='5'
          fontSize='md'
          lineHeight='7'
          fontWeight='semibold'
          color='black'
          // borderBottomWidth={1}
        >
          Contact form
        </ModalHeader>
        <ModalBody px={'7'}>
          {renderWhen()}
          <Text color='gray.700' fontSize={'lg'}>
            Ask for
          </Text>
          <VStack pt={6} spacing={6}>
            {renderName()}
            {renderEmail()}
            {renderPhone()}
          </VStack>
          <Flex direction='column' maxW='50%' gap='16px'>
            {/* <FormControl isInvalid={nameError}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={onChange('name')}
                onFocus={() => setNameError(false)}
                onBlur={() => setNameError(name === '')}
              />
              {nameError && (
                <FormErrorMessage>Set contact name</FormErrorMessage>
              )}
            </FormControl> */}
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent={'flex-end'} pt={8}>
          <HStack>
            <Button
              variant='ghost'
              fontSize='md'
              lineHeight='6'
              fontWeight='semibold'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button px={12} colorScheme='teal' onClick={onSubmit}>
              Update
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ContactFormSettingsModal)
