import * as types from 'model/actionTypes'
import { DictT, ResponseT } from 'shared/types/model'

interface responseActionT {
  type: string
  responses: DictT<ResponseT> | null
}

export function receiveResponses (
  responses: DictT<ResponseT> | null
): responseActionT {
  return {
    type: types.RECEIVE_RESPONSES,
    responses
  }
}
