import {
  Ref,
  useState,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef
} from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  ModalFooter,
  HStack,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react'

import { dbUpdateTemplateTag } from 'controllers/templates'
import { useSelector } from 'model/hooks'

export interface IRenameTemplateTagModal {
  open: (formId: string) => void
}

type Props = {}

const RenameTemplateTagModal: ForwardRefRenderFunction<
  IRenameTemplateTagModal,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const templatesTags = useSelector(state => state.templatesTags)
  const [tagId, setTagId] = useState<string>()
  const [name, setName] = useState('')

  useImperativeHandle(ref, () => ({
    open: (tagId: string) => {
      setTagId(tagId)
      setName(templatesTags[tagId])
      onOpen()
    }
  }))

  const onSubmit = async (): Promise<void> => {
    if (tagId) {
      dbUpdateTemplateTag(tagId, name)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p='7'
          pb='7'
          fontSize='lg'
          lineHeight='7'
          fontWeight='bold'
          color='gray.900'
          borderBottomWidth={1}
        >
          Edit tag
        </ModalHeader>
        <ModalCloseButton color='gray.700' mt='4' mr='7' />
        <ModalBody pt='7'>
          <FormControl isInvalid={name === ''}>
            <FormLabel>Name</FormLabel>
            <Input
              type='text'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent={'flex-start'} pt={8}>
          <HStack>
            <Button
              colorScheme='blue'
              onClick={onSubmit}
              isDisabled={name === ''}
            >
              Update
            </Button>
            <Button
              variant='outline'
              fontSize='md'
              lineHeight='6'
              fontWeight='semibold'
              onClick={onClose}
            >
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(RenameTemplateTagModal)
