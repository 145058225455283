import { useEffect, useState } from 'react'
import {
  Button,
  Text,
  Heading,
  Textarea,
  VStack,
  HStack,
  Select,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import { AIConfT, AIPromptConfT } from 'shared/types/model'
import { dbUpdateAICFQSuggestions } from 'controllers/settings'

const SettingsSuggestionsCFQs = () => {
  const [prompt, setPrompt] = useState('')
  const [model, setModel] = useState('gpt-3.5-turbo')
  const [temperature, setTemperature] = useState('0')
  const [maxTokens, setMaxTokens] = useState('1024')
  const aiConf: AIConfT | null = useSelector(state => state.aiConf)

  useEffect(() => {
    if (aiConf?.CFQSuggestions) {
      setPrompt(aiConf.CFQSuggestions.prompt)
      setModel(aiConf.CFQSuggestions.model)
      setTemperature(_.toString(aiConf.CFQSuggestions.temperature))
      setMaxTokens(_.toString(aiConf.CFQSuggestions.maxTokens))
    }
  }, [aiConf])

  const saveSettings = () => {
    const c: AIPromptConfT = {
      prompt,
      temperature: Number(temperature),
      model,
      maxTokens: Number(maxTokens)
    }
    dbUpdateAICFQSuggestions(c)
  }

  const renderSuggestionsVariables = () => {
    return (
      <VStack w='full' align='flex-start'>
        <Heading>Variables</Heading>
        <Text fontSize='sm'>
          <strong>$INTERVIEW</strong> - will be replaced with all questions and
          answers
        </Text>
      </VStack>
    )
  }

  const renderSuggestionsAISettings = () => {
    return (
      <VStack w='full' align='flex-start'>
        <Heading>AI Settings</Heading>
        <Select value={model} onChange={e => setModel(e.target.value)}>
          <option value='gpt-4'>gpt-4</option>
          <option value='gpt-4-0314'>gpt-4-0314</option>
          <option value='gpt-4-32k'>gpt-4-32k</option>
          <option value='gpt-4-32k-0314'>gpt-4-32k-0314</option>
          <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
          <option value='gpt-3.5-turbo-0301'>gpt-3.5-turbo-0301</option>
        </Select>
        <FormControl>
          <FormLabel>Temperature</FormLabel>
          <NumberInput
            value={temperature}
            onChange={str => setTemperature(str)}
            min={0}
            max={1}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Max tokens</FormLabel>
          <NumberInput
            value={maxTokens}
            onChange={str => setMaxTokens(str)}
            min={0}
            max={2048}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </VStack>
    )
  }

  return (
    <VStack w='full' mt='12' sx={{ marginTop: '4rem !important', paddingBottom: '4rem !important' }}>
      <HStack w='full' justify='space-between'>
        <Heading>Suggestions settings</Heading>
        <Button onClick={saveSettings} colorScheme='blue'>
          Save suggestions settings
        </Button>
      </HStack>
      <HStack w='full'>
        <VStack w='full' align='flex-start'>
          <Heading fontSize='2xl'>Prompt</Heading>
          <Textarea
            value={prompt}
            size='sm'
            onChange={e => setPrompt(e.target.value)}
            background='white'
            minH='80'
          />
        </VStack>
        <VStack w='full' spacing={6}>
          {renderSuggestionsVariables()}
          {renderSuggestionsAISettings()}
        </VStack>
      </HStack>
    </VStack>
  )
}

export default SettingsSuggestionsCFQs
