import * as types from 'model/actionTypes'
import { DictT, ProductT } from 'shared/types/model'

interface ProductActionT {
  type: string
  products: DictT<ProductT> | null
}

export function receiveProducts (
  products: DictT<ProductT> | null
): ProductActionT {
  return {
    type: types.RECEIVE_PRODUCTS,
    products
  }
}
