import { FC } from 'react'
import {
  VStack,
  GridItem,
  Grid,
  Heading,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  HStack,
  Button,
  Text,
  Menu,
  MenuButton,
  Divider,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { CFQT, DictT, QA, ResponseT } from 'shared/types/model'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faPlus,
  faQuestion,
  faTimes,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'
import { generateId } from 'controllers/db'
import { useSelector } from 'model/hooks'
import { getFormLabel } from 'shared/utils/form'
import { dbFetchAnswers } from 'controllers/responses'
import { getAllQuestions } from 'model/selectors/questions'

type Props = {
  qas: QA[]
  setQas: (qas: QA[]) => void
  cfqsIds: string[]
  setCfqsIds: (cfqs: string[]) => void
  cfqsWithData: DictT<Partial<CFQT>>
  setCfqsWithData: (v: DictT<Partial<CFQT>>) => void
  explanation: DictT<boolean>
}

const CFQTestCase: FC<Props> = ({
  qas,
  setQas,
  cfqsIds,
  setCfqsIds,
  cfqsWithData,
  setCfqsWithData,
  explanation
}) => {
  const responses = useSelector(state => state.responses)
  const forms = useSelector(state => state.forms)
  const questions = useSelector(getAllQuestions)

  // const formsWithCfqIds = useMemo(() => {
  //   return _.reduce(
  //     forms,
  //     (res: string[], v: FormT) => {
  //       if (_.has(v, 'cfqs')) {
  //         res.push(v.id)
  //       }
  //       return res
  //     },
  //     []
  //   )
  // }, [forms])

  // const responsesWithCfq = useMemo(() => {
  //   return _.reduce(
  //     responses,
  //     (res: DictT<ResponseT>, v: ResponseT) => {
  //       if (_.includes(formsWithCfqIds, v.formId)) {
  //         _.set(res, v.id, v)
  //       }
  //       return res
  //     },
  //     {}
  //   )
  // }, [forms, responses])

  const onEditQ = (qaId: string, nextValue: string) => {
    console.log('onEditQ', qaId, nextValue)
    const newQas = _.map(qas, qa => {
      if (qa.id === qaId) {
        return { ...qa, q: nextValue }
      } else {
        return qa
      }
    })
    setQas(newQas)
  }

  const onEditA = (qaId: string, nextValue: string) => {
    console.log('onEditA', qaId, nextValue)
    const newQas = _.map(qas, qa => {
      if (qa.id === qaId) {
        return { ...qa, a: nextValue }
      } else {
        return qa
      }
    })
    setQas(newQas)
  }

  const onEditC = (cfqId: string, nextValue: string) => {
    console.log('onEditC', cfqId, nextValue)
    const newCfqs = { ...cfqsWithData, [cfqId]: { ...cfqsWithData[cfqId], condition: nextValue } }
    setCfqsWithData(newCfqs)
  }

  const onEditFQ = (cfqId: string, nextValue: string) => {
    console.log('onEditC', cfqId, nextValue)
    const newCfqs = { ...cfqsWithData, [cfqId]: { ...cfqsWithData[cfqId], question: nextValue } }
    setCfqsWithData(newCfqs)
  }

  const deleteQA = (qaId: string) => {
    const newQas = _.filter(qas, qa => qa.id !== qaId)
    setQas(newQas)
  }

  const deleteCFQ = (cfqId: string) => {
    const newCfqs = _.filter(cfqsIds, cId => cId !== cfqId)
    setCfqsIds(newCfqs)
  }

  const onAddQARow = () => {
    const id = generateId()
    const qa = {
      id,
      q: '',
      a: '',
      ai: ''
    }
    setQas([...qas, qa])
  }

  const onAddCFQRow = () => {
    const id = generateId()
    const cfq = {
      id,
      question: '',
      condition: ''
    }
    setCfqsWithData({ ...cfqsWithData, cfq })
    setCfqsIds([...cfqsIds, id])
  }

  const renderFormRow = (qa: QA) => {
    return [
      <GridItem
        key={'q_' + qa.id}
        p={2}
        borderTopWidth={1}
        borderColor='gray.100'
      >
        <Editable
          placeholder='enter question'
          fontSize='sm'
          w={'full'}
          defaultValue={qa.q}
          onSubmit={(nextValue: string) => onEditQ(qa.id, nextValue)}
        >
          <EditablePreview w='full' />
          <EditableInput w='full' />
        </Editable>
      </GridItem>,
      <GridItem
        key={'a_' + qa.id}
        p={2}
        borderTopWidth={1}
        borderColor='gray.100'
      >
        <HStack w='full' justify='space-between'>
          <Editable
            placeholder='enter answer'
            fontSize='sm'
            w='full'
            defaultValue={qa.a}
            onSubmit={(nextValue: string) => onEditA(qa.id, nextValue)}
          >
            <EditablePreview w='full' />
            <EditableInput w='full' />
          </Editable>
        </HStack>
      </GridItem>,
      <GridItem
        key={'actions_' + qa.id}
        borderTopWidth={1}
        borderColor='gray.100'
      >
        <HStack h='full' align='center' justify='flex-end' w='full' px={4}>
          <IconButton
            size='sm'
            aria-label='delete qa'
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            onClick={() => deleteQA(qa.id)}
          />
        </HStack>
      </GridItem>
    ]
  }

  const renderCfqRow = (cfqId: string) => {
    const cfq = _.get(cfqsWithData, cfqId)
    return [
      <GridItem
        key={'c_' + _.get(cfq, 'id')}
        p={2}
        borderTopWidth={1}
        borderColor='gray.100'
      >
        <Editable
          placeholder='Enter condition'
          fontSize='sm'
          w='full'
          defaultValue={_.get(cfq, 'condition')}
          onSubmit={(nextValue: string) => onEditC(cfqId, nextValue)}
        >
          <EditablePreview w='full' />
          <EditableInput w='full' />
        </Editable>
      </GridItem>,
      <GridItem
        key={'fq_' + _.get(cfq, 'id')}
        p={2}
        borderTopWidth={1}
        borderColor={'gray.100'}
      >
        <HStack w='full' justify='space-between'>
          <Editable
            placeholder='Enter followup question'
            fontSize='sm'
            w='full'
            defaultValue={_.get(cfq, 'question')}
            onSubmit={(nextValue: string) => onEditFQ(cfqId, nextValue)}
          >
            <EditablePreview w='full' />
            <EditableInput w='full' />
          </Editable>
        </HStack>
      </GridItem>,
      <GridItem
        key={'e_' + _.get(cfq, 'id')}
        p={2}
        borderTopWidth={1}
        borderColor={'gray.100'}
      >
        <HStack w='full' justify='start' align='center'>
          <FontAwesomeIcon
            icon={
              _.has(explanation, cfqId)
                ? _.get(explanation, cfqId)
                  ? faTimes
                  : faCheck
                : faQuestion
            }
          />
        </HStack>
      </GridItem>,
      <GridItem
        key={'actions_' + cfqId}
        borderTopWidth={1}
        borderColor='gray.100'
      >
        <HStack h='full' align='center' justify='flex-end' w='full' px={4}>
          <IconButton
            size='sm'
            aria-label='delete cfq'
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            onClick={() => deleteCFQ(cfqId)}
          />
        </HStack>
      </GridItem>
    ]
  }

  const onImportResponse = async (r: ResponseT) => {
    console.log('onImportResponse', r)
    const dbAnswers = await dbFetchAnswers(r.id)
    console.log('dbAnswers', dbAnswers)
    const newQas: QA[] = []
    _.forEach(r.answersIds, aId => {
      const a = _.get(dbAnswers, aId)
      if (a) {
        const q = _.get(questions, a.questionId)
        if (q) {
          newQas.push({
            id: a.id,
            a: a.transcription || '',
            q: q.text,
            ai: ''
          })
        }
      }
    })
    const formId = _.get(r, 'formId')
    const form = _.get(forms, formId)
    const dbCfqs = _.get(form, 'cfqsIds', [])
    console.log('dbCfqs', dbCfqs)
    setCfqsIds(dbCfqs)
    console.log('newQas', newQas)
    setQas(newQas)
  }

  const renderResponseImport = () => {
    return (
      <Menu>
        <MenuButton as={Button} colorScheme='blue' variant='outline'>
          Import response
        </MenuButton>
        <MenuList maxH={'sm'} overflowY={'auto'}>
          {_.map(responses, r => {
            const form = _.get(forms, r.formId)
            return (
              <MenuItem
                key={r.id}
                value={r.id}
                onClick={() => onImportResponse(r)}
              >
                <VStack w='full' spacing={0} align='flex-start'>
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    {form ? getFormLabel(form) : ''}
                  </Text>
                  <Text fontSize={'xs'}>{r.name}</Text>
                  <Divider />
                </VStack>
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    )
  }

  return (
    <VStack w='full'>
      <HStack w='full' justify={'space-between'}>
        <Heading>Test case</Heading>
        {renderResponseImport()}
      </HStack>
      <Heading size='md' alignSelf='start' px='2'>
        Questions
      </Heading>
      <Grid
        w='full'
        gridTemplateColumns='1fr 1fr 100px'
        borderWidth={1}
        bg='white'
      >
        <GridItem p={2}>
          <Heading size='sm'>Question</Heading>
        </GridItem>
        <GridItem p={2}>
          <Heading size='sm'>Answer</Heading>
        </GridItem>
        <GridItem p={2}>
          <Heading size='sm'></Heading>
        </GridItem>
        {_.map(qas, renderFormRow)}
      </Grid>
      <HStack justify={'flex-end'} w='full'>
        <Button
          size='sm'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onAddQARow}
          variant={'outline'}
          colorScheme='blue'
        >
          Add row
        </Button>
      </HStack>
      <Heading size='md' alignSelf='start' px='2'>
        Conditional Followup Questions
      </Heading>
      <Grid
        w='full'
        gridTemplateColumns='1fr 1fr 200px 100px'
        borderWidth={1}
        bg='white'
      >
        <GridItem p={2}>
          <Heading size='sm'>Condition</Heading>
        </GridItem>
        <GridItem p={2}>
          <Heading size='sm'>Followup question</Heading>
        </GridItem>
        <GridItem p={2}>
          <Heading size='sm'>Question will be asked</Heading>
        </GridItem>
        <GridItem p={2}>
          <Heading size='sm'></Heading>
        </GridItem>
        {_.map(cfqsIds, renderCfqRow)}
      </Grid>
      <HStack justify={'flex-end'} w='full'>
        <Button
          size='sm'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onAddCFQRow}
          variant={'outline'}
          colorScheme='blue'
        >
          Add row
        </Button>
      </HStack>
    </VStack>
  )
}

export default CFQTestCase
