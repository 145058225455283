import * as types from 'model/actionTypes'
import { NotificationsSettingsT } from 'shared/types/model'

export const receiveNotificationsSettings = (
  settings: NotificationsSettingsT
) => {
  return {
    type: types.RECEIVE_NOTIFICATIONS_SETTINGS,
    settings
  }
}
