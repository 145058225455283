import { Box } from '@chakra-ui/react'

import SettingsAccount from 'modals/settingsModal/SettingsAccount'
import SettingsProfile from 'modals/settingsModal/SettingsProfile'
import SettingsTeam from 'modals/settingsModal/SettingsTeam'
import SettingsNotifications from 'modals/settingsModal/SettingsNotifications'
import SettingsBilling from 'modals/settingsModal/SettingsBilling'
import SettingsIntergrations from 'modals/settingsModal/SettingsIntergrations'
import SettingsLibrary from 'modals/settingsModal/SettingsLibrary'
import SettingsTemplates from 'modals/settingsModal/SettingsTemplates'
import SettingsOnboarding from 'modals/settingsModal/SettingsOnboarding'
import SettingsAnalysisPrompt from 'modals/settingsModal/SettingsAnalysisPrompt'
import SettingsCFQ from 'modals/settingsModal/SettingsCFQ'
import SettingsResponseAnalysis from 'modals/settingsModal/SettingsResponseAnalysis'

const SettingsBody = ({ selectedTab }: { selectedTab: number }) => {
  const renderTab = () => {
    switch (selectedTab) {
      case 0: {
        return <SettingsAccount />
      }
      case 1: {
        return <SettingsProfile />
      }
      case 2: {
        return <SettingsTeam />
      }
      case 3: {
        return <SettingsNotifications />
      }
      case 4: {
        return <SettingsBilling />
      }
      case 5: {
        return <SettingsIntergrations />
      }
      case 6: {
        return <SettingsLibrary />
      }
      case 7: {
        return <SettingsTemplates />
      }
      case 8: {
        return <SettingsOnboarding />
      }
      case 9: {
        return <SettingsAnalysisPrompt />
      }
      case 10: {
        return <SettingsCFQ />
      }
      case 11: {
        return <SettingsResponseAnalysis />
      }
      default: return null
    }
  }
  return (
    <Box h='2xl' w='full' p='4'>
      {renderTab()}
    </Box>
  )
}

export default SettingsBody
