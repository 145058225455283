import * as types from 'model/actionTypes'
import { AnyAction } from '@reduxjs/toolkit'
import { SubscriptionT } from 'shared/types/model'

const initialState: SubscriptionT | null = null

export default function (
  state = initialState,
  action: AnyAction
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_SUBSCRIPTION:
      return action.subscription
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
