import { FC, useEffect } from 'react'
import {
  // Box,
  Button,
  // Input,
  Text,
  useClipboard,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  VStack
  // TabList,
  // Tab
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import // faCubes,
// faUsers,
// faPlus,
// faTrashCan
// faInboxIn,
// faPersonCircleQuestion,
// faMemoCircleInfo
'@fortawesome/pro-light-svg-icons'
import {
  faEllipsisH,
  faLink,
  // faInputText,
  // faArrowUpRightFromSquare,
  faHand,
  faExternalLink,
  faUserHeadset,
  faList,
  faWandSparkles,
  faPalette,
  faGrid3,
  faArrowLeft
} from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'
// import moment from 'moment'

import { FormT } from 'shared/types/model'
import ShareModal from 'modals/ShareModal'
import { logFormLinkCopied } from 'controllers/analytics'
import { faIdBadge } from '@fortawesome/pro-solid-svg-icons'

type Props = {
  questionsAmount: number
  form?: FormT
  onBrowseTemplatesClick: () => void
  onAddQuestionClick: () => void
  onPlaceContactForm: () => void
  onDeleteForm: () => void
  onEditWelcomeClick: () => void
  onContactFormClick: () => void
  formId: string
  label?: string
  setLabel: (label: string) => void
  isEdit: boolean
  responsesAmount: number
  goBack?: () => void
}

const EditorNavbar: FC<Props> = ({
  questionsAmount,
  form,
  // onBrowseTemplatesClick,
  // onAddQuestionClick,
  // onPlaceContactForm,
  // onDeleteForm,
  onEditWelcomeClick,
  onContactFormClick,
  formId,
  label = '',
  goBack
  // setLabel,
  // isEdit
  // responsesAmount
}) => {
  const { onCopy, hasCopied, setValue } = useClipboard(
    `${process.env.REACT_APP_VIEW_FORM_URL}/?form=${formId}`
  )
  // const labelRef = useRef<HTMLInputElement>(null)
  // const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    setValue(`${process.env.REACT_APP_VIEW_FORM_URL}/?form=${formId}`)
  }, [formId])

  // const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
  //   if (error) setError(false)
  //   _setLabel(e.target.value)
  // }

  // const saveLabel = () => {
  //   setLabel(_label)
  // }

  const onPreviewClick = () => {
    window.open(
      `${process.env.REACT_APP_VIEW_FORM_URL}/${formId}?preview`,
      '_blank'
    )
  }

  const menuList = () => {
    return (
      <MenuList zIndex={100} color={'gray.500'} fontWeight={'bold'}>
        <MenuItem
          icon={<FontAwesomeIcon icon={faUserHeadset} fixedWidth />}
          // onClick={() => onAddQuestionClick()}
        >
          Conversation Settings
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faHand} fixedWidth />}
          onClick={() => onEditWelcomeClick()}
        >
          Welcome page
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faIdBadge} fixedWidth />}
          onClick={() => onContactFormClick()}
        >
          Contact form
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faList} fixedWidth />}
          // onClick={() => onAddQuestionClick()}
        >
          Questions
        </MenuItem>

        <MenuItem
          icon={<FontAwesomeIcon icon={faWandSparkles} fixedWidth />}
          // onClick={() => onAddQuestionClick()}
        >
          Evaluation criteria
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faPalette} fixedWidth />}
          // onClick={() => onAddQuestionClick()}
        >
          Logo and Colors
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faGrid3} fixedWidth />}
          // onClick={() => onAddQuestionClick()}
        >
          Integrations
        </MenuItem>

        {/* {questionsAmount === 0 ? (
          <MenuItem
            onClick={onBrowseTemplatesClick}
            color={'gray.500'}
            icon={<FontAwesomeIcon icon={faCubes} fixedWidth />}
          >
            <Text color='gray.700' fontWeight={'semibold'}>
              Browse templates
            </Text>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={onPlaceContactForm}
            color={'gray.500'}
            icon={<FontAwesomeIcon icon={faUsers} fixedWidth />}
            isDisabled={form && !_.isNil(form.contactFormQuestionId)}
          >
            <Text color='gray.700' fontWeight={'semibold'}>
              Place contact form
            </Text>
          </MenuItem>
        )} */}
        {/* <MenuItem
          onClick={onEditWelcomeClick}
          color={'gray.500'}
          icon={<FontAwesomeIcon icon={faHand} fixedWidth />}
        >
          <Text color='gray.700' fontWeight={'semibold'}>
            Welcome page
          </Text>
        </MenuItem>

        <MenuItem
          color='red.500'
          onClick={onDeleteForm}
          icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
        >
          <Text fontWeight={'semibold'}>Delete</Text>
        </MenuItem> */}
      </MenuList>
    )
  }

  const renderMenu = () => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisH} />}
          variant={'ghost'}
          size='lg'
        />
        {menuList()}
      </Menu>
    )
  }

  const copyLinkToClipboard = () => {
    logFormLinkCopied(formId)
    onCopy()
  }

  const renderButtons = () => {
    return (
      <HStack flexShrink={0} spacing={5}>
        <Button
          variant='outline'
          size='md'
          leftIcon={<FontAwesomeIcon icon={faExternalLink} />}
          onClick={onPreviewClick}
          isDisabled={
            !_.has(form, 'previewUrl') ||
            (form && !_.isEmpty(form.syncRequests))
          }
          isLoading={form && !_.isEmpty(form.syncRequests)}
          color='gray.500'
          px={6}
        >
          Preview
        </Button>
        <Tooltip
          label='Invite candidates to this form'
          shouldWrapChildren
          hasArrow
          closeDelay={1000}
        >
          <ShareModal formId={formId} />
        </Tooltip>
        <Tooltip
          label={hasCopied ? 'Link copied!' : 'Copy link'}
          shouldWrapChildren
          hasArrow
          closeDelay={1000}
          closeOnClick={false}
        >
          <Button
            size='lg'
            variant='ghost'
            colorScheme='gray'
            onClick={copyLinkToClipboard}
            className='copy-link-button'
            isDisabled={questionsAmount === 0}
          >
            <FontAwesomeIcon icon={faLink} />
          </Button>
        </Tooltip>
        {renderMenu()}
      </HStack>
    )
  }

  return (
    <HStack
      px='4'
      w='full'
      align='center'
      justify='space-between'
      h='20'
      bg='white'
      borderLeftWidth={1}
      borderLeftColor='gray.50'
      borderBottomWidth={1}
      borderBottomColor='gray.100'
      flexShrink={0}
    >
      <VStack spacing={2} align='flex-start' w='full' pt={0}>
        <HStack>
          {goBack && (
            <IconButton
              aria-label='back'
              variant={'ghost'}
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={goBack}
            />
          )}
          <Text color='gray.700' fontSize={'lg'} fontWeight={'semibold'}>
            {label}
          </Text>
        </HStack>
        {/* <Input
          name='formname'
          role='presentation'
          type='text'
          ref={labelRef}
          variant='unstyled'
          autoComplete='off'
          value={_label}
          onChange={onChange}
          placeholder='Name your video form'
          fontSize='lg'
          w='100%'
          color='gray.700'
          _placeholder={{
            color: error ? 'red.300' : 'gray.300',
            fontSize: 'lg'
          }}
          isInvalid={error}
          onBlur={saveLabel}
          onFocus={() => setError(false)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              labelRef.current?.blur()
            }
          }}
        /> */}
      </VStack>
      {renderButtons()}
    </HStack>
  )
}

export default EditorNavbar
