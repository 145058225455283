import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Flex
} from '@chakra-ui/react'
import validator from 'validator'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'model/hooks'
import { AppConfT } from 'shared/types/model'
import Loading from 'pages/LoadingPage'
import { receiveOnboarding } from 'model/actions/onboardingAC'
import AuthHeader from 'pages/auth/AuthHeader'

const OnboardingForm = () => {
  const [searchParams] = useSearchParams()
  const [name, setName] = useState<string>(searchParams.get('name') || '')
  const [email, setEmail] = useState<string>(searchParams.get('email') || '')
  const [nameError, setNameError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const appConf: AppConfT | null = useSelector(state => state.appConf)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!_.isNil(appConf) && _.isNil(appConf.defaultIndustry)) {
      navigate('/auth/signup')
    }
  }, [appConf])

  const resetErrors = () => {
    setNameError(null)
    setEmailError(null)
  }

  const submit = () => {
    let eError = null
    let nError = null
    if (_.isEmpty(email)) {
      eError = 'Please enter your email'
    } else if (!validator.isEmail(email)) {
      eError =
        'Please enter a valid email address using the following format: name@example.com'
    }

    if (_.isEmpty(_.toString(name))) {
      nError = 'Please enter your name'
    }
    setNameError(nError)
    setEmailError(eError)
    if (_.isNil(eError) && _.isNil(nError)) {
      dispatch(
        receiveOnboarding({
          name,
          email
        })
      )
      navigate('/onboarding/industry')
    }
  }

  const pageHeader = (
    <AuthHeader
      title='Try a demo'
      description='With this interactive demo, you can experience FaceFile as an applicant and interviewer. It will only take you 3 minutes to understand how it all works.'
    />
  )

  if (_.isNil(appConf)) {
    return <Loading />
  } else {
    return (
      <Flex
        h='full'
        w='full'
        align='flex-start'
        justify={'center'}
        bg={{ base: 'white', lg: 'gray.50' }}
        pt={12}
        pb={12}
        overflowY='auto'
      >
        <VStack spacing='2' w='full' h='full' overflow={'auto'}>
          {pageHeader}
          <VStack
            bgColor='white'
            py='10'
            px={{ base: 6, lg: 10 }}
            w='full'
            maxW='2xl'
            rounded='lg'
            boxShadow={{ lg: 'sm', base: 'none' }}
          >
            <VStack spacing={5} w='full'>
              <FormControl isRequired isInvalid={!_.isNil(nameError)}>
                <FormLabel htmlFor='name'>Name</FormLabel>
                <Input
                  id='name'
                  type='text'
                  value={name}
                  onFocus={resetErrors}
                  color={_.isNil(nameError) ? undefined : 'red.500'}
                  onChange={e => setName(e.target.value)}
                  size='lg'
                  className='onboarding-contact-name-input'
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      emailInputRef.current?.focus()
                    }
                  }}
                />
                <FormErrorMessage>{nameError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!_.isNil(emailError)}>
                <FormLabel htmlFor='email-onboarding'>Email</FormLabel>
                <Input
                  id='email-onboarding'
                  type='email'
                  value={email}
                  ref={emailInputRef}
                  color={_.isNil(emailError) ? undefined : 'red.500'}
                  size='lg'
                  onFocus={resetErrors}
                  onChange={e => setEmail(e.target.value)}
                  className='onboarding-contact-email-input'
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      submit()
                    }
                  }}
                />
                <FormErrorMessage>{emailError}</FormErrorMessage>
              </FormControl>
              <Button
                w='full'
                colorScheme={'blue'}
                size='lg'
                onClick={submit}
                className='onboarding-contact-next-button'
              >
                Next
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </Flex>
    )
  }
}

export default OnboardingForm
