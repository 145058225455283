import { ReactComponent as Logo } from 'shared/assets/nav_bar_logo.svg'
import { Button, VStack, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  title: string
  description: string
}

const AuthHeader: FC<Props> = ({ title, description }) => {
  return (
    <VStack spacing={4} px={{ base: 4, lg: 0 }}>
      <Flex justifyContent='center'>
        <Button variant='link' as='a' href='https://facefile.co/'>
          <Logo width='142px' height='32px' />
        </Button>
      </Flex>
      <Heading size={{ base: 'md', lg: 'lg' }} pt={2} textAlign='center'>
        {title}
      </Heading>
      <Text color='gray.600' fontSize='md' pb={5} textAlign='center' maxW='xl'>
        {description}
      </Text>
    </VStack>
  )
}

export default AuthHeader
