import { AnyAction } from '@reduxjs/toolkit'
import * as types from 'model/actionTypes'
import { DictT, ResponseT } from 'shared/types/model'

const initialState: DictT<ResponseT> | null = null

export default function (
  state = initialState,
  action: AnyAction
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_RESPONSES:
      return action.responses
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
