import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    body: "'Inter', sans-serif"
  },
  components: {
    Select: { defaultProps: { focusBorderColor: 'gray.200' } },
    Input: { defaultProps: { focusBorderColor: 'gray.200' } }
  },
  breakpoints: {
    lg: '62em'
  }
})
