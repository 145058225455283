import {
  Avatar,
  Grid,
  GridItem,
  HStack,
  Stack,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import _ from 'lodash'
import { FC, useMemo } from 'react'
import { ResponseT } from 'shared/types/model'
import moment from 'moment'

type Props = {
  r: ResponseT
  onClick: (requestId: string) => void
}

const ResponseRow: FC<Props> = ({ r, onClick }) => {
  const nameCell = (
    <GridItem py={4}>
      <HStack pl={6} spacing={3} h='full' align={'center'}>
        <Avatar w='10' h='10' src={r.thumbnail} />
        <VStack spacing={0} align={'flex-start'}>
          <Text
            textAlign='start'
            textColor='gray.900'
            fontSize='sm'
            lineHeight='20px'
            fontWeight='medium'
            noOfLines={1}
          >
            {r.name || 'Human'}
          </Text>
          <Text
            textAlign='start'
            textColor='gray.600'
            fontSize='sm'
            lineHeight='20px'
            fontWeight='normal'
            noOfLines={1}
          >
            {r.email}
          </Text>
        </VStack>
      </HStack>
    </GridItem>
  )

  const typeCell = (
    <GridItem>
      <Stack w='full' h='full' align='center' justify={'center'}>
        <Tag
          rounded='full'
          colorScheme='teal'
          bg='#DBFFF8'
          size='sm'
          fontSize={'10px'}
          fontWeight={'medium'}
        >
          Interview
        </Tag>
      </Stack>
    </GridItem>
  )

  const questionsCell = (
    <GridItem>
      <Stack w='full' h='full' align='center' justify={'center'}>
        <Text fontSize={'sm'} fontWeight={'normal'} color='gray.600'>
          {_.size(r.answersIds)}
        </Text>
      </Stack>
    </GridItem>
  )

  const timeCell = (
    <GridItem>
      <Stack w='full' h='full' align='center' justify={'center'}>
        <Text fontSize={'sm'} fontWeight={'normal'} color='gray.600'>
          {moment(r.createdAt).fromNow()}
        </Text>
      </Stack>
    </GridItem>
  )

  const scoreColorScheme = useMemo(() => {
    if (!r.score) return 'gray'
    if (r.score > 75) {
      return 'greem'
    } else if (r.score > 50) {
      return 'blue'
    } else if (r.score > 25) {
      return 'yellow'
    } else {
      return 'red'
    }
  }, [r.score])

  const scoreCell = (
    <GridItem>
      <Stack w='full' h='full' align='center' justify={'center'}>
        {r.score && (
          <Tag
            rounded='full'
            color='teal.800'
            colorScheme={scoreColorScheme}
            size='sm'
            fontSize={'10px'}
            fontWeight={'medium'}
            px={5}
          >
            {r.score}
          </Tag>
        )}
      </Stack>
    </GridItem>
  )

  const summaryCell = (
    <GridItem>
      <Stack w='full' h='full' align='center' justify={'center'} py={4}>
        <Text
          fontSize={'10px'}
          fontWeight={'normal'}
          lineHeight={'18px'}
          color='gray.600'
        >
          {r.evaluation}
        </Text>
      </Stack>
    </GridItem>
  )

  return (
    <Grid
      templateColumns='2.5fr 1fr 1fr 2fr 1fr 3fr'
      gap={0}
      w='full'
      _hover={{ bg: 'gray.50' }}
      cursor={'pointer'}
      onClick={() => onClick(r.id)}
    >
      {nameCell}
      {typeCell}
      {questionsCell}
      {timeCell}
      {scoreCell}
      {summaryCell}
    </Grid>
  )
}

export default ResponseRow
