import { FC, useMemo, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Flex, Tooltip, IconButton, useClipboard } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoCamera } from '@fortawesome/pro-regular-svg-icons'
import {
  faLink,
  faArrowUpRightFromSquare
} from '@fortawesome/pro-light-svg-icons'
import { NavButton } from 'components/sidebar/NavButton'
import { FormT } from 'shared/types/model'
import { getFormLabel } from 'shared/utils/form'
import { useSelector } from 'model/hooks'
import { logFormLinkCopied } from 'controllers/analytics'
import DrawerContext from 'contexts/DrawerContext'

type Props = {
  form: FormT
}

export const SideMenuNavButton: FC<Props> = ({ form }) => {
  const { closeDrawer } = useContext(DrawerContext)
  const url = `${process.env.REACT_APP_VIEW_FORM_URL}/${form?.id}`
  const { hasCopied, onCopy } = useClipboard(url || '')
  const templates = useSelector(state => state.templates)
  const classNameByLabel = useMemo(() => {
    const res = {}
    _.forEach(templates, t => {
      _.set(res, t.title, _.kebabCase(t.title))
    })
    return res
  }, [templates])

  const navigate = useNavigate()
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location.pathname])

  const className = form.label ? _.get(classNameByLabel, form.label, '') : ''

  const onPreviewClick = () => {
    window.open(`${process.env.REACT_APP_VIEW_FORM_URL}/${form?.id}`, '_blank')
  }

  const copyLinkToClipboard = () => {
    logFormLinkCopied(form.id)
    onCopy()
  }

  const toForm = () => {
    closeDrawer()
    navigate(`/form/${form.id}`)
  }

  return (
    <NavButton
      isActive={_.includes(pathname, `/${form.id as string}`)}
      key={form.id as string}
      label={getFormLabel(form)}
      color='gray.700'
      iconColor='gray.700'
      lineHeight='5'
      fontWeight='semibold'
      icon={faVideoCamera}
      className={className}
      onClick={toForm}
      options={
        <Flex alignItems='center'>
          {form?.previewUrl && (
            <Tooltip
              hasArrow
              label={hasCopied ? 'Link copied!' : 'Copy link'}
              placement='top'
            >
              <IconButton
                variant='unstyled'
                visibility='hidden'
                _groupHover={{ visibility: 'visible' }}
                aria-label='Copy Link'
                h='100%'
                m='0'
                size='xs'
                icon={
                  <FontAwesomeIcon
                    onClick={e => {
                      e.stopPropagation()
                      copyLinkToClipboard()
                    }}
                    color={'#4A5568'}
                    icon={faLink}
                    size={'lg'}
                  />
                }
              />
            </Tooltip>
          )}
          <Tooltip hasArrow label='Open FaceFile in new tab' placement='top'>
            <IconButton
              variant='unstyled'
              visibility='hidden'
              _groupHover={{ visibility: 'visible' }}
              aria-label='Open FaceFile in new tab'
              h='100%'
              m='0'
              size='xs'
              icon={
                <FontAwesomeIcon
                  onClick={onPreviewClick}
                  color={'#4A5568'}
                  icon={faArrowUpRightFromSquare}
                  size={'lg'}
                />
              }
            />
          </Tooltip>
        </Flex>
      }
    />
  )
}
