import { FC } from 'react'
import { ContactFormSettingsT, InteractionContactT } from 'shared/types/model'

type Props = {
  contactForm: ContactFormSettingsT
  contact: InteractionContactT
  setContact: (c: InteractionContactT) => void
}

const ContactForm: FC<Props> = ({ contactForm, contact, setContact }) => {
  const inputClassName =
    'w-full min-w-0	outline-none h-12 border-b border-gray-200 focus:border-gray-600 text-lg pt-6'

  const renderNameInput = () => {
    if (contactForm.name) {
      return (
        <input
          type='text'
          className={inputClassName}
          placeholder='Name'
          value={contact.name || ''}
          onChange={e => setContact({ ...contact, name: e.target.value })}
        />
      )
    }
  }

  const renderEmailInput = () => {
    if (contactForm.email) {
      return (
        <input
          type='email'
          className={inputClassName}
          placeholder='Email'
          value={contact.email || ''}
          onChange={e => setContact({ ...contact, email: e.target.value })}
        />
      )
    }
  }

  const renderPhoneInput = () => {
    if (contactForm.phone) {
      return (
        <input
          type='tel'
          className={inputClassName}
          placeholder='Phone'
          value={contact.phone || ''}
          onChange={e => setContact({ ...contact, phone: e.target.value })}
        />
      )
    }
  }

  return (
    <div className=''>
      {renderNameInput()}
      {renderEmailInput()}
      {renderPhoneInput()}
    </div>
  )
}

export default ContactForm
