import React from 'react'

export interface DrawerContextT {
  drawerIsOpen: boolean
  openDrawer: () => void
  closeDrawer: () => void
}

const Ctx = React.createContext<DrawerContextT>({
  drawerIsOpen: false,
  openDrawer: () => undefined,
  closeDrawer: () => undefined
})

export default Ctx
