import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  arrayUnion
} from 'firebase/firestore'
import { User } from 'firebase/auth'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import moment from 'moment'

import { auth, db, dbOmit } from 'controllers/db'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { receiveForms } from 'model/actions/formsAC'
import { FormT, OverlayTextT } from 'shared/types/model'
import { trackFormCreated } from 'controllers/account'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { logNewFormCreated } from 'controllers/analytics'
import { getRequestHeaders } from 'controllers/headers'
import { dbUpdateUser } from './user'

export const fetchForms = async (accountId: string): Promise<void> => {
  try {
    if (!_.isNil(accountId)) {
      const q = query(
        collection(db, 'forms'),
        where('accountId', '==', accountId)
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const res = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.set(res, doc.id, p)
          })
          store.dispatch(receiveForms(res))
          const isLocalHost =
            location.hostname === 'localhost' ||
            location.hostname === '127.0.0.1'
          if (!isLocalHost) {
            const heap = _.get(window, 'heap')
            if (!_.isNil(heap)) {
              console.log('triggering heap')
              const formsData = _.reduce(
                _.values(res) as FormT[],
                (
                  result: {
                    questionsAmount: 0
                    firstFormCreatedDate: null
                    lastFormCreatedDate: 0
                    formsAmount: 0
                    deletedFormsAmount: 0
                    deletedFormsQuestionsAmount: 0
                  },
                  f: FormT,
                  index: number
                ) => {
                  const questionsAmount = _.size(_.get(f, 'questions', []))
                  _.set(
                    result,
                    'questionsAmount',
                    _.get(result, 'questionsAmount', 0) + questionsAmount
                  )

                  const formCreatedAt = _.get(f, 'createdAt')

                  const prevFirstFormCreatedDate = _.get(
                    result,
                    'firstFormCreatedDate'
                  )
                  if (
                    _.isNil(prevFirstFormCreatedDate) ||
                    prevFirstFormCreatedDate > formCreatedAt
                  ) {
                    _.set(result, 'firstFormCreatedDate', formCreatedAt)
                  }

                  const prevLastFormCreatedDate = _.get(
                    result,
                    'lastFormCreatedDate'
                  )
                  if (
                    _.isNil(prevLastFormCreatedDate) ||
                    prevLastFormCreatedDate < formCreatedAt
                  ) {
                    _.set(result, 'lastFormCreatedDate', formCreatedAt)
                  }

                  _.set(
                    result,
                    'formsAmount',
                    _.get(result, 'formsAmount', 0) + 1
                  )

                  if (f.deleted > 0) {
                    _.set(
                      result,
                      'deletedFormsAmount',
                      _.get(result, 'deletedFormsAmount', 0) + 1
                    )
                    _.set(
                      result,
                      'deletedFormsQuestionsAmount',
                      _.get(result, 'deletedFormsQuestionsAmount', 0) +
                        _.size(_.get(f, 'questions', []))
                    )
                  }

                  if (_.isEqual(index, _.size(res) - 1)) {
                    const firstFormCreatedDate = _.get(
                      result,
                      'firstFormCreatedDate'
                    )
                    const lastFormCreatedDate = _.get(
                      result,
                      'lastFormCreatedDate'
                    )
                    if (!_.isNil(firstFormCreatedDate)) {
                      _.set(
                        result,
                        'firstFormCreatedDate',
                        moment(firstFormCreatedDate).format('DD MMM YY')
                      )
                    }
                    if (!_.isNil(lastFormCreatedDate)) {
                      _.set(
                        result,
                        'lastFormCreatedDate',
                        moment(lastFormCreatedDate).format('DD MMM YY')
                      )
                    }
                  }
                  return result
                },
                {
                  questionsAmount: 0,
                  firstFormCreatedDate: null,
                  lastFormCreatedDate: 0,
                  formsAmount: 0,
                  deletedFormsAmount: 0,
                  deletedFormsQuestionsAmount: 0
                }
              )
              heap.addUserProperties({
                ...formsData
              })
            }
          }
        },
        err => {
          console.log(`fetchForms error: ${err.message}`)
        }
      )
      addListener('forms', unsubscribe)
    } else {
      console.log('fetchForms error: accountId missing')
      Sentry.captureException(
        new Error('cannot fetch forms, accountId is missing')
      )
    }
  } catch (e) {
    console.log('fetchForms error', e)
    Sentry.captureException(e)
  }
}

export const dbCreateForm = async (
  formId: string,
  params?: Partial<FormT>,
  isGuide?: boolean
) => {
  try {
    const state = store.getState()
    const accountId = state.account.id
    const authUser: User | null = auth.currentUser
    if (authUser) {
      const createdBy = authUser.uid
      const form: FormT = {
        id: formId,
        accountId,
        createdAt: _.now(),
        createdBy,
        deleted: 0,
        ..._.omitBy(params, _.isNil)
      }
      const ref = doc(collection(db, 'forms'), form.id)
      await setDoc(ref, form)
      logNewFormCreated(formId)
      const user = state.user
      const isTracked = _.get(
        user,
        ['stonly', isGuide ? 'formCreatedByGuide' : 'formCreated'],
        false
      )
      if (!isTracked) {
        trackFormCreated(!!isGuide)
      }
    } else {
      Sentry.captureException(
        new Error('cannot create form, auth.currentUser is undefined')
      )
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateForm = async (formId: string, upd: Partial<FormT>) => {
  try {
    const ref = doc(collection(db, 'forms'), formId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    Sentry.captureException(e)
  }
}

// FIXME: lastUsedAt will work incorrectly if the question is public
export const dbAddQuestion = async (questionId: string, formId: string) => {
  try {
    const formRef = doc(collection(db, 'forms'), formId)
    const upd = {
      questions: arrayUnion(questionId),
      syncRequests: arrayUnion(_.now())
    }
    await updateDoc(formRef, upd)
    const isLocalHost =
      location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    if (!isLocalHost) {
      const heap = _.get(window, 'heap')
      const state = store.getState()
      const user = _.get(state, 'user')
      const questionsAdded = _.get(user, 'heap.questionsAdded', 0)
      const newQuestionsAdded = questionsAdded + 1
      if (!_.isNil(user)) {
        dbUpdateUser(user.id, {
          heap: {
            ..._.get(user, 'heap'),
            questionsAdded: newQuestionsAdded
          }
        })
      }
      if (!_.isNil(heap)) {
        heap.addUserProperties({
          questionsAdded: newQuestionsAdded
        })
      }
    }
    // const questionRef = doc(collection(db, 'questions'), questionId)
    // await updateDoc(questionRef, { lastUsedAt: _.now() })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteForm = async (formId: string) => {
  try {
    console.log('dbDeleteForm', formId)
    const ref = doc(collection(db, 'forms'), formId)
    await updateDoc(ref, { deleted: _.now() })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const shareFormWithEmails = async (
  emails: string[],
  copyUrl: string
) => {
  try {
    const url = `${
      process.env.REACT_APP_DYNAMIC_URL as string
    }/account/share-form-with-emails`
    const headers = await getRequestHeaders()
    const state = store.getState()
    const userProfile = getCurrentUserProfile(state)
    const name = _.get(userProfile, 'name')
    await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        emails,
        name: _.get(userProfile, 'email', name),
        url: copyUrl
      })
    })
  } catch (e) {
    console.log('shareFormWithEmails error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateOverlayText = async (
  formId: string,
  overlayText: OverlayTextT
) => {
  try {
    await dbUpdateForm(formId, {
      overlayText,
      syncRequests: arrayUnion(_.now())
    })
  } catch (e) {
    console.log('dbUpdateOverlayText error', e)
    Sentry.captureException(e)
  }
}
