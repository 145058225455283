import { logEmailVerified } from 'controllers/analytics'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const EmailVerified = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const email = searchParams.get('email')
    logEmailVerified(email || 'unknown')
    navigate('/')
  }, [])

  return null
}

export default EmailVerified
