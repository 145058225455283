import * as types from 'model/actionTypes'
import { DictT, FormT } from 'shared/types/model'

interface formsActionT {
  type: string
  forms: DictT<FormT> | null
}

export function receiveForms (forms: DictT<FormT> | null): formsActionT {
  return {
    type: types.RECEIVE_FORMS,
    forms
  }
}
