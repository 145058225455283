import { FC, useMemo, SyntheticEvent, useRef } from 'react'
import { Box, Text, Tag, HStack, IconButton } from '@chakra-ui/react'
import _ from 'lodash'
import {
  CreatableSelect,
  GroupBase,
  MultiValue,
  chakraComponents,
  OptionProps,
  GroupHeadingProps
} from 'chakra-react-select'
import { useSelector } from 'model/hooks'
import { dbCreateTemplateTag } from 'controllers/templates'
import { generateId } from 'controllers/db'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-regular-svg-icons'
import RenameTemplateTagModal, {
  IRenameTemplateTagModal
} from 'modals/RenameTemplateTagModal'

type TagOptionT = {
  value: string
  label: string
  colorScheme: string | null
}

type Props = {
  tagsIds: string[]
  setTagsIds: (tagsIds: string[]) => void
}

const TemplateTagsSelect: FC<Props> = ({ tagsIds, setTagsIds }) => {
  const renameTagRef = useRef<IRenameTemplateTagModal>(null)
  const templatesTags = useSelector(state => state.templatesTags)

  const options = useMemo(() => {
    return _.map(templatesTags, (label, value) => {
      return {
        value,
        label,
        colorScheme: 'gray'
      }
    })
  }, [templatesTags])

  const value = useMemo(() => {
    const optionsDict = _.keyBy(options, 'value')
    return _.map(tagsIds, tagId => optionsDict[tagId])
  }, [options, tagsIds])

  const onChange = (opts: MultiValue<TagOptionT>) => {
    console.log('onChange: opts', opts)
    const tagsIds = _.map(opts, opt => opt.value)
    setTagsIds(tagsIds)
  }

  const onEditTag = (e: SyntheticEvent, tagId: string) => {
    e.stopPropagation()
    renameTagRef.current?.open(tagId)
  }

  const customComponents = {
    GroupHeading: (
      props: GroupHeadingProps<TagOptionT, true, GroupBase<TagOptionT>>
    ) => (
      <chakraComponents.GroupHeading {...props}>
        <Text fontWeight={'normal'} fontSize='xs'>
          {props.data.label}
        </Text>
      </chakraComponents.GroupHeading>
    ),
    Option: ({
      children,
      ...props
    }: OptionProps<TagOptionT, true, GroupBase<TagOptionT>>) => (
      <chakraComponents.Option {...props}>
        <HStack
          w='full'
          justify={'space-between'}
          color='gray.400'
          role='group'
        >
          <Tag colorScheme={props.data.colorScheme || 'gray'}>{children}</Tag>
          <IconButton
            variant={'unstyled'}
            size='xs'
            aria-label='edit tag'
            icon={<FontAwesomeIcon icon={faPen} />}
            onClick={e => onEditTag(e, props.data.value)}
            display='none'
            _groupHover={{ display: 'block' }}
          />
        </HStack>
      </chakraComponents.Option>
    )
  }

  const onCreateTag = (inputValue: string) => {
    console.log('onCreateTag', inputValue)
    if (!_.isEmpty(inputValue)) {
      const id = generateId()
      dbCreateTemplateTag(id, inputValue)
    }
  }

  return (
    <Box w='full' maxW={'80'}>
      <CreatableSelect<TagOptionT, true, GroupBase<TagOptionT>>
        maxMenuHeight={800}
        isMulti
        closeMenuOnSelect={false}
        name='emails'
        options={[
          {
            label: 'Select an option or create one',
            options
          }
        ]}
        placeholder=''
        value={value}
        onChange={onChange}
        onCreateOption={onCreateTag}
        size='sm'
        components={customComponents}
        chakraStyles={{
          container: provided => ({
            ...provided,
            bgColor: 'white',
            width: 'full',
            borderColor: 'gray.200'
          }),
          indicatorsContainer: provided => ({
            ...provided,
            display: 'none'
          })
        }}
      />
      <RenameTemplateTagModal ref={renameTagRef} />
    </Box>
  )
}

export default TemplateTagsSelect
