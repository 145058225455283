import types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { AppConfT } from 'shared/types/model'

const initialState: AppConfT | null = null

export default function (
  state: typeof initialState = initialState,
  action: AnyAction
): any {
  switch (action.type) {
    case types.RECEIVE_APP_CONF:
      return action.appConf
    default:
      return state
  }
}
