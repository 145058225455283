import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState,
  useMemo
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  HStack,
  Text,
  Button,
  IconButton,
  Box,
  VStack,
  Flex,
  Badge
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { QuestionT, DictT, TemplateSectionsT } from 'shared/types/model'
import { arrayInsertAt } from 'shared/utils/array'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faXmark } from '@fortawesome/pro-solid-svg-icons'
import VideoPlayer from 'components/VideoPlayer'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import ContactForm from 'components/ContactForm'

export interface IViewTemplateMobileModal {
  open: (templateId: string, index: number) => void
}

type Props = {}

const ViewTemplateMobileModal: ForwardRefRenderFunction<
  IViewTemplateMobileModal,
  Props
> = (props: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [templateId, setTemplateId] = useState<string>()
  const [index, setIndex] = useState<number>(0)
  const templates = useSelector(state => state.templates)
  const questionsDict: DictT<QuestionT> = useSelector(
    state => state.questionsLibrary
  )
  const templatesSections: TemplateSectionsT = useSelector(
    state => state.templatesSections
  )

  useImperativeHandle(ref, () => ({
    open: (templateId: string, index: number) => {
      setTemplateId(templateId)
      setIndex(index)
      onOpen()
    }
  }))

  const template = useMemo(() => {
    if (templateId) {
      return _.get(templates, templateId)
    }
  }, [templates, templateId])

  const questionsIds = useMemo(() => {
    if (template) {
      const res = [...template.questions]
      if (template.contactFormQuestionId) {
        const cfqIndex = _.indexOf(res, template.contactFormQuestionId)
        return arrayInsertAt(res, cfqIndex + 1, null)
      } else {
        return res
      }
    } else {
      return []
    }
  }, [template])

  console.log('template', template)
  console.log('questionsIds', questionsIds)

  const currentQuestionId = useMemo(() => {
    return _.get(questionsIds, index)
  }, [questionsIds, index])

  const question = useMemo(() => {
    if (currentQuestionId) {
      return questionsDict[currentQuestionId]
    }
  }, [currentQuestionId, questionsDict])

  const renderHeader = () => {
    const sectionId = _.get(template, 'sectionId')
    const sectionLabel =
      sectionId &&
      _.get(templatesSections, ['sections', sectionId, 'label'], '')
    console.log('sectionId', sectionId)
    return (
      <VStack w='full'>
        <HStack w='full' justify={'space-between'} px={4} pt={6}>
          <Button
            size='sm'
            onClick={onClose}
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            flexShrink={0}
            variant='ghost'
            pl={0}
          >
            <Text color='gray.600'>Back</Text>
          </Button>
          <IconButton
            aria-label='close'
            icon={<FontAwesomeIcon icon={faXmark} />}
            onClick={onClose}
          />
        </HStack>
        <HStack
          w='full'
          zIndex={2}
          px={4}
          pt={2}
          justify='space-between'
          align='center'
        >
          <Text color='gray.800' fontWeight={'semibold'}>
            {template?.title}
          </Text>
          <Badge variant='outline' color='gray.400'>
            {sectionLabel}
          </Badge>
        </HStack>
      </VStack>
    )
  }

  const renderVideo = () => {
    if (currentQuestionId === null) {
      return (
        <Box position='absolute' top={0} left={0} w='full' h='full'>
          <VStack spacing={1} pt={'40%'} justify='flex-start' h='full' px={4}>
            <ContactForm />
          </VStack>
        </Box>
      )
    } else if (question && question.mediaUrl) {
      return (
        <Box position='absolute' top={0} left={0} w='full' h='full'>
          <VideoPlayer
            mediaUrl={question.mediaUrl}
            autoplay
            showControls={false}
            thumbnail={question.thumbnail}
            key={question.mediaUrl}
          />
        </Box>
      )
    }
  }

  const renderButtonLeft = () => {
    if (index === 0) {
      return <Flex flex={1} flexShrink={0} />
    } else {
      return (
        <Button
          variant={'outline'}
          color='gray.200'
          colorScheme={'whiteAlpha'}
          size='lg'
          leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          flexBasis='50%'
          _focus={{ bg: 'transparent', color: 'gray.200' }}
          _active={{ bg: 'transparent', color: 'gray.200' }}
          onClick={() => setIndex(index - 1)}
        >
          Previous
        </Button>
      )
    }
  }

  const renderButtonRight = () => {
    if (index === _.size(questionsIds) - 1) {
      return <Flex flex={1} flexShrink={0} />
    } else {
      return (
        <Button
          variant={'outline'}
          color='gray.200'
          size='lg'
          colorScheme={'whiteAlpha'}
          flexBasis='50%'
          _focus={{ bg: 'transparent', color: 'gray.200' }}
          _active={{ bg: 'transparent', color: 'gray.200' }}
          rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={() => setIndex(index + 1)}
        >
          Next
        </Button>
      )
    }
  }

  const renderQuestionsSwitch = () => {
    return (
      <Box position={'absolute'} bottom={0} left={0} w='full' px={4} pb={6}>
        <VStack bg='blackAlpha.500' p={4} pt={6} spacing={6} w='full'>
          <HStack w='full'>
            {renderButtonLeft()}
            {renderButtonRight()}
          </HStack>
          <Text
            color='white'
            fontWeight={'bold'}
            textShadow='md'
            textAlign={'left'}
            w='full'
          >
            <Text as='span' fontWeight={'normal'} color='whiteAlpha.800'>
              Step {index + 1} of {_.size(questionsIds)}:
            </Text>{' '}
            {currentQuestionId === null ? 'Contact form' : question?.text}
          </Text>
        </VStack>
      </Box>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='full' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody w='full' h='full' p='0' overflow={'hidden'}>
          {renderVideo()}
          {renderHeader()}
          {renderQuestionsSwitch()}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ViewTemplateMobileModal)
