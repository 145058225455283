import { FC, useEffect, useState, useMemo } from 'react'
import { Tag, Flex, HStack, Text, Tooltip } from '@chakra-ui/react'

import Answers from 'pages/form/Answers'
import _ from 'lodash'
import { logResponsePlayed } from 'controllers/analytics'
import { useSelector } from 'model/hooks'
import { getAllQuestions } from 'model/selectors/questions'
import { AnswerT, DictT, QuestionT, ResponseT } from 'shared/types/model'
import { dbFetchAnswers } from 'controllers/responses'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobot } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  responseId: string
  onBack?: () => void
}

const Response: FC<Props> = ({ responseId, onBack }) => {
  const questions = useSelector(getAllQuestions)
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>()
  const [answers, setAnswers] = useState<DictT<AnswerT>>({})
  const navigate = useNavigate()

  useEffect(() => {
    logResponsePlayed(responseId)
  }, [responseId])

  const response: ResponseT | undefined = useSelector(state =>
    responseId ? _.get(state.responses, responseId) : undefined
  )

  useEffect(() => {
    if (
      response &&
      !_.isEmpty(response.answersIds) &&
      (!selectedAnswerId || !_.includes(response.answersIds, selectedAnswerId))
    ) {
      setSelectedAnswerId(response.answersIds[0])
    }
  }, [selectedAnswerId, response])

  const currentAnswerIndex = useMemo(() => {
    if (response && response.answersIds && selectedAnswerId) {
      return _.indexOf(response.answersIds, selectedAnswerId)
    } else {
      return 0
    }
  }, [selectedAnswerId, response])

  const answersAr = useMemo(() => {
    if (response && response.answersIds && answers) {
      return _.compact(_.map(response.answersIds, aId => answers[aId]))
    } else {
      return []
    }
  }, [response, answers])

  console.log('answersAr', answersAr)

  const answer = useMemo(() => {
    if (selectedAnswerId) {
      return _.get(answers, selectedAnswerId)
    } else {
      return null
    }
  }, [answers, selectedAnswerId])

  const question: QuestionT | null =
    answer && _.get(questions, answer.questionId)

  useEffect(() => {
    if (!responseId) return
    console.log('RESPONSE, responseId changed')
    const run = async () => {
      const res = await dbFetchAnswers(responseId)
      setAnswers(res)
    }
    run()
  }, [responseId])

  const onBackClick = () => {
    if (onBack) {
      onBack()
    } else {
      navigate('/responses')
    }
  }

  if (_.isNil(answer) || _.isNil(response) || _.isNil(question)) {
    console.log('answer', answer, 'response', response, 'question', question)
    return null
  }

  const renderAIAnalysis = () => {
    return (
      <HStack w='full' justify={'space-between'} px={{ lg: '12', base: 0 }}>
        {response.evaluation ? (
          <Tooltip label={response.evaluation}>
            <HStack>
              <Text fontSize={'sm'} fontWeight={'semibold'}>
                AI Analysis
              </Text>
              <FontAwesomeIcon icon={faRobot} />
            </HStack>
          </Tooltip>
        ) : (
          <Flex />
        )}
        {response.score ? (
          <Tag colorScheme='teal' size='sm'>
            Score {response.score}
          </Tag>
        ) : (
          <Flex />
        )}
      </HStack>
    )
  }

  return (
    <Flex flexGrow={1} p={{ lg: '2', base: 0 }} direction={'column'}>
      {renderAIAnalysis()}
      <Answers
        selectedAnswerId={selectedAnswerId}
        response={response}
        answer={answer}
        currentAnswerIndex={currentAnswerIndex}
        answersAr={answersAr}
        question={question}
        setSelectedAnswerId={setSelectedAnswerId}
        onBack={onBackClick}
      />
    </Flex>
  )
}

export default Response
