import { Text } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {}

const ApplicantEvaluation: FC<Props> = () => {
  return <Text>ApplicantEvaluation</Text>
}

export default ApplicantEvaluation
