import { FC, useState, useRef } from 'react'
import { HStack, FormControl, Input, IconButton } from '@chakra-ui/react'
import { IndustryT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import TemplateSelect from 'pages/settingsPages/settingsOnboarding/TemplateSelect'

type Props = {
  industry: IndustryT
  onTitleChange: (industryId: string, v: string) => void
  onSelectTemplate: (industryId: string, templateId: string) => void
  onDelete?: (industryId: string) => void
}

const IndustryRow: FC<Props> = ({
  industry,
  onTitleChange,
  onSelectTemplate,
  onDelete
}) => {
  const [value, setValue] = useState(industry.title)
  const ref = useRef<HTMLInputElement>(null)

  const updateTitle = () => {
    onTitleChange(industry.id, value || '')
  }

  return (
    <HStack w='full'>
      <FormControl>
        <Input
          ref={ref}
          id={industry.id}
          type='text'
          size='sm'
          value={value}
          placeholder='Industry name'
          onBlur={updateTitle}
          onChange={e => setValue(e.target.value)}
          onKeyPress={async e => {
            if (e.key === 'Enter') {
              ref.current?.blur()
            }
          }}
        />
      </FormControl>
      <TemplateSelect
        value={industry.templateId}
        onSelect={tId => onSelectTemplate(industry.id, tId)}
      />
      {onDelete && (
        <IconButton
          size='sm'
          aria-label='delete row'
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => onDelete(industry.id)}
        />
      )}
    </HStack>
  )
}

export default IndustryRow
