import { FC, MouseEvent, useState, useEffect, useRef } from 'react'
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Input,
  IconButton,
  Text,
  VStack
} from '@chakra-ui/react'
import validator from 'validator'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenLine } from '@fortawesome/pro-solid-svg-icons/faPenLine'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import { useSelector } from 'model/hooks'
import FilesPickerButton from 'components/FilesPickerButton'
import { dbUpdateAccountProfile } from 'controllers/profiles'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { AccountProfileT, DictT } from 'shared/types/model'
import { FileT } from 'shared/types/storage'
import storage from 'controllers/storage'
import {
  logSettingsFaviconAdded,
  logSettingsFaviconRemoved,
  logSettingsLogoAdded,
  logSettingsLogoRemoved
} from 'controllers/analytics'
import { convertToIco, resizeFile, resizeFileSquare } from 'utils/imageResizer'
import SettingsCardTitle from 'components/settings/SettingsCardTitle'

const SettingsAccount: FC = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const redirectUrlInputRef = useRef<HTMLInputElement>(null)
  const customDomainInputRef = useRef<HTMLInputElement>(null)
  const [name, setName] = useState('')
  const [redirectUrl, setRedirectUrl] = useState('')
  const [redirectUrlError, setRedirectUrlError] = useState(false)
  const [customDomainUrl, setCustomDomainUrl] = useState('')
  const [customDomainUrlError, setCustomDomainUrlError] = useState(false)
  const profile = useSelector(getCurrentUserProfile)
  const accountProfile: AccountProfileT | null = useSelector(
    state => state.accountProfile
  )

  useEffect(() => {
    if (accountProfile) {
      setName(accountProfile.name || '')
      setRedirectUrl(accountProfile.logoRedirectUrl || '')
      setCustomDomainUrl(accountProfile.customDomainUrl || '')
    }
  }, [accountProfile])

  const onRedirectUrlChanged = () => {
    const url =
      _.isEmpty(redirectUrl) || _.startsWith(redirectUrl, 'https://')
        ? redirectUrl
        : `https://${redirectUrl}`
    const isUrl = validator.isURL(url, { protocols: ['https'] })
    if (accountProfile && (isUrl || _.isEmpty(redirectUrl))) {
      setRedirectUrlError(false)
      dbUpdateAccountProfile(accountProfile.id, {
        logoRedirectUrl: url
      })
    } else if (!isUrl && !_.isEmpty(redirectUrl)) {
      setRedirectUrlError(true)
    }
  }

  const onCustomDomainUrlChanged = () => {
    const url =
      _.isEmpty(customDomainUrl) || _.startsWith(customDomainUrl, 'https://')
        ? customDomainUrl
        : `https://${customDomainUrl}`
    const isUrl = validator.isURL(url, { protocols: ['https'] })
    if (accountProfile && (isUrl || _.isEmpty(customDomainUrl))) {
      setCustomDomainUrlError(false)
      dbUpdateAccountProfile(accountProfile.id, {
        customDomainUrl: url
      })
    } else if (!isUrl && !_.isEmpty(customDomainUrl)) {
      setCustomDomainUrlError(true)
    }
  }

  const onRemoveLogoClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (accountProfile) {
      dbUpdateAccountProfile(accountProfile.id, {
        logoUrl: undefined
      })
      logSettingsLogoRemoved(accountProfile.logoUrl || '')
    }
  }

  const onLogoUploaded = (files: FileT[]) => {
    if (_.size(files) > 0 && accountProfile) {
      const f = files[0]
      dbUpdateAccountProfile(accountProfile.id, { logoUrl: f.url })
      logSettingsLogoAdded(f.url)
    }
  }

  const onFaviconRemoveClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (accountProfile) {
      dbUpdateAccountProfile(accountProfile.id, {
        favicons: undefined
      })
      logSettingsFaviconRemoved(_.get(accountProfile, 'favicons.icon192', ''))
    }
  }

  const processFaviconFiles = async (files: File[]) => {
    console.log('processFaviconFiles', files)
    if (accountProfile && !_.isEmpty(files)) {
      const f: File = files[0]
      const storagePath = `/accounts/${accountProfile.id}/`
      const sizes = [32, 128, 180, 192]
      const urls = await Promise.all(
        _.map(sizes, async size => {
          const resizedFile = await resizeFileSquare(f, size)
          const fileInfo = await storage.saveFileObject(
            resizedFile,
            storagePath + `/favicon${size}.png`
          )
          return _.get(fileInfo, 'url', null)
        })
      )
      console.log('sizes', sizes)
      console.log('urls', urls)
      const favicons: DictT<string> = {}
      _.forEach(sizes, (size, i) => {
        const key = `icon${size}`
        const url = urls[i]
        if (!_.isNil(url)) {
          favicons[key] = url
        }
      })
      const icoResizedFile = await resizeFileSquare(f, 32)
      const icoFile = await convertToIco(icoResizedFile)
      const faviconInfo = await storage.saveFileObject(
        icoFile,
        storagePath + '/favicon.ico'
      )
      if (faviconInfo) {
        favicons.ico = faviconInfo?.url
      }
      dbUpdateAccountProfile(accountProfile.id, { favicons })
      logSettingsFaviconAdded(favicons.icon192)
    }
  }

  const renderName = () => {
    return (
      <FormControl>
        <FormLabel>Organization name</FormLabel>
        <Input
          ref={nameInputRef}
          type='text'
          size='sm'
          rounded='md'
          maxW='96'
          value={name}
          placeholder='name'
          onChange={e => setName(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              nameInputRef.current?.blur()
            }
          }}
          onBlur={() =>
            accountProfile &&
            dbUpdateAccountProfile(accountProfile.id, { name })
          }
          onSubmit={() => nameInputRef.current?.blur()}
        />
      </FormControl>
    )
  }

  const renderRedirectUrl = () => {
    return (
      <FormControl isInvalid={redirectUrlError}>
        <FormLabel>Redirect url when clicking the logo</FormLabel>
        <Input
          ref={redirectUrlInputRef}
          type='url'
          size='sm'
          rounded='md'
          maxW='96'
          onFocus={() => setRedirectUrlError(false)}
          value={redirectUrl}
          placeholder='https://www.theqtopanga.com/'
          onChange={e => setRedirectUrl(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              redirectUrlInputRef.current?.blur()
            }
          }}
          onBlur={onRedirectUrlChanged}
          onSubmit={() => redirectUrlInputRef.current?.blur()}
        />
        <FormErrorMessage>
          Enter a valid url that starts with https://
        </FormErrorMessage>
      </FormControl>
    )
  }

  const renderCustomDomainInput = () => {
    return (
      <FormControl isInvalid={customDomainUrlError}>
        <HStack
          justify={'space-between'}
          align='center'
          pb={{ base: 2, lg: 0 }}
        >
          <FormLabel>Use a custom domain</FormLabel>
          <Button
            colorScheme='blue'
            variant='link'
            fontSize='md'
            lineHeight='6'
            fontWeight='medium'
          >
            How to setup
          </Button>
        </HStack>
        <Input
          ref={customDomainInputRef}
          type='url'
          size='sm'
          rounded='md'
          w='full'
          onFocus={() => setCustomDomainUrlError(false)}
          value={customDomainUrl}
          placeholder='https://forms.mycustomdomain.com'
          onChange={e => setCustomDomainUrl(e.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault()
              customDomainInputRef.current?.blur()
            }
          }}
          onBlur={onCustomDomainUrlChanged}
          onSubmit={() => customDomainInputRef.current?.blur()}
        />
        <FormErrorMessage pt={{ base: 2, lg: 0 }}>
          Enter a valid url that starts with https://
        </FormErrorMessage>
        {!customDomainUrlError && (
          <FormHelperText pt={{ base: 2, lg: 0 }}>
            FaceFile forms you create will show up on this custom domain
          </FormHelperText>
        )}
      </FormControl>
    )
  }

  const renderLogoUpload = () => {
    if (accountProfile) {
      return (
        <VStack align='flex-start' minW='64' spacing={4} flex={1}>
          <Text fontWeight={'semibold'} color='gray.800'>
            Company Logo
          </Text>
          <FilesPickerButton
            userId={profile ? profile.id : ''}
            storagePath={`/accounts/${accountProfile.id}/`}
            onComplete={onLogoUploaded}
            fileProcessor={resizeFile}
            options={{
              accept: {
                'image/*': ['.png', '.jpeg', '.jpg', '.heic']
              },
              multiple: false
            }}
            storage={storage}
            generateId={() => 'logo'}
            wrapperOptions={{
              width: '70%'
            }}
            hideProgress
          >
            <HStack
              w='full'
              spacing={4}
              color='gray.600'
              position='relative'
              _hover={{
                color: 'gray.900',
                '#account-avatar-edit-avatar': {
                  filter: 'brightness(50%)',
                  transition: 'all 0.3s ease'
                },
                '#account-avatar-edit-icon': {
                  opacity: 1
                },
                '#account-avatar-remove-icon': {
                  opacity: 1
                }
              }}
            >
              <Box position='relative'>
                <Avatar
                  size='lg'
                  bg='gray.300'
                  name={accountProfile.name}
                  src={accountProfile.logoUrl}
                  id='account-avatar-edit-avatar'
                />
                {accountProfile.logoUrl && (
                  <Box position='absolute' top='30%' left='0' right='0' id='account-avatar-edit-icon' opacity={0}>
                    <FontAwesomeIcon icon={faPenLine} color='white' />
                  </Box>
                )}
              </Box>
              <Text>Upload a logo</Text>
              {accountProfile.logoUrl && (
                <IconButton
                  position='absolute'
                  right={0}
                  top={0}
                  aria-label='delete image'
                  icon={<FontAwesomeIcon icon={faTimes} />}
                  rounded='full'
                  size='xs'
                  onClick={onRemoveLogoClick}
                  id='account-avatar-remove-icon'
                  opacity={0}
                />
              )}
            </HStack>
          </FilesPickerButton>
        </VStack>
      )
    }
  }

  const renderFaviconUpload = () => {
    if (accountProfile) {
      return (
        <VStack align='flex-start' minW='64' spacing={4} flex={1}>
          <Text fontWeight={'semibold'} color='gray.800'>
            Add a favicon
          </Text>

          <FilesPickerButton
            userId={profile ? profile.id : ''}
            storagePath={`/accounts/${accountProfile.id}/`}
            onComplete={() => null}
            fileProcessor={f => resizeFileSquare(f, 400)}
            options={{
              accept: {
                'image/*': ['.png', '.jpeg', '.jpg']
              },
              multiple: false
            }}
            storage={storage}
            generateId={() => 'favicon'}
            hideProgress
            onFilesPicked={processFaviconFiles}
          >
            <HStack
              w='full'
              spacing={4}
              color='gray.600'
              position='relative'
              _hover={{
                color: 'gray.900',
                '#account-favicon-edit-avatar': {
                  filter: 'brightness(50%)',
                  transition: 'all 0.3s ease'
                },
                '#account-favicon-edit-icon': {
                  opacity: 1
                },
                '#account-favicon-remove-icon': {
                  opacity: 1
                }
              }}
            >
              <Box position='relative'>
                <Avatar
                  size='lg'
                  bg='gray.300'
                  src={_.get(accountProfile, 'favicons.icon192')}
                  id='account-favicon-edit-avatar'
                />
                {accountProfile.favicons && (
                  <Box position='absolute' top='30%' left='0' right='0' id='account-favicon-edit-icon' opacity={0}>
                    <FontAwesomeIcon icon={faPenLine} color='white' />
                  </Box>
                )}
              </Box>
              <Text>Upload a favicon</Text>
              {accountProfile.favicons && (
                <IconButton
                  position='absolute'
                  right={0}
                  top={0}
                  aria-label='delete image'
                  icon={<FontAwesomeIcon icon={faTimes} />}
                  rounded='full'
                  size='xs'
                  onClick={onFaviconRemoveClick}
                  id='account-favicon-remove-icon'
                  opacity={0}
                />
              )}
            </HStack>
          </FilesPickerButton>
        </VStack>
      )
    }
  }

  return (
    <VStack
      px={{ lg: '8', base: '0' }}
      py={{ lg: '4', base: '0' }}
      overflowY='auto'
      bg='white'
      spacing={8}
      w='full'
      h='full'
      align='start'
    >
      <SettingsCardTitle
        title='Account'
        description='Configure the name, logo and/or domain for the video forms your applicants will see'
      />
      {renderName()}
      <HStack w='full'>
        {renderLogoUpload()}
        {renderFaviconUpload()}
      </HStack>
      {renderRedirectUrl()}
      {renderCustomDomainInput()}
    </VStack>
  )
}

export default SettingsAccount
