import types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { AIConfT } from 'shared/types/model'

const initialState: AIConfT | null = null

export default function (
  state: typeof initialState = initialState,
  action: AnyAction
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_AI_CONF:
      return action.aiConf
    default:
      return state
  }
}
