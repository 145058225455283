import { AnyAction } from '@reduxjs/toolkit'
import * as types from 'model/actionTypes'
import { DictT } from 'shared/types/model'

const initialState: DictT<string> = {}

export default function (
  state = initialState,
  action: AnyAction
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_TEMPLATES_TAGS:
      return action.tags
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
