export const CLEAR = 'CLEAR'
export const LOGOUT = 'LOGOUT'
export const RECEIVE_AUTH_DATA = 'RECEIVE_AUTH_DATA'
export const RECEIVE_FORMS = 'RECEIVE_FORMS'
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS'
export const RECEIVE_QUESTIONS_LIBRARY = 'RECEIVE_QUESTIONS_LIBRARY'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES'
export const RECEIVE_TEMPLATES_SECTIONS = 'RECEIVE_TEMPLATES_SECTIONS'
export const RECEIVE_TEMPLATES_TAGS = 'RECEIVE_TEMPLATES_TAGS'
export const RECEIVE_RESPONSES = 'RECEIVE_RESPONSES'
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT'
export const RECEIVE_ACCOUNT_PROFILE = 'RECEIVE_ACCOUNT_PROFILE'
export const RECEIVE_USERS_PROFILES = 'RECEIVE_USERS_PROFILES'
export const RECEIVE_USER_ACCOUNTS = 'RECEIVE_USER_ACCOUNTS'
export const RECEIVE_PRICES = 'RECEIVE_PRICES'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION'
export const RECEIVE_NOTIFICATIONS_SETTINGS = 'RECEIVE_NOTIFICATIONS_SETTINGS'
export const RECEIVE_INVITATIONS = 'RECEIVE_INVITATIONS'
export const RECEIVE_APP_CONF = 'RECEIVE_APP_CONF'
export const RECEIVE_AI_CONF = 'RECEIVE_AI_CONF'
export const RECEIVE_ONBOARDING = 'RECEIVE_ONBOARDING'
export const RECEIVE_ONBOARDING_CONTACT_ID = 'RECEIVE_ONBOARDING_CONTACT_ID'
export const RESET_ONBOARDING = 'RESET_ONBOARDING'
export const RECEIVE_CFQS = 'RECEIVE_CFQS'

export default {
  CLEAR,
  LOGOUT,
  RECEIVE_AUTH_DATA,
  RECEIVE_USER,
  RECEIVE_TEMPLATES,
  RECEIVE_TEMPLATES_SECTIONS,
  RECEIVE_TEMPLATES_TAGS,
  RECEIVE_QUESTIONS,
  RECEIVE_FORMS,
  RECEIVE_RESPONSES,
  RECEIVE_ACCOUNT,
  RECEIVE_ACCOUNT_PROFILE,
  RECEIVE_USERS_PROFILES,
  RECEIVE_USER_ACCOUNTS,
  RECEIVE_QUESTIONS_LIBRARY,
  RECEIVE_PRICES,
  RECEIVE_SUBSCRIPTION,
  RECEIVE_NOTIFICATIONS_SETTINGS,
  RECEIVE_INVITATIONS,
  RECEIVE_APP_CONF,
  RECEIVE_AI_CONF,
  RECEIVE_ONBOARDING,
  RECEIVE_ONBOARDING_CONTACT_ID,
  RESET_ONBOARDING,
  RECEIVE_CFQS
}
