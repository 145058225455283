import { FC, useEffect, useMemo, useRef } from 'react'
import {
  Flex,
  // HStack,
  // Tabs,
  Show,
  VStack,
  useBreakpointValue,
  StackDivider
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { arrayUnion } from 'firebase/firestore'

// import ResponsesPanel from 'pages/form/ResponsesPanel'
// import Response from 'pages/form/Response'
import { useSelector } from 'model/hooks'
import { FormT, TemplateT } from 'shared/types/model'
import { getResponsesByFormId } from 'model/selectors/responses'
import FormEmptyState from 'pages/form/FormEmptyState'
// import { dbDeleteResponse } from 'controllers/responses'
import EditorNavbar from 'components/EditorNavbar'
import SelectQuestionModal, {
  ISelectQuestionModal
} from 'modals/SelectQuestionModal'
import TemplatesModal, { ITemplatesModal } from 'modals/TemplatesModal'
import { dbCreateForm, dbUpdateForm } from 'controllers/forms'
// import SelectType from 'pages/formEdit/SelectType'
// import FormEditMain from 'pages/formEdit/FormEditMain'
import RemoveFormModal, { IRemoveFormModal } from 'modals/RemoveFormModal'
import {
  logFormContactformAdded,
  logFormStartFromScratch,
  logFormTemplateSelected,
  logFormTitleSaved
} from 'controllers/analytics'
// import { getBlockedResponses } from 'model/selectors/paymentPlans'
import NavBarMobile from 'components/NavBarMobile'
import CreateFormMobileEmptyState from 'pages/formEdit/CreateFormMobileEmptyState'
import EditorNavbarMobile from 'components/EditorNavbarMobile'
// import CustomizeOverlayText from 'pages/formEdit/CustomizeOverlayText'
// import FormEditWithCfq from 'pages/formEdit/FormEditWithCfq'
import CustomizeWelcomePageModal, {
  ICustomizeWelcomePageModal
} from 'modals/CustomizeWelcomePageModal'
import ResponseRow from 'components/ResponseRow'
import ResponsesTableHeader from 'components/ResponsesTableHeader'
import ContactFormSettingsModal, {
  IContactFormSettingsModal
} from 'modals/ContactFormSettingsModal'
import { getAllQuestions } from 'model/selectors/questions'
import FormGenerating from './form/FormGenerating'

type Props = {
  type: string
}

// const TYPES = {
//   responses: {
//     title: 'responses',
//     index: 0
//   },
//   edit: {
//     title: 'edit',
//     index: 1
//   },
//   customize: {
//     title: 'customize',
//     index: 2
//   }
// }

const Form: FC<Props> = () => {
  const { responseId } = useParams()
  const { formId } = useParams() as { formId: string }
  // const allResponses = useSelector(state => state.responses)
  // const blockedResponses = useSelector(getBlockedResponses)
  const navigate = useNavigate()
  const formsDict = useSelector(state => state.forms)
  const form: FormT | undefined = useSelector(state =>
    _.get(state, ['forms', formId as string])
  )
  const questions = useSelector(getAllQuestions)
  const responsesByFormId = useSelector(getResponsesByFormId)
  const selectQuestionModalRef = useRef<ISelectQuestionModal>(null)
  const contactFormRef = useRef<IContactFormSettingsModal>(null)
  const templatesModalRef = useRef<ITemplatesModal>(null)
  const removeFormModalRef = useRef<IRemoveFormModal>(null)
  const customizeWelcomeModalRef = useRef<ICustomizeWelcomePageModal>(null)
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const formQuestions = useMemo(
    () => _.map(_.get(form, 'questions', []), qId => _.get(questions, qId)),
    [form, questions]
  )

  const questionsInProgress = useMemo(() => {
    return _.reduce(formQuestions, (res: string[], q) => {
      if (!_.has(q, 'mediaUrl')) {
        res.push(q.id)
      }
      return res
    }, [])
  }, [form, questions, formQuestions])

  useEffect(() => {
    const formsLoaded = !_.isNil(formsDict)
    if (formsLoaded && !_.isNil(formId) && !_.has(formsDict, formId)) {
      console.log('form creation should be here')
      dbCreateForm(formId)
    }
  }, [formsDict, formId])

  const responses = useMemo(() => {
    if (formId && responsesByFormId) {
      return responsesByFormId[formId]
    } else {
      return []
    }
  }, [responsesByFormId, formId])

  // useEffect(() => {
  //   if (type === TYPES.responses.title && isDesktop) {
  //     const responseIsNotSelected = _.isNil(responseId)
  //     const responseDoesNotExist =
  //       !_.isEmpty(responses) &&
  //       !_.isNil(responseId) &&
  //       !_.has(allResponses, responseId)
  //     const responseIsBlocked =
  //       responses && !_.isNil(responseId) && _.has(blockedResponses, responseId)
  //     if (responseIsNotSelected || responseDoesNotExist || responseIsBlocked) {
  //       const newestResponse = _.find(
  //         responses,
  //         r => !_.has(blockedResponses, r.id)
  //       )
  //       const rId = _.get(newestResponse, 'id')
  //       console.log('newest response id', rId)
  //       if (rId) {
  //         navigate(`/form/${formId}/${rId}`)
  //       } else if (form && _.isEmpty(form.questions)) {
  //         console.log('navigate to edit')
  //         navigate(`/form/${formId}/edit`)
  //       } else {
  //         navigate(`/form/${formId}`)
  //       }
  //     }
  //   }
  // }, [responses, responseId, type, formId, form?.questions, allResponses])

  const onResponseClick = (rId: string) => {
    if (rId !== responseId) {
      navigate(`/form/${formId}/${rId}`)
    }
  }

  // const onDeleteResponse = (responseId: string) => {
  //   console.log('onDeleteResponse', responseId)
  //   dbDeleteResponse(responseId)
  // }

  // const onViewContact = (responseId: string) => {
  //   console.log('onViewContact', responseId)
  //   if (allResponses) {
  //     const r = allResponses[responseId]
  //     if (r && !_.isEmpty(r.email)) {
  //       navigate(`/contacts/${r.email}`)
  //     }
  //   }
  // }

  const onBrowseTemplatesClick = () => {
    templatesModalRef.current?.open(formId)
  }

  const onAddQuestionClick = (qId?: string) => {
    if (isDesktop) {
      selectQuestionModalRef.current?.open(formId, qId)
    } else {
      navigate(`/form/${formId}/add_question`, { state: { qId } })
    }
  }

  const updateForm = async (upd: Partial<FormT>) => {
    console.log('updateForm', upd)
    if (_.isEmpty(form)) {
      await dbCreateForm(formId)
    }
    await dbUpdateForm(formId, upd)
  }

  const onDeleteForm = () => {
    removeFormModalRef.current?.open(formId)
  }

  const setLabel = async (label: string) => {
    console.log('set label', label)
    await updateForm({ label })
    logFormTitleSaved(formId)
  }

  const onUseTemplate = async (t: TemplateT) => {
    const upd: Partial<FormT> = {
      questions: t.questions,
      syncRequests: arrayUnion(_.now()),
      templateId: t.id
    }
    if (t.contactFormQuestionId) {
      upd.contactFormQuestionId = t.contactFormQuestionId
    }
    await updateForm(upd)
    logFormTemplateSelected(t.id)
  }

  const handleContactFormClick = () => {
    const cfQId = form && form.questions && _.last(form.questions)
    if (cfQId) {
      updateForm({
        contactFormQuestionId: cfQId,
        syncRequests: arrayUnion(_.now())
      })
      logFormContactformAdded(form.id, form.templateId)
    }
  }

  const renderFormResponses = () => {
    if (_.isEmpty(responses)) {
      if (!_.isEmpty(questionsInProgress)) {
        return <FormGenerating questions={formQuestions} questionsInProgress={questionsInProgress} />
      }
      return formId && <FormEmptyState formId={formId} />
    } else if (isDesktop || _.isNil(responseId)) {
      return (
        <VStack
          divider={<StackDivider color='gray.200' />}
          w='full'
          spacing={0}
        >
          <ResponsesTableHeader />
          <VStack
            spacing={0}
            w='full'
            overflowY={'auto'}
            divider={<StackDivider color='gray.200' />}
          >
            {_.map(responses, r => (
              <ResponseRow key={r.id} r={r} onClick={onResponseClick} />
            ))}
          </VStack>
        </VStack>
      )
      // return (
      //   <HStack
      //     w='full'
      //     flexShrink={0}
      //     h='full'
      //     maxH='full'
      //     align={'flex-start'}
      //     overflow={'hidden'}
      //   >
      //     <Flex
      //       h='full'
      //       w={{ base: 'full', lg: 'sm' }}
      //       overflow={'hidden'}
      //       // pr={{ base: 4, lg: 0 }}
      //       flexShrink={0}
      //     >
      //       <ResponsesPanel
      //         responses={responses}
      //         selectedResponseId={responseId}
      //         onClick={onResponseClick}
      //         onDeleteResponse={onDeleteResponse}
      //         onViewContact={onViewContact}
      //       />
      //     </Flex>
      //     <Flex w='full' h='full'>
      //       {responseId && (
      //         <Response key={responseId} responseId={responseId} />
      //       )}
      //     </Flex>
      //   </HStack>
      // )
    }
  }

  // const renderQuestions = () => {
  //   return (
  //     <FormEditWithCfq
  //       formId={_.get(form, 'id', '')}
  //       currentQuestionsIds={_.get(form, 'questions', [])}
  //       currentCfqsIds={_.get(form, 'cfqsIds', [])}
  //     />
  //   )
  // if (_.isEmpty(form?.questions)) {
  //   return (
  //     <SelectType
  //       onAddQuestionClick={onAddQuestionClick}
  //       onBrowseTemplatesClick={onBrowseTemplatesClick}
  //     />
  //   )
  // } else if (form) {
  //   return (
  //     <FormEditMain
  //       form={form}
  //       onAddQuestionClick={onAddQuestionClick}
  //       handleContactFormClick={handleContactFormClick}
  //     />
  //   )
  // }
  // }

  const onStartFromScratchClick = () => {
    logFormStartFromScratch(formId)
    selectQuestionModalRef.current?.open(formId)
  }

  const onEditWelcomeClick = () => {
    customizeWelcomeModalRef.current?.open(formId)
  }

  const renderMobileContent = () => {
    if (_.isEmpty(form?.questions)) {
      return (
        <CreateFormMobileEmptyState
          onBrowseTemplatesClick={() =>
            navigate(`/form/${formId}/edit/select_template`)
          }
        />
      )
      // } else if (type === TYPES.edit.title && form) {
      //   return (
      //     <VStack w='full' h='full' overflow={'hidden'}>
      //       <EditorNavbarMobile
      //         form={form}
      //         questionsAmount={_.size(form?.questions)}
      //         onBrowseTemplatesClick={onBrowseTemplatesClick}
      //         onAddQuestionClick={onAddQuestionClick}
      //         onPlaceContactForm={handleContactFormClick}
      //         onDeleteForm={onDeleteForm}
      //         formId={formId}
      //         label={form?.label}
      //         setLabel={setLabel}
      //         isEdit={type === TYPES.edit.title}
      //         responsesAmount={_.size(responses)}
      //       />
      //       <FormEditMain
      //         form={form}
      //         onAddQuestionClick={onAddQuestionClick}
      //         handleContactFormClick={handleContactFormClick}
      //       />
      //     </VStack>
      //   )
      // } else if (type === TYPES.responses.title && !_.isNil(responseId)) {
      //   return (
      //     <Flex w='full' h='full' flexGrow='1'>
      //       {responseId && (
      //         <Response
      //           responseId={responseId}
      //           onBack={() => navigate(`/form/${formId}`)}
      //         />
      //       )}
      //     </Flex>
      //   )
    } else if (form) {
      return (
        <VStack w='full' h='full' overflow={'hidden'}>
          <EditorNavbarMobile
            form={form}
            questionsAmount={_.size(form?.questions)}
            onBrowseTemplatesClick={onBrowseTemplatesClick}
            onAddQuestionClick={onAddQuestionClick}
            onPlaceContactForm={handleContactFormClick}
            onDeleteForm={onDeleteForm}
            formId={formId}
            label={form?.label + ' - responses'}
            setLabel={setLabel}
            // isEdit={type === TYPES.edit.title}
            isEdit
            responsesAmount={_.size(responses)}
          />
          {renderFormResponses()}
        </VStack>
      )
    }
  }

  return (
    <>
      <Show above='lg'>
        <Flex direction='column' h='full' w='full' bg='white'>
          <EditorNavbar
            form={form}
            questionsAmount={_.size(form?.questions)}
            onBrowseTemplatesClick={onBrowseTemplatesClick}
            onAddQuestionClick={onAddQuestionClick}
            onPlaceContactForm={handleContactFormClick}
            onEditWelcomeClick={onEditWelcomeClick}
            onDeleteForm={onDeleteForm}
            onContactFormClick={() => contactFormRef.current?.open(formId)}
            formId={formId}
            label={form?.label + ' - responses'}
            setLabel={setLabel}
            // isEdit={type === TYPES.edit.title}
            isEdit
            responsesAmount={_.size(responses)}
          />
          {renderFormResponses()}
        </Flex>

        <SelectQuestionModal
          ref={selectQuestionModalRef}
          handleContactFormClick={handleContactFormClick}
        />
        <TemplatesModal
          ref={templatesModalRef}
          toStartFromScratch={onStartFromScratchClick}
          onUseTemplate={onUseTemplate}
        />
        <RemoveFormModal ref={removeFormModalRef} />
        <CustomizeWelcomePageModal ref={customizeWelcomeModalRef} />
        <ContactFormSettingsModal ref={contactFormRef} />
      </Show>
      <Show below='lg'>
        <VStack align='flex-start' spacing={0} w='full' h='full' bg='white'>
          {_.isNil(responseId) && <NavBarMobile />}
          {renderMobileContent()}
        </VStack>
      </Show>
    </>
  )
}

export default Form
