import { FC, useState } from 'react'
import {
  Avatar,
  HStack,
  VStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBolt,
  faArrowsRepeat,
  faPowerOff,
  faGear,
  faMessageDots
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import { useNavigate } from 'react-router-dom'
import { dbSignOut, switchAccount } from 'controllers/auth'
import { AccountProfileT } from 'shared/types/model'
import AccountProfileButton from 'components/sidebar/AccountProfileButton'
import { auth } from 'controllers/db'

type Props = {
  onUpgradeClick: () => void
  onSettingsClick: () => void
}

const AccountButton: FC<Props> = ({ onUpgradeClick, onSettingsClick }) => {
  const userProfile = useSelector(getCurrentUserProfile)
  const account = useSelector(state => state.account)
  const userAccounts = useSelector(state => state.userAccounts)
  const subscription = useSelector(state => state.subscription)
  const navigate = useNavigate()
  const [menuKey, setMenuKey] = useState(_.now())

  const switchAccountPopover = () => {
    return (
      <Menu placement='right-end' closeOnBlur>
        <MenuButton _hover={{ bg: 'gray.100' }} w='full'>
          <MenuItem
            icon={<FontAwesomeIcon icon={faArrowsRepeat} fixedWidth />}
            fontWeight='semibold'
            as='div'
          >
            <HStack justify={'space-between'}>
              <Text>Switch account</Text>
              <FontAwesomeIcon icon={faChevronRight} />
            </HStack>
          </MenuItem>
        </MenuButton>
        <MenuList color={'gray.500'}>
          {_.map(userAccounts, (v: AccountProfileT) => (
            <MenuItem
              key={v.id}
              onClick={() => switchAccount(v.id, navigate)}
              isDisabled={v.id === account.id}
            >
              <AccountProfileButton
                label={v.name || 'Unnamed'}
                avatar={v.logoUrl}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
  }

  const menuItems = (
    <MenuList color={'gray.500'}>
      {_.isNil(subscription) && (
        <MenuItem
          icon={<FontAwesomeIcon icon={faBolt} fixedWidth />}
          fontWeight='semibold'
          onClick={() => {
            setMenuKey(_.now())
            onUpgradeClick()
          }}
        >
          Upgrade
        </MenuItem>
      )}
      <MenuItem
        icon={<FontAwesomeIcon icon={faGear} fixedWidth />}
        fontWeight='semibold'
        // onClick={() => navigate('/settings/account')}
        onClick={onSettingsClick}
      >
        Settings
      </MenuItem>
      <MenuItem
        icon={<FontAwesomeIcon icon={faMessageDots} fixedWidth />}
        fontWeight='semibold'
        onClick={() => console.log('open Missive live chat widget')}
        isDisabled
      >
        FaceFile assistant
      </MenuItem>
      {_.size(userProfile?.accounts) > 1 && switchAccountPopover()}
      <MenuItem
        icon={<FontAwesomeIcon icon={faPowerOff} fixedWidth />}
        fontWeight='semibold'
        onClick={() => dbSignOut(navigate)}
      >
        Log out
      </MenuItem>
    </MenuList>
  )

  console.log('user avatar', userProfile?.avatar)

  return (
    <Menu closeOnSelect key={menuKey}>
      <MenuButton>
        <HStack w='full' flexGrow={0} flexShrink={0}>
          <Avatar
            size='sm'
            name={userProfile?.name}
            src={userProfile?.avatar}
            color='white'
            bg='teal.700'
          />
          <VStack align='flex-start' spacing={0} w='full'>
            <Text fontSize={'sm'} fontWeight='medium' color='black'>
              {_.get(userProfile, 'name', auth.currentUser?.email)}
            </Text>
            <Text
              fontSize={'xs'}
              color='gray.500'
              noOfLines={1}
              wordBreak='break-all'
            >
              {_.get(userProfile, 'email')}
            </Text>
          </VStack>
          <Box color='gray.500' fontSize={'0.8em'}>
            <FontAwesomeIcon icon={faChevronDown} />
          </Box>
        </HStack>
      </MenuButton>
      {menuItems}
    </Menu>
  )
}

export default AccountButton
