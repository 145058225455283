import { FC } from 'react'
import _ from 'lodash'
import {
  Text,
  Box,
  Heading,
  VStack,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { getTemplatesBySection } from 'model/selectors/templates'
import {
  TemplateSectionsT,
  TemplateSectionT,
  TemplateT
} from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { NavButton } from 'components/sidebar/NavButton'
import {
  dbEnableTemplate,
  dbUpdateSectionsOrder,
  dbRemoveTemplatesSection,
  dbRemoveTemplate,
  dbUpdateTemplatesOrder
} from 'controllers/templates'
import { arrayMove } from 'shared/utils/array'

type Props = {
  canEdit?: boolean
  onCreateTemplateClick?: (sectionId: string) => void
  onEditTemplateClick?: (
    sectionId: string,
    templateId: string,
    templateName: string
  ) => void
  onEditSectionClick?: (s: TemplateSectionT) => void
  onCreateTemplateSectionClick?: () => void
  onTemplateClick: (templateId: string) => void
  currentTemplateId?: string
}

const TemplatesSideBar: FC<Props> = ({
  canEdit = false,
  onCreateTemplateClick = () => null,
  onEditTemplateClick = () => null,
  onEditSectionClick = () => null,
  onCreateTemplateSectionClick = () => null,
  currentTemplateId,
  onTemplateClick
}) => {
  const templatesBySectionId = useSelector(getTemplatesBySection)
  const templatesSections: TemplateSectionsT = useSelector(
    state => state.templatesSections
  )
  const allTemplates = useSelector(state => state.templates)

  const onMoveSectionClick = (index: number, isUp: boolean) => {
    if (templatesSections.order) {
      const toIndex = isUp ? index - 1 : index + 1
      const newOrder = arrayMove(templatesSections.order, index, toIndex)
      dbUpdateSectionsOrder(newOrder)
    }
  }

  const onMoveTemplateClick = (
    s: TemplateSectionT,
    index: number,
    isUp: boolean
  ) => {
    if (s.templatesOrder) {
      const toIndex = isUp ? index - 1 : index + 1
      const newOrder = arrayMove(s.templatesOrder, index, toIndex)
      dbUpdateTemplatesOrder(s.id, newOrder)
    }
  }

  const renderSectionMenu = (
    s: TemplateSectionT,
    index: number,
    amount: number
  ) => {
    if (canEdit) {
      return (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            visibility='hidden'
            _groupHover={{ visibility: 'visible' }}
            variant={'unstyled'}
            size='sm'
          />
          <MenuList>
            <MenuItem onClick={() => onEditSectionClick(s)}>Edit</MenuItem>
            <MenuItem
              isDisabled={index === 0}
              onClick={() => onMoveSectionClick(index, true)}
            >
              Move Up
            </MenuItem>
            <MenuItem
              isDisabled={index === amount - 1}
              onClick={() => onMoveSectionClick(index, false)}
            >
              Move Down
            </MenuItem>
            <MenuItem
              color='red.500'
              onClick={() => dbRemoveTemplatesSection(s)}
            >
              Remove
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }
  }

  const onEnableTemplateClick = (t: TemplateT) => {
    dbEnableTemplate(t.id, !t.enabled)
  }

  const renderTemplateMenu = (
    t: TemplateT,
    s: TemplateSectionT,
    index: number,
    amount: number
  ) => {
    if (canEdit) {
      return (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            visibility='hidden'
            _groupHover={{ visibility: 'visible' }}
            variant={'unstyled'}
            size='sm'
          />
          <MenuList>
            <MenuItem
              onClick={() => onEditTemplateClick(t.sectionId, t.id, t.title)}
            >
              Edit
            </MenuItem>
            <MenuItem
              isDisabled={index === 0}
              onClick={() => onMoveTemplateClick(s, index, true)}
            >
              Move Up
            </MenuItem>
            <MenuItem
              isDisabled={index === amount - 1}
              onClick={() => onMoveTemplateClick(s, index, false)}
            >
              Move Down
            </MenuItem>
            <MenuItem onClick={() => onEnableTemplateClick(t)}>
              {t.enabled ? 'Disable' : 'Enable'}
            </MenuItem>
            <MenuItem
              onClick={() => dbRemoveTemplate(t.sectionId, t.id)}
              color='red.500'
            >
              Remove
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }
  }

  const renderSection = (sectionId: string, index: number, amount: number) => {
    const section: TemplateSectionT = templatesSections.sections[sectionId]
    if (!allTemplates || !section) return null
    const templatesAmount = _.size(section.templatesOrder)
    return (
      <VStack
        key={sectionId}
        mt='0'
        align={'flex-start'}
        justify={'flex-start'}
        w='full'
      >
        <HStack
          w='full'
          align='center'
          justify={'space-between'}
          // mb='3'
          role='group'
        >
          <Text
            fontSize='xs'
            color='gray.500'
            fontWeight='medium'
            letterSpacing='wider'
            lineHeight='4'
            textAlign={'left'}
          >
            {_.upperCase(section.label || '')}
          </Text>
          {renderSectionMenu(section, index, amount)}
        </HStack>
        {_.map(section.templatesOrder, (tId: string, templateIndex: number) => {
          const t = allTemplates[tId]
          if (!t.enabled && !canEdit) return null
          return (
            <HStack key={t.id} role='group' w='full' spacing={0}>
              <NavButton
                label={t.title}
                onClick={() => onTemplateClick(t.id)}
                icon={fas[section.icon || 'faSquareList']}
                isActive={t.id === currentTemplateId}
                color={t.enabled ? undefined : 'gray.400'}
              />
              {renderTemplateMenu(t, section, templateIndex, templatesAmount)}
            </HStack>
          )
        })}
        {canEdit && (
          <Button
            pl={3}
            size='xs'
            variant={'link'}
            colorScheme='blue'
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => onCreateTemplateClick(sectionId)}
          >
            Add template
          </Button>
        )}

        {/* <FontAwesomeIcon icon={far.faPlus} /> */}
      </VStack>
    )
  }

  const renderSections = () => {
    const amount = _.size(templatesSections.order)
    return (
      <VStack
        align={'flex-start'}
        flex={1}
        w='full'
        spacing={6}
        overflowY='auto'
      >
        {_.map(templatesSections.order, (sectionId, index) => {
          const templates = _.get(templatesBySectionId, sectionId, [])
          if (!canEdit && _.isEmpty(templates)) {
            return null
          } else {
            return renderSection(sectionId, index, amount)
          }
        })}
      </VStack>
    )
  }

  const renderAddSectionButton = () => {
    if (canEdit) {
      return (
        <Box pr={6} w='full'>
          <Button
            colorScheme={'blue'}
            size='sm'
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            w='full'
            onClick={onCreateTemplateSectionClick}
          >
            Add section
          </Button>
        </Box>
      )
    }
  }

  return (
    <VStack
      w='64'
      background='white'
      px={5}
      py={6}
      pr={canEdit ? 0 : 5}
      borderTopLeftRadius='6'
      borderRight='1px'
      borderColor='gray.100'
      h='full'
      align={'flex-start'}
      overflow='hidden'
    >
      <Box pb={3}>
        <Heading as='h4' size='sm' color='gray.600' fontWeight='semibold'>
          Templates
        </Heading>
      </Box>
      {renderSections()}
      {renderAddSectionButton()}
    </VStack>
  )
}

export default TemplatesSideBar
