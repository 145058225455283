import {
  ChangeEvent,
  Ref,
  useState,
  useMemo,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle
} from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
  FormErrorMessage,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import _ from 'lodash'

import isEmail from 'validator/lib/isEmail'
import { updateResponsesNameEmail } from 'controllers/responses'

export interface ICreateContactModal {
  open: (responseId: string) => void
}

type Props = {}

const CreateContactModal: ForwardRefRenderFunction<
  ICreateContactModal,
  Props
> = (_props, ref: Ref<unknown>) => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [responseId, setResponseId] = useState('')
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  useImperativeHandle(ref, () => ({
    open: (rId: string) => {
      setName('')
      setEmail('')
      setResponseId(rId)
      setNameError(false)
      setEmailError(false)
      onOpen()
    }
  }))

  const emailValid = useMemo(() => isEmail(email), [email])

  const onChange = (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = _.get(e, 'target.value')
    if (type === 'name') {
      setName(value)
      setNameError(false)
    } else {
      setEmail(value)
      setEmailError(false)
    }
  }

  const onSubmit = async (): Promise<void> => {
    updateResponsesNameEmail([responseId], name, email)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p='7'
          pb='7'
          fontSize='lg'
          lineHeight='7'
          fontWeight='bold'
          color='gray.900'
          borderBottomWidth={1}
        >
          Create contact
        </ModalHeader>
        <ModalCloseButton color='gray.700' mt='4' mr='7' />
        <ModalBody pt='7'>
          <Flex direction='column' maxW='50%' gap='16px'>
            <FormControl isInvalid={nameError}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={onChange('name')}
                onFocus={() => setNameError(false)}
                onBlur={() => setNameError(name === '')}
              />
              {nameError && (
                <FormErrorMessage>Set contact name</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={emailError}>
              <FormLabel>Email</FormLabel>
              <Input
                type='email'
                value={email}
                onChange={onChange('email')}
                onFocus={() => setEmailError(false)}
                onBlur={() => setEmailError(!isEmail(email))}
              />
              {emailError && (
                <FormErrorMessage>
                  This email address is invalid
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent={'flex-start'} pt={8}>
          <HStack>
            <Button
              colorScheme='blue'
              onClick={onSubmit}
              isDisabled={name === '' || !emailValid}
            >
              Create
            </Button>
            <Button
              variant='outline'
              fontSize='md'
              lineHeight='6'
              fontWeight='semibold'
              onClick={onClose}
            >
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreateContactModal)
