import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons/faCircleUser'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'

import { WelcomeT } from 'shared/types/model'

type Props = {
  welcome: WelcomeT
}

const WelcomeContacts: FC<Props> = ({ welcome }) => {
  const [expanded, setExpanded] = useState(false)

  const toggle = () => {
    setExpanded(!expanded)
  }

  return (
    <div className='w-full'>
      <button
        className='w-full hover:bg-gray-50 rounded-md transition-colors'
        onClick={toggle}
      >
        <div className='flex gap-4 p-4 items-center'>
          <div className='w-4 h-4 flex items-center justify-center'>
            <FontAwesomeIcon icon={faCircleUser} />
          </div>
          <p>{welcome.name || ''} invited you</p>
          <div className='ml-auto w-4 h-4 flex items-center justify-center'>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </button>
      <div
        className={
          'overflow-hidden transition-[flex] duration-300 pl-6 pr-6 pb-6 text-gray-800  ' +
          (expanded ? 'flex-1' : 'hidden flex-0')
        }
      >
        <div className='text-gray-600 text-sm font-medium flex flex-col gap-3 pt-2'>
          <div className='flex flex-col gap-2.5'>
            <span className='font-semibold font-gray-900 text-[16px]'>
              You were invited by
            </span>
            <span>{welcome.name}</span>
            <span>{welcome.email}</span>
            <span>{welcome.phone}</span>
          </div>
          <hr className='w-full border-b-1 border-gray-100' />
          <div className='flex flex-col gap-2.5'>
            <span>{welcome.companyName}</span>
            {welcome.website && (
              <a
                href={
                  welcome.website.startsWith('http')
                    ? welcome.website
                    : 'https://' + welcome.website
                }
                target='_blank'
              >
                <span>{welcome.website}</span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeContacts
