import { FC, useState, useRef, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputRightElement,
  IconButton
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'

import { dbSignUp } from 'controllers/auth'
import GoogleAuthButton from 'pages/auth/GoogleAuthButton'
import AuthDivider from 'pages/auth/AuthDivider'
import AuthGeneralError from 'pages/auth/AuthGeneralError'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import AuthHeader from 'pages/auth/AuthHeader'
import { useSelector } from 'model/hooks'
import { OnboardingT } from 'shared/types/model'

const SignUp: FC = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [generalError, setGeneralError] = useState<string | null>(null)
  const [nameError, setNameError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const onboarding: OnboardingT = useSelector(state => state.onboarding)

  useEffect(() => {
    if (onboarding) {
      setName(onboarding.name || '')
      setEmail(onboarding.email || '')
    }
  }, [onboarding])

  const isOnboarding = _.has(onboarding, 'industry')

  const submit = async (): Promise<void> => {
    setGeneralError(null)
    let eError = null
    let pError = null
    let nError = null
    console.log('name, email', name, email)
    if (_.isEmpty(email)) {
      eError = 'Please enter your email'
    } else if (!validator.isEmail(email)) {
      eError =
        'Please enter a valid email address using the following format: name@example.com'
    }
    if (_.isEmpty(_.toString(password))) {
      pError = 'Please enter your password'
    } else if (_.size(password) < 8) {
      pError = 'Passwords must be 8 or more characters.'
    }

    if (_.isEmpty(_.toString(name))) {
      nError = 'Please enter your name'
    }

    if (_.isNil(eError) && _.isNil(pError) && _.isNil(nError)) {
      setLoading(true)
      const errorCode = await dbSignUp(name, email, password)
      if (!_.isNil(errorCode)) {
        switch (errorCode) {
          case 'auth/email-already-in-use':
            eError =
              'Another account with this email already exists. Would you like to sign in?'
            break
          default:
            setGeneralError(authErrorToString(errorCode))
        }
      }
      setLoading(false)
    }
    setNameError(nError)
    setEmailError(eError)
    setPasswordError(pError)
  }

  const pageHeader = (
    <AuthHeader
      title={
        isOnboarding ? 'View interview responses' : 'Let’s create an account'
      }
      description={
        isOnboarding
          ? "Continue to see how candidates' responses will look in your FaceFile account."
          : 'FaceFile the easiest way to conduct one-way video interviews'
      }
    />
  )

  const resetErrors = () => {
    setNameError(null)
    setEmailError(null)
    setPasswordError(null)
  }

  const togglePasswordVisibility = () => setShow(show => !show)

  const renderInputs = () => {
    return (
      <VStack spacing={5} w='full'>
        <FormControl isRequired isInvalid={!_.isNil(nameError)}>
          <FormLabel htmlFor='name'>Name</FormLabel>
          <Input
            id='name'
            type='text'
            value={name}
            onFocus={resetErrors}
            color={_.isNil(nameError) ? undefined : 'red.500'}
            onChange={e => setName(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                emailInputRef.current?.focus()
              }
            }}
          />
          <FormErrorMessage>{nameError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!_.isNil(emailError)}>
          <FormLabel htmlFor='email-signup'>Email</FormLabel>
          <Input
            id='email-signup'
            type='email'
            value={email}
            ref={emailInputRef}
            color={_.isNil(emailError) ? undefined : 'red.500'}
            onFocus={resetErrors}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={async e => {
              if (e.key === 'Enter') {
                passwordInputRef.current?.focus()
              }
            }}
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!_.isNil(passwordError)}>
          <FormLabel htmlFor='password'>Password</FormLabel>
          <InputGroup size='md'>
            <Input
              id='password'
              type={show ? 'text' : 'password'}
              value={password}
              onFocus={resetErrors}
              color={_.isNil(passwordError) ? undefined : 'red.500'}
              onChange={e => setPassword(e.target.value)}
              ref={passwordInputRef}
              onKeyPress={async e => {
                if (e.key === 'Enter') {
                  submit()
                }
              }}
            />
            <InputRightElement>
              <IconButton
                aria-label='show/hide password'
                variant={'unstyled'}
                icon={<FontAwesomeIcon icon={show ? faEyeSlash : faEye} />}
                onClick={togglePasswordVisibility}
              />
            </InputRightElement>
          </InputGroup>
          {_.isNil(passwordError) ? (
            <FormHelperText>At least 8 characters long</FormHelperText>
          ) : (
            <FormErrorMessage>{passwordError}</FormErrorMessage>
          )}
        </FormControl>
      </VStack>
    )
  }

  const renderGeneralError = () => {
    if (!_.isNil(generalError)) {
      return (
        <AuthGeneralError
          errorMessage={generalError}
          onReset={() => setGeneralError(null)}
        />
      )
    }
  }

  const renderChangeMode = () => {
    if (!isOnboarding) {
      return (
        <HStack spacing='2' justify='center' align='center' pt={4}>
          <Text color='gray.600'>Already have an account?</Text>
          <Button
            variant='link'
            color='blue.600'
            onClick={() => navigate('/auth/signin')}
          >
            Log in
          </Button>
        </HStack>
      )
    }
  }

  const renderCTA = () => {
    return (
      <Box w='full' pt={4}>
        <Button
          isLoading={loading}
          variant='solid'
          colorScheme={isOnboarding ? 'blue' : 'teal'}
          onClick={submit}
          w='full'
          size='lg'
        >
          {isOnboarding ? 'View responses' : 'Create account'}
        </Button>
      </Box>
    )
  }

  return (
    <VStack spacing='2' w='full' h='full' overflow={'auto'}>
      {pageHeader}
      <VStack
        bgColor='white'
        p='10'
        w='full'
        maxW='2xl'
        rounded='lg'
        boxShadow='sm'
      >
        {renderGeneralError()}
        {renderInputs()}
        {renderCTA()}
        {renderChangeMode()}
        <AuthDivider />
        <GoogleAuthButton title='Sign up with Google' />
      </VStack>
    </VStack>
  )
}

export default SignUp
