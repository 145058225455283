import * as types from 'model/actionTypes'
import { DictT } from 'shared/types/model'

interface TemplatesTagsActionT {
  type: string
  tags: DictT<string>
}

export const receiveTemplatesTags = (
  tags: DictT<string>
): TemplatesTagsActionT => {
  return {
    type: types.RECEIVE_TEMPLATES_TAGS,
    tags
  }
}
