import { combineReducers } from 'redux'
import authData from 'model/reducers/authData'
import forms from 'model/reducers/forms'
import questions from 'model/reducers/questions'
import user from 'model/reducers/user'
import templates from 'model/reducers/templates'
import templatesSections from 'model/reducers/templatesSections'
import responses from 'model/reducers/responses'
import account from 'model/reducers/account'
import accountProfile from 'model/reducers/accountProfile'
import profiles from 'model/reducers/profiles'
import userAccounts from 'model/reducers/userAccounts'
import questionsLibrary from 'model/reducers/questionsLibrary'
import prices from 'model/reducers/prices'
import products from 'model/reducers/products'
import subscription from 'model/reducers/subscription'
import notificationsSettings from 'model/reducers/notificationsSettings'
import invitations from 'model/reducers/invitations'
import templatesTags from 'model/reducers/templatesTags'
import appConf from 'model/reducers/appConf'
import aiConf from 'model/reducers/aiConf'
import onboarding from 'model/reducers/onboarding'
import cfqs from 'model/reducers/cfqs'

export const rootReducer = combineReducers({
  authData,
  forms,
  questions,
  user,
  templates,
  templatesSections,
  responses,
  account,
  accountProfile,
  profiles,
  userAccounts,
  questionsLibrary,
  prices,
  products,
  subscription,
  notificationsSettings,
  invitations,
  templatesTags,
  appConf,
  onboarding,
  aiConf,
  cfqs
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
