import {
  ChangeEvent,
  Ref,
  useState,
  useMemo,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle
} from 'react'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
  FormErrorMessage,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import _ from 'lodash'

import isEmail from 'validator/lib/isEmail'
import { useSelector } from 'model/hooks'
import { getResponsesByEmailWithDeleted } from 'model/selectors/responses'
import { updateResponsesNameEmail } from 'controllers/responses'
import { useNavigate } from 'react-router-dom'

export interface IEditContactModal {
  open: (name: string, email: string) => void
}

type Props = {}

const EditContactModal: ForwardRefRenderFunction<IEditContactModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [originEmail, setOriginEmail] = useState('')
  const responsesByEmail = useSelector(getResponsesByEmailWithDeleted)
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    open: (name: string, email: string) => {
      setName(name)
      setEmail(email)
      setOriginEmail(email)
      setNameError(false)
      setEmailError(false)
      onOpen()
    }
  }))

  const emailValid = useMemo(() => isEmail(email), [email])

  const onChange = (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = _.get(e, 'target.value')
    if (type === 'name') {
      setName(value)
      setNameError(false)
    } else {
      setEmail(value)
      setEmailError(false)
    }
  }

  const onSubmit = async (): Promise<void> => {
    const responses = _.get(responsesByEmail, originEmail)
    const ids = _.map(responses, r => r.id)
    navigate(`/contacts/${email}`)
    await updateResponsesNameEmail(ids, name, email)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p='7'
          pb='7'
          fontSize='lg'
          lineHeight='7'
          fontWeight='bold'
          color='gray.900'
          borderBottomWidth={1}
        >
          Edit contact details
        </ModalHeader>
        <ModalCloseButton color='gray.700' mt='4' mr='7' />
        <ModalBody pt='7'>
          <Flex
            direction='column'
            maxW={{ base: 'full', lg: '50%' }}
            gap='16px'
          >
            <FormControl isInvalid={nameError}>
              <FormLabel>Name</FormLabel>
              <Input
                type='text'
                value={name}
                onChange={onChange('name')}
                onFocus={() => setNameError(false)}
                onBlur={() => setNameError(name === '')}
              />
              {nameError && (
                <FormErrorMessage>Set contact name</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={emailError}>
              <FormLabel>Email</FormLabel>
              <Input
                type='email'
                value={email}
                onChange={onChange('email')}
                onFocus={() => setEmailError(false)}
                onBlur={() => setEmailError(!isEmail(email))}
              />
              {emailError && (
                <FormErrorMessage>
                  This email address is invalid
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent={'flex-start'} pt={8}>
          <HStack>
            <Button
              colorScheme='blue'
              onClick={onSubmit}
              isDisabled={name === '' || !emailValid}
            >
              Update
            </Button>
            <Button
              variant='outline'
              fontSize='md'
              lineHeight='6'
              fontWeight='semibold'
              onClick={onClose}
            >
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(EditContactModal)
