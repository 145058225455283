import { FC } from 'react'
import {
  HStack,
  Flex,
  Show,
  Box,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import DrawerContext from 'contexts/DrawerContext'

import Sidebar from 'components/Sidebar'

const PageLayout: FC<{}> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const renderDrawer = () => {
    return (
      <Show below='lg'>
        <Drawer isOpen={isOpen} onClose={onClose} placement='left'>
          <DrawerOverlay bg='blackAlpha.500' />
          <DrawerCloseButton size='lg' color='white' zIndex='overlay' />
          <DrawerContent>
            <Sidebar />
          </DrawerContent>
        </Drawer>
      </Show>
    )
  }

  return (
    <DrawerContext.Provider
      value={{
        drawerIsOpen: isOpen,
        openDrawer: onOpen,
        closeDrawer: onClose
      }}
    >
      <HStack
        align='flex-start'
        spacing={0}
        w='full'
        h='full'
        overflow='hidden'
      >
        <Show above='lg'>
          <Box h='full' w={72} flexShrink={0}>
            <Sidebar />
          </Box>
        </Show>

        {renderDrawer()}
        <Flex h='full' w='full' bg='gray.100' overflow='hidden'>
          <Outlet />
        </Flex>
      </HStack>
    </DrawerContext.Provider>
  )
}

export default PageLayout
