import {
  VStack,
  HStack,
  Button,
  Text
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'

import { DictT, QuestionT } from 'shared/types/model'
import Title from 'modals/questionsAndCFQ/Title'

const Questions = ({
  selectedQuestions,
  questions,
  openAddQuestionModal,
  onRemoveQuestion
}: {
  selectedQuestions: string[]
  questions: DictT<QuestionT> | null
  openAddQuestionModal: () => void
  openCreateQuestionModal: () => void
  onRemoveQuestion: (qId?: string) => void
}) => {
  return (
    <>
      <Title
        title='Questions'
        description='The applicant will be asked the following AI generated questions:'
        onAdd={openAddQuestionModal}
      />
      <VStack justify='start' align='center' w='full'>
        {_.map(selectedQuestions, id => {
          const q = _.get(questions, id)
          return (
            <HStack
              key={_.get(q, 'id', id)}
              w='full'
              px='8'
              py='4'
              borderBottom='1px'
              borderColor='gray.100'
              align='center'
              justify='space-between'
              sx={{
                '> button': {
                  color: 'transparent'
                },
                ':hover': {
                  '> button': {
                    color: 'black'
                  }
                }
              }}
            >
              <Text
                fontSize='md'
                fontWeight='medium'
              >
                {_.get(q, 'text')}
              </Text>
              <Button
                variant='ghost'
                onClick={() => onRemoveQuestion(_.get(q, 'id'))}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </HStack>
          )
        })}
        <HStack w='full' py='2'>
          <Button
            variant='ghost'
            size='sm'
            leftIcon={
              <FontAwesomeIcon icon={faPlus} />
            }
            onClick={openAddQuestionModal}
          >
            Add question
          </Button>
        </HStack>
      </VStack>
    </>
  )
}

export default Questions
