import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

const PreviewPage: FC = () => {
  const { previewId } = useParams()
  return (
    <Box w='100vw' h='full'>
      <iframe
        src={`https://www.videoask.com/${previewId as string}`}
        allow='camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;'
        width='100%'
        height='100%'
      />
    </Box>
  )
}

export default PreviewPage
