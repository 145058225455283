import { FC, ReactElement } from 'react'
import { Box, Button, Flex, Image, Text, HStack } from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'

import TextLogo from 'shared/assets/thumb_text.svg'
import AudioLogo from 'shared/assets/thumb_audio.svg'
import { AnswerT } from 'shared/types/model'

const ThumbnailWrapper = ({
  active,
  logo
}: {
  active: boolean
  logo: string
}): ReactElement => (
  <Box
    border={active ? '3px solid rgb(234, 201, 26)' : undefined}
    w='100%'
    h='100%'
    background='center center / cover rgb(244, 244, 244)'
    bgImage={logo}
    borderRadius='12px'
  />
)

type Props = {
  answers: AnswerT[]
  selected?: string
  onChange: (answerId: string) => void
  isText: boolean
}

const ResponsesNavigation: FC<Props> = ({
  answers,
  selected,
  onChange,
  isText
}) => {
  const getThumbnail = (type: string): string => {
    switch (type) {
      case 'audio':
        return AudioLogo
      case 'text':
        return TextLogo
      default:
        return TextLogo
    }
  }

  return (
    <Flex
      background='linear-gradient(rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)'
      pointerEvents='auto'
      overflow='auto'
      h='168px'
      border={isText ? '1px solid rgb(244, 244, 244)' : 'none'}
      borderRadius='0 0 24px 24px'
    >
      <Box position='relative' w='100%' h='100%'>
        <Box
          position='absolute'
          display='flex'
          flexDirection='row'
          h='100%'
          w='100%'
          px='32px'
          alignItems='center'
        >
          {_.map(answers, a => {
            const active = a.id === selected
            return (
              <Button
                key={a.id}
                onClick={() => onChange(a.id)}
                w='89px'
                h='118px'
                variant='unstyled'
                mr='8px'
                display='flex'
                flexDirection='column'
                outline='none'
                transition='all 0.2s ease-in-out 0s'
                transform={active ? 'translateY(-10px)' : undefined}
                flexShrink={0}
              >
                <Box
                  w='100%'
                  h='100%'
                  _hover={{
                    transform: 'scale(1.05)'
                  }}
                >
                  <Image
                    src={_.get(a, 'thumbnail')}
                    fit='contain'
                    alt='response nav thumbnail'
                    objectFit='cover'
                    h='100%'
                    borderRadius='12px'
                    border={active ? '3px solid rgb(234, 201, 26)' : undefined}
                    fallback={
                      <ThumbnailWrapper
                        active={active}
                        logo={getThumbnail(_.get(a, 'type'))}
                      />
                    }
                  />
                  <HStack spacing={1}>
                    <Text
                      fontSize='xs'
                      color={'gray.100'}
                      textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
                      fontWeight={'normal'}
                      letterSpacing={'tight'}
                    >
                      {moment(a.createdAt).format('DD MMM YY')}
                    </Text>
                    <Text
                      fontSize='xs'
                      color='gray.400'
                      textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
                      fontWeight={'normal'}
                      letterSpacing={'tight'}
                    >
                      {moment(a.createdAt).format('hh:mm')}
                    </Text>
                  </HStack>
                </Box>
              </Button>
            )
          })}
        </Box>
      </Box>
    </Flex>
  )
}

export default ResponsesNavigation
