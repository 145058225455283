import * as types from 'model/actionTypes'
import { FormT, DictT } from 'shared/types/model'

const initialState: DictT<FormT> | null = null

export default function (
  state = initialState,
  action: { type: string; forms: DictT<FormT> }
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_FORMS:
      return action.forms
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
