import { FC, useMemo, useRef, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  Divider,
  Progress,
  Tooltip
} from '@chakra-ui/react'
import {
  faClapperboardPlay,
  faAddressBook,
  faPlus
} from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Logo } from 'shared/assets/nav_bar_logo.svg'
import { NavButton } from 'components/sidebar/NavButton'
import { useSelector } from 'model/hooks'
import { FormT } from 'shared/types/model'
// import { generateId } from 'controllers/db'
import { SideMenuNavButton } from './sidebar/SideMenuNavButton'
import UpgradeModal, { IUpgradeModal } from 'modals/UpgradeModal'
import { getSortedForms } from 'model/selectors/forms'
import AccountButton from 'components/sidebar/AccountButton'
// import { logNewFormClicked } from 'controllers/analytics'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import DrawerContext from 'contexts/DrawerContext'
import { getAccount } from 'model/selectors/base'
import CreateFormModal, { ICreateFormModal } from 'modals/CreateFormModal'
import QuestionsAndCFQModal, { IQuestionsAndCFQModal } from 'modals/QuestionsAndCFQModal'
import SettingsModal, { ISettingsModal } from 'modals/SettingsModal'

const Sidebar: FC<{}> = () => {
  const { closeDrawer, drawerIsOpen } = useContext(DrawerContext)
  const navigate = useNavigate()
  const location = useLocation()
  const upgradeModalRef = useRef<IUpgradeModal>(null)
  const pathname = useMemo(() => location.pathname, [location.pathname])
  const forms = useSelector(getSortedForms)
  const planUsage = useSelector(getCurrentPlanUsage)
  const contanerRef = useRef<HTMLDivElement>(null)
  const isTestingAccount = useSelector(state =>
    _.get(getAccount(state), 'isTestingAccount', false)
  )
  const createFormModalRef = useRef<ICreateFormModal>(null)
  const questionsAndCFQModalRef = useRef<IQuestionsAndCFQModal>(null)
  const settingsModalRef = useRef<ISettingsModal>(null)

  const onNewVideoFormClick = () => {
    closeDrawer()
    // const formId = generateId()
    // logNewFormClicked(formId)
    // navigate(`/form/${formId}/edit`)
    createFormModalRef.current?.open()
  }

  const toResponses = () => {
    closeDrawer()
    navigate('/responses')
  }

  const toContacts = () => {
    closeDrawer()
    navigate('/contacts')
  }

  useEffect(() => {
    contanerRef.current?.focus()
  }, [drawerIsOpen])

  return (
    <Flex h='full' w='full' flexShrink={0} ref={contanerRef}>
      <Flex boxShadow={useColorModeValue('sm', 'sm-dark')} w='full'>
        <Stack
          justify='space-between'
          spacing='1'
          width='full'
          flexShrink={0}
          flexGrow={0}
        >
          <Stack
            spacing='6'
            flex='1 1 auto'
            overflowY='auto'
            py={{ base: '6', sm: '8' }}
            px={{ base: '3', sm: '4' }}
          >
            <Button
              variant='unstyled'
              pl='2'
              flexShrink='0'
              _focus={{ outline: '0' }}
              onClick={toResponses}
            >
              <Box>
                <Logo />
              </Box>
            </Button>
            <Stack flexShrink='0' spacing='1'>
              <NavButton
                label='All responses'
                fontSize='md'
                fontWeight='semibold'
                color='gray.500'
                iconColor='gray.500'
                icon={faClapperboardPlay}
                onClick={toResponses}
                isActive={_.startsWith(pathname, '/responses')}
              />
              <Tooltip
                label='View responses grouped by Contact'
                shouldWrapChildren
                hasArrow
                closeDelay={1000}
              >
                <NavButton
                  label='Contacts'
                  fontSize='md'
                  fontWeight='semibold'
                  color='gray.500'
                  iconColor='gray.500'
                  icon={faAddressBook}
                  onClick={toContacts}
                  isActive={_.startsWith(pathname, '/contacts')}
                />
              </Tooltip>
            </Stack>
            <Tooltip
              label='Create a new video form'
              shouldWrapChildren
              hasArrow
              closeDelay={1000}
            >
              <Box>
                <Button
                  w='100%'
                  h='8'
                  fontWeight='semibold'
                  variant='outline'
                  color='gray.500'
                  fontSize='sm'
                  leftIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={onNewVideoFormClick}
                >
                  New video form
                </Button>
              </Box>
            </Tooltip>
            {!_.isEmpty(forms) && (
              <Stack
                spacing={3}
                overflow='hidden'
                minH='24'
                flex-grow='1'
                flex-basis='0'
              >
                <Text
                  fontSize='xs'
                  letterSpacing='0.05em'
                  textTransform='uppercase'
                  color='gray.500'
                >
                  FORMS
                </Text>
                <Stack spacing='1' overflowY='auto' h='100%'>
                  {_.map(forms, (form: FormT) => (
                    <SideMenuNavButton key={form.id as string} form={form} />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack gap='3'>
            <Button
              mx='4'
              h='8'
              size='sm'
              colorScheme='teal'
              onClick={() => upgradeModalRef.current?.open()}
            >
              Upgrade
            </Button>
            <Flex
              m='0'
              px='26px'
              py='2.5'
              h='9'
              gap='2.5'
              alignContent='center'
            >
              <Flex w='40%'>
                <Progress
                  alignSelf='center'
                  flex='1'
                  value={
                    planUsage.limitReached
                      ? 100
                      : (planUsage.used / planUsage.responsesIncluded) * 100
                  }
                  // value={1}
                  size='xs'
                  colorScheme={planUsage.limitReached ? 'red' : 'teal'}
                />
              </Flex>
              <Text
                fontSize='xs'
                flex='1'
                lineHeight='4'
                fontWeight='normal'
                color={planUsage.limitReached ? 'red' : 'gray.700'}
                className='responses-counter'
              >
                {`${planUsage.used} / ${
                  isTestingAccount ? '∞' : planUsage.responsesIncluded
                } responses`}
              </Text>
            </Flex>
            <Box>
              <Divider />
              <Stack
                py={{ base: '2', sm: '5' }}
                px={{ base: '2', sm: '5' }}
                bg={_.startsWith(pathname, '/settings') ? 'gray.200' : 'white'}
              >
                <AccountButton
                  onUpgradeClick={() => upgradeModalRef.current?.open()}
                  onSettingsClick={() => settingsModalRef.current?.open()}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Flex>
      <UpgradeModal ref={upgradeModalRef} />
      <CreateFormModal ref={createFormModalRef} onSuccess={({
        numberOfCfqs
      }: {
        numberOfCfqs: number
      }) => questionsAndCFQModalRef.current?.open({ numberOfCfqs })} />
      <QuestionsAndCFQModal ref={questionsAndCFQModalRef} />
      <SettingsModal ref={settingsModalRef} />
    </Flex>
  )
}

export default Sidebar
