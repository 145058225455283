import { createSelector } from '@reduxjs/toolkit'
import { getTemplates, getTemplateSections } from 'model/selectors/base'
import _ from 'lodash'
import { DictT, TemplateT } from 'shared/types/model'

export const getTemplatesBySection = createSelector(
  [getTemplates, getTemplateSections],
  (templates, templateSections) => {
    if (!templates) return {}
    const res: DictT<TemplateT[]> = {}
    _.forEach(templateSections.sections, s => {
      _.forEach(s.templatesOrder, tId => {
        const t = templates[tId]
        if (t && t.enabled) {
          if (!_.has(res, s.id)) res[s.id] = []
          res[s.id].push(t)
        }
      })
    })
    return res
  }
)
