import { FC } from 'react'
import { Box, Heading, Text, HStack, Tooltip, VStack } from '@chakra-ui/react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

type Props = {
  createdAt: number
  name?: string
  email?: string
}

const ResponsesFooter: FC<Props> = ({
  createdAt,
  name = 'Human',
  email = ''
}) => {
  return (
    <Box>
      <HStack spacing={2} align='center'>
        <Heading
          size='lg'
          color={'white'}
          textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
        >
          {name}
        </Heading>
        <Tooltip
          label={
            <VStack spacing={0}>
              <Text>{name}</Text>
              <Text>{email}</Text>
            </VStack>
          }
        >
          <Box
            boxSize={'5'}
            sx={{
              svg: {
                filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))'
              }
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} color='white' />
          </Box>
        </Tooltip>
      </HStack>
      <Text
        fontSize='xs'
        color={'gray.100'}
        textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
      >
        {moment(createdAt).format('DD MMM YY @ hh:mm a')}
      </Text>
    </Box>
  )
}

export default ResponsesFooter
