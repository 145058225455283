import { FC, useMemo, useState } from 'react'
import {
  Text,
  Stack,
  Flex,
  HStack,
  FormControl,
  FormLabel,
  Box,
  Tag,
  Button,
  VStack
} from '@chakra-ui/react'
import _ from 'lodash'
import {
  Select,
  CreatableSelect,
  GroupBase,
  MultiValue,
  chakraComponents,
  OptionProps,
  GroupHeadingProps,
  ChakraStylesConfig
} from 'chakra-react-select'
import validator from 'validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoArrowDownLeft } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'

import { NotificationsSettingsT, SendAtT, FormT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import { getCurrentUserProfile } from 'model/selectors/profiles'
import {
  dbCreateNotificationsSettings,
  dbUpdateNotificationsSettings
} from 'controllers/notificationsSettings'
import SingleSelect from 'components/SingleSelect'
import { getSortedForms } from 'model/selectors/forms'
import { getFormLabel } from 'shared/utils/form'
import { getResponsesByFormId } from 'model/selectors/responses'
import { roundRobinGet } from 'shared/utils/array'
import { colors } from 'shared/constants/colors'
import SettingsCardTitle from 'components/settings/SettingsCardTitle'

type FormOptionT = {
  value: string
  label: string
  responsesAmount: number
  createdAt: number
  colorScheme: string | null
}

type EmailOptionT = {
  value: string
  label: string
  colorScheme: string
}

const SettingsNotifications: FC = () => {
  const ns: NotificationsSettingsT | null = useSelector(
    state => state.notificationsSettings
  )
  const [emailMenuOpen, setEmailMenuOpen] = useState(false)
  const forms = useSelector(getSortedForms)
  const userProfile = useSelector(getCurrentUserProfile)
  const account = useSelector(state => state.account)
  const responsesByFormId = useSelector(getResponsesByFormId)
  const formsOptions = useMemo(() => {
    return _.map(forms, (f: FormT, i: number) => {
      return {
        value: f.id,
        label: getFormLabel(f),
        responsesAmount: _.size(_.get(responsesByFormId, f.id, [])),
        createdAt: f.createdAt,
        colorScheme: roundRobinGet(colors, i) || null
      }
    })
  }, [forms])

  const emailsOptions = useMemo(() => {
    const userEmail = _.get(userProfile, 'email')
    let emails = ns?.emails
    if (_.isNil(emails)) {
      emails = userEmail ? [userEmail] : []
    }
    return _.map(emails, (email: string) => {
      return {
        value: email,
        label: email,
        colorScheme: 'gray'
      }
    })
  }, [ns, userProfile])

  // const formsOptionsDict = useMemo(() => {
  //   return _.keyBy(formsOptions, 'value')
  // }, [formsOptions])

  const selectedOptions = useMemo(() => {
    if (ns && !_.isEmpty(ns.mutedFormsIds)) {
      const mutedDict = _.keyBy(ns.mutedFormsIds)
      return _.filter(formsOptions, fo => _.has(mutedDict, fo.value))
    }
    return []
  }, [ns, formsOptions])

  const updateNS = (params: Partial<NotificationsSettingsT>) => {
    console.log('updateNS', params)
    console.log('userProfile', userProfile)
    if (userProfile) {
      if (_.isNil(ns)) {
        dbCreateNotificationsSettings(account.id, userProfile?.id, params)
      } else {
        dbUpdateNotificationsSettings(account.id, userProfile?.id, params)
      }
    }
  }

  const onEmailsChanged = (opts: MultiValue<EmailOptionT>) => {
    // console.log('onEmailsChanged: opts', opts)
    const emails = _.map(opts, opt => opt.value)
    const validEmails = _.filter(emails, email => validator.isEmail(email))
    if (userProfile) {
      dbUpdateNotificationsSettings(account.id, userProfile.id, {
        emails: validEmails
      })
    }
  }

  const renderEmails = () => {
    const chakraStyles: ChakraStylesConfig<
      EmailOptionT,
      true,
      GroupBase<EmailOptionT>
    > = {
      menuList: provided => ({
        ...provided,
        bg: 'gray.50'
      }),
      option: provided => ({
        ...provided,
        bg: 'gray.50'
      })
    }
    const formatCreateLabel = (inputValue: string) => {
      return (
        <HStack w='full' h='full'>
          <Text fontWeight={'normal'} fontSize='xs'>
            Add
          </Text>
          <Tag size='sm'>{inputValue}</Tag>
        </HStack>
      )
    }
    return (
      <FormControl>
        <FormLabel>Send notifications to:</FormLabel>
        <CreatableSelect<EmailOptionT, true, GroupBase<EmailOptionT>>
          maxMenuHeight={800}
          isMulti
          closeMenuOnSelect={false}
          name='emails'
          options={emailsOptions}
          placeholder='Enter email address'
          value={emailsOptions}
          onChange={onEmailsChanged}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          menuIsOpen={emailMenuOpen}
          chakraStyles={chakraStyles}
          formatCreateLabel={formatCreateLabel}
          onInputChange={(newValue: string) =>
            setEmailMenuOpen(validator.isEmail(newValue))
          }
          isValidNewOption={(inputValue: string) =>
            validator.isEmail(inputValue)
          }
        />
      </FormControl>
    )
  }

  const renderSendAt = () => {
    return (
      <FormControl>
        <HStack justify={'space-between'} align='center'>
          <FormLabel>Send notifications</FormLabel>
          <Box maxW='60' w='full'>
            <SingleSelect
              options={[
                {
                  label: 'Immediately',
                  value: SendAtT.IMMEDIATELY
                },
                {
                  label: 'Daily',
                  value: SendAtT.DAILY
                },
                {
                  label: 'Hourly',
                  value: SendAtT.HOURLY
                },
                {
                  label: 'Never',
                  value: SendAtT.NEVER
                }
              ]}
              value={ns ? ns.sendAt : SendAtT.IMMEDIATELY}
              onSelect={v => updateNS({ sendAt: v as SendAtT })}
            />
          </Box>
        </HStack>
      </FormControl>
    )
  }

  const onMuteChanged = (opts: MultiValue<FormOptionT>) => {
    console.log('onMuteChanged: userProfile', userProfile)
    if (userProfile) {
      const ids = _.map(opts, opt => opt.value)
      dbUpdateNotificationsSettings(account.id, userProfile.id, {
        mutedFormsIds: ids
      })
    }
  }

  const onSelectAll = () => {
    if (userProfile) {
      const ids = _.map(forms, f => f.id)
      dbUpdateNotificationsSettings(account.id, userProfile.id, {
        mutedFormsIds: ids
      })
    }
  }

  const onSelectNone = () => {
    if (userProfile) {
      dbUpdateNotificationsSettings(account.id, userProfile.id, {
        mutedFormsIds: []
      })
    }
  }

  const customComponents = {
    Option: ({
      children,
      ...props
    }: OptionProps<FormOptionT, true, GroupBase<FormOptionT>>) => (
      <chakraComponents.Option {...props}>
        <HStack w='full' justify={'space-between'} py={3}>
          <HStack>
            <Tag colorScheme={props.data.colorScheme || 'gray'}>{children}</Tag>
            <Text color='gray.400' fontSize={'sm'}>
              {moment(props.data.createdAt).format('ll')}
            </Text>
          </HStack>
          <HStack
            color={props.data.responsesAmount > 0 ? 'gray.500' : 'gray.400'}
            align={'center'}
            spacing={2}
          >
            <FontAwesomeIcon icon={faVideoArrowDownLeft} />
            <Text fontSize={'md'}>{props.data.responsesAmount}</Text>
          </HStack>
        </HStack>
      </chakraComponents.Option>
    ),
    GroupHeading: (
      props: GroupHeadingProps<FormOptionT, true, GroupBase<FormOptionT>>
    ) => (
      <chakraComponents.GroupHeading {...props}>
        <HStack justify={'space-between'}>
          <Text fontWeight={'normal'} fontSize='xs'>
            {props.data.label}
          </Text>
          <HStack spacing={8}>
            <Button
              size={'xs'}
              variant='link'
              colorScheme={'blue'}
              onClick={onSelectAll}
            >
              Select all
            </Button>
            <Button
              size={'xs'}
              variant='link'
              colorScheme={'blue'}
              onClick={onSelectNone}
            >
              Select none
            </Button>
          </HStack>
        </HStack>
      </chakraComponents.GroupHeading>
    )
  }

  const chakraStyles: ChakraStylesConfig<
    FormOptionT,
    true,
    GroupBase<FormOptionT>
  > = {
    menu: provided => ({
      ...provided,
      mb: '20px'
    })
  }

  const renderFormsSelect = () => {
    if (ns && ns.sendAt !== SendAtT.NEVER) {
      return (
        <FormControl>
          <FormLabel>Mute notifications of responses to the forms:</FormLabel>
          <Select<FormOptionT, true, GroupBase<FormOptionT>>
            // menuShouldScrollIntoView
            // menuPortalTarget={document.body}
            maxMenuHeight={800}
            isMulti
            name='forms'
            options={[
              {
                label: 'Select forms to mute notifications from:',
                options: formsOptions
              }
            ]}
            placeholder='Select forms to mute notifications from'
            closeMenuOnSelect={false}
            value={selectedOptions}
            components={customComponents}
            onChange={onMuteChanged}
            hasStickyGroupHeaders
            chakraStyles={chakraStyles}
          />
        </FormControl>
      )
    }
  }

  return (
    <Flex
      w='full'
      px={{ lg: '8', base: '0' }}
      py={{ lg: '4', base: '0' }}
      pb={4}
      overflowY='hidden'
    >
      <VStack
        w='full'
        bg='white'
        spacing={{ lg: '16', base: 0 }}
        align='flex-start'
        h='fit-content'
      >
        <SettingsCardTitle title='Notifications' description='Select the type of notifications you want to receive' />
        <Stack direction='column' spacing='12' flex={2} w='full'>
          {renderEmails()}
          {renderSendAt()}
          {renderFormsSelect()}
        </Stack>
      </VStack>
    </Flex>
  )
}

export default SettingsNotifications
