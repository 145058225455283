import {
  query,
  collection,
  onSnapshot,
  where,
  doc,
  setDoc,
  updateDoc,
  deleteField,
  FieldValue
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db, generateId, auth } from 'controllers/db'
import store from 'model/store'
import { receiveQuestions } from 'model/actions/questionsAC'
import { addListener } from 'controllers/listeners'
import { QuestionT, VideoT } from 'shared/types/model'
import { User } from 'firebase/auth'
import { receiveQuestionsLibrary } from 'model/actions/questionsLibraryAC'

export const fetchQuestions = async (accountId: string): Promise<any> => {
  try {
    const q = query(
      collection(db, 'questions'),
      where('accountId', '==', accountId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
          const isLocalHost =
          location.hostname === 'localhost' || location.hostname === '127.0.0.1'
          if (!isLocalHost) {
            const heap = _.get(window, 'heap')
            if (!_.isNil(heap)) {
              heap.addUserProperties({
                questionsCreated: _.size(res)
              })
            }
          }
        })
        store.dispatch(receiveQuestions(res))
      },
      err => {
        console.log(`fetchQuestions error: ${err.message}`)
      }
    )
    addListener('questions', unsubscribe)
  } catch (e) {
    console.log('fetchQuestions error', e)
    Sentry.captureException(e)
  }
}

export const fetchQuestionsLibrary = async (): Promise<any> => {
  try {
    const q = query(collection(db, 'questions'), where('isPublic', '==', true))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveQuestionsLibrary(res))
      },
      err => {
        console.log(`fetchQuestionsLibrary error: ${err.message}`)
      }
    )
    addListener('questionsLibrary', unsubscribe)
  } catch (e) {
    console.log('fetchQuestionsLibrary error', e)
    Sentry.captureException(e)
  }
}

export const dbCreateQuestion = async (text: string, accountId: string) => {
  const id = generateId()
  const authUser: User | null = auth.currentUser
  try {
    if (authUser) {
      const q: QuestionT = {
        id,
        text,
        createdBy: authUser.uid,
        createdAt: _.now(),
        accountId
      }
      const ref = doc(collection(db, 'questions'), id)
      await setDoc(ref, q)
      return id
    } else {
      return null
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbSavePublicQuestion = async (q: QuestionT) => {
  try {
    const ref = doc(collection(db, 'questions'), q.id)
    await setDoc(ref, q)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateQuestion = async (
  questionId: string,
  upd: Partial<QuestionT> | { deleted?: FieldValue }
) => {
  try {
    console.log('dbUpdateQuestion', questionId, upd)
    const ref = doc(collection(db, 'questions'), questionId)
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}

const _fetchElaiQuestionsPage = async (pageNum: number) => {
  try {
    console.log('_fetchElaiQuestionsPage, pageNum', pageNum)
    const url = `${process.env
      .REACT_APP_DYNAMIC_URL as string}/adm/library_videos/${pageNum}`
    console.log('fetch url', url)
    const idToken = await auth.currentUser?.getIdToken()
    if (!_.isNil(idToken)) {
      const resRaw = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: idToken
        }
      })
      const res = await resRaw.json()
      return res
    } else {
      return null
    }
  } catch (e) {
    console.log('submitForm error', e)
    Sentry.captureException(e)
    return null
  }
}

export const fetchElaiQuestions = async () => {
  try {
    console.log('fetchElaiQuestions')
    let res: VideoT[] = []
    const firstPage = await _fetchElaiQuestionsPage(1)
    if (firstPage) {
      res = firstPage.videos as VideoT[]
      if (firstPage.pages > 1) {
        for (let i = 2; i <= firstPage.pages; i++) {
          const page = await _fetchElaiQuestionsPage(i)
          res.push(...page.videos)
        }
      }
    }
    return res
  } catch (e) {
    Sentry.captureException(e)
    console.log('submitForm error', e)
    return null
  }
}

export const dbArchiveQuestion = (questionId: string) => {
  dbUpdateQuestion(questionId, { deleted: _.now() })
}

export const dbUnarchiveQuestion = (questionId: string) => {
  dbUpdateQuestion(questionId, { deleted: deleteField() })
}
