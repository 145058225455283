import { FC } from 'react'
import {
  Flex,
  Alert,
  VStack,
  AlertTitle,
  Tooltip,
  AlertDescription
} from '@chakra-ui/react'
import VideoPlayer from 'components/VideoPlayer'

type Props = {
  text?: string
  mediaUrl?: string
  hideAlert?: boolean
}

const TemplateQuestionPreview: FC<Props> = ({
  text = '',
  mediaUrl,
  hideAlert = false
}) => {
  const questionPreviewAlert = (
    <Alert
      status='info'
      alignSelf='center'
      justifyContent='space-between'
      p={0}
      flexShrink={0}
    >
      <VStack spacing={0.5} align='flex-start' h='full' px={4} py={4}>
        <AlertTitle fontWeight='semibold' color='gray.700'>
          Question preview
        </AlertTitle>
        <Tooltip hasArrow label={text}>
          <AlertDescription fontWeight='normal' color='gray.700' noOfLines={3}>
            {text}
          </AlertDescription>
        </Tooltip>
      </VStack>
    </Alert>
  )

  return (
    <VStack w='full' h='full' overflow={'hidden'}>
      {!hideAlert && questionPreviewAlert}
      <Flex flex={1} h='full' w='full' p={8} overflow='hidden'>
        <Flex
          h='full'
          w='full'
          position={'relative'}
          rounded='2xl'
          boxShadow='lg'
        >
          {mediaUrl && <VideoPlayer mediaUrl={mediaUrl} key={mediaUrl} />}
        </Flex>
      </Flex>
    </VStack>
  )
}

export default TemplateQuestionPreview
