import { FC, useRef } from 'react'
import {
  Box,
  Center,
  Flex,
  VStack,
  Text,
  Tooltip,
  Show,
  Button,
  Heading,
  CloseButton,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react'
import _ from 'lodash'
import {
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faRobot
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ResponsesFooter from 'components/ResponsesFooter'
import ResponsesNavigation from 'components/ResponsesNavigation'
import QuestionNumber from 'pages/form/answers/QuestionNumber'
import VideoPlayer from 'components/VideoPlayer'
import { logAnswerNext, logAnswerPrevious } from 'controllers/analytics'
import { AnswerT, QuestionT, ResponseT } from 'shared/types/model'

type Props = {
  selectedAnswerId?: string
  response?: ResponseT
  answer?: AnswerT
  currentAnswerIndex: number
  answersAr: AnswerT[]
  question?: QuestionT
  setSelectedAnswerId: (answerId: string) => void
  onBack?: () => void
}

const Answers: FC<Props> = ({
  selectedAnswerId,
  response,
  answer,
  currentAnswerIndex,
  answersAr,
  question,
  setSelectedAnswerId,
  onBack
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const mediaControlsContainerMobileRef = useRef<HTMLDivElement>(null)
  const mediaControlsContainerDesktopRef = useRef<HTMLDivElement>(null)
  const mediaDurationContainerRef = useRef<HTMLDivElement>(null)
  if (_.isNil(answer) || _.isNil(question) || _.isNil(response)) {
    return null
  }

  const toPrevAnswer = () => {
    logAnswerNext(response.id)
    setSelectedAnswerId(response.answersIds[currentAnswerIndex - 1])
  }
  const toNextAnswer = () => {
    logAnswerPrevious(response.id)
    setSelectedAnswerId(response.answersIds[currentAnswerIndex + 1])
  }

  const renderAIAnalysis = () => {
    if (answer && answer.evaluation) {
      return (
        <Tooltip label={answer.evaluation} shouldWrapChildren>
          <FontAwesomeIcon icon={faRobot} color='white' />
        </Tooltip>
      )
    }
  }

  const renderTopNav = () => {
    return (
      <Box position='absolute' top={6} px={4} left={0} right={0}>
        <VStack align='flex-start' w='full'>
          <Show below='lg'>
            <Flex
              w='full'
              alignItems='center'
              justifyContent='space-between'
              // px='4'
              // pt='10'
              pb='4'
            >
              {onBack && (
                <Button
                  variant='solid'
                  size='sm'
                  leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                  color='gray.600'
                  onClick={onBack}
                >
                  Back
                </Button>
              )}
              <Heading size='sm' color='gray.100'>
                {response?.name}
              </Heading>
              <Text fontSize='sm' color='gray.100'>
                {moment(response?.createdAt).fromNow()}
              </Text>
              {onBack && (
                <CloseButton
                  variant='solid'
                  size='md'
                  backgroundColor='gray.100'
                  color='gray.600'
                  onClick={onBack}
                />
              )}
            </Flex>
          </Show>
          <HStack justify='space-between' w='full' align='flex-start'>
            <VStack align='flex-start'>
              <HStack>
                <QuestionNumber
                  num={currentAnswerIndex + 1}
                  amount={_.size(response.answersIds)}
                  toPrev={toPrevAnswer}
                  toNext={toNextAnswer}
                />
                {renderAIAnalysis()}
              </HStack>
              <Text
                bg='blackAlpha.400'
                color='white'
                fontWeight={'bold'}
                rounded='sm'
                px={2}
              >
                {question.text}
              </Text>
            </VStack>
            <HStack spacing={4}>
              <Box ref={mediaDurationContainerRef} />
              <Show above='lg'>
                <Box ref={mediaControlsContainerDesktopRef} />
              </Show>
            </HStack>
          </HStack>
        </VStack>
      </Box>
    )
  }

  const renderArrowButton = (
    isLeft: boolean,
    onClick: () => void,
    tip: string
  ) => {
    return (
      <Tooltip label={tip}>
        <Center
          zIndex={100}
          position='absolute'
          top='50%'
          left={isLeft ? 2 : undefined}
          right={!isLeft ? 2 : undefined}
          visibility='hidden'
          _groupHover={{ visibility: 'visible' }}
          sx={{
            transform: 'translate(0%,-50%) scale(1)',
            'transform-origin': 'center center',
            transition: 'all 0.3s ease 0.1s',
            svg: {
              filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))'
            }
          }}
          as='button'
          onClick={onClick}
          rounded={'full'}
          _hover={{ transform: 'translate(0%,-50%) scale(1.2)' }}
          color='white'
        >
          <FontAwesomeIcon
            fixedWidth
            icon={isLeft ? faChevronLeft : faChevronRight}
            size='3x'
          />
        </Center>
      </Tooltip>
    )
  }

  const renderArrowLeft = () => {
    if (currentAnswerIndex !== 0) {
      return renderArrowButton(true, toPrevAnswer, 'Previous answer')
    }
  }

  const renderArrowRight = () => {
    if (currentAnswerIndex < _.size(response.answersIds) - 1) {
      return renderArrowButton(false, toNextAnswer, 'Next answer')
    }
  }

  return (
    <Flex
      flexDirection='column'
      flexGrow={1}
      px={{ base: 0, lg: 12 }}
      py={{ base: 0, lg: 6 }}
    >
      <Flex
        h='full'
        w='full'
        background='gray'
        borderRadius='24px'
        position='relative'
        zIndex='100'
        flexDirection='column'
      >
        <VideoPlayer
          key={answer.mediaUrl}
          mediaUrl={answer.mediaUrl}
          showControls
          transcription={answer.transcription}
          thumbnail={answer.thumbnail}
          mediaControlsContainerRef={
            isDesktop
              ? mediaControlsContainerDesktopRef
              : mediaControlsContainerMobileRef
          }
          mediaDurationContainerRef={mediaDurationContainerRef}
        />
        <Box
          w='full'
          role='group'
          position={'absolute'}
          h='40%'
          top='50%'
          sx={{
            transform: 'translate(0%,-50%) scale(1)',
            'transform-origin': 'center center'
          }}
        >
          {renderArrowLeft()}
          {renderArrowRight()}
        </Box>
        {renderTopNav()}
        {/* <MediaMenu /> */}
        <Box
          position={'absolute'}
          bottom='0'
          mt='auto'
          w='full'
          borderRadius='0 0 24px 24px'
        >
          <HStack
            w='full'
            justify={'space-between'}
            align='center'
            pb={6}
            px={4}
          >
            <ResponsesFooter
              createdAt={answer.createdAt}
              name={response.name}
              email={response.email}
            />
            <Show below='lg'>
              <Box ref={mediaControlsContainerMobileRef} />
            </Show>
          </HStack>
          {_.size(response?.answersIds) > 1 && (
            <ResponsesNavigation
              answers={answersAr}
              onChange={(answerId: string) => setSelectedAnswerId(answerId)}
              selected={selectedAnswerId}
              isText={_.get(answer, 'type', 'video') === 'text'}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default Answers
