import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getQuestions, getQuestionsLibrary } from 'model/selectors/base'
import { QuestionT, DictT } from 'shared/types/model'

export const getAllQuestions = createSelector(
  [getQuestions, getQuestionsLibrary],
  (questions, questionsLibrary) => {
    return {
      ...(questions || {}),
      ...(questionsLibrary || {})
    } as DictT<QuestionT>
  }
)

export const getLibraryQuestions = createSelector(
  [getQuestionsLibrary],
  questionsLibrary => {
    return _.orderBy(questionsLibrary, 'createdAt', 'desc')
  }
)
