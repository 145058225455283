export interface DictT<T> {
  [key: string]: T
}

// private user record, for storing sensitive info

export enum Role {
  OWNER = 'OWNER',
  EDITOR = 'EDITOR'
}
export interface AccountAdminT {
  id: string
  role: Role
}

export interface AuthUser {
  createdAt: string
  displayName?: string
  email: string
  lastLoginAt?: string
  phoneNumber?: string
  photoURL?: string
  uid: string
  [key: string]: any
}

// organisation record containing sensitive info
export interface AccountT {
  id: string
  createdAt: number
  admins: DictT<AccountAdminT>
  vaFolderId?: string
  isFaceFileAdmin?: boolean
  stripeCustomerId?: string
  subscriptionId?: string
}
export interface AccountProfileT {
  id: string
  createdAt: number
  name?: string
  logoUrl?: string
  logoRedirectUrl?: string
  customDomainUrl?: string
  favicons?: DictT<string>
}

export interface OnboardingT {
  name?: string
  email?: string
  contactId?: string
  industry?: IndustryT
}

export interface UserT {
  id: string
  createdAt: number
  adminOfAccounts: string[]
  currentAccountId?: string
  emailVerified?: boolean
  mailchimpId?: string
  stonly?: {
    formCreatedByGuide: boolean
    formCreated: boolean
  }
  onboarding?: OnboardingT
  heap?: {
    inviteOptionUsedTimes?: number
    createQuestionAttempts?: number
    questionsAdded?: number
  }
}

// public user profile record
export interface UserProfileT {
  id: string
  name: string
  email: string
  accounts?: string[] // the accounts can read the user
  avatar?: string
}

export interface WelcomeT {
  imageUrl?: string
  description?: string
  explanation?: string
  learnMoreUrl?: string
  shareMessage?: string
  shareMessageEnabled?: boolean
  name?: string
  email?: string
  phone?: string
  website?: string
  companyName?: string
}

export enum ContactFormOrderT {
  START = 'start',
  END = 'end',
  NEVER = 'never'
}

export interface ContactFormSettingsT {
  when?: ContactFormOrderT
  name?: boolean
  email?: boolean
  phone?: boolean
}

export interface FormT {
  id: string
  accountId: string
  createdAt: number
  createdBy: string
  updatedAt?: number
  label?: string
  questions?: string[]
  previewUrl?: string
  vaPreviewFormId?: string
  vaPreviewSyncedAt?: number
  contactFormQuestionId?: string
  syncRequests?: number[] | any
  deleted: number
  templateId?: string
  overlayText?: OverlayTextT
  cfqs?: DictT<CFQT>
  cfqsIds?: string[]
  welcome?: WelcomeT
  contactForm?: ContactFormSettingsT
}

export interface OverlayTextT {
  explanation?: string
  description?: string
  url?: string
  name?: string
  email?: string
  phone?: string
  website?: string
}

export interface QuestionT {
  createdAt: number
  lastUsedAt?: number
  createdBy: string
  length?: number
  thumbnail?: string
  mediaUrl?: string
  text: string
  id: string
  accountId?: string
  elaiVideoId?: string
  isPublic?: boolean
  variant?: {
    mediaUrl: string
    elaiVideoId: string
  }
  deleted?: number
  tags?: string[]
}
export interface AnswerT {
  id: string
  formId: string
  responseId: string
  accountId: string
  createdAt: number
  mediaUrl: string
  duration: number
  questionId: string
  thumbnail: string
  transcription?: string
  evaluation?: string
}

export interface InteractionAnswerT {
  id: string
  interactionId: string
  questionId: string
  createdAt: number
  mediaUrl: string
  duration?: number
  thumbnail?: string
  transcript?: string
}

export interface QuestionLightT {
  id: string
  length?: number
  thumbnail?: string
  mediaUrl?: string
  text: string
  answerId?: string
  isCFQ?: boolean
}

export interface ResponseT {
  id: string
  email: string
  name: string
  phone: string | null
  createdAt: number
  formId: string
  accountId: string
  answersIds: string[]
  thumbnail: string
  deleted: number
  evaluation?: string
  score?: number
}

export interface TemplateT {
  id: string
  questions: string[]
  title: string
  sectionId: string
  contactFormQuestionId?: string
  createdAt: number
  enabled: boolean
  vaPreviewFormId?: string
  previewUrl?: string
  vaPreviewSyncedAt?: number
  tags?: string[]
}

export interface TemplateSectionT {
  id: string
  label: string
  icon: string
  templatesOrder: string[]
}
export interface TemplateSectionsT {
  sections: DictT<TemplateSectionT>
  order: string[]
}

export interface InvitationT {
  id: string
  accountId: string
  createdAt: number
  createdBy: string
  email: string
  accountName: string
  status: 'pending' | 'declined'
}

export interface VideoSlideT {
  duration: 13.14225
  id: 1200422514896
  language: string
  speech: string
  status: string
  voice: string
}
export interface VideoT {
  accountId: string
  createdAt: string
  deleted: boolean
  duration: number
  name: string
  public: false
  slides: VideoSlideT[]
  status: string
  tags: string[]
  thumbnail: string
  updatedAt: string
  url: string
  userId: string
  verified: boolean
  _id: string
}

export interface ContactT {
  name: string
  email: string
  phone: string | null
  thumbnail: string
  lastResponseId: string
}

export enum SendAtT {
  IMMEDIATELY = 'immediately',
  DAILY = 'daily',
  HOURLY = 'hourly',
  NEVER = 'never'
}

export interface NotificationsSettingsT {
  userId: string
  accountId: string
  emails?: string[]
  sendAt: SendAtT
  mutedFormsIds: string[]
}

export interface ProductT {
  active: boolean
  default_price: string
  description: string
  id: string
  metadata: {
    amount: string
    isCustom?: string
  }
  name: string
}

export interface PriceT {
  id: string
  active: boolean
  product: string
  recurring: {
    interval: 'month' | 'year'
  }
  unit_amount: number
  metadata: {
    mostPopular?: string
  }
}

export interface SubscriptionT {
  id: string
  status: string
  accountId: string
  currentPeriodStart: number
  currentPeriodEnd: number
  cancelAtPeriodEnd?: boolean
  cancelAt: number
  quantity: number
  productId: string
  priceId: string
}

export interface AccountEventT {
  id: string
  accountId: string
  name: string
  params?: object
}

export interface IndustryT {
  id: string
  templateId?: string
  previewUrl?: string
  title?: string
  icon?: string
  createdAt: number
}

export interface AppConfT {
  industries?: DictT<IndustryT>
  defaultIndustry?: IndustryT
}

export interface AIPromptConfT {
  prompt: string
  model: string
  temperature: number
  maxTokens: number
}

export interface AICFQT {
  prompt: string
  model: string
  temperature: number
  maxTokens: number
}

export interface AIConfT {
  CFQ: AICFQT
  answerAnalysis: AIPromptConfT
  responseAnalysis: AIPromptConfT
  CFQSuggestions: AICFQT
}

export interface QA {
  id: string
  q: string
  a: string
  ai: string
}

export interface InteractionContactT {
  name?: string
  email?: string
  phone?: string
}

export interface InteractionT {
  id: string
  formId: string
  createdAt: number
  questions: QuestionLightT[]
  completed?: boolean
  completedAt?: number
  hasCFQs?: boolean
  cfqsGenerated?: boolean
  contact?: InteractionContactT
  contactForm: ContactFormSettingsT | null
}

export interface CFQT {
  id: string
  accountId: string
  createdBy: string
  deleted?: number
  condition?: string
  question?: string
  createdAt?: number
  questionId?: string
  included?: boolean
}

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
