import { onSnapshot, doc, updateDoc, deleteField } from 'firebase/firestore'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { AIPromptConfT, AIConfT, AppConfT, IndustryT, AICFQT } from 'shared/types/model'
import { receiveAppConf } from 'model/actions/appConf'
import _ from 'lodash'
import { addListener } from 'controllers/listeners'
import { receiveAIConf } from 'model/actions/aiConf'

export const fetchAppConf = async (): Promise<any> => {
  try {
    const ref = doc(db, 'settings', 'appConf')
    onSnapshot(
      ref,
      sn => {
        store.dispatch(receiveAppConf((sn.data() as AppConfT) || {}))
      },
      err => {
        console.log(`fetchAppConf error: ${err.message}`)
        Sentry.captureException(err)
      }
    )
  } catch (e) {
    console.log('fetchAppConf error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateIndustry = async (
  industryId: string,
  params: Partial<IndustryT>
) => {
  const upd: any = {}
  _.forEach(params, (v, k) => {
    upd[`industries.${industryId}.${k}`] = v
  })
  await updateDoc(doc(db, 'settings', 'appConf'), upd)
}

export const dbUpdateDefaultIndustry = async (params: Partial<IndustryT>) => {
  const upd: any = {}
  _.forEach(params, (v, k) => {
    upd[`defaultIndustry.${k}`] = v
  })
  await updateDoc(doc(db, 'settings', 'appConf'), upd)
}

export const dbDeleteIndustry = async (industryId: string) => {
  const upd = {
    [`industries.${industryId}`]: deleteField()
  }
  await updateDoc(doc(db, 'settings', 'appConf'), upd)
}

export const fetchAIConf = async (): Promise<any> => {
  try {
    const ref = doc(db, 'settings', 'aiConf')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        store.dispatch(receiveAIConf((sn.data() as AIConfT) || {}))
      },
      err => {
        console.log(`receiveAIConf error: ${err.message}`)
        Sentry.captureException(err)
      }
    )
    addListener('aiconf', unsubscribe)
  } catch (e) {
    console.log('receiveAIConf error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateAIAnswerAnalysis = async (data: AIPromptConfT) => {
  await updateDoc(doc(db, 'settings', 'aiConf'), { answerAnalysis: data })
}

export const dbUpdateAICFQ = async (data: AICFQT) => {
  await updateDoc(doc(db, 'settings', 'aiConf'), { CFQ: data })
}

export const dbUpdateAIResponseAnalysis = async (data: AIPromptConfT) => {
  await updateDoc(doc(db, 'settings', 'aiConf'), { responseAnalysis: data })
}

export const dbUpdateAICFQSuggestions = async (data: AICFQT) => {
  await updateDoc(doc(db, 'settings', 'aiConf'), { CFQSuggestions: data })
}
