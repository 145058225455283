import { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import {
  Text,
  VStack,
  Flex,
  FormControl,
  Input,
  Button,
  HStack,
  FormHelperText
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'

import { useSelector } from 'model/hooks'
import { AppConfT, IndustryT } from 'shared/types/model'
import Loading from 'pages/LoadingPage'
import { receiveOnboarding } from 'model/actions/onboardingAC'
import AuthHeader from 'pages/auth/AuthHeader'

const OnboardingIndustry = () => {
  const [industryName, setIndustryName] = useState<string>('')
  const [showInput, setShowInput] = useState(false)
  const appConf: AppConfT | null = useSelector(state => state.appConf)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const sortedIndustries = useMemo(() => {
    return _.sortBy(appConf?.industries, 'createdAt')
  }, [appConf])

  useEffect(() => {
    if (!_.isNil(appConf) && _.isNil(appConf.defaultIndustry)) {
      navigate('/auth/signup')
    }
  }, [appConf])

  const handleHeap = (industry?: string) => {
    const isLocalHost =
      location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    if (!isLocalHost) {
      const heap = _.get(window, 'heap')
      if (!_.isNil(heap) && !_.isNil(industry)) {
        heap.addUserProperties({
          industry
        })
      }
    }
  }

  const goNext = () => {
    navigate('/onboarding/video')
  }

  const submit = () => {
    if (appConf) {
      const industry: IndustryT = {
        ...appConf.defaultIndustry,
        title: industryName
      } as IndustryT
      handleHeap(industry.title)
      dispatch(receiveOnboarding({ industry }))
      goNext()
    }
  }

  const onSkip = () => {
    if (appConf) {
      handleHeap(_.get(appConf, 'defaultIndustry.title'))
      dispatch(receiveOnboarding({ industry: appConf.defaultIndustry }))
      goNext()
    }
  }

  const onIndustryClick = (industry: IndustryT) => {
    handleHeap(industry.title)
    dispatch(receiveOnboarding({ industry }))
    goNext()
  }

  const renderIndustries = () => {
    return _.map(sortedIndustries, ind => {
      return (
        <HStack
          key={ind.id}
          borderWidth={1}
          borderColor='gray.200'
          w='full'
          px={6}
          py={4}
          spacing={4}
          rounded='md'
          as='button'
          justify={'flex-start'}
          onClick={() => onIndustryClick(ind)}
        >
          <FontAwesomeIcon icon={fas[ind.icon || 'faSquareList']} fixedWidth />
          <Text
            fontSize={'lg'}
            fontWeight='semibold'
            color={'gray.700'}
            textAlign='left'
          >
            {ind.title}
          </Text>
        </HStack>
      )
    })
  }

  const renderDefaultCase = () => {
    if (showInput) {
      return (
        <FormControl>
          <Input
            id='industry'
            type='text'
            value={industryName}
            autoFocus
            _focus={{ borderColor: 'blue.500' }}
            // color={_.isNil(emailError) ? undefined : 'red.500'}
            size='lg'
            // onFocus={resetErrors}
            onChange={e => setIndustryName(e.target.value)}
          />
          <FormHelperText>Type your industry or role</FormHelperText>
        </FormControl>
      )
    } else {
      return (
        <HStack
          borderWidth={1}
          borderColor='gray.200'
          w='full'
          pl={14}
          py={4}
          spacing={4}
          rounded='md'
          as='button'
          onClick={() => setShowInput(true)}
        >
          <Text fontSize={'lg'} fontWeight='semibold' color={'gray.700'}>
            {appConf && appConf?.defaultIndustry?.title}
          </Text>
        </HStack>
      )
    }
  }

  const pageHeader = (
    <AuthHeader
      title='Enter your industry'
      description='To personalize your demo, please select your industry:'
    />
  )

  if (_.isNil(appConf)) {
    return <Loading />
  } else {
    return (
      <Flex
        h='full'
        w='full'
        align='flex-start'
        justify={'center'}
        bg={{ base: 'white', lg: 'gray.50' }}
        pt={12}
        pb={12}
        overflowY='auto'
      >
        <VStack spacing='2' w='full' h='full' overflow={'auto'}>
          {pageHeader}
          <VStack
            bgColor='white'
            py='10'
            px={{ base: 6, lg: 10 }}
            w='full'
            maxW='2xl'
            rounded='lg'
            boxShadow={{ lg: 'sm', base: 'none' }}
            spacing={10}
          >
            <VStack spacing={4} w='full'>
              {renderIndustries()}
              {renderDefaultCase()}
            </VStack>
            <VStack w='full' spacing={4}>
              <Button w='full' colorScheme={'blue'} size='lg' onClick={submit}>
                Next
              </Button>
              <Button
                w='full'
                variant={'unstyled'}
                color={'blue.500'}
                size='md'
                onClick={onSkip}
              >
                Skip
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </Flex>
    )
  }
}

export default OnboardingIndustry
