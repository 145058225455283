import { FC, useState, useMemo } from 'react'
import {
  Avatar,
  Flex,
  Box,
  Button,
  Text,
  Input,
  InputLeftElement,
  InputGroup,
  Image,
  Tooltip,
  Hide,
  VStack
} from '@chakra-ui/react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useSelector } from 'model/hooks'
import { faMagnifyingGlass, faPlus } from '@fortawesome/pro-light-svg-icons'
import { generateId } from 'controllers/db'
import { getContacts } from 'model/selectors/responses'
import noContactImage from 'assets/no_notification.png'
import { logNewFormClicked } from 'controllers/analytics'
import { ContactT } from 'shared/types/model'
import NavBarMobile from 'components/NavBarMobile'

const Contacts: FC<{}> = () => {
  const navigate = useNavigate()
  const contacts: ContactT[] = useSelector(getContacts)
  const [search, setSearch] = useState<string>('')

  const filteredContacts = useMemo(() => {
    const reg = new RegExp(_.escapeRegExp(search), 'gi')
    return _.filter(
      contacts,
      c => !_.isEmpty(c.name.match(reg)) || !_.isEmpty(c.email.match(reg))
    )
  }, [contacts, search])

  const onNewVideoFormClick = () => {
    const formId = generateId()
    logNewFormClicked(formId)
    navigate(`/form/${formId}/edit`)
  }

  const emptyState = (
    <Flex
      justifyContent={{ base: 'flex-start', lg: 'center' }}
      alignItems='center'
      w='full'
      h='full'
      direction='column'
      gap='6'
      p={{ base: '16', lg: 0 }}
    >
      <Image src={noContactImage} alt='Empty contacts' boxSize='44' />
      <Text
        maxWidth='96'
        textAlign='center'
        fontWeight='normal'
        fontSize='md'
        lineHeight='24px'
        color='gray.600'
        px={4}
      >
        No contacts. Create a video form to start receiving video responses from
        new contacts.
      </Text>
      <Tooltip
        label='Create a new video form'
        shouldWrapChildren
        hasArrow
        closeDelay={1000}
      >
        <Button
          w='56'
          h='12'
          fontWeight='semibold'
          variant='outline'
          color='gray.700'
          fontSize='lg'
          lineHeight='7'
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onNewVideoFormClick}
        >
          New video form
        </Button>
      </Tooltip>
    </Flex>
  )

  const renderContacts = () => {
    if (contacts.length > 0) {
      return (
        <Flex w={{ base: 'full', lg: 'sm' }} flexDirection='column' pt={4}>
          <Box ml={{ base: 4, lg: 7 }}>
            <Text color='gray.500' fontWeight='700' fontSize='sm'>
              {`${_.size(filteredContacts)} contact${
                _.size(filteredContacts) > 1 ? 's' : ''
              }`}
            </Text>
          </Box>
          <Box ml={{ base: 4, lg: 7 }} pt='2' pr='4' my='2'>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <FontAwesomeIcon size='sm' icon={faMagnifyingGlass} />
              </InputLeftElement>
              <Input
                backgroundColor='white'
                placeholder='Find a contact'
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </InputGroup>
          </Box>
          <Box
            pl='1'
            pr='1'
            w='full'
            h='full'
            gap='10px'
            overflowY='auto'
            sx={{
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            {_.map(filteredContacts, contact => {
              return (
                <Box
                  key={contact.email}
                  _hover={{
                    background: 'white',
                    boxShadow: 'md',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigate(`/contacts/${_.get(contact, 'email')}`)
                  }}
                  rounded='md'
                  mb='1'
                >
                  <Box w='full' h='fit-content' display='block'>
                    <Flex
                      py='4'
                      px={{ base: 4, lg: 7 }}
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Flex>
                        <Avatar
                          w='48px'
                          h='48px'
                          src={_.get(contact, 'avatar')}
                        />
                        <Flex
                          flexDirection='column'
                          justifyContent='space-between'
                          pl='4'
                        >
                          <Text
                            textAlign='start'
                            textColor='gray.800'
                            fontSize='xl'
                            lineHeight='7'
                            fontWeight='semibold'
                            noOfLines={1}
                          >
                            {contact.name}
                          </Text>
                          <Text
                            mt='1'
                            textAlign='start'
                            textColor='gray.600'
                            fontSize='sm'
                            lineHeight='5'
                            fontWeight='normal'
                          >
                            {contact.email || ''}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Flex>
      )
    } else {
      return emptyState
    }
  }

  return (
    <VStack
      w='full'
      h='full'
      overflow={'hidden'}
      bg={{ base: 'white', lg: 'gray.100' }}
      spacing={0}
      align='flex-start'
    >
      <Hide above='lg'>
        <NavBarMobile />
      </Hide>
      {renderContacts()}
    </VStack>
  )
}

export default Contacts
