import * as types from 'model/actionTypes'
import { DictT, CFQT } from 'shared/types/model'

const initialState: DictT<CFQT> | null = null

export default function (
  state = initialState,
  action: { type: string; cfqs: DictT<CFQT> }
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_CFQS:
      return action.cfqs
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
