import { storage } from 'controllers/db'
import {
  ref,
  getDownloadURL,
  getMetadata,
  uploadBytesResumable
} from 'firebase/storage'
import Resizer from 'react-image-file-resizer'
import { StorageFileT } from 'shared/types/storage'
import * as Sentry from '@sentry/react'

export async function saveFile (
  storagePath: string,
  fileUrl: string,
  onProgress?: (v: number) => void
) {
  try {
    console.log('saveFile', storagePath, fileUrl)
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        console.log('saveFile error', e)
        Sentry.captureException(e)
        reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', fileUrl, true)
      xhr.send(null)
    })
    // TODO: test it
    const storageRef = ref(storage, storagePath)
    console.log('storage ref', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, blob)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot =>
        onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      )
    }
    const snapshot = await uploadTask
    console.log('totalBytes', snapshot.metadata.size)
    console.log('getDownloadUrl')
    const url = await getDownloadURL(storageRef)
    console.log('download url is', url)
    const sf: StorageFileT = {
      url,
      size: snapshot.metadata.size,
      contentType: snapshot.metadata.contentType
    }
    console.log('sf', sf)
    return sf
  } catch (error) {
    Sentry.captureException(error)
    console.log(error)
  }
}

export async function getContentType (url: string) {
  const storagePath = url
    .split('o/')[1]
    .split('?alt')[0]
    .split('%2F')
    .join('/')
  const storageRef = ref(storage, storagePath)
  const { contentType } = await getMetadata(storageRef)
  return contentType
}

export const saveFileObject = async (
  fileObject: File | Blob | Uint8Array | ArrayBuffer,
  storagePath: string,
  onProgress?: (v: number) => void
) => {
  try {
    console.log('saveFileObject', fileObject, storagePath)
    const storageRef = ref(storage, storagePath)
    console.log('storageRef', storageRef)
    const uploadTask = uploadBytesResumable(storageRef, fileObject)
    if (onProgress) {
      uploadTask.on('state_changed', snapshot =>
        onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      )
    }
    uploadTask.catch((error: any) => {
      console.warn(error)
    })
    const snapshot = await uploadTask
    console.log('snapshot', snapshot)
    console.log('totalBytes', snapshot.metadata.size)

    console.log('totalBytes', snapshot.metadata.size)
    console.log('getDownloadUrl')
    const url = await getDownloadURL(storageRef)
    console.log('download url is', url)
    const sf: StorageFileT = {
      url,
      size: snapshot.metadata.size,
      contentType: snapshot.metadata.contentType
    }
    console.log('sf', sf)
    return sf
  } catch (e) {
    Sentry.captureException(e)
    console.log('saveFileObject error: ', e)
  }
}

export const deleteFile = async (storagePath: String) => {
  console.log('deleteFile: the file will be not realy deleted', storagePath)
}

export function createImageThumbnail (file: any, thumbWidth = 120) {
  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        thumbWidth,
        thumbWidth,
        'PNG',
        100,
        0,
        (blob: any) => {
          const resName = `thumb_${file.name}`
          const resFile = new File([blob], resName)
          resolve(resFile)
        },
        'blob'
      )
    } catch (e) {
      Sentry.captureException(e)
      reject(new Error('Image optimize error'))
    }
  })
}

export default {
  saveFile,
  getContentType,
  saveFileObject,
  deleteFile,
  createImageThumbnail
}
