import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { AccountProfileT } from 'shared/types/model'

const initialState: AccountProfileT | null = null

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_ACCOUNT_PROFILE:
      return action.accountProfile
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
