import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Text,
  Heading,
  Textarea,
  VStack,
  HStack,
  Select,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  useToast
} from '@chakra-ui/react'
import _ from 'lodash'

import { callOpenaiChat } from 'controllers/admin'
import { AIPromptConfT, AIConfT, CFQT, QA, DictT } from 'shared/types/model'
import { generateId } from 'controllers/db'
import { dbUpdateAICFQ, fetchAIConf } from 'controllers/settings'
import { useSelector } from 'model/hooks'
import CFQTestCase from 'components/settings/CFQTestCase'
import SettingsSuggestionsCFQs from './SettingsSuggestionsCFQs'

const SettingsCFQ = () => {
  const [prompt, setPrompt] = useState('')
  const [model, setModel] = useState('gpt-3.5-turbo')
  const [temperature, setTemperature] = useState('0')
  const [maxTokens, setMaxTokens] = useState('1024')
  const [loading, setLoading] = useState(false)
  const [explanation, setExplanation] = useState<DictT<boolean>>({})
  const aiConf: AIConfT | null = useSelector(state => state.aiConf)
  const toast = useToast()
  const [qas, setQas] = useState<QA[]>([
    { id: generateId(), q: '', a: '', ai: '' }
  ])
  const dbCfqs = useSelector(state => state.cfqs)
  const [cfqsWithData, setCfqsWithData] = useState<DictT<Partial<CFQT>>>({})
  const [cfqsIds, setCfqsIds] = useState<string[]>([generateId()])

  useEffect(() => {
    setCfqsWithData({ ...cfqsWithData, ...dbCfqs })
  }, [dbCfqs])

  useEffect(() => {
    fetchAIConf()
  }, [])

  useEffect(() => {
    if (aiConf?.CFQ) {
      setPrompt(aiConf.CFQ.prompt)
      setModel(aiConf.CFQ.model)
      setTemperature(_.toString(aiConf.CFQ.temperature))
      setMaxTokens(_.toString(aiConf.CFQ.maxTokens))
    }
  }, [aiConf])

  const interview_ = useMemo(() => {
    const r: string[] = []
    _.forEach(qas, (qa, i) => {
      r.push(`Question ${i + 1}: ${qa.q}`)
      r.push(`Answer ${i + 1}: ${qa.a}`)
    })
    return _.join(r, '/n ')
  }, [qas])

  const makePrompt = (condition: string) => {
    let res = _.replace(prompt, '$INTERVIEW', interview_)
    res = _.replace(res, '$CFQ', condition)
    return res
  }

  const saveSettings = () => {
    const c: AIPromptConfT = {
      prompt,
      temperature: Number(temperature),
      model,
      maxTokens: Number(maxTokens)
    }
    dbUpdateAICFQ(c)
  }

  const send = async () => {
    try {
      setExplanation({})
      setLoading(true)
      const resp = await Promise.all(
        _.map(cfqsIds, async cfqId => {
          const cfq = _.get(cfqsWithData, cfqId)
          if (_.isNil(cfq)) {
            return { id: cfqId, value: false }
          }
          const message = [
            { role: 'user', content: makePrompt(cfq.condition || '') }
          ]
          const apires = await callOpenaiChat(
            message,
            model,
            Number(temperature),
            Number(maxTokens)
          )
          console.log('apires', apires)
          const resM = _.get(apires, ['choices', 0, 'message', 'content'])
          return { id: cfq.id || '', value: resM === '1' }
        })
      )
      console.log('resp', resp)
      const newExplanation = _.reduce(
        resp,
        (res, { id, value }) => ({ ...res, [id]: value }),
        {}
      )
      setExplanation(newExplanation)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.warn(e)
      toast({
        title: 'Error',
        description: _.get(e, 'message'),
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }

  const renderTestCase = () => {
    return (
      <CFQTestCase
        qas={qas}
        setQas={setQas}
        cfqsIds={cfqsIds}
        setCfqsIds={setCfqsIds}
        cfqsWithData={cfqsWithData}
        setCfqsWithData={setCfqsWithData}
        explanation={explanation}
      />
    )
  }

  const renderNavBar = () => {
    return (
      <HStack justify='flex-end' w='full' py='4'>
        <HStack>
          <Button onClick={send} colorScheme='blue' isLoading={loading}>
            Test configuration
          </Button>
          <Button colorScheme='blue' onClick={saveSettings}>
            Save settings
          </Button>
        </HStack>
      </HStack>
    )
  }

  const renderVariables = () => {
    return (
      <VStack w='full' align='flex-start'>
        <Heading fontSize='2xl'>Variables</Heading>
        <Text fontSize='sm'>
          <strong>$INTERVIEW</strong> - will be replaced with all questions and
          answers
        </Text>
        <Text fontSize='sm'>
          <strong>$CFQ</strong> - will be replaced with Conditional Followup
          Questions
        </Text>
      </VStack>
    )
  }

  const renderAISettings = () => {
    return (
      <VStack w='full' align='flex-start'>
        <Heading fontSize='2xl'>AI Settings</Heading>
        <Select value={model} onChange={e => setModel(e.target.value)}>
          <option value='gpt-4'>gpt-4</option>
          <option value='gpt-4-0314'>gpt-4-0314</option>
          <option value='gpt-4-32k'>gpt-4-32k</option>
          <option value='gpt-4-32k-0314'>gpt-4-32k-0314</option>
          <option value='gpt-3.5-turbo'>gpt-3.5-turbo</option>
          <option value='gpt-3.5-turbo-0301'>gpt-3.5-turbo-0301</option>
        </Select>
        <FormControl>
          <FormLabel>Temperature</FormLabel>
          <NumberInput
            value={temperature}
            onChange={str => setTemperature(str)}
            min={0}
            max={1}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Max tokens</FormLabel>
          <NumberInput
            value={maxTokens}
            onChange={str => setMaxTokens(str)}
            min={0}
            max={2048}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      </VStack>
    )
  }

  return (
    <VStack w='full' h='full' overflowY='auto' px={8} mb={8}>
      {renderNavBar()}
      {renderTestCase()}
      <HStack align='flex-start' spacing={16} w='full'>
        <VStack w='full' align='flex-start'>
          <Heading fontSize='2xl'>Prompt</Heading>
          <Textarea
            value={prompt}
            size='sm'
            onChange={e => setPrompt(e.target.value)}
            background='white'
            minH='80'
          />
        </VStack>
        <VStack w='full' spacing={6}>
          {renderVariables()}
          {renderAISettings()}
        </VStack>
      </HStack>
      <SettingsSuggestionsCFQs />
    </VStack>
  )
}

export default SettingsCFQ
