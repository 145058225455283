import { HStack, VStack, Text, Button } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

const Title = ({
  title,
  description,
  onAdd
}: {
  title: string,
  description: string,
  onAdd: () => void
}) => (
  <HStack
    w='full'
    direction='row'
    justify='space-between'
    align='center'
    py='4'
    px='2'
    fontSize='md'
    fontWeight='normal'
    color='black'
    flex={1}
    flexShrink={0}
    flexGrow={0}
  >
    <VStack align='start' justify='center'>
      <Text fontSize='lg' color='gray.900' fontWeight='medium'>
        {title}
      </Text>
      <Text fontSize='sm' color='gray.500'>
        {description}
      </Text>
    </VStack>
    <Button variant='ghost' onClick={onAdd}>
      <FontAwesomeIcon icon={faPlus} color='black' />
    </Button>
  </HStack>
)

export default Title
