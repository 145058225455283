export const colors = [
  'blue',
  'green',
  'pink',
  'purple',
  'yellow',
  'cyan',
  'teal',
  'red',
  'orange',
  'gray',
  'pink',
  'purple',
  'facebook',
  'linkedin',
  'telegram',
  'twitter',
  'whatsapp',
  'whiteAlpha',
  'blackAlpha',
  'messenger'
]
