import * as types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { DictT, UserProfileT } from 'shared/types/model'

const initialState: DictT<UserProfileT> = {}

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case types.RECEIVE_USERS_PROFILES:
      return action.profiles
    case types.CLEAR:
      return initialState
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
