import { query, collection, where, onSnapshot } from 'firebase/firestore'
import LogRocket from 'logrocket'
import { db, analytics } from 'controllers/db'
import _ from 'lodash'
import { logEvent as _logEvent, setUserId } from 'firebase/analytics'
import { addListener } from 'controllers/listeners'
import { AccountEventT } from 'shared/types/model'
import { getRequestHeaders } from 'controllers/headers'

export const logUserId = (userId: string) => {
  setUserId(analytics, userId)
}

const logEvent = (eventName: string, params?: any) => {
  console.log('analytics: _logEvent', eventName, 'params', params)
  LogRocket.getSessionURL(sessionURL => {
    _logEvent(analytics, eventName, { params, logRocketSessionUrl: sessionURL })
  })
}

const tryToSendAccountEvent = async (event: AccountEventT) => {
  console.log('tryToSendAccountEvent', event)
  const url = `${process.env
    .REACT_APP_DYNAMIC_URL as string}/account/account_event_request_access/${
    event.id
  }`
  const headers = await getRequestHeaders()
  const responseRaw = await fetch(url, {
    method: 'GET',
    headers
  })
  console.log('responseRaw.status', responseRaw.status)
  if (responseRaw.status === 200) {
    logEvent(event.name, event.params)
  }
  return true
}

export const processAnalyticsEvents = (accountId: string) => {
  try {
    if (!_.isNil(accountId)) {
      const q = query(
        collection(db, 'accountEvents'),
        where('accountId', '==', accountId)
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const changes = sn.docChanges()
          _.forEach(changes, ch => {
            if (ch.type === 'added') {
              tryToSendAccountEvent(ch.doc.data() as AccountEventT)
            }
          })
        },
        err => {
          console.log(`processAnalyticsEvents error: ${err.message}`)
        }
      )
      addListener('analytics_events', unsubscribe)
    } else {
      console.log('processAnalyticsEvents error: accountId missing')
    }
  } catch (e) {
    console.log('processAnalyticsEvents error', e)
  }
}

export const logLogin = (method: string, userId: string) => {
  logEvent('login', { method, userId })
}

export const logSignInGoogleSuccess = (userId: string) => {
  logEvent('auth_social_signin_success', { userId })
}

export const logSignInGoogleFailed = (errorMessage: string) => {
  logEvent('auth_social_signin_fail', { errorMessage })
}

export const logSignInEmailSuccess = (userId: string) => {
  logEvent('auth_email_signin_success', { userId })
}

export const logSignInEmailFailed = (errorMessage: string) => {
  logEvent('auth_email_signin_fail', { errorMessage })
}

export const logNewFormClicked = (formId: string) => {
  logEvent('form_new', { formId })
}

export const logNewFormCreated = (formId: string) => {
  logEvent('form_created', { formId })
}

export const logFormTitleSaved = (formId: string) => {
  logEvent('form_title_saved', { formId })
}

export const logFormTemplateSelected = (templateId: string) => {
  logEvent('template_selected', { templateId })
}

export const logFormStartFromScratch = (formId: string) => {
  logEvent('form_scratch', { formId })
}

export const logCreateCustomQuestion = (questionId: string) => {
  logEvent('custom_question', { questionId })
}

export const logFormAddQuestion = (
  questionId: string,
  formId: string,
  templateId?: string
) => {
  logEvent('form_add_question', { questionId, formId, templateId })
}

export const logFormQuestionRemoved = (
  questionId: string,
  formId: string,
  templateId?: string
) => {
  logEvent('form_question_removed', { questionId, formId, templateId })
}

export const logFormContactformAdded = (
  formId: string,
  templateId?: string
) => {
  logEvent('form_contactform_added', { formId, templateId })
}

export const logFormContactformMoved = (
  formId: string,
  templateId?: string
) => {
  logEvent('form_contactform_moved', { formId, templateId })
}

export const logFormContactformRemoved = (
  formId: string,
  templateId?: string
) => {
  logEvent('form_contactform_removed', { formId, templateId })
}

export const logFormLinkCopied = (formId: string) => {
  logEvent('form_copy_link', { formId })
}

export const logTeammateInvited = (email: string) => {
  logEvent('teammate_invited', { email })
}

export const logResponsePlayed = (responseId: string) => {
  logEvent('response_played', { responseId })
}

export const logResponseDeleted = (responseId: string) => {
  logEvent('response_deleted', { responseId })
}

export const logSettingsLogoAdded = (logoUrl: string) => {
  logEvent('settings_logo_added', { logoUrl })
}

export const logSettingsLogoRemoved = (logoUrl: string) => {
  logEvent('settings_logo_removed', { logoUrl })
}

export const logSettingsFaviconAdded = (faviconUrl: string) => {
  logEvent('settings_favicon_added', { faviconUrl })
}

export const logSettingsFaviconRemoved = (faviconUrl: string) => {
  logEvent('settings_favicon_removed', { faviconUrl })
}

export const logSwitchAccount = (accountId: string) => {
  logEvent('settings_tenant_switched', { accountId })
}

export const logEmailVerified = (email: string) => {
  logEvent('auth_email_verified', { email })
}

export const logAnswerNext = (responseId: string) => {
  logEvent('answer_next', { responseId })
}

export const logAnswerPrevious = (responseId: string) => {
  logEvent('answer_previous', { responseId })
}
