import { Grid, GridItem, HStack, Text } from '@chakra-ui/react'

const ResponsesTableHeader = () => {
  const renderGridItem = (title: string, align: 'left' | 'center') => {
    return (
      <GridItem w='100%'>
        <HStack w='full' h='full' justify={align} px={6} py={3}>
          <Text color='gray.600' fontWeight={'medium'}>
            {title}
          </Text>
        </HStack>
      </GridItem>
    )
  }

  return (
    <Grid
      templateColumns='2.5fr 1fr 1fr 2fr 1fr 3fr'
      gap={0}
      w='full'
      bg='gray.50'
    >
      {renderGridItem('Name', 'left')}
      {renderGridItem('Type', 'center')}
      {renderGridItem('Questions', 'center')}
      {renderGridItem('Last Response', 'center')}
      {renderGridItem('Score', 'center')}
      {renderGridItem('Summary', 'center')}
    </Grid>
  )
}

export default ResponsesTableHeader
