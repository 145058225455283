import * as types from 'model/actionTypes'
import { DictT, InvitationT } from 'shared/types/model'

interface invitationsActionT {
  type: string
  invitations: DictT<InvitationT> | null
}

export function receiveInvitations (invitations: DictT<InvitationT> | null): invitationsActionT {
  return {
    type: types.RECEIVE_INVITATIONS,
    invitations
  }
}
