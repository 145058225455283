import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse'
import { faChartSimple } from '@fortawesome/pro-regular-svg-icons/faChartSimple'
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell'
import { faMoneyBillsSimple } from '@fortawesome/pro-regular-svg-icons/faMoneyBillsSimple'
import { faLaptopCode } from '@fortawesome/pro-regular-svg-icons/faLaptopCode'
import { faBooks } from '@fortawesome/pro-regular-svg-icons/faBooks'
import { faPenNib } from '@fortawesome/pro-regular-svg-icons/faPenNib'
import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons/faRightToBracket'
import { faMicrochipAi } from '@fortawesome/pro-regular-svg-icons/faMicrochipAi'
import _ from 'lodash'

import { AccountT, DictT } from 'shared/types/model'
import { useSelector } from 'model/hooks'

type TabItemT = {
  label: string
  path: string
  tabIndex: number
  icon: IconDefinition
}

const tabs: DictT<TabItemT> = {
  '/settings/account': {
    label: 'Account',
    path: '/settings/account',
    icon: faHouse,
    tabIndex: 0
  },
  '/settings/profile': {
    label: 'Profile',
    path: '/settings/profile',
    icon: faChartSimple,
    tabIndex: 1
  },
  '/settings/team': {
    label: 'Team',
    path: '/settings/team',
    icon: faUsers,
    tabIndex: 2
  },
  '/settings/notifications': {
    label: 'Notifications',
    path: '/settings/notifications',
    icon: faBell,
    tabIndex: 3
  },
  '/settings/billing': {
    label: 'Plan & Billing',
    path: '/settings/billing',
    icon: faMoneyBillsSimple,
    tabIndex: 4
  },
  '/settings/integrations': {
    label: 'Integrations',
    path: '/settings/integrations',
    icon: faLaptopCode,
    tabIndex: 5
  }
}

const adminTabs: DictT<TabItemT> = {
  '/settings/questions_library': {
    label: 'Questions library',
    path: '/settings/questions_library',
    icon: faBooks,
    tabIndex: 6
  },
  '/settings/templates': {
    label: 'Template Designer',
    path: '/settings/templates',
    icon: faPenNib,
    tabIndex: 7
  },
  '/settings/onboarding': {
    label: 'Onboarding',
    path: '/settings/onboarding',
    icon: faRightToBracket,
    tabIndex: 8
  }
}

const aiTabs: DictT<TabItemT> = {
  '/settings/analysis_prompt': {
    label: 'Answers Analysis',
    path: '/settings/analysis_prompt',
    icon: faMicrochipAi,
    tabIndex: 9
  },
  '/settings/cfq': {
    label: 'CFQ',
    path: '/settings/cfq',
    icon: faMicrochipAi,
    tabIndex: 10
  },
  '/settings/response_prompt': {
    label: 'Response Analysis',
    path: '/settings/response_prompt',
    icon: faMicrochipAi,
    tabIndex: 11
  }
}

const SettingsSidebar = ({ selectedTab, setSelectedTab }: { selectedTab: number, setSelectedTab: (e: number) => void }) => {
  const account: AccountT = useSelector(state => state.account)
  return (
    <Box h='2xl' py='4' px='2' backgroundColor='gray.50' rounded='lg'>
      <VStack alignItems='start' w='full' h='full'>
        {_.map(tabs, (c, i) => (
          <Button
            key={i}
            w='full'
            justifyContent='start'
            alignItems='center'
            backgroundColor='transparent'
            isActive={selectedTab === c.tabIndex}
            _active={{ backgroundColor: 'gray.100' }}
            onClick={() => setSelectedTab(c.tabIndex)}
          >
            <HStack py='2' pl='1' pr='8' spacing='4'>
              <FontAwesomeIcon icon={c.icon} fixedWidth />
              <Text>{c.label}</Text>
            </HStack>
          </Button>
        ))}
        {account.isFaceFileAdmin && (
          _.map({ ...adminTabs, ...aiTabs }, (c, i) => (
            <Button
              key={i}
              w='full'
              justifyContent='start'
              alignItems='center'
              backgroundColor='transparent'
              isActive={selectedTab === c.tabIndex}
              _active={{ backgroundColor: 'gray.100' }}
              onClick={() => setSelectedTab(c.tabIndex)}
              color='teal'
            >
              <HStack py='2' pl='1' pr='8' spacing='4'>
                <FontAwesomeIcon icon={c.icon} fixedWidth />
                <Text>{c.label}</Text>
              </HStack>
            </Button>
          ))
        )}
      </VStack>
    </Box>
  )
}

export default SettingsSidebar
