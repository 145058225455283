import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState
} from 'react'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  VStack,
  ModalCloseButton,
  Input
} from '@chakra-ui/react'
import { TemplateSectionT } from 'shared/types/model'
import { generateId } from 'controllers/db'
import {
  dbCreateTemplateSection,
  dbUpdateTemplateSection
} from 'controllers/templates'

export interface ICreateTemplateSectionModal {
  open: (s?: TemplateSectionT) => void
}

type Props = {}

const CreateTemplateSectionModal: ForwardRefRenderFunction<
  ICreateTemplateSectionModal,
  Props
> = (props, ref: Ref<unknown>) => {
  const [section, setSection] = useState<TemplateSectionT>()
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: (s: TemplateSectionT) => {
      setSection(s)
      setName(s ? s.label : '')
      setIcon(s ? s.icon : '')
      onOpen()
    }
  }))

  const onCreate = () => {
    if (section) {
      const newSection = {
        ...section,
        label: name,
        icon
      }
      dbUpdateTemplateSection(newSection)
    } else {
      const s: TemplateSectionT = {
        id: generateId(),
        label: name,
        templatesOrder: [],
        icon
      }
      dbCreateTemplateSection(s)
    }
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {section ? 'Edit section' : 'New template section'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4}>
          <VStack spacing={8}>
            <FormControl isRequired w='full'>
              <FormLabel htmlFor='sectionName'>Section name</FormLabel>
              <Input
                id='name'
                type='text'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired w='full'>
              <FormLabel htmlFor='sectionIcon'>Icon</FormLabel>
              <Input
                id='icon'
                type='text'
                placeholder={'faPlus'}
                value={icon}
                onChange={e => setIcon(e.target.value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'blue'}
            isDisabled={name.length === 0 || icon.length === 0}
            onClick={onCreate}
          >
            {section ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreateTemplateSectionModal)
