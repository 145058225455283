import { FC } from 'react'
import { VStack, Heading, Text } from '@chakra-ui/react'

type Props = {
  title?: string
  description?: string
}

const SettingsCardTitle: FC<Props> = ({ title, description }) => {
  return (
    <VStack align='start' justify='start' borderBottom='1px' borderColor='gray.100' pb='6' w='full'>
      {title && (
        <Heading fontSize='3xl' fontWeight='medium' color='gray.900'>
          {title}
        </Heading>
      )}
      {description && (
        <Text fontWeight='normal' color='gray.600'>
          {description}
        </Text>
      )}
    </VStack>
  )
}

export default SettingsCardTitle
