import _ from 'lodash'
import types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { OnboardingT } from 'shared/types/model'

const initialState: OnboardingT = {}

export default function C (
  state: typeof initialState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case types.RECEIVE_ONBOARDING:
      return _.omitBy(
        {
          ...state,
          ...action.onboarding
        },
        _.isNil
      )
    case types.RESET_ONBOARDING:
      return null
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
