import { FC, useState } from 'react'
import { Button, Flex, Box, Text, VStack, Progress, HStack } from '@chakra-ui/react'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import { openPaymentPage } from 'controllers/stripe'
import { getAccount } from 'model/selectors/base'
import SettingsCardTitle from 'components/settings/SettingsCardTitle'

const INVOICES = [
  'INV-01 - May 25 2021',
  'INV-02 - May 25 2021'
]

const SettingsBilling: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const subscription = useSelector(state => state.subscription)
  const products = useSelector(state => state.products)
  const planUsage = useSelector(getCurrentPlanUsage)
  const currentProduct = _.get(products, _.get(subscription, 'planId'))
  const isTestingAccount = useSelector(state => (_.get(getAccount(state), 'isTestingAccount', false)))

  const onManageSubscription = async () => {
    setLoading(true)
    await openPaymentPage()
    setLoading(false)
  }

  return (
    <VStack
      align='center'
      justify='start'
      direction='column'
      w='full'
      h='full'
      overflowY='hidden'
      px={{ lg: '8', base: '0' }}
      py={{ lg: '4', base: '0' }}
      spacing={8}
    >
      <SettingsCardTitle title='Plan and Billing' description='Invite your teammates to your FaceFile account' />
      <Box
        w='full'
        maxW='4xl'
        bg='white'
        boxShadow={{
          lg: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }}
        rounded='xl'
        position='relative'
        borderTopWidth={{ lg: 2, base: 0 }}
        borderTopColor='blue.500'
      >
        <Flex p={{ base: 4, lg: 6 }} direction='row' justify='space-between' borderBottom='1px' borderColor='gray.200'>
          <Box>
            <Text fontWeight={700} fontSize='lg' color='gray.900'>
              Your plan
            </Text>
            <Text fontWeight={400} fontSize='sm' color='gray.600'>
              {_.get(currentProduct, 'name', '')}
            </Text>
          </Box>
          {!_.isNil(subscription) && (
            <HStack spacing='4'>
              <Text color='gray.600' fontSize='sm'>
                Change plan
              </Text>
              <Button
                variant='solid'
                colorScheme='teal'
                onClick={onManageSubscription}
                isLoading={loading}
              >
                Upgrade
              </Button>
            </HStack>
          )}
        </Flex>
        <Flex px={{ base: 4, lg: 6 }} py='4' backgroundColor='white'>
          <Box w='3xs'>
            <Text fontWeight={400} fontSize='md' color='gray.800'>
              Usage
            </Text>
          </Box>
          <HStack w='40%' spacing='4'>
            <Progress
              alignSelf='center'
              flex='1'
              value={
                planUsage.limitReached
                  ? 100
                  : (planUsage.used / planUsage.responsesIncluded) * 100
              }
              size='xs'
              colorScheme={planUsage.limitReached ? 'red' : 'teal'}
            />
            <Text
              fontSize='xs'
              flex='1'
              lineHeight='4'
              fontWeight='normal'
              color={planUsage.limitReached ? 'red' : 'gray.700'}
              className='responses-counter'
            >
              {`${planUsage.used} / ${
                isTestingAccount ? '∞' : planUsage.responsesIncluded
              } responses`}
            </Text>
          </HStack>
        </Flex>
        <Flex px={{ base: 4, lg: 6 }} py='4' backgroundColor='gray.50'>
          <Box w='3xs'>
            <Text fontWeight={400} fontSize='md' color='gray.800'>
              Minutes included
            </Text>
          </Box>
          <Box>
            <Text fontWeight={600} fontSize='md' color='gray.900'>
              ? minutes per month
            </Text>
          </Box>
        </Flex>
        <Flex px={{ base: 4, lg: 6 }} py='4' h='full'>
          <Box w='3xs'>
            <Text fontWeight={400} fontSize='md' color='gray.800'>
              Current plan
            </Text>
          </Box>
          <Box>
            <Text fontWeight={600}>
              {planUsage.name}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        w='100%'
        maxW='4xl'
        bg='white'
        boxShadow={{
          lg: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }}
        rounded='xl'
        position='relative'
        borderTopWidth={{ lg: 2, base: 0 }}
        borderTopColor='blue.500'
        h='full'
        overflowY='auto'
      >
        <VStack
          w='full'
          align='flex-start'
          spacing={{ base: 6, lg: 0 }}
          p={{ base: 4, lg: 6 }}
          borderBottom='1px'
          borderColor='gray.200'
        >
          <Text fontWeight={700} fontSize='lg' color='gray.900'>
            Invoices
          </Text>
        </VStack>
        <VStack
          rounded='xl'
        >
          {_.map(INVOICES, (invoice: string, index: number) => (
            <Flex
              key={index}
              align='center'
              justify='start'
              w='full'
              p='4'
              bg={index % 2 === 0 ? 'gray.50' : 'white'}
              borderBottomRadius={index === _.size(INVOICES) - 1 ? 'xl' : 'none'}
            >
              <Button variant='link' colorScheme='blue' onClick={() => {}}>
                <Text fontWeight={600}>
                  {invoice}
                </Text>
              </Button>
            </Flex>
          ))}
        </VStack>
      </Box>
      <Box>
        <Button variant='link' colorScheme='red'>
          Cancel my subscription
        </Button>
      </Box>
    </VStack>
  )
}

export default SettingsBilling
