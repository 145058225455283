import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  VStack,
  ModalCloseButton,
  Input
} from '@chakra-ui/react'
import { TemplateT } from 'shared/types/model'
import { generateId } from 'controllers/db'
import { dbCreateTemplate, dbUpdateTemplate } from 'controllers/templates'

export interface ICreateTemplateModal {
  open: (sectionId: string, tId?: string, tName?: string) => void
}

type Props = {}

const CreateTemplateModal: ForwardRefRenderFunction<
  ICreateTemplateModal,
  Props
> = (props, ref: Ref<unknown>) => {
  const [sectionId, setSectionId] = useState('')
  const [templateId, setTemplateId] = useState<string>()
  const [name, setName] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: (sectionId: string, tId: string, tName: string) => {
      setSectionId(sectionId)
      setTemplateId(tId)
      setName(tName || '')
      onOpen()
    }
  }))

  const onCreate = () => {
    if (templateId) {
      dbUpdateTemplate(templateId, { title: name })
    } else {
      const t: TemplateT = {
        id: generateId(),
        questions: [],
        title: name,
        sectionId,
        createdAt: _.now(),
        enabled: false
      }
      dbCreateTemplate(t)
    }
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {templateId ? 'Edit template' : 'New template'}{' '}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4}>
          <VStack spacing={8}>
            <FormControl isRequired w='full'>
              <FormLabel htmlFor='elaiVideoId'>Template name</FormLabel>
              <Input
                id='name'
                type='text'
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyPress={async e => {
                  if (e.key === 'Enter') {
                    onCreate()
                  }
                }}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={'blue'}
            isDisabled={name.length === 0}
            onClick={onCreate}
          >
            {templateId ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreateTemplateModal)
