import {
  Ref,
  useState,
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useRef
} from 'react'
import _ from 'lodash'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure
} from '@chakra-ui/react'

// import { removeContact } from 'controllers/responses'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import { getResponsesByEmailWithDeleted } from 'model/selectors/responses'
import { dbDeleteResponses } from 'controllers/responses'

export interface IRemoveContactModal {
  open: (email: string) => void
}

type Props = {}

const EditContact: ForwardRefRenderFunction<IRemoveContactModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [email, setEmail] = useState<string>()
  const navigate = useNavigate()
  const responsesByEmail = useSelector(getResponsesByEmailWithDeleted)
  const cancelRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: (contactEmail: string) => {
      setEmail(contactEmail)
      onOpen()
    }
  }))

  const onSubmit = async (): Promise<void> => {
    if (email) {
      const responses = _.get(responsesByEmail, email)
      const ids = _.map(responses, r => r.id)
      if (!_.isEmpty(ids)) {
        dbDeleteResponses(ids)
      }
      onClose()
      navigate('/contacts')
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Remove contact
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to remove the contact and its responses?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant='ghost' ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={onSubmit} ml={3}>
              Remove
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default forwardRef(EditContact)
