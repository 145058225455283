import { FC, useRef } from 'react'
import { VStack, Text } from '@chakra-ui/react'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import { ResponseT } from 'shared/types/model'
import ResponseRow from 'pages/form/responsesPanel/ResponseRow'
import CreateContactModal, {
  ICreateContactModal
} from 'modals/CreateContactModal'
import { getBlockedResponses } from 'model/selectors/paymentPlans'

type Props = {
  responses: ResponseT[]
  onClick: (responseId: string) => void
  selectedResponseId?: string
  showFormName?: boolean
  onDeleteResponse: (responseId: string) => void
  onViewContact: (responseId: string) => void
  title?: string
  hideMenu?: boolean
}

const ResponsesPanel: FC<Props> = ({
  responses,
  onClick,
  selectedResponseId,
  showFormName = false,
  onDeleteResponse,
  onViewContact,
  title,
  hideMenu
}) => {
  const forms = useSelector(state => state.forms)
  const blockedResponses = useSelector(getBlockedResponses)
  const createContactRef = useRef<ICreateContactModal>(null)

  const onCreateContact = (responseId: string) => {
    createContactRef.current?.open(responseId)
  }

  return (
    <VStack
      flexShrink={0}
      h='full'
      w='full'
      align={'flex-start'}
      spacing={0}
      pl={{ base: 0, lg: 4 }}
      pt={4}
      overflow='hidden'
    >
      {title && (
        <Text pl='1' color='gray.500' fontSize={'xs'}>
          {title}
        </Text>
      )}

      <VStack
        // pr='1'
        flexShrink={0}
        // h='full'
        w='full'
        flex={1}
        spacing={2}
        align='flex-start'
        overflowX={'hidden'}
        overflowY='auto'
        scrollSnapType={'y proximity'}
        sx={{
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {_.map(responses, r => {
          const formName = _.get(forms, [_.get(r, 'formId'), 'label'])
          const isSelected = selectedResponseId === r.id
          return (
            <ResponseRow
              key={r.id}
              r={r}
              formName={showFormName ? formName : undefined}
              onClick={onClick}
              isSelected={isSelected}
              onDeleteResponse={onDeleteResponse}
              onViewContact={() => onViewContact(r.id)}
              onCreateContact={() => onCreateContact(r.id)}
              hideMenu={hideMenu}
              isBlocked={_.has(blockedResponses, r.id)}
            />
          )
        })}
      </VStack>
      <CreateContactModal ref={createContactRef} />
    </VStack>
  )
}

export default ResponsesPanel
