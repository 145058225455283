import { FC, useMemo, useRef } from 'react'
import _ from 'lodash'
import {
  VStack,
  Text,
  Box,
  Heading,
  HStack,
  Image,
  Button,
  Tooltip,
  IconButton,
  Switch,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { getQuestionsLibrary } from 'model/selectors/base'
import { QuestionT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faArrowUp,
  faArrowDown,
  faTrashCan,
  faInfoCircle
} from '@fortawesome/pro-regular-svg-icons'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import SelectPublicQuestionModal, {
  ISelectPublicQuestionModal
} from 'modals/SelectPublicQuestionModal'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import {
  dbRemoveQuestionFromTemplate,
  dbEnableTemplate,
  dbUpdateTemplate
} from 'controllers/templates'
import { arrayMove, arrayInsertAt } from 'shared/utils/array'
import ContactFormSkeleton from 'components/templates/templateQuestions/ContactFormSkeleton'
import TemplateTagsSelect from 'components/templates/templateQuestions/TemplateTagsSelect'

type Props = {
  templateId?: string
  canEdit?: boolean
  onQuestionClick: (questionId: string) => void
}

const TemplateQuestions: FC<Props> = ({
  templateId,
  canEdit = false,
  onQuestionClick
}) => {
  const template = useSelector(
    state => templateId && state.templates && state.templates[templateId]
  )
  const selectPublicQuestionModalRef = useRef<ISelectPublicQuestionModal>(null)
  const questions = useSelector(getQuestionsLibrary)

  const toggleTemplateEnabled = () => {
    if (template) {
      dbEnableTemplate(template.id, !template.enabled)
    }
  }

  const contactFormIndex = useMemo(() => {
    if (template && template.questions && template.contactFormQuestionId) {
      return _.indexOf(template.questions, template.contactFormQuestionId)
    }
  }, [template])

  const updateTemplateTags = (tags: string[]) => {
    if (template) {
      dbUpdateTemplate(template.id, { tags })
    }
  }

  const renderTemplateTags = () => {
    if (template && canEdit) {
      return (
        <TemplateTagsSelect
          tagsIds={template.tags || []}
          setTagsIds={updateTemplateTags}
        />
      )
    }
  }

  const renderHeader = () => {
    if (template) {
      return (
        <HStack justify={'space-between'} w='full' px={6}>
          <VStack align={'flex-start'} spacing={1} flex={1}>
            <Heading as='h4' fontWeight='bold' fontSize='lg' color='gray.900'>
              {template.title}
            </Heading>
            <Text color='gray.600' fontSize='sm' fontWeight='normal'>
              The questions below will be asked in your video form.
            </Text>
          </VStack>

          {canEdit && (
            <VStack flex='1' align={'flex-end'}>
              <Tooltip
                shouldWrapChildren
                hasArrow
                label='The template is available for customers if enabled'
              >
                <FormControl display='flex' flexDirection={'row'}>
                  <FormLabel htmlFor='email-alerts' mb='0' fontSize='xs'>
                    Template enabled
                  </FormLabel>
                  <Switch
                    id='email-alerts'
                    isChecked={template.enabled}
                    onChange={toggleTemplateEnabled}
                    size='sm'
                  />
                </FormControl>
              </Tooltip>
              {renderTemplateTags()}
            </VStack>
          )}
        </HStack>
      )
    }
  }

  const onRemoveQuestionClick = (qId: string) => {
    if (template && template.questions) {
      if (qId !== template.contactFormQuestionId) {
        dbRemoveQuestionFromTemplate(template.id, qId)
      } else {
        const qIndex = _.indexOf(template.questions, qId)
        const newQuestions = _.pull(template.questions, qId)
        const newContactFormIndex = qIndex > 0 ? qIndex - 1 : 0
        const newContactFormQuestionId = newQuestions[newContactFormIndex]
        dbUpdateTemplate(template.id, {
          questions: newQuestions,
          contactFormQuestionId: newContactFormQuestionId
        })
      }
    }
  }

  const onMoveQuestionMoveClick = (qId: string, isUp: boolean) => {
    if (template && template.questions) {
      if (template.contactFormQuestionId && !_.isNil(contactFormIndex)) {
        const questionsWithForm = arrayInsertAt(
          template.questions,
          contactFormIndex + 1,
          null
        )
        // console.log('questionsWithForm', questionsWithForm)
        const questionIndex = _.indexOf(questionsWithForm, qId)
        const toIndex = isUp ? questionIndex - 1 : questionIndex + 1
        const newOrder = arrayMove(questionsWithForm, questionIndex, toIndex)
        const newContactFormIndex = _.indexOf(newOrder, null)
        const newContactFormQuestionId = newOrder[newContactFormIndex - 1]
        const newQuestionsOrder = _.compact(newOrder)
        dbUpdateTemplate(template.id, {
          questions: newQuestionsOrder,
          contactFormQuestionId: newContactFormQuestionId
        })
      } else {
        const index = _.indexOf(template.questions, qId)
        const toIndex = isUp ? index - 1 : index + 1
        const newOrder = arrayMove(template.questions, index, toIndex)
        dbUpdateTemplate(template.id, { questions: newOrder })
      }
    }
  }

  const onRemoveContactForm = () => {
    if (template) {
      dbUpdateTemplate(template.id, {
        contactFormQuestionId: undefined
      })
    }
  }

  const onMoveContactForm = (toTop: boolean) => {
    // console.log('onMoveContactForm', toTop, contactFormIndex)
    if (!_.isNil(contactFormIndex) && template && template.questions) {
      const updatedOrder = toTop ? contactFormIndex - 1 : contactFormIndex + 1
      const newContantFormQuestionId: string | undefined =
        template.questions[updatedOrder] ||
        template.questions[template.questions.length - 1]
      dbUpdateTemplate(template.id, {
        contactFormQuestionId: newContantFormQuestionId
      })
    }
  }

  const renderQuestionMenu = (q: QuestionT, index: number, amount: number) => {
    if (template && canEdit) {
      const cannotMoveDown =
        (index === amount - 1 && template.contactFormQuestionId !== q.id) ||
        (template.contactFormQuestionId === q.id && index === 0)
      return (
        <HStack visibility='hidden' _groupHover={{ visibility: 'visible' }}>
          <IconButton
            aria-label='move_up'
            icon={<FontAwesomeIcon icon={faArrowUp} />}
            onClick={() => onMoveQuestionMoveClick(q.id, true)}
            display={index === 0 ? 'none' : 'block'}
            size='sm'
            variant={'ghost'}
          />
          <IconButton
            aria-label='move_down'
            icon={<FontAwesomeIcon icon={faArrowDown} />}
            onClick={() => onMoveQuestionMoveClick(q.id, false)}
            display={cannotMoveDown ? 'none' : 'block'}
            size='sm'
            variant={'ghost'}
          />
          <IconButton
            aria-label='remove'
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            onClick={() => onRemoveQuestionClick(q.id)}
            colorScheme='red'
            size='sm'
            variant={'ghost'}
          />
        </HStack>
      )
    }
  }

  const renderContactFormMenu = (index: number, amount: number) => {
    if (canEdit) {
      return (
        <HStack visibility='hidden' _groupHover={{ visibility: 'visible' }}>
          <IconButton
            aria-label='move_up'
            icon={<FontAwesomeIcon icon={faArrowUp} />}
            onClick={() => onMoveContactForm(true)}
            display={index <= 1 ? 'none' : 'block'}
            size='sm'
            variant={'ghost'}
          />
          <IconButton
            aria-label='move_down'
            icon={<FontAwesomeIcon icon={faArrowDown} />}
            onClick={() => onMoveContactForm(false)}
            display={index < amount - 1 ? 'block' : 'none'}
            size='sm'
            variant={'ghost'}
          />
          <IconButton
            aria-label='remove'
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            onClick={onRemoveContactForm}
            colorScheme='red'
            size='sm'
            variant={'ghost'}
          />
        </HStack>
      )
    }
  }

  const renderContactForm = (index: number, amount: number) => {
    return (
      <HStack
        key={'contact form'}
        borderTopWidth={1}
        pt={4}
        align='center'
        spacing='5'
        w='full'
        background='transparent' // selectedQuestion === qId ? 'gray.100'
        cursor='default'
        role='group'
      >
        <ContactFormSkeleton />
        <VStack align='flex-start' spacing={1} w='full'>
          <Text
            fontSize='sm'
            fontWeight='bold'
            textAlign='left'
            color='blue.600'
            className='contact-form-label'
            lineHeight={1.2}
          >
            Contact form
          </Text>
          <Text fontSize='sm' fontWeight='medium' color='gray.500' pt={1}>
            Step {index + 1} of {amount}
          </Text>
        </VStack>
        {renderContactFormMenu(index, amount)}
        <Tooltip
          label='In this step, the Name and email of the applicant will be collected in a text form'
          placement='top'
          hasArrow
        >
          <Box color='gray.600'>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Box>
        </Tooltip>
      </HStack>
    )
  }

  const renderQuestion = (q: QuestionT, index: number, amount: number) => {
    return (
      <HStack
        key={q.id}
        borderTopWidth={1}
        pt={4}
        align='center'
        spacing='5'
        w='full'
        background='transparent' // selectedQuestion === qId ? 'gray.100'
        cursor='default'
        role='group'
      >
        <Image
          w='66px'
          h='71px'
          objectFit='cover'
          src={_.get(q, 'thumbnail', '')}
          alt='question thumbnail'
          rounded='0.25rem'
          fallback={
            <Box w='66px' h='71px' bgColor='blackAlpha.700' rounded='0.25rem' />
          }
        />
        <VStack flex={1} align='flex-start'>
          <Tooltip label={q.text}>
            <Text
              color='gray.800'
              fontWeight='medium'
              fontSize='sm'
              lineHeight='5'
              noOfLines={3}
            >
              {q.text}
            </Text>
          </Tooltip>
          <Text fontSize='sm' fontWeight='medium' color='gray.500'>
            Step {index + 1} of {amount}
          </Text>
        </VStack>
        <HStack justify={'center'}>
          {renderQuestionMenu(q, index, amount)}
          <Button
            size='sm'
            variant={'outline'}
            leftIcon={<FontAwesomeIcon icon={faPlay} />}
            onClick={() => onQuestionClick(q.id)}
          >
            Play
          </Button>
        </HStack>
      </HStack>
    )
  }

  const renderQuestionsList = () => {
    if (template && questions) {
      const amount = template.contactFormQuestionId
        ? _.size(template.questions) + 1
        : _.size(template.questions)
      return _.map(template.questions, (qId, index) => {
        const i =
          !_.isNil(contactFormIndex) && index > contactFormIndex
            ? index + 1
            : index
        const q = questions[qId]
        if (q) {
          if (q.id === template.contactFormQuestionId) {
            return [
              renderQuestion(q, i, amount),
              renderContactForm(i + 1, amount)
            ]
          } else {
            return renderQuestion(q, i, amount)
          }
        } else {
          return null
        }
      })
    }
  }

  const onAddQuestionClick = () => {
    selectPublicQuestionModalRef.current?.open(templateId)
  }

  const onAddContactFormClick = () => {
    // console.log('onAddContactFormClick')
    if (
      template &&
      _.isNil(template.contactFormQuestionId) &&
      !_.isEmpty(template.questions)
    ) {
      dbUpdateTemplate(template.id, {
        contactFormQuestionId: _.last(template.questions)
      })
    }
  }

  const renderAddQuestionButton = () => {
    if (canEdit && template) {
      return (
        <HStack px={6}>
          <Button
            colorScheme={'blue'}
            size='sm'
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={onAddQuestionClick}
          >
            Add question
          </Button>
          <Button
            size='sm'
            variant={'outline'}
            leftIcon={<FontAwesomeIcon icon={faUsers} />}
            onClick={onAddContactFormClick}
            isDisabled={
              _.isEmpty(template.questions) ||
              !_.isNil(template.contactFormQuestionId)
            }
          >
            Place contact form
          </Button>
        </HStack>
      )
    }
  }

  return (
    <VStack
      flex={1}
      borderRightWidth={1}
      py='6'
      h='full'
      align='start'
      justify='start'
      bg='gray.50'
      flexShrink={0}
      flexGrow={1}
    >
      {renderHeader()}
      <VStack
        flex={1}
        spacing={6}
        pt={6}
        px='6'
        w='full'
        overflowY={'auto'}
        scrollSnapType={'y proximity'}
      >
        {renderQuestionsList()}
      </VStack>
      {renderAddQuestionButton()}
      <SelectPublicQuestionModal ref={selectPublicQuestionModalRef} />
    </VStack>
  )
}

export default TemplateQuestions
