import {
  Ref,
  useState,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useMemo
} from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  HStack,
  IconButton,
  Textarea,
  VStack,
  Switch,
  Text,
  Divider
} from '@chakra-ui/react'
import _ from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/pro-regular-svg-icons'
import SelectOption from 'modals/createFormModal/SelectOption'
import ResponseTypeSwitch from 'modals/createFormModal/ResponseTypeSwitch'
// import { updateResponsesNameEmail } from 'controllers/responses'

export interface ICreateFormModal {
  open: () => void
}

type Props = {
  onSuccess: ({ numberOfCfqs }: { numberOfCfqs: number }) => void
}

const CreateFormModal: ForwardRefRenderFunction<ICreateFormModal, Props> = (
  { onSuccess },
  ref: Ref<unknown>
) => {
  const [promptVisible, setPromptVisible] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [formType, setFormType] = useState('interview')
  const [industry, setIndustry] = useState('hospitality')
  const [position, setPosition] = useState('')
  const [aiFollowups, setAIFollowups] = useState(true)
  const [followupsAmount, setFollowupsAmount] = useState('5')
  const [allowedResponses, setAllowedResponses] = useState({
    video: true,
    text: true,
    voice: true
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const allowedR = useMemo(
    () => _.filter(allowedResponses, v => v),
    [allowedResponses]
  )

  useImperativeHandle(ref, () => ({
    open: () => {
      setPrompt('')
      setPromptVisible(false)
      setFormType('interview')
      setIndustry('hospitality')
      setPosition('')
      setAIFollowups(true)
      setFollowupsAmount('5')
      setAllowedResponses({
        video: true,
        text: true,
        voice: true
      })
      onOpen()
    }
  }))

  const onSubmit = async () => {
    if (_.isEmpty(allowedR)) {
      return null
    } else {
      onClose()
      onSuccess({ numberOfCfqs: aiFollowups ? _.toNumber(followupsAmount) : 0 })
    }
  }

  const togglePrompt = () => {
    setPromptVisible(v => !v)
  }

  const promptSwitch = (
    <IconButton
      aria-label='show_hide_prompt'
      icon={<FontAwesomeIcon icon={faCode} />}
      backgroundColor={promptVisible ? 'blue.50' : 'white'}
      onClick={togglePrompt}
    />
  )

  const renderPrompt = () => {
    if (promptVisible) {
      return (
        <FormControl px={7} py={5} bg='blue.50' rounded={'md'} mb={6}>
          <FormLabel color='gray.500'>Prompt</FormLabel>
          <Textarea
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            fontSize={'sm'}
            bg='white'
            height={28}
            placeholder='Create a 5 question interview for a customer service representative position in the hospitality industry. Questions should be open ended and short.'
            // onFocus={() => setNameError(false)}
            // onBlur={() => setNameError(name === '')}
          />
        </FormControl>
      )
    }
  }

  const renderAISwitch = () => {
    return (
      <HStack w='full' justify={'space-between'}>
        <Text color='gray.500' fontWeight={'medium'}>
          Enable AI generated followups
        </Text>
        <Switch
          isChecked={aiFollowups}
          onChange={e => setAIFollowups(e.target.checked)}
          colorScheme='teal'
        />
      </HStack>
    )
  }

  const renderFollowupsAmount = () => {
    if (aiFollowups) {
      return (
        <SelectOption
          title='Max followups'
          options={[
            { value: '1', title: '1 question' },
            { value: '2', title: '2 questions' },
            { value: '3', title: '3 questions' },
            { value: '4', title: '4 questions' },
            { value: '5', title: '5 questions' }
          ]}
          value={followupsAmount}
          onChange={setFollowupsAmount}
        />
      )
    }
  }

  const renderAllowedResponses = () => {
    return (
      <VStack w='full' align={'flex-start'} spacing={6}>
        <VStack align='flex-start' spacing={0}>
          <Text fontSize={'lg'} color='gray.700'>
            Allowed responses
          </Text>
          {_.isEmpty(allowedR) && (
            <Text color={'red.500'} fontSize={'sm'}>
              Select at least one response type
            </Text>
          )}
        </VStack>
        <ResponseTypeSwitch
          title='Video'
          checked={allowedResponses.video}
          onChange={v => setAllowedResponses({ ...allowedResponses, video: v })}
        />
        <ResponseTypeSwitch
          title='Voice'
          checked={allowedResponses.voice}
          onChange={v => setAllowedResponses({ ...allowedResponses, voice: v })}
        />
        <ResponseTypeSwitch
          title='Text'
          checked={allowedResponses.text}
          onChange={v => setAllowedResponses({ ...allowedResponses, text: v })}
        />
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md'>
      <ModalOverlay />
      <ModalContent>
        <HStack justify={'space-between'} w='full' p='6'>
          <ModalHeader
            p={0}
            fontSize='md'
            // lineHeight='7'
            fontWeight='semibold'
            color='black'
            flexShrink={0}
            flex={1}
            // borderBottomWidth={1}
          >
            Create new form
          </ModalHeader>
          {promptSwitch}
        </HStack>

        <ModalBody pt='0' px={4}>
          {renderPrompt()}
          <VStack spacing={6} px={4}>
            <SelectOption
              title='Type'
              options={[
                { value: 'interview', title: 'Interview' },
                { value: 'feedback', title: 'Feedback / Survey' },
                { value: 'confirmation', title: 'Confirmation' },
                { value: 'qualification', title: 'Lead qualification' },
                { value: 'support', title: 'Support request' }
              ]}
              value={formType}
              onChange={setFormType}
            />
            <SelectOption
              title='Industry'
              options={[
                { value: 'hospitality', title: 'Hospitality' },
                { value: 'medicine', title: 'Medicine' },
                { value: 'custome', title: 'Custom industry' }
              ]}
              value={industry}
              onChange={setIndustry}
            />
            <FormControl>
              <FormLabel color='gray.700' fontSize={'lg'} fontWeight={'normal'}>
                Position
              </FormLabel>
              <Input
                type='text'
                value={position}
                onChange={e => setPosition(e.target.value)}
                // onFocus={() => setNameError(false)}
                // onBlur={() => setNameError(name === '')}
                placeholder='Customer service representative'
              />
            </FormControl>
            <Divider />
            {renderAISwitch()}
            {renderFollowupsAmount()}
            <Divider />
            {renderAllowedResponses()}
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={'flex-start'} pt={8}>
          <HStack justify={'flex-end'} w='full'>
            <Button
              variant='ghost'
              fontSize='md'
              lineHeight='6'
              fontWeight='semibold'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button colorScheme='teal' onClick={onSubmit}>
              Generate
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreateFormModal)
