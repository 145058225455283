import * as types from 'model/actionTypes'
import { OnboardingT } from 'shared/types/model'

interface OnboardingActionT {
  type: string
  onboarding: OnboardingT
}

export function receiveOnboarding (
  onboarding: Partial<OnboardingT>
): OnboardingActionT {
  return {
    type: types.RECEIVE_ONBOARDING,
    onboarding
  }
}
