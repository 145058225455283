import { FC, useState } from 'react'
import { VideoT, QuestionT } from 'shared/types/model'
import {
  HStack,
  Image,
  VStack,
  Text,
  Button,
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
  IconButton,
  Tooltip,
  Tag
} from '@chakra-ui/react'
import _ from 'lodash'
import { dbUpdateQuestion, dbSavePublicQuestion } from 'controllers/questions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faRotate,
  faInfoCircle,
  faTrashCan,
  faTrashUndo
} from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'
import { generateId } from 'controllers/db'
import { useSelector } from 'model/hooks'
import TemplateTagsSelect from 'components/templates/templateQuestions/TemplateTagsSelect'

interface Props {
  q?: QuestionT
  elaiQ?: VideoT
  onPlay: (questionId: string) => void
  isSelected: boolean
  onArchive?: (questionId: string) => void
  onUnarchive?: (questionId: string) => void
}

const LibraryQuestion: FC<Props> = ({
  q,
  onPlay,
  elaiQ,
  isSelected,
  onArchive,
  onUnarchive
}) => {
  const [label, setLabel] = useState(q ? q.text : '')
  const userId = useSelector(state => state.user?.id || '')

  const isArchived = q && q.deleted && q.deleted > 0

  const changeLabel = () => {
    if (q) {
      dbUpdateQuestion(q.id, { text: label })
    }
  }

  const syncVideo = () => {
    if (q && elaiQ && elaiQ.url) {
      dbUpdateQuestion(q.id, { mediaUrl: elaiQ?.url })
    }
  }

  const onCreate = () => {
    if (elaiQ) {
      const qId = generateId()
      const q: QuestionT = {
        createdAt: _.now(),
        createdBy: userId,
        length: elaiQ.duration,
        thumbnail: elaiQ.thumbnail,
        mediaUrl: elaiQ.url,
        text: _.get(elaiQ, ['slides', 0, 'speech'], ''),
        id: qId,
        elaiVideoId: elaiQ._id,
        isPublic: true
      }
      onPlay(qId)
      dbSavePublicQuestion(q)
    }
  }

  const renderImage = () => {
    return (
      <Box>
        <Image
          w='16'
          h='20'
          objectFit='cover'
          src={_.get(q, 'thumbnail', _.get(elaiQ, 'thumbnail', ''))}
          alt='question thumbnail'
          rounded='base'
          fallback={
            <Box w='16' h='20' bgColor='blackAlpha.700' rounded='base' />
          }
          opacity={isArchived ? 0.4 : 1}
        />
        {isArchived && (
          <Tag size='sm' colorScheme={'red'}>
            archived
          </Tag>
        )}
      </Box>
    )
  }

  const renderLabel = () => {
    if (q) {
      return (
        <Editable
          value={label}
          onChange={(nextValue: string) => setLabel(nextValue)}
          w='full'
          onSubmit={changeLabel}
        >
          <EditablePreview fontWeight={'semibold'} />
          <EditableTextarea />
        </Editable>
      )
    } else {
      return (
        <Button
          variant={'link'}
          size='sm'
          colorScheme={'blue'}
          pb='1'
          onClick={onCreate}
        >
          Create question
        </Button>
      )
    }
  }

  const renderTexts = () => {
    return (
      <VStack
        flex={1}
        align='flex-start'
        spacing={0}
        opacity={q && q.deleted && q.deleted > 0 ? 0.4 : 1}
      >
        {renderLabel()}
        {elaiQ && (
          <>
            <Text fontSize='xs' color='gray.400'>
              <b>Elai name:</b> {elaiQ?.name}
            </Text>
            <Text fontSize='xs' color='gray.400'>
              <b>Speach:</b> {_.get(elaiQ, ['slides', 0, 'speech'], '')}
            </Text>
          </>
        )}
        {!elaiQ && (
          <Text fontSize={'sm'} color='red.500'>
            Elai question was not found
          </Text>
        )}
      </VStack>
    )
  }

  const renderDates = () => {
    return (
      <VStack spacing={0} align='flex-end'>
        <Text fontSize={'xs'} color='gray.400'>
          <b>Elai video ID:</b> {elaiQ?._id}
        </Text>
        <Text fontSize={'xs'} color='gray.400'>
          <b>FaceFile question created at:</b>{' '}
          {q ? moment(q.createdAt).format('lll') : '-'}
        </Text>
        <Text fontSize={'xs'} color='gray.400'>
          <b>Elai video created at:</b> {moment(elaiQ?.createdAt).format('lll')}
        </Text>
        <Text fontSize={'xs'} color='gray.400'>
          <b>Elai video updated at:</b> {moment(elaiQ?.updatedAt).format('lll')}
        </Text>
      </VStack>
    )
  }

  const renderArchiveButton = () => {
    if (q && q.deleted && q.deleted > 0) {
      return (
        <Tooltip label='Restore the question'>
          <IconButton
            _groupHover={{ display: _.isNil(q) ? 'none' : 'block' }}
            display={'none'}
            variant={'ghost'}
            aria-label='Restore the question'
            icon={<FontAwesomeIcon icon={faTrashUndo} />}
            color='blue.500'
            onClick={() => q && onUnarchive && onUnarchive(q.id)}
            size='sm'
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip label='Archive the question'>
          <IconButton
            _groupHover={{ display: _.isNil(q) ? 'none' : 'block' }}
            display={'none'}
            variant={'ghost'}
            aria-label='Archive the question'
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            color='red.500'
            onClick={() => q && onArchive && onArchive(q.id)}
            size='sm'
          />
        </Tooltip>
      )
    }
  }

  const setTagsToQuestion = (tagsIds: string[]) => {
    console.log('setTagsToQuestion', tagsIds)
    if (q) {
      dbUpdateQuestion(q.id, { tags: tagsIds })
    }
  }

  const renderTags = () => {
    if (q) {
      return (
        <TemplateTagsSelect
          tagsIds={q?.tags || []}
          setTagsIds={setTagsToQuestion}
        />
      )
    }
  }

  const renderActions = () => {
    const questionId = _.get(q, 'id', _.get(elaiQ, '_id'))
    return (
      <VStack align={'flex-end'} w='80'>
        {renderTags()}
        <HStack w='52' align='center' justify={'flex-end'}>
          {renderArchiveButton()}
          <Tooltip label='Open Elai video'>
            <IconButton
              _groupHover={{ display: _.isNil(elaiQ) ? 'none' : 'block' }}
              display={'none'}
              variant={'ghost'}
              aria-label='open_elai_video'
              icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              onClick={() =>
                window.open(`https://app.elai.io/video/${elaiQ?._id}`, '_blank')
              }
              size={'sm'}
            />
          </Tooltip>
          <Tooltip label={renderDates()}>
            <Box
              _groupHover={{ display: _.isNil(elaiQ) ? 'none' : 'block' }}
              display={'none'}
              px='2'
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Box>
          </Tooltip>
          {q && elaiQ && q.mediaUrl !== elaiQ?.url && (
            <Tooltip label='Update FaceFile question video (sync from Elai)'>
              <IconButton
                aria-label='sync video'
                size='sm'
                icon={<FontAwesomeIcon icon={faRotate} />}
                colorScheme='blue'
                onClick={syncVideo}
              />
            </Tooltip>
          )}

          <Button
            size='sm'
            variant={'outline'}
            onClick={() => questionId && onPlay(questionId)}
          >
            Play
          </Button>
        </HStack>
      </VStack>
    )
  }

  return (
    <HStack
      py={2}
      px={2}
      align='center'
      spacing='5'
      w='full'
      cursor='default'
      role='group'
      borderLeftWidth={'4px'}
      borderLeftColor={isSelected ? 'teal.500' : 'transparent'}
      scrollSnapAlign='start'
    >
      {renderImage()}
      {renderTexts()}
      {renderActions()}
    </HStack>
  )
}

export default LibraryQuestion
