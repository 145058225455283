import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getProfiles, getUser } from 'model/selectors/base'

export const getCurrentUserProfile = createSelector(
  [getUser, getProfiles],
  (user, profiles) => {
    // console.log('user', user, 'profiles', profiles)
    return user ? _.get(profiles, user.id) : undefined
  }
)
