import {
  ForwardRefRenderFunction,
  useState,
  forwardRef,
  useImperativeHandle,
  Ref
} from 'react'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  VStack,
  ModalCloseButton
} from '@chakra-ui/react'

import { useSelector } from 'model/hooks'
import CreateQuestionForm from 'modals/selectQuestionModal/CreateQuestionForm'
import { dbCreateQuestion } from 'controllers/questions'
import SelectQuestion from 'modals/selectQuestionModal/SelectQuestion'
import _ from 'lodash'
import { logCreateCustomQuestion } from 'controllers/analytics'

export interface ISelectQuestionModal {
  open: (formId: string, selectedQuestionId?: string) => void
}

type Props = {
  handleContactFormClick: () => void
}

const SelectQuestionModal: ForwardRefRenderFunction<
  ISelectQuestionModal,
  Props
> = ({ handleContactFormClick }, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [formId, setFormId] = useState<string | null>(null)
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>()
  const accountId = useSelector(state => state.account.id)
  const [isCreateQuestion, setIsCreateQuestion] = useState(false)

  useImperativeHandle(ref, () => ({
    open: (formId: string, qId: string) => {
      setSelectedQuestionId(qId)
      setIsCreateQuestion(false)
      setFormId(formId)
      onOpen()
    }
  }))

  const onCreateQuestion = async (text: string) => {
    console.log('onCreateQuestion', text)
    const qId = await dbCreateQuestion(text, accountId)
    setIsCreateQuestion(false)
    if (!_.isNil(qId)) {
      logCreateCustomQuestion(qId)
    }
  }

  const renderBody = () => {
    if (formId) {
      if (isCreateQuestion) {
        return (
          <ModalBody w='full' h='full' p='0'>
            <VStack w='full' h='full'>
              <ModalCloseButton size='lg' className='close-button' />
              <CreateQuestionForm
                onClose={() => setIsCreateQuestion(false)}
                onCreateQuestion={onCreateQuestion}
              />
            </VStack>
          </ModalBody>
        )
      } else {
        return (
          <SelectQuestion
            replacedQuestionId={selectedQuestionId}
            onClose={onClose}
            formId={formId}
            toCreateQuestion={() => setIsCreateQuestion(true)}
            handleContactFormClick={handleContactFormClick}
          />
        )
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl' autoFocus={false}>
      <ModalOverlay className='select_question_modal' />
      <ModalContent h='80%' maxH='800px'>
        {renderBody()}
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(SelectQuestionModal)
