import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'

import { getProducts, getSubscription } from 'model/selectors/base'

export const getSubscriptionResponses = createSelector([getProducts, getSubscription], (products, subscription) => {
  const planId = _.get(subscription, 'planId')
  const productResponses = _.get(products, [planId, 'description'])
  return productResponses
})
