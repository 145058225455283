import { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Textarea,
  Tooltip,
  Input,
  useClipboard,
  HStack,
  VStack,
  Stack
} from '@chakra-ui/react'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLink } from '@fortawesome/pro-light-svg-icons'
// import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import _ from 'lodash'
import isEmail from 'validator/lib/isEmail'

import { shareFormWithEmails } from 'controllers/forms'
import { useSelector } from 'model/hooks'
import { dbUpdateUser } from 'controllers/user'

type Props = {
  formId: string
  onClose: () => void
}

const ShareModal: FC<Props> = ({ formId, onClose }) => {
  const [inviteOptionUsed, setInviteOptionUsed] = useState<boolean>(false)
  const [isBulkInvite, setIsBulkInvite] = useState<boolean>(false)
  const [emails, setEmails] = useState<string[]>(['', ''])
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const copyUrl = `${process.env.REACT_APP_VIEW_FORM_URL}/?form=${formId}`
  const user = useSelector(state => state.user)

  const { onCopy, hasCopied, setValue } = useClipboard(copyUrl)

  useEffect(() => {
    setValue(copyUrl)
  }, [formId])

  const onRemove = (index: number) => {
    const newArray = [...emails]
    _.pullAt(newArray, index)
    setEmails(newArray)
  }

  const onChange = (index: number, value: string) => {
    const newArray = [...emails]
    newArray[index] = value
    setEmails(newArray)
  }

  const onSubmit = () => {
    handleHeapAction()
    const res = _.uniq(
      _.reduce(
        isBulkInvite ? _.split(textAreaValue, ',') : emails,
        (res: string[], v: string) => {
          const value = _.trim(v)
          if (isEmail(value)) {
            res.push(value)
          }
          return res
        },
        []
      )
    )

    shareFormWithEmails(res, copyUrl)
    handleClose()
  }

  const handleHeapAction = async () => {
    const isLocalHost =
      location.hostname === 'localhost' || location.hostname === '127.0.0.1'
    if (!inviteOptionUsed && !isLocalHost) {
      setInviteOptionUsed(true)
      const userId = _.get(user, 'id')
      const heap = _.get(window, 'heap')
      const inviteOptionUsedTimes = _.get(user, 'heap.inviteOptionUsedTimes', 0)
      const newInviteOptionUsedTimesValue = inviteOptionUsedTimes + 1
      if (!_.isNil(userId)) {
        await dbUpdateUser(userId, {
          heap: {
            ..._.get(user, 'heap'),
            inviteOptionUsedTimes: newInviteOptionUsedTimesValue
          }
        })
      }
      if (!_.isNil(heap)) {
        heap.addUserProperties({
          inviteOptionUsedTimes: newInviteOptionUsedTimesValue
        })
      }
    }
  }

  const handleCopy = () => {
    handleHeapAction()
    onCopy()
  }

  const handleClose = () => {
    setInviteOptionUsed(false)
    setEmails(['', ''])
    setTextAreaValue('')
    setIsBulkInvite(false)
    onClose()
  }

  const renderEmailsInput = () => {
    if (isBulkInvite) {
      return (
        <Box>
          <Textarea
            size='sm'
            value={textAreaValue}
            placeholder='Enter multiple comma-separated email addresses.'
            onChange={e => setTextAreaValue(e.target.value)}
            bg='white'
            rounded='md'
            sx={{ '::placeholder': { color: 'blackAlpha.500' } }}
          />
        </Box>
      )
    } else {
      return (
        <Box>
          <VStack w='full'>
            {_.map(emails, (v: string, index: number) => (
              <HStack w='full' key={index}>
                <Input
                  value={v}
                  onChange={e => onChange(index, e.target.value)}
                  placeholder='name@example.com'
                  w='calc(100% - 50px)'
                />
                {index > 0 && (
                  <Button
                    variant='ghost'
                    color='gray.400'
                    onClick={() => onRemove(index)}
                    disabled={_.size(emails) === 1}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                )}
              </HStack>
            ))}
          </VStack>
          <Flex
            align='center'
            justify={{ base: 'flex-start', lg: 'flex-end' }}
            mt='4'
            pl={4}
            pr={2}
            py={2}
          >
            <Button
              variant='link'
              colorScheme='blue'
              onClick={() => setEmails([...emails, ''])}
              size='xs'
            >
              Add another email
            </Button>
          </Flex>
        </Box>
      )
    }
  }

  return (
    <Box>
      <Box pt={{ lg: 4, base: 0 }}>
        <HStack spacing={2} px={{ base: 4, lg: 0 }}>
          <Text fontSize='md' fontWeight={'bold'}>
            Share video form link
          </Text>
          <FontAwesomeIcon icon={faLink} size='xs' />
        </HStack>
        <Text fontSize='sm' color='gray.600' px={{ base: 4, lg: 0 }}>
          Share with applicants who you need answers from (view-only)
        </Text>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          px={{ base: 4, lg: 0 }}
          pt={4}
        >
          <Input
            readOnly
            backgroundColor='gray.100'
            defaultValue={copyUrl}
            size={{ base: 'lg', lg: 'md' }}
          />
          <Tooltip
            label={hasCopied ? 'Link copied!' : 'Copy link'}
            shouldWrapChildren
            hasArrow
          >
            <Button
              colorScheme='blue'
              onClick={handleCopy}
              className='share-invite-copy-link'
              size={{ base: 'lg', lg: 'md' }}
            >
              Copy link
            </Button>
          </Tooltip>
        </Stack>
      </Box>
      <Divider mt='10' mb='10' />
      <Box w='full' px={{ base: 4, lg: 0 }}>
        <Flex mb='1' align='center' justify='space-between'>
          <HStack spacing={2}>
            <Text fontWeight={'bold'} fontSize='md'>
              {isBulkInvite
                ? 'Bulk invite new applicants via email'
                : 'Invite new applications'}
            </Text>
            <FontAwesomeIcon icon={faEnvelope} size='xs' />
          </HStack>
          {/* <Flex align='center' gap='10px'>
                    <Text fontSize='xs'>
                      Import from
                    </Text>
                    <Button
                      size='sm'
                      variant='outline'
                      colorScheme='gray'
                      disabled
                    >
                      <FontAwesomeIcon icon={faGoogle} />
                    </Button>
                  </Flex> */}
        </Flex>
        <Flex justify='space-between' align='center' my='4' px={2}>
          <Text fontSize='xs'>Emails</Text>
          <Button
            variant='link'
            colorScheme='blue'
            onClick={() => {
              setEmails(['', ''])
              setTextAreaValue('')
              setIsBulkInvite(!isBulkInvite)
            }}
            size='xs'
          >
            {isBulkInvite ? 'Add one at a time' : 'Add many at once'}
          </Button>
        </Flex>
        {renderEmailsInput()}
      </Box>
      <Flex
        direction={'row'}
        justify='flex-end'
        pt={6}
        pb={6}
        px={{ base: 4, lg: 0 }}
      >
        <Button
          colorScheme='teal'
          size='lg'
          w={{ base: 'full', lg: 'auto' }}
          onClick={onSubmit}
          disabled={
            _.size(emails) === 1 &&
            _.size(emails[0]) === 0 &&
            _.size(textAreaValue) === 0
          }
          className={isBulkInvite ? 'share-invite-bulk' : 'share-invite'}
        >
          {isBulkInvite ? 'Bulk invite users' : 'Invite users'}
        </Button>
      </Flex>
    </Box>
  )
}

export default ShareModal
