import { Box } from '@chakra-ui/react'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, SyntheticEvent } from 'react'

type Props = {
  onClick: (e: SyntheticEvent) => void
}

const PlayButton: FC<Props> = ({ onClick }) => {
  return (
    <Box
      zIndex={100}
      position='absolute'
      top='50%'
      left='50%'
      // right='0'
      mx='auto'
      w='20'
      h='20'
      sx={{
        transform: 'translate(-50%,-50%) scale(1)',
        'transform-origin': 'center center',
        transition: 'all 0.3s ease 0.3s'
      }}
      // filter='drop-shadow(rgba(0, 0, 0, 0.6) 0px 0px 20px)'
      as='button'
      onClick={onClick}
      bg='whiteAlpha.700'
      rounded={'full'}
      pl={2}
      _hover={{ transform: 'translate(-50%,-50%) scale(1.2)' }}
    >
      <FontAwesomeIcon icon={faPlay} size='2x' />
    </Box>
  )
}

export default PlayButton
