import { FC, useState, useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  Divider,
  Spinner
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import validator from 'validator'

import { ReactComponent as Logo } from 'shared/assets/nav_bar_logo.svg'
import { ReactComponent as GoogleIcon } from 'assets/logo_googleg_48dp.svg'

import { dbSignUp, dbSignIn } from 'controllers/auth'
import {
  acceptInvitation,
  declineInvitation,
  fetchInvitation
} from 'controllers/invitations'
import { InvitationT } from 'shared/types/model'
import _ from 'lodash'
import { useSelector } from 'model/hooks'

const InvitationPage: FC<{}> = () => {
  const { invitationId } = useParams()
  const navigate = useNavigate()
  const [invitation, setInvitation] = useState<InvitationT | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [type, setType] = useState<string>('sign-up')
  const isLoggedIn = useSelector(state => !_.isNil(state.authData))

  useEffect(() => {
    const run = async (): Promise<void> => {
      setLoading(true)
      const res = await fetchInvitation(invitationId as string)
      setInvitation(res)
      setLoading(false)
    }
    if (!_.isNil(invitationId)) {
      run()
    }
  }, [invitationId])

  useEffect(() => {
    if (
      !loading &&
      (_.isNil(invitation) || _.get(invitation, 'status') === 'declined')
    ) {
      navigate('/')
    }
  }, [loading, invitation])

  useEffect(() => {
    if (isLoggedIn) setType('logged-in')
  }, [isLoggedIn])

  const canProceed = useMemo((): boolean => {
    switch (type) {
      case 'sign-up':
        return (
          name.length > 0 &&
          email.length > 0 &&
          password.length > 0 &&
          validator.isEmail(email) &&
          !loading
        )
      case 'sign-in':
        return (
          email.length > 0 &&
          password.length > 0 &&
          validator.isEmail(email) &&
          !loading
        )
      default:
        return false
    }
  }, [name, email, password, type])

  const onSignUp = async (): Promise<void> => {
    if (canProceed && invitationId) {
      setLoading(true)
      await dbSignUp(name, email, password)
      await acceptInvitation(invitationId)
    }
  }

  const onSignIn = async (): Promise<void> => {
    if (canProceed && invitationId) {
      setLoading(true)
      await dbSignIn(email, password)
      await acceptInvitation(invitationId)
      // setLoading(false)
    }
  }

  const onAccept = async (): Promise<void> => {
    if (invitationId) {
      setLoading(true)
      await acceptInvitation(invitationId)
    }
  }

  const onDecline = async (): Promise<void> => {
    setLoading(true)
    await declineInvitation(invitationId as string)
    navigate('/')
  }

  if (loading) {
    return (
      <Flex w='full' h='full' justify='center' align='center'>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex
      direction='column'
      justify='start'
      align='center'
      p={{ base: '10', lg: '20' }}
      background='gray.50'
      flexShrink={0}
      flexGrow={1}
      overflow='scroll'
      w='full'
    >
      <Flex
        display='flex'
        maxW='lg'
        flexDirection='column'
        alignItems='center'
        gap='12'
        flexShrink={0}
      >
        <Box w='3xs'>
          <Button variant='link' as='a' href='https://facefile.co/'>
            <Logo width='100%' height='100%' />
          </Button>
        </Box>
        <Flex align='center' direction='column' gap='6' flexShrink={0}>
          <Flex gap='2'>
            <Text color='black' fontSize='md' textAlign='center'>
              <b>{_.get(invitation, 'createdBy')}</b> has invited you to join
              Facefile{' '}
              {_.has(invitation, 'accountName') &&
                `at ${_.get(invitation, 'accountName')}`}
            </Text>
          </Flex>
          <Box>
            <Text color='gray.600' fontSize='sm' textAlign='center'>
              FaceFile helps you find the right candidates quickly using one-way
              video interviews via simply sharing a link.
            </Text>
          </Box>
        </Flex>
      </Flex>
      {type === 'logged-in' && (
        <Flex justifyContent='center' alignItems='center' gap='6' mt='5'>
          <Button
            colorScheme='red'
            variant='solid'
            size='md'
            onClick={onDecline}
          >
            Reject
          </Button>
          <Button
            colorScheme='blue'
            variant='solid'
            size='md'
            onClick={onAccept}
          >
            Accept
          </Button>
        </Flex>
      )}
      {type === 'sign-up' && (
        <Box
          bgColor='white'
          p='10'
          rounded='lg'
          boxShadow='sm'
          w={{ base: 'xs', lg: 'lg' }}
          mt='10'
        >
          <HStack justify='center' spacing='1' mb='4'>
            <Text fontSize='sm' color='muted'>
              To get started,
              <Button
                variant='link'
                colorScheme='blue'
                size='sm'
                onClick={() => setType('sign-in')}
                mx='1'
              >
                sign in
              </Button>
              or create an account.
            </Text>
          </HStack>
          <Stack spacing='6'>
            <Stack spacing='5'>
              <FormControl isRequired>
                <FormLabel htmlFor='name'>Name</FormLabel>
                <Input
                  id='name'
                  type='text'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      await onSignUp()
                    }
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor='email-invitation-signup'>Email</FormLabel>
                <Input
                  id='email-invitation-signup'
                  type='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      await onSignUp()
                    }
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <Input
                  id='password'
                  type='password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      await onSignUp()
                    }
                  }}
                />
              </FormControl>
            </Stack>
            <Stack spacing='4'>
              <Button
                isLoading={loading}
                variant='solid'
                colorScheme='teal'
                disabled={!canProceed}
                onClick={onSignUp}
              >
                Create account
              </Button>
              <Button
                disabled
                variant='secondary'
                leftIcon={<GoogleIcon />}
                iconSpacing='3'
              >
                Sign up with Google
              </Button>
            </Stack>
          </Stack>
          <HStack justify='center' spacing='1' mt='4'>
            <Text fontSize='sm' color='muted'>
              Already have an account?
            </Text>
            <Button
              variant='link'
              colorScheme='blue'
              size='sm'
              onClick={() => setType('sign-in')}
            >
              Log in
            </Button>
          </HStack>
        </Box>
      )}
      {type === 'sign-in' && (
        <Box
          bgColor='white'
          p='10'
          rounded='lg'
          boxShadow='sm'
          w={{ base: 'xs', lg: 'lg' }}
          mt='10'
        >
          <Stack spacing='6'>
            <Stack spacing='4' pt='4'>
              <FormControl isRequired>
                <FormLabel htmlFor='email-invitation-signin'>Email</FormLabel>
                <Input
                  id='email-invitation-signin'
                  type='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      await onSignIn()
                    }
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <Input
                  id='password'
                  type='password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyPress={async e => {
                    if (e.key === 'Enter') {
                      await onSignIn()
                    }
                  }}
                />
              </FormControl>
              <Button
                isLoading={loading}
                variant='primary'
                onClick={onSignIn}
                disabled={!canProceed}
              >
                Submit
              </Button>
            </Stack>
            <HStack>
              <Divider />
              <Text fontSize='sm' color='muted'>
                OR
              </Text>
              <Divider />
            </HStack>
            <Stack spacing='3'>
              <Button
                disabled
                variant='secondary'
                leftIcon={<GoogleIcon />}
                iconSpacing='3'
              >
                Continue with Google
              </Button>
            </Stack>
            <HStack spacing='1' justify='center'>
              <Text fontSize='sm' color='muted'>
                Don&apos;t have an account?
              </Text>
              <Button
                variant='link'
                colorScheme='blue'
                size='sm'
                onClick={() => setType('sign-up')}
              >
                Sign up
              </Button>
            </HStack>
          </Stack>
        </Box>
      )}
    </Flex>
  )
}

export default InvitationPage
