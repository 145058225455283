import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Select,
  OptionBase,
  GroupBase,
  SingleValue,
  OptionProps,
  chakraComponents
  // ChakraStylesConfig,
  // Size
} from 'chakra-react-select'
import { useSelector } from 'model/hooks'
// import { getTemplatesBySection } from 'model/selectors/templates'
import { TemplateSectionsT, TemplateSectionT } from 'shared/types/model'
import { HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'

export interface OptionT extends OptionBase {
  label: string
  value: string
  icon?: string
}

type Props = {
  value?: string
  onSelect: (v: string) => void
}

const TemplateSelect: FC<Props> = ({ value, onSelect }) => {
  // const templatesBySectionId = useSelector(getTemplatesBySection)
  const templatesSections: TemplateSectionsT = useSelector(
    state => state.templatesSections
  )
  const allTemplates = useSelector(state => state.templates)

  const options = useMemo(() => {
    const res: any = []
    _.forEach(templatesSections.order, sectionId => {
      const s: TemplateSectionT = templatesSections.sections[sectionId]
      const templatesOptions = _.map(s.templatesOrder, tId => {
        const t = _.get(allTemplates, tId)
        return { value: t?.id, label: t?.title, icon: s.icon } as OptionT
      })

      const sOption = {
        label: _.upperCase(s.label),
        options: templatesOptions
      }
      res.push(sOption)
    })
    return res
  }, [templatesSections, allTemplates])

  console.log('options', options)

  const valueOpt = useMemo(() => {
    if (value) {
      return { value, label: _.get(allTemplates, [value, 'title']) } as OptionT
    }
  }, [value])

  const onChange = (opt: SingleValue<OptionT>) => {
    if (opt) {
      onSelect(opt.value)
    } else {
      // onSelect(null)
    }
  }

  const customComponents = {
    Option: ({
      children,
      ...props
    }: OptionProps<OptionT, false, GroupBase<OptionT>>) => (
      <chakraComponents.Option {...props}>
        <HStack>
          <FontAwesomeIcon icon={fas[props.data.icon || 'faSquareList']} />
          <Text>{children}</Text>
        </HStack>
      </chakraComponents.Option>
    )
  }

  return (
    <Select<OptionT, false, GroupBase<OptionT>>
      useBasicStyles
      colorScheme='white'
      closeMenuOnSelect
      selectedOptionStyle='check'
      hideSelectedOptions={false}
      value={valueOpt}
      options={options}
      placeholder={'Select template'}
      onChange={onChange}
      focusBorderColor='gray.200'
      size={'sm'}
      chakraStyles={{
        menu: provided => ({
          ...provided,
          zIndex: 1000,
          width: 'full',
          overflow: 'visible'
        }),
        container: provided => ({
          ...provided,
          bgColor: 'white',
          width: 'full',
          borderColor: 'gray.200'
        }),
        control: provided => ({
          ...provided,
          boxShadow: 'none !important'
        })
      }}
      components={customComponents}
    />
  )
}

export default TemplateSelect
