import { ReactNode, FC, useEffect, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  useToast,
  VStack,
  Box,
  Text,
  HStack,
  Stack
} from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'

// import { auth } from 'controllers/db'
import { appInitialized } from 'controllers/init'
import { useSelector } from 'model/hooks'
import { resendVerificationEmail } from 'controllers/account'
import { getCurrentPlanUsage } from 'model/selectors/paymentPlans'
import { AuthUser } from 'shared/types/model'
import { handleVerifyEmail } from 'controllers/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { getAnswersUsed } from 'model/selectors/responses'
import { getAccount } from 'model/selectors/base'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [validCode, setCodeValid] = useState<boolean>(true)
  const [code, setCode] = useState<string | null>(null)
  const [authActionFired, setAuthActionFired] = useState<boolean>(false)
  const usageInfo = useSelector(getCurrentPlanUsage)
  const authData: AuthUser | null = useSelector(state => state.authData)
  const user = useSelector(state => state.user)
  const emailVerified = _.get(user, 'emailVerified', false)
  const responsesUsed = useSelector(getAnswersUsed)
  const isTestingAccount = useSelector(state => (_.get(getAccount(state), 'isTestingAccount', false)))

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const oobCode = queryParams.get('oobCode')

  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    appInitialized(navigate)
  }, [])

  useEffect(() => {
    const run = async () => {
      setAuthActionFired(true)
      const res = await handleVerifyEmail(code as string, _.get(user, 'id') as string)
      if (!res) setCodeValid(false)
    }

    if (!_.isNil(code) && !_.isNil(user) && !authActionFired) {
      run()
    }
  }, [code, user])

  useEffect(() => {
    if (!_.isNil(oobCode)) setCode(oobCode)
  }, [oobCode])

  useEffect(() => {
    if (responsesUsed > 0) {
      const isLocalHost = _.get(location, 'hostname') === 'localhost' || _.get(location, 'hostname') === '127.0.0.1'
      if (!isLocalHost) {
        const heap = _.get(window, 'heap')
        if (!_.isNil(heap)) {
          heap.addUserProperties({
            responsesUsed
          })
        }
      }
    }
  }, [responsesUsed])

  const onClick = async () => {
    setLoading(true)
    const res = await resendVerificationEmail()
    setLoading(false)
    if (res) {
      toast({
        title: 'Success',
        description: 'Email sent',
        status: 'success',
        duration: 9000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Error',
        description: 'Error while sending email',
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }

  const renderEmailConfirmationAlert = () => {
    if (!emailVerified && !_.isNil(authData)) {
      return (
        <Flex w='full' h='14'>
          <Alert status='warning' justifyContent='center'>
            <AlertIcon />
            <HStack spacing={{ base: 2, lg: 4 }} p={{ base: 2 }}>
              <Text fontSize={{ base: 'xs', lg: 'md' }} lineHeight={1.2}>
                Check your email and click the verification link we sent you.
              </Text>
              <Box>
                <Button
                  p={0}
                  isLoading={loading}
                  size={{ lg: 'sm', base: 'xs' }}
                  variant='outline'
                  colorScheme='blackAlpha'
                  onClick={onClick}
                >
                  Resend email
                </Button>
              </Box>
            </HStack>
          </Alert>
        </Flex>
      )
    }
  }

  const renderLimitReachedAlert = () => {
    if (!_.isNil(authData) && usageInfo.limitReached && !isTestingAccount) {
      return (
        <Flex w='full' h='14' bg='orange.400' align={'center'} justify='center'>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 0, lg: 4 }}
            p={{ base: 2 }}
            align='center'
            justify='center'
          >
            <Text
              fontSize={{ base: 'sm', lg: 'md' }}
              lineHeight={1.2}
              fontWeight='medium'
              color='white'
              textAlign={'center'}
            >
              Your team has run out of responses.
            </Text>
            <Box>
              <Button
                isLoading={loading}
                size={{ base: 'xs' }}
                variant='link'
                color='whiteAlpha.900'
                onClick={() => navigate('/settings/billing')}
                fontWeight='medium'
                textDecoration={'underline'}
              >
                Upgrade now to view all your responses!
              </Button>
            </Box>
          </Stack>
        </Flex>
      )
    }
  }

  const renderCodeInvalidAlert = () => {
    if (!validCode) {
      return (
        <Flex w='full' h='14'>
          <Alert status='info' justifyContent='center'>
            <AlertIcon color='black' />
            <HStack spacing={{ base: 2, lg: 4 }} p={{ base: 2 }}>
              <Text fontSize={{ base: 'xs', lg: 'md' }} lineHeight={1.2}>
                Expired or previously used verification link.
              </Text>
              <Box>
                <Button
                  p={0}
                  size={{ lg: 'sm', base: 'xs' }}
                  variant='unstyled'
                  colorScheme='blackAlpha'
                  onClick={() => setCodeValid(true)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Box>
            </HStack>
          </Alert>
        </Flex>
      )
    }
  }

  return (
    <VStack h='full' w='full' overflow='hidden' spacing={0}>
      {renderEmailConfirmationAlert()}
      {renderCodeInvalidAlert()}
      {renderLimitReachedAlert()}
      <Flex h='full' w='full' overflow='hidden'>
        {children}
      </Flex>
    </VStack>
  )
}

export default NavWrapper
