import { useState, useEffect, useMemo, useRef } from 'react'
import {
  VStack,
  HStack,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Button
} from '@chakra-ui/react'
import _ from 'lodash'
import NavBarMobile from 'components/NavBarMobile'
import BackButtonMobile from 'components/BackButtonMobile'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import QuestionsFilterTags from 'components/QuestionsFilterTags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownShortWide,
  faPlus
} from '@fortawesome/pro-regular-svg-icons'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'model/hooks'
import { getAllQuestions } from 'model/selectors/questions'
import { FormT, QuestionT } from 'shared/types/model'
import { arrayUnion } from 'firebase/firestore'
import {
  logFormAddQuestion,
  logFormQuestionRemoved,
  logCreateCustomQuestion
} from 'controllers/analytics'
import { dbUpdateForm, dbAddQuestion } from 'controllers/forms'
import ViewQuestionMobile, {
  IViewQuestionMobile
} from 'modals/ViewQuestionMobile'
import CreateQuestionModal, {
  ICreateQuestionModal
} from 'modals/CreateQuestionModal'
import { dbCreateQuestion } from 'controllers/questions'

type SortingOptionT = {
  value: string
  dir: 'asc' | 'desc'
  label: string
}

const AddQuestion = () => {
  const { formId } = useParams() as { formId: string }
  const navigate = useNavigate()
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const allQuestions = useSelector(getAllQuestions)
  const [sortByField, setSortByField] = useState('createdAt')
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const form: FormT | undefined = useSelector(state =>
    formId && state.forms ? state.forms[formId] : undefined
  )
  const templates = useSelector(state => state.templates)
  const location = useLocation()
  const replacedQuestionId: string | undefined = _.get(location, 'state.qId')
  const viewQuestionModalRef = useRef<IViewQuestionMobile>(null)
  const accountId = useSelector(state => state.account.id)
  const createQuestionModalRef = useRef<ICreateQuestionModal>(null)

  useEffect(() => {
    if (form && form.templateId && templates) {
      const t = templates[form.templateId]
      if (t) {
        const tags = t.tags || []
        setSelectedTags(tags)
      }
    }
  }, [form, templates])

  const questions = useMemo(() => {
    let res = _.filter(allQuestions, (q: QuestionT) => {
      const filtersPassed =
        _.isEmpty(selectedTags) ||
        (!_.isEmpty(q.tags) &&
          _.size(_.intersection(q.tags, selectedTags)) === _.size(selectedTags))
      return !q.deleted && filtersPassed
    })
    if (form && form.questions && !_.isEmpty(form.questions)) {
      res = _.filter(res, q => !_.includes(form.questions, q.id))
    }
    return _.orderBy(res, [sortByField], [order as 'asc' | 'desc'])
  }, [form, allQuestions, sortByField, order, selectedTags])

  const goBack = () => {
    navigate(-1)
  }

  const sortByOptions: SortingOptionT[] = [
    { value: 'createdAt', dir: 'desc', label: 'Date created (desc)' },
    { value: 'createdAt', dir: 'asc', label: 'Date created (asc)' },
    { value: 'lastUsedAt', dir: 'desc', label: 'Last used (desc)' },
    { value: 'lastUsedAt', dir: 'asc', label: 'Last used (asc)' },
    { value: 'length', dir: 'desc', label: 'Length (desc)' },
    { value: 'length', dir: 'asc', label: 'Length (asc)' },
    { value: 'createdBy', dir: 'desc', label: 'Created by (desc)' },
    { value: 'createdBy', dir: 'asc', label: 'Created by (asc)' }
  ]

  const renderSortingButton = () => {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          variant='ghost'
          size='md'
          color={'gray.500'}
        >
          <FontAwesomeIcon icon={faArrowDownShortWide} />
        </MenuButton>
        <MenuList>
          {_.map(sortByOptions, opt => {
            const isSelected = opt.value === sortByField && opt.dir === order
            return (
              <MenuItem
                key={opt.label}
                onClick={() => {
                  setOrder(opt.dir)
                  setSortByField(opt.value)
                }}
                bg={isSelected ? 'blue.500' : 'white'}
                _focus={{ bg: isSelected ? 'blue.500' : 'transparent' }}
              >
                <Text
                  color={isSelected ? 'white' : 'gray.700'}
                  // fontWeight='semibold'
                  fontSize={'sm'}
                >
                  {opt.label}
                </Text>
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    )
  }

  const renderTopBar = () => {
    return (
      <HStack justify={'space-between'} w='full' p={4}>
        <Flex>
          <BackButtonMobile onClick={goBack} />
        </Flex>
        <Flex>
          <Heading size='sm'>
            {_.isNil(replacedQuestionId)
              ? 'Add a question'
              : 'Replace question'}
          </Heading>
        </Flex>
        <HStack spacing={1}>
          {renderSortingButton()}
          <QuestionsFilterTags
            selectedTags={selectedTags}
            setSelectedTags={tagsIds => setSelectedTags(tagsIds)}
            isIconButton
          />
        </HStack>
      </HStack>
    )
  }

  const onAdd = async (qId: string) => {
    if (replacedQuestionId && form) {
      const newQuestions = form?.questions ?? []
      const index = newQuestions.indexOf(replacedQuestionId)
      newQuestions.splice(index, 1, qId)

      const upd: Partial<FormT> = {
        questions: newQuestions,
        syncRequests: arrayUnion(_.now())
      }

      if (
        form &&
        form.contactFormQuestionId &&
        form.contactFormQuestionId === replacedQuestionId
      ) {
        upd.contactFormQuestionId = qId
      }
      logFormQuestionRemoved(replacedQuestionId, formId, form?.templateId)
      logFormAddQuestion(qId, formId, form?.templateId)
      dbUpdateForm(form.id, upd)
      goBack()
    } else {
      if (_.isEmpty(form)) {
        throw new Error('cannot add question to a form that is not created')
      }
      dbAddQuestion(qId, formId)
      logFormAddQuestion(qId, formId, form?.templateId)
      goBack()
    }
  }

  const openVideo = (q: QuestionT) => {
    if (!_.isNil(q.mediaUrl)) {
      viewQuestionModalRef.current?.open(q.id)
    }
  }

  const renderQuestion = (q: QuestionT) => {
    return (
      <HStack
        key={q.id}
        bg='white'
        rounded='md'
        w='full'
        px={4}
        justify='space-between'
      >
        <VStack w='full' py={4} align='flex-start' onClick={() => openVideo(q)}>
          <Text fontSize={'sm'} fontWeight='medium'>
            {q.text}
          </Text>
          {_.isNil(q.mediaUrl) && (
            <Text
              fontSize='xs'
              fontWeight='light'
              fontStyle='italic'
              textAlign='left'
              color='gray.500'
            >
              Your video is being rendered, you will receive a notification when
              it is ready for use.
            </Text>
          )}
        </VStack>
        <HStack color='gray.800'>
          <IconButton
            aria-label='add question'
            icon={<FontAwesomeIcon icon={faPlus} />}
            size='sm'
            variant='ghost'
            onClick={() => onAdd(q.id)}
          />
          <IconButton
            aria-label='play question'
            icon={<FontAwesomeIcon icon={faPlay} />}
            size='sm'
            variant='ghost'
            onClick={() => openVideo(q)}
            isDisabled={_.isNil(q.mediaUrl)}
          />
        </HStack>
      </HStack>
    )
  }

  const renderCreateQuestionButton = () => {
    return (
      <Button
        leftIcon={<FontAwesomeIcon icon={faPlus} />}
        variant='outline'
        w='full'
        size='md'
        flexShrink={0}
        bg='white'
        borderStyle={'dashed'}
        py={6}
        color='gray.500'
        onClick={() => createQuestionModalRef.current?.open()}
      >
        Create question
      </Button>
    )
  }

  const onCreateQuestion = async (text: string) => {
    console.log('onCreateQuestion', text)
    const qId = await dbCreateQuestion(text, accountId)
    if (!_.isNil(qId)) {
      logCreateCustomQuestion(qId)
    }
  }

  return (
    <VStack
      align={'flex-start'}
      spacing={0}
      w='full'
      h='full'
      bg='white'
      overflow='hidden'
    >
      <NavBarMobile />
      <VStack w='full' h='full' bg='gray.50' overflow='hidden' pt={4}>
        {renderTopBar()}
        <VStack w='full' h='full' overflowY={'auto'} p={4}>
          {_.map(questions, renderQuestion)}
          {renderCreateQuestionButton()}
        </VStack>
      </VStack>
      <ViewQuestionMobile ref={viewQuestionModalRef} onAdd={onAdd} />
      <CreateQuestionModal
        onCreateQuestion={onCreateQuestion}
        ref={createQuestionModalRef}
      />
    </VStack>
  )
}

export default AddQuestion
