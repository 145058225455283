import { FC, useMemo } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import PageLayout from 'navigation/PageLayout'
import AuthPageLayout from 'navigation/AuthPageLayout'
import NavWrapper from 'navigation/NavWrapper'
import Contacts from 'pages/Contacts'
import Contact from 'pages/Contact'
import Responses from 'pages/Responses'
import LoadingPage from 'pages/LoadingPage'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import PreviewPage from 'pages/PreviewPage'
import InvitationPage from 'pages/InvitationPage'
import Form from 'pages/Form'

import ForgotPassword from 'pages/ForgotPassword'
import EmailVerified from 'pages/EmailVerified'
import SelectTemplateMobile from 'pages/form/SelectTemplateMobile'
import ShareForm from 'pages/mobile/ShareForm'
import AddQuestion from 'pages/mobile/AddQuestion'
import OnboardingForm from 'pages/mobile/OnboardingForm'
import OnboardingVideoForm from 'pages/mobile/OnboardingVideoForm'
import OnboardingIndustry from 'pages/mobile/OnboardingIndustry'
import ResponsePage from 'pages/ResponsePage'
import FormResponse from 'pages/FormResponse'

const AppRouter: FC<{}> = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  console.log('appLoaded', appLoaded)

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData) || !_.isNil(appLoaded)) {
      return <Route path='*' element={<LoadingPage />} />
    } else {
      return (
        <>
          {/* <Route path='/settings' element={<SettingsLayout />}>
            <Route path='/settings' element={<SettingsAccount />} />
            <Route path='account' element={<SettingsAccount />} />
            <Route path='profile' element={<SettingsProfile />} />
            <Route path='team' element={<Team />} />
            <Route path='billing' element={<Billing />} />
            <Route path='branding' element={<Branding />} />
            <Route path='questions_library' element={<QuestionsLibrary />} />
            <Route path='templates' element={<SettingsTemplates />} />
            <Route path='notifications' element={<SettingsNotifications />} />
            <Route path='integrations' element={<SettingsIntegrations />} />
            <Route path='onboarding' element={<SettingsOnboarding />} />
            <Route
              path='analysis_prompt'
              element={<SettingsAnalysisPrompt />}
            />
            <Route path='cfq' element={<SettingsCFQ />} />
            <Route
              path='response_prompt'
              element={<SettingsResponseAnalysis />}
            />
          </Route> */}

          <Route path='/p/:previewId' element={<PreviewPage />} />
          <Route path='/i/:invitationId' element={<InvitationPage />} />

          <Route path='/' element={<PageLayout />}>
            <Route path='/form'>
              <Route
                path=':formId/edit/select_template'
                element={<SelectTemplateMobile />}
              />
              <Route path=':formId/edit' element={<Form type='edit' />} />
              <Route
                path=':formId/customize'
                element={<Form type='customize' />}
              />
              <Route path=':formId/:responseId' element={<FormResponse />} />
              <Route path=':formId/share' element={<ShareForm />} />
              <Route path=':formId/add_question' element={<AddQuestion />} />
              <Route path=':formId' element={<Form type='responses' />} />
            </Route>
            <Route path='/contacts/:email' element={<Contact />} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/responses/:responseId' element={<Responses />} />
            <Route path='/responses' element={<Responses />} />
            <Route path='/' element={<Navigate replace to='/responses' />} />
            <Route path='*' element={<Navigate replace to='/responses' />} />
          </Route>
        </>
      )
    }
  }, [authData, appLoaded])

  const publicRoutes = useMemo(() => {
    if (!_.isNil(authData) && !_.isNil(appLoaded)) {
      return <Route path='*' element={<LoadingPage />} />
    } else {
      return (
        <>
          <Route path='/auth' element={<AuthPageLayout />}>
            <Route path='signin' element={<SignIn />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='reset_password' element={<ForgotPassword />} />
          </Route>
          <Route path='/p' element={<AuthPageLayout />}>
            <Route path=':previewId' element={<PreviewPage />} />
          </Route>
          <Route path='/r/:responseId' element={<ResponsePage />} />
          <Route path='/i/:invitationId' element={<InvitationPage />} />
          <Route path='/email_verified' element={<EmailVerified />} />
          <Route path='/onboarding'>
            <Route path='contacts' element={<OnboardingForm />} />
            <Route path='industry' element={<OnboardingIndustry />} />
            <Route path='video' element={<OnboardingVideoForm />} />
            <Route path='' element={<OnboardingForm />} />
            <Route
              path='*'
              element={<Navigate replace to='/onboarding/contacts' />}
            />
          </Route>
        </>
      )
    }
  }, [authData, appLoaded])

  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
