import * as types from 'model/actionTypes'
import { TemplateSectionsT } from 'shared/types/model'

const initialState: TemplateSectionsT = { sections: {}, order: [] }

export default function (
  state = initialState,
  action: { type: string; sections: TemplateSectionsT }
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_TEMPLATES_SECTIONS:
      return action.sections
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
