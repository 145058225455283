import { createSelector } from '@reduxjs/toolkit'
import {
  getProducts,
  getPrices,
  getSubscription,
  getResponses,
  getAccount
} from 'model/selectors/base'
import { PriceT } from 'shared/types/model'
import _ from 'lodash'
import { FREE_PLAN_LIMIT } from 'shared/constants/paymentPlans'
import { getAnswersUsed } from 'model/selectors/responses'
import numeral from 'numeral'

export const getPricesByInterval = createSelector(
  [getProducts, getPrices],
  (products, prices) => {
    const neededPrices = _.filter(
      prices,
      p =>
        _.get(products, [p.product, 'metadata', 'isPublic']) === 'true' &&
        p.active
    )
    const groupedByInterval = _.groupBy(neededPrices, p => p.recurring.interval)
    const sortPrices = (ar: PriceT[]) => {
      return _.sortBy(ar, pr => {
        const amount = _.get(products, [pr.product, 'metadata', 'amount'])
        return _.toNumber(amount)
      })
    }
    return {
      year: sortPrices(groupedByInterval.year),
      month: sortPrices(groupedByInterval.month)
    }
  }
)

export const getCurrentPlanUsage = createSelector(
  [getSubscription, getPrices, getProducts, getAnswersUsed, getAccount],
  (subscription, prices, products, answersUsed, account) => {
    const isTestingAccount = _.get(account, 'isTestingAccount', false)
    let res = {
      name: 'Free',
      responsesIncluded: FREE_PLAN_LIMIT,
      used: answersUsed,
      limitReached: isTestingAccount ? false : answersUsed > FREE_PLAN_LIMIT,
      subscriptionPeriodStart: 0
    }
    if (!_.isNil(subscription) && !_.isNil(prices) && !_.isNil(products)) {
      const price = prices[subscription.priceId]
      const product = products[subscription.productId]
      if (price && product) {
        res = {
          name: `${numeral(price.unit_amount / 100).format('$0')}/${
            price.recurring.interval === 'month' ? 'mo' : 'yr'
          }`,
          responsesIncluded: product.metadata.isCustom || isTestingAccount
            ? answersUsed + 1
            : _.toNumber(product.metadata.amount),
          used: answersUsed,
          limitReached: product.metadata.isCustom || isTestingAccount
            ? false
            : answersUsed >= _.toNumber(product.metadata.amount),
          subscriptionPeriodStart: subscription.currentPeriodStart
        }
      }
    }

    return res
  }
)

export const getBlockedResponses = createSelector(
  [getCurrentPlanUsage, getResponses],
  (planUsage, responses) => {
    if (planUsage.limitReached) {
      const sortedResponses = _.orderBy(responses, 'createdAt', 'asc')
      const res: {
        counter: number
        idsOverLimit: Record<string, boolean>
      } = _.reduce(
        sortedResponses,
        ({ counter, idsOverLimit }, r) => {
          if (r.createdAt >= planUsage.subscriptionPeriodStart) {
            const newAmount = counter + _.size(r.answersIds)
            return {
              counter: newAmount,
              idsOverLimit:
                newAmount > planUsage.responsesIncluded
                  ? { [r.id]: true, ...idsOverLimit }
                  : idsOverLimit
            }
          } else {
            return { counter, idsOverLimit }
          }
        },
        { counter: 0, idsOverLimit: {} }
      )
      console.log('----> getBlockedResponses', res.idsOverLimit)
      return res.idsOverLimit
    } else {
      return {}
    }
  }
)
