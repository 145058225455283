import { FC, useMemo } from 'react'
import {
  Popover,
  PopoverTrigger,
  Button,
  Box,
  PopoverContent,
  PopoverBody,
  Tag,
  Text,
  HStack,
  Flex,
  IconButton,
  Badge
} from '@chakra-ui/react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCaretDown,
  faChevronDown,
  faTimesCircle,
  faFilterList as faFilterListSolid
} from '@fortawesome/pro-solid-svg-icons'
import {
  Select,
  GroupBase,
  MultiValue,
  OptionProps,
  chakraComponents,
  ClearIndicatorProps
} from 'chakra-react-select'
import { useSelector } from 'model/hooks'
import { faFilterList } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  selectedTags: string[]
  setSelectedTags: (tagsIds: string[]) => void
  isIconButton?: boolean
}

type TagOptionT = {
  value: string
  label: string
}

const QuestionsFilterTags: FC<Props> = ({
  selectedTags,
  setSelectedTags,
  isIconButton = false
}) => {
  const templatesTags = useSelector(state => state.templatesTags)

  const options = useMemo(() => {
    return _.map(templatesTags, (label, value) => {
      return {
        value,
        label,
        colorScheme: 'blackAlpha'
      }
    })
  }, [templatesTags])

  const value = useMemo(() => {
    const optionsDict = _.keyBy(options, 'value')
    return _.map(selectedTags, tagId => optionsDict[tagId])
  }, [options, selectedTags])

  const onChange = (opts: MultiValue<TagOptionT>) => {
    console.log('onChange: opts', opts)
    const tagsIds = _.map(opts, opt => opt.value)
    setSelectedTags(tagsIds)
  }

  const renderPopoverButtonContent = () => {
    if (_.isEmpty(selectedTags)) {
      return <Text fontWeight='normal'>Filters</Text>
    } else {
      const str = _.join(
        _.map(selectedTags, tagId => templatesTags[tagId]),
        ', '
      )
      return (
        <HStack w='full'>
          <Text fontWeight='semibold'>Filters:</Text>
          <Text fontWeight='normal'>{_.truncate(str, { length: 22 })}</Text>
        </HStack>
      )
    }
  }

  const renderPopoverTrigger = () => {
    if (isIconButton) {
      return (
        <Flex position='relative'>
          <IconButton
            variant={!_.isEmpty(selectedTags) ? 'outline' : 'ghost'}
            size='md'
            aria-label='tags filter'
            // bg={currentFilter === 'contacts' ? 'gray.100' : 'white'}
            icon={
              <FontAwesomeIcon
                icon={
                  !_.isEmpty(selectedTags) ? faFilterListSolid : faFilterList
                }
              />
            }
            color={!_.isEmpty(selectedTags) ? 'blue.400' : 'gray.500'}
            borderColor={!_.isEmpty(selectedTags) ? 'blue.400' : ''}
          />
          {!_.isEmpty(selectedTags) && (
            <Badge
              position='absolute'
              backgroundColor='blue.600'
              color='white'
              rounded={'full'}
              right='-8px'
              top='-8px'
              minW='18px'
              textAlign={'center'}
              zIndex={2}
            >
              {_.size(selectedTags)}
            </Badge>
          )}
        </Flex>
      )
    } else {
      return (
        <Button
          size='sm'
          variant={'outline'}
          borderColor={_.isEmpty(selectedTags) ? 'gray.200' : 'blue.100'}
          bg={_.isEmpty(selectedTags) ? 'white' : 'blue.50'}
          color={_.isEmpty(selectedTags) ? 'blackAlpha.600' : 'blue.400'}
          fontWeight={_.isEmpty(selectedTags) ? 'normal' : 'semibold'}
          leftIcon={
            <Box color={_.isEmpty(selectedTags) ? 'gray.400' : 'blue.400'}>
              <FontAwesomeIcon icon={faCircleCaretDown} fontSize={'1em'} />
            </Box>
          }
          rightIcon={
            <Box color={_.isEmpty(selectedTags) ? 'gray.400' : 'blue.400'}>
              <FontAwesomeIcon icon={faChevronDown} fontSize={'0.8em'} />
            </Box>
          }
        >
          {renderPopoverButtonContent()}
        </Button>
      )
    }
  }

  const customComponents = {
    Option: ({
      children,
      ...props
    }: OptionProps<TagOptionT, true, GroupBase<TagOptionT>>) => (
      <chakraComponents.Option {...props}>
        <Tag size='sm' colorScheme={'blackAlpha'}>
          {children}
        </Tag>
      </chakraComponents.Option>
    ),
    ClearIndicator: ({
      ...props
    }: ClearIndicatorProps<TagOptionT, true, GroupBase<TagOptionT>>) => (
      <chakraComponents.ClearIndicator {...props}>
        <Box color='gray.400'>
          <FontAwesomeIcon icon={faTimesCircle} fontSize='1.6em' />
        </Box>
      </chakraComponents.ClearIndicator>
    )
  }

  const renderTagsSelect = () => {
    return (
      <Select<TagOptionT, true, GroupBase<TagOptionT>>
        maxMenuHeight={800}
        isMulti
        closeMenuOnSelect={false}
        name='emails'
        options={options}
        menuIsOpen
        placeholder='Select filter tags'
        value={value}
        onChange={onChange}
        isClearable
        isSearchable={false}
        size='sm'
        components={customComponents}
        chakraStyles={{
          container: provided => ({
            ...provided,
            zIndex: 101
          }),
          menu: provided => ({
            ...provided,
            position: 'relative'
          }),
          menuList: provided => ({
            ...provided,
            p: 0,
            border: 'none'
          }),
          control: provided => ({
            ...provided,
            bgColor: 'white',
            width: 'full',
            borderColor: 'blue.200',
            borderWidth: '1.5px',
            borderRadius: 'md',
            _hover: {
              borderColor: 'blue.200'
            },
            _focus: {
              borderColor: 'blue.200'
            }
          }),
          input: provided => ({
            ...provided
          }),
          indicatorsContainer: provided => ({
            ...provided
            // display: 'none'
          }),
          dropdownIndicator: provided => ({
            ...provided,
            display: 'none'
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: 'none'
          })
        }}
      />
    )
  }

  return (
    <Popover placement='bottom-start'>
      <PopoverTrigger>{renderPopoverTrigger()}</PopoverTrigger>
      <PopoverContent>
        <PopoverBody p={1}>{renderTagsSelect()}</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default QuestionsFilterTags
