import { VStack, Text, Input } from '@chakra-ui/react'

const ContactForm = () => {
  return (
    <VStack spacing={6}>
      <Text
        pt={12}
        fontSize='lg'
        fontWeight='semibold'
        textAlign='left'
        color='gray.800'
      >
        Before we continue, please leave your contact details so we can get back
        to you...
      </Text>
      <Input
        type='text'
        aria-label='Your name'
        autoComplete='name'
        name='name'
        placeholder='*Your name'
        required
        px={0}
        size='lg'
        borderWidth={0}
        borderBottomWidth={1}
        rounded='none'
        sx={{ '::placeholder': { color: 'gray.400' } }}
        _focusVisible={{
          borderWidth: 0,
          borderBottomWidth: 2,
          borderColor: 'gray.800'
        }}
      />
      <Input
        type='email'
        aria-label='Your email'
        autoComplete='email'
        name='email'
        placeholder='*Your email'
        required
        px={0}
        size='lg'
        borderWidth={0}
        borderBottomWidth={1}
        rounded='none'
        sx={{ '::placeholder': { color: 'gray.400' } }}
        _focusVisible={{
          borderWidth: 0,
          borderBottomWidth: 2,
          borderColor: 'gray.800'
        }}
      />
    </VStack>
  )
}

export default ContactForm
