import { FC, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  Text,
  HStack,
  VStack,
  Button,
  Flex,
  Divider,
  Center,
  Spinner,
  FormControl,
  FormLabel,
  Switch,
  Box
} from '@chakra-ui/react'
import { QuestionT, VideoT, DictT } from 'shared/types/model'
import { dbUnarchiveQuestion, fetchElaiQuestions } from 'controllers/questions'
// import Question from 'components/Question'
import { useSelector } from 'model/hooks'
import { getLibraryQuestions } from 'model/selectors/questions'
import LibraryQuestion from 'pages/settingsPages/questionsLibrary/LibraryQuestion'
import TemplateQuestionPreview from 'components/templates/TemplateQuestionPreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/pro-regular-svg-icons'
import ArchiveQuestionAlert, {
  IArchiveQuestionAlert
} from 'modals/ArchiveQuestionAlert'

const QuestionsLibrary: FC = () => {
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [videos, setVideos] = useState<DictT<VideoT>>({})
  const questionsDict: DictT<QuestionT> = useSelector(
    state => state.questionsLibrary
  )
  const questions: QuestionT[] = useSelector(getLibraryQuestions)
  const [currentQuestionId, setCurrentQuestionId] = useState<string>()
  const archiveQuestionAlertRef = useRef<IArchiveQuestionAlert>(null)

  const currentQuestion = useMemo(
    () => (currentQuestionId ? questionsDict[currentQuestionId] : undefined),
    [questionsDict, currentQuestionId]
  )

  const usedElaiVideosDict = useMemo(() => {
    return _.keyBy(questions, 'elaiVideoId')
  }, [questions])

  const unusedElaiVideos = useMemo(() => {
    const filteredVideos = _.filter(
      videos,
      (v: VideoT) => !_.has(usedElaiVideosDict, v._id)
    )
    const sortedVideos = _.sortBy(filteredVideos, (r: VideoT) =>
      moment(r.createdAt).valueOf()
    )
    _.reverse(sortedVideos)
    return sortedVideos
  }, [videos, usedElaiVideosDict])

  // console.log('unusedElaiVideos', unusedElaiVideos)

  const syncElaiQuestions = async () => {
    setLoadingQuestions(true)
    const elaiQuestions = await fetchElaiQuestions()
    console.log('elaiQuestions', elaiQuestions)
    if (elaiQuestions) {
      setVideos(_.keyBy(elaiQuestions, '_id'))
    }
    setLoadingQuestions(false)
  }

  useEffect(() => {
    syncElaiQuestions()
  }, [])

  const renderNavBar = () => {
    return (
      <HStack
        justify={'space-between'}
        bg='white'
        borderBottomWidth={1}
        borderColor='gray.100'
        px={8}
        w='full'
        overflow='hidden'
        py={4}
        flexShrink={0}
      >
        <VStack align='flex-start' spacing={0}>
          <Text fontSize='lg' fontWeight={'bold'} color='gray.900'>
            Questions library
          </Text>
          <Text size='sm' color='gray.600'>
            Available for FaceFile admins only
          </Text>
        </VStack>
        <HStack spacing={8}>
          <FormControl display='flex' alignItems='center'>
            <FormLabel htmlFor='show-archived' mb='0'>
              Show archived questions?
            </FormLabel>
            <Switch
              id='show-archived'
              isChecked={showArchived}
              onChange={e => setShowArchived(e.target.checked)}
            />
          </FormControl>
          <Box>
            <Button
              variant={'outline'}
              colorScheme='blue'
              isLoading={loadingQuestions}
              leftIcon={<FontAwesomeIcon icon={faRotate} />}
              onClick={syncElaiQuestions}
            >
              Fetch Elai videos
            </Button>
          </Box>
        </HStack>
      </HStack>
    )
  }

  const playQuestion = (qId: string) => {
    setCurrentQuestionId(qId)
  }

  const onArchive = (qId: string) => {
    archiveQuestionAlertRef.current?.open(qId)
  }

  const onUnarchive = (qId: string) => {
    dbUnarchiveQuestion(qId)
  }

  const renderQuestions = () => {
    return (
      <VStack
        h='full'
        w='full'
        overflowY={'auto'}
        my={4}
        divider={<Divider />}
        p={4}
        spacing={0}
        scrollSnapType={'y proximity'}
      >
        {_.map(questions, (q: QuestionT) => {
          if (!showArchived && q.deleted && q.deleted > 0) return null
          return (
            <LibraryQuestion
              key={q.id}
              q={q}
              elaiQ={q.elaiVideoId ? _.get(videos, q.elaiVideoId) : undefined}
              onPlay={playQuestion}
              isSelected={q.id === currentQuestionId}
              onArchive={onArchive}
              onUnarchive={onUnarchive}
            />
          )
        })}
        {_.map(unusedElaiVideos, (v: VideoT) => {
          return (
            <LibraryQuestion
              key={v._id}
              elaiQ={v}
              onPlay={playQuestion}
              isSelected={v._id === currentQuestionId}
            />
          )
        })}
      </VStack>
    )
  }

  if (_.isEmpty(videos)) {
    return (
      <Center w='full' h='full'>
        <VStack>
          <Spinner
            thickness='1px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='sm'
          />
          <Text fontSize='xs' color='gray.400'>
            Loading Elai videos...
          </Text>
        </VStack>
      </Center>
    )
  } else {
    return (
      <VStack
        w='full'
        h='full'
        overflow={'hidden'}
        borderLeftWidth={1}
        borderLeftColor='gray.100'
        spacing={0}
      >
        {renderNavBar()}
        <HStack w='full' h='full' overflow={'hidden'}>
          {renderQuestions()}
          <Flex w='30%' h='full' flexShrink={0} overflow='hidden' bg='white'>
            <TemplateQuestionPreview
              text={
                currentQuestion
                  ? currentQuestion.text
                  : currentQuestionId &&
                    _.get(videos, [currentQuestionId, 'name'])
              }
              mediaUrl={
                currentQuestion
                  ? currentQuestion.mediaUrl
                  : currentQuestionId &&
                    _.get(videos, [currentQuestionId, 'url'])
              }
            />
          </Flex>
        </HStack>
        <ArchiveQuestionAlert ref={archiveQuestionAlertRef} />
      </VStack>
    )
  }
}

export default QuestionsLibrary
