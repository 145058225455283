import { FC } from 'react'
import { HStack, Text, Center } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCompress,
  faExpandWide,
  faClosedCaptioning
} from '@fortawesome/pro-light-svg-icons'

type Props = {
  playbackRate: number
  setPlaybackRate: (v: number) => void
  toFullScreen: () => void
  isFullScreen: boolean
  toggleSubtitles: () => void
  subtitleEnabled: boolean
}

const MediaControls: FC<Props> = ({
  playbackRate,
  setPlaybackRate,
  toFullScreen,
  isFullScreen,
  subtitleEnabled,
  toggleSubtitles
}) => {
  return (
    <HStack align='center' spacing={2}>
      <Center
        boxSize={10}
        borderWidth={1}
        borderColor={'white'}
        rounded='md'
        bg={!subtitleEnabled ? undefined : 'white'}
        as='button'
        cursor={'pointer'}
        onClick={toggleSubtitles}
        boxShadow='base'
        sx={{
          svg: {
            filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))'
          }
        }}
      >
        <FontAwesomeIcon
          icon={faClosedCaptioning}
          color={!subtitleEnabled ? 'white' : 'gray.700'}
        />
      </Center>
      <Center
        boxSize={10}
        borderWidth={1}
        borderColor={'white'}
        rounded='md'
        color={playbackRate === 1 ? 'white' : 'gray.700'}
        bg={playbackRate === 1 ? undefined : 'white'}
        as='button'
        cursor={'pointer'}
        boxShadow='base'
        onClick={() =>
          setPlaybackRate(playbackRate < 2.5 ? playbackRate + 0.5 : 1)
        }
        sx={{
          svg: {
            filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))'
          }
        }}
      >
        <Text fontSize={'xs'} textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'>
          {playbackRate}x
        </Text>
      </Center>
      <Center
        boxSize={10}
        borderWidth={1}
        borderColor={'white'}
        rounded='md'
        color='white'
        as='button'
        cursor={'pointer'}
        onClick={toFullScreen}
        boxShadow='base'
        sx={{
          svg: {
            filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))'
          }
        }}
      >
        <FontAwesomeIcon
          icon={isFullScreen ? faCompress : faExpandWide}
          color='white'
        />
      </Center>
    </HStack>
  )
}

export default MediaControls
