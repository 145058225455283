import * as types from 'model/actionTypes'
import { DictT, InvitationT } from 'shared/types/model'

const initialState: DictT<InvitationT> | null = null

export default function (
  state = initialState,
  action: { type: string; invitations: DictT<InvitationT> }
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_INVITATIONS:
      return action.invitations
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
