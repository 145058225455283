import { FC, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  VStack,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Text,
  Flex,
  Show
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'model/hooks'
import { getSubscriptionResponses } from 'model/selectors/subscripiton'

type Props = {
  onClose: () => void
  onCreateQuestion: (text: string) => void
}

const CreateQuestionForm: FC<Props> = ({ onClose, onCreateQuestion }) => {
  const [value, setValue] = useState('')
  const subscriptionResponses = useSelector(getSubscriptionResponses)

  const isButtonDisabled = useMemo(() => {
    return _.size(value) === 0
  }, [value])
  return (
    <VStack w='100%' h='full'>
      <Flex
        maxW='2xl'
        pt='12'
        flexDirection='column'
        w='full'
        align='flex-start'
      >
        <Show above='lg'>
          <Button
            size='lg'
            lineHeight='7'
            fontWeight='semibold'
            variant={'ghost'}
            color='blue.500'
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={onClose}
            p={4}
            className='create-question-back-button'
          >
            Back
          </Button>
        </Show>
        <FormControl p={{ base: 0, lg: 4 }}>
          <FormLabel>
            <Text
              fontSize={{ base: 'xs', lg: 'md' }}
              fontWeight={{ base: 'semibold', lg: 'medium' }}
              color='gray.700'
            >
              Input your question and FaceFile will generate a video with this
              custom text
            </Text>
          </FormLabel>
          <Textarea
            placeholder='Input your question and FaceFile will generate a video with this custom text'
            fontSize={{ base: 'xs', lg: 'md' }}
            lineHeight='6'
            fontWeight='normal'
            h='150px'
            value={value}
            onChange={e => setValue(e.target.value)}
            maxLength={200}
          />
          <FormHelperText
            fontSize={{ base: 'xs', lg: 'md' }}
            color={{ base: 'blackAlpha.600', lg: undefined }}
          >
            {`${_.size(value)}/250 characters`}
          </FormHelperText>
        </FormControl>
      </Flex>
      <Flex
        px={{ base: 0, lg: '140px' }}
        w='100%'
        display='flex'
        justifyContent='end'
        pt={{ base: 6, lg: 4 }}
      >
        <Button
          bg='teal.400'
          w={{ base: 'full', lg: '160px' }}
          onClick={() => onCreateQuestion(value)}
          colorScheme='teal'
          size='lg'
          isDisabled={isButtonDisabled}
          className={
            _.isNil(subscriptionResponses)
              ? 'free-plan-create-question-button'
              : `pro-${subscriptionResponses}-create-question-button`
          }
        >
          Create
        </Button>
      </Flex>
    </VStack>
  )
}

export default CreateQuestionForm
