import { onAuthStateChanged, User } from 'firebase/auth'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { NavigateFunction } from 'react-router-dom'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import moment from 'moment'

import store from 'model/store'
import { db, auth } from 'controllers/db'
import { receiveAuthData, logoutUser } from 'model/actions/authDataAC'
import {
  addListener,
  clearListeners,
  existingUser,
  setExistingUser
} from 'controllers/listeners'
import { fetchForms } from 'controllers/forms'
import { fetchQuestions, fetchQuestionsLibrary } from 'controllers/questions'
import {
  fetchTemplates,
  fetchTemplatesSections,
  fetchTemplatesTags
} from 'controllers/templates'
import { fetchResponses } from 'controllers/responses'
import { AuthUser, UserT } from 'shared/types/model'
import { receiveUser, clear } from 'model/actions/userAC'
import { fetchAccount, fetchUserAccounts } from 'controllers/account'
import { fetchAccountProfile, fetchUserProfiles } from 'controllers/profiles'
import {
  fetchPrices,
  fetchProducts,
  fetchSubscription
} from 'controllers/stripe'
import { fetchNotificationsSettings } from 'controllers/notificationsSettings'
import { fetchInvitations } from 'controllers/invitations'
import {
  logLogin,
  logUserId,
  processAnalyticsEvents
} from 'controllers/analytics'
import { fetchAppConf } from 'controllers/settings'
import actionTypes from 'model/actionTypes'
import { fetchCFQs } from 'controllers/cfqs'

export const initAccountData = async (
  accountId: string,
  userId: string
): Promise<void> => {
  try {
    await Promise.all([
      fetchAccount(accountId),
      fetchAccountProfile(accountId),
      fetchUserProfiles(accountId, userId),
      fetchForms(accountId),
      fetchQuestions(accountId),
      fetchTemplates(),
      fetchTemplatesSections(),
      fetchTemplatesTags(),
      fetchResponses(accountId),
      fetchPrices(),
      fetchProducts(),
      fetchSubscription(accountId),
      fetchInvitations(accountId),
      fetchNotificationsSettings(accountId, userId),
      fetchCFQs(accountId),
      processAnalyticsEvents(accountId)
    ])
  } catch (e) {
    Sentry.captureException(e)
  }
}

const userChanged = async (
  user: UserT,
  isLocalChange: boolean,
  navigate: NavigateFunction
) => {
  try {
    const currentAccountNow = _.get(existingUser, 'currentAccountId')
    console.log(
      '%cuser changed, currentAccountNow',
      'color: lightgreen',
      currentAccountNow
    )
    console.log(
      '%cuser changed, newAccountId',
      'color: lightgreen',
      _.get(user, 'currentAccountId')
    )
    if (!currentAccountNow && user && user.currentAccountId && !isLocalChange) {
      console.log('userChanged 1')
      setExistingUser(user)
      store.dispatch(receiveUser(user))
      await initAccountData(user.currentAccountId, user.id)
    } else if (
      user &&
      user.currentAccountId &&
      currentAccountNow !== user.currentAccountId &&
      !isLocalChange
    ) {
      // console.log('userChanged 2')
      setExistingUser(user)
      store.dispatch(clear())
      clearListeners()
      store.dispatch(receiveUser(user))
      navigate('/')
      await initAccountData(user.currentAccountId, user.id)
    } else if (!isLocalChange) {
      // console.log('userChanged 3')
      setExistingUser(user)
      store.dispatch(receiveUser(user))
    } else {
      // console.log('userChanged 4')
      store.dispatch(receiveUser(user))
    }
  } catch (e) {
    console.log('userChanged error', e)
    Sentry.captureException(e)
  }
}

const processOnboarding = async (user: UserT) => {
  const state = store.getState()
  const onboarding = state.onboarding
  if (!_.isEmpty(onboarding) && !_.has(user, 'onboarding')) {
    updateDoc(doc(db, 'users', user.id), {
      onboarding
    })
    store.dispatch({ type: actionTypes.RESET_ONBOARDING })
  }
}

const fetchUser = async (authData: User, navigate: NavigateFunction) => {
  const userId = authData.uid
  console.log('fetch user', userId)
  try {
    // fix needed
    // @ts-ignore
    // eslint-disable-next-line no-undef
    StonlyWidget('identify', userId)

    const unsubscribe = onSnapshot(
      doc(db, 'users', userId),
      { includeMetadataChanges: true },
      userSN => {
        const isLocalChange = userSN.metadata.hasPendingWrites
        console.log('isLocalChange', isLocalChange)
        const user = userSN.data() as UserT | null
        if (user) {
          console.log('user received', user)
          userChanged(user, isLocalChange, navigate)
          processOnboarding(user)
          fetchUserAccounts(user)

          const isLocalHost =
          location.hostname === 'localhost' || location.hostname === '127.0.0.1'
          if (!isLocalHost) {
            const heap = _.get(window, 'heap')
            if (!_.isNil(heap)) {
              console.log('triggering heap')
              const createdAt = _.has(user, 'createdAt') ? moment(_.get(user, 'createdAt')).format('DD MMM YY') : null
              heap.addUserProperties({
                createdAt,
                accountId: _.get(user, 'currentAccountId'),
                lastSeenAt: moment().format('DD MMM YY')
              })
            }
          }
        }
      },
      err => {
        console.log('fetch user error', err)
        Sentry.captureMessage('fetch user error')
        Sentry.captureException(err)
      }
    )
    addListener('user', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch user error', e)
  }
}

const init = async (
  authData: User,
  navigate: NavigateFunction
): Promise<void> => {
  // TODO: fetch user independed data here
  fetchQuestionsLibrary()
  await fetchUser(authData, navigate)
}

const onAuth = async (authData: User | null, navigate: NavigateFunction) => {
  if (!_.isNil(authData)) {
    logUserId(authData.uid)
    logLogin(
      _.get(authData, ['providerData', 0, 'providerId'], 'unknown'),
      authData.uid
    )
    store.dispatch(receiveAuthData(authData.toJSON() as AuthUser))
    await init(authData, navigate)
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/auth')) {
      navigate('/')
    }
  } else {
    store.dispatch(receiveAuthData(null))
    setExistingUser(null)
    clearListeners()
    let needToRedirect = true
    const pathname = window.location.pathname
    if (
      _.startsWith(pathname, '/i/') ||
      _.startsWith(pathname, '/r/') ||
      _.startsWith(pathname, '/auth') ||
      _.startsWith(pathname, '/onboarding')
    ) {
      needToRedirect = false
    }
    if (needToRedirect) {
      navigate('/auth/signin')
    }
    store.dispatch(logoutUser())
  }
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    fetchAppConf()
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    console.log('app initialization error', e)
    Sentry.captureException(e)
  }
}
