import { Flex, HStack, VStack } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import EditorNavbar from 'components/EditorNavbar'
import Loading from 'pages/LoadingPage'
import { useEffect, useMemo, useRef, useState } from 'react'
import { dbFetchAnswers } from 'controllers/responses'
import { AnswerT, DictT } from 'shared/types/model'
import AnswerRow from 'pages/formResponse/AnswerRow'
import { getAllQuestions } from 'model/selectors/questions'
import AnswerSidePanel from 'pages/formResponse/AnswerSidePanel'
import ApplicantEvaluation from 'pages/formResponse/ApplicantEvaluation'
import ContactFormSettingsModal, {
  IContactFormSettingsModal
} from 'modals/ContactFormSettingsModal'

const FormResponse = () => {
  const navigate = useNavigate()
  const { formId, responseId } = useParams() as {
    formId: string
    responseId: string
  }
  const response = useSelector(state => _.get(state.responses, responseId))
  const questions = useSelector(getAllQuestions)
  const form = useSelector(state => _.get(state.forms, formId))
  const [answers, setAnswers] = useState<DictT<AnswerT>>({})
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>()
  const contactFormRef = useRef<IContactFormSettingsModal>(null)

  useEffect(() => {
    const run = async () => {
      const answers = await dbFetchAnswers(responseId)
      setAnswers(answers)
    }
    run()
  }, [])

  const selectedAnswer = useMemo(() => {
    return selectedAnswerId && answers[selectedAnswerId]
  }, [answers, selectedAnswerId])

  const renderAnswer = (answerId: string, i: number) => {
    const a = _.get(answers, answerId)
    const q = a && _.get(questions, a.questionId)
    if (a && q) {
      return (
        <AnswerRow
          a={a}
          key={answerId}
          num={i + 1}
          q={q}
          onSelect={setSelectedAnswerId}
          isSelected={selectedAnswerId === a.id}
        />
      )
    } else {
      return null
    }
  }

  const renderPanel = () => {
    if (selectedAnswer && response) {
      const q = _.get(questions, selectedAnswer.questionId)
      return (
        <AnswerSidePanel
          a={selectedAnswer}
          num={_.indexOf(response.answersIds, selectedAnswerId) + 1}
          onBack={() => setSelectedAnswerId(undefined)}
          q={q}
        />
      )
    } else {
      return <ApplicantEvaluation />
    }
  }

  const renderContent = () => {
    if (response) {
      return (
        <HStack w='full' h='full' overflow={'hidden'} maxH='full' spacing={0}>
          <VStack w='full' h='full' overflowY={'auto'} py={3} spacing={5}>
            {_.map(response.answersIds, renderAnswer)}
          </VStack>
          <Flex w='xl' h='full' borderLeftWidth={1} borderColor={'gray.200'}>
            {renderPanel()}
          </Flex>
        </HStack>
      )
    }
  }

  if (form && response) {
    return (
      <VStack direction='column' h='full' w='full' bg='white' spacing={0}>
        <EditorNavbar
          form={form}
          questionsAmount={_.size(form?.questions)}
          onBrowseTemplatesClick={() => console.log('onBrowseTemplatesClick')}
          onAddQuestionClick={() => console.log('onAddQuestionClick')}
          onPlaceContactForm={() => console.log('onPlaceContactForm')}
          onEditWelcomeClick={() => console.log('onEditWelcomeClick')}
          onDeleteForm={() => console.log('onDeleteForm')}
          onContactFormClick={() => contactFormRef.current?.open(formId)}
          formId={formId}
          label={response.name || 'Human'}
          setLabel={() => null}
          goBack={() => navigate(`/form/${formId}`)}
          // isEdit={type === TYPES.edit.title}
          isEdit
          responsesAmount={0}
          // responsesAmount={_.size(responses)}
        />
        {renderContent()}
        <ContactFormSettingsModal ref={contactFormRef} />
      </VStack>
    )
  } else {
    return <Loading />
  }
}

export default FormResponse
