import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import PACKAGE from '../package.json'

console.log(
  `%cFaceFile application version ${PACKAGE.version}`,
  'color: gold; font-weight: bold'
)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: PACKAGE.version,
    environment:
      window.location.hostname === 'localhost' ? 'localhost' : 'production'
  })
} else {
  console.log('sentry is off in development mode')
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
