import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  Wrap,
  WrapItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Textarea
} from '@chakra-ui/react'
import _ from 'lodash'

import ZapierLogo from 'shared/assets/zapier_logo.svg'
import SlackLogo from 'shared/assets/slack_logo.svg'
import GoogleSheetsLogo from 'shared/assets/google_sheets_logo.svg'
import TrelloLogo from 'shared/assets/trello_logo.svg'
import DropboxLogo from 'shared/assets/dropbox_logo.svg'
import AirtableLogo from 'shared/assets/airtable_logo.svg'
import NotionLogo from 'shared/assets/notion_logo.svg'
import MondayLogo from 'shared/assets/monday_logo.svg'
import AsanaLogo from 'shared/assets/asana_logo.svg'
import { useSelector } from 'model/hooks'
import { getUser } from 'model/selectors/base'
import { userRequestedIntegration } from 'controllers/notificationsSettings'
import SettingsCardTitle from 'components/settings/SettingsCardTitle'

const INTEGRATIONS_OPTIONS = [
  {
    id: 'zapier',
    label: 'Zapier',
    logo: ZapierLogo,
    active: true
  },
  {
    id: 'slack',
    label: 'Slack',
    logo: SlackLogo,
    active: true
  },
  {
    id: 'google_sheets',
    label: 'Google Sheets',
    logo: GoogleSheetsLogo,
    active: true
  },
  {
    id: 'trello',
    label: 'Trello',
    logo: TrelloLogo,
    active: false
  },
  {
    id: 'dropbox',
    label: 'Dropbox',
    logo: DropboxLogo,
    active: false
  },
  {
    id: 'airtable',
    label: 'Airtable',
    logo: AirtableLogo,
    active: false
  },
  {
    id: 'notion',
    label: 'Notion',
    logo: NotionLogo,
    active: false
  },
  {
    id: 'monday',
    label: 'Monday',
    logo: MondayLogo,
    active: false
  },
  {
    id: 'asana',
    label: 'Asana',
    logo: AsanaLogo,
    active: false
  }
]

const SettingsIntegrations = () => {
  const [selectedIntegration, setSelectedIntegration] = useState<number | null>(null)
  const [comment, setComment] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector(getUser)

  const handleClose = () => {
    setSelectedIntegration(null)
    setComment('')
    onClose()
  }

  const handleOpen = (index: number) => {
    setSelectedIntegration(index)
    onOpen()
  }

  const handleSubmit = () => {
    const integration = _.get(INTEGRATIONS_OPTIONS, [selectedIntegration as number, 'label'])
    const accountId = _.get(user, 'currentAccountId' as string)
    const userId = _.get(user, 'id' as string)
    userRequestedIntegration(accountId, userId, integration, comment)
    handleClose()
  }

  const renderOption = (v: { label: string, logo: string, active: boolean }, index: number) => {
    return (
      <WrapItem key={index} order={index}>
        <Flex
          direction='column'
          align='center'
          justify='center'
          p='8'
          w={{ base: '40vw', lg: '200px' }}
          _hover={{ bg: 'gray.50' }}
          borderRadius='lg'
          role='group'
          borderWidth={0.5}
          borderStyle='solid'
          borderColor={v.active ? 'transparent' : 'gray.100'}
          cursor='default'
        >
          <Box w='14' h='14'>
            <Image src={v.logo} h='full' w='full' />
          </Box>
          <Text color='gray.800' fontWeight={800} mt='4'>
            {v.label}
          </Text>
          <Button
            colorScheme={v.active ? 'blue' : 'gray'}
            variant={v.active ? 'solid' : 'outline'}
            mt='6'
            size='sm'
            onClick={() => v.active ? {} : handleOpen(index)}
          >
            {v.active ? 'Connect' : 'Request'}
          </Button>
        </Flex>
      </WrapItem>
    )
  }

  return (
    <>
      <Flex
        w='full'
        px={{ lg: '8', base: '0' }}
        py={{ lg: '4', base: '0' }}
        pb={4}
        bg='white'
        h='full'
        overflowY='hidden'
      >
        <VStack
          w='full'
          bg='white'
          align='flex-start'
          h='full'
        >
          <SettingsCardTitle
            title='Integrations'
            description='Discover the power of our integrations'
          />
          <Wrap justify={{ base: 'space-between', lg: 'start' }} overflowY='auto' h='full'>
            {_.map(INTEGRATIONS_OPTIONS, renderOption)}
          </Wrap>
        </VStack>
      </Flex>

      <Modal isOpen={isOpen} onClose={handleClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box position='relative'>
              <Heading as='h3' fontSize='lg' color='gray.900' fontWeight={700}>
                {_.get(INTEGRATIONS_OPTIONS, [selectedIntegration as number, 'label'], '')} integration is coming soon
              </Heading>
              <Text fontSize='sm' pt='2' color='gray.600' fontWeight={400}>
                Sign up to be notified when this integration is available
              </Text>
              <ModalCloseButton bottom='25%' right='0' />
            </Box>
          </ModalHeader>
          <ModalBody>
            <Textarea
              placeholder='Comments'
              onChange={e => setComment(e.target.value)}
              value={comment}
              size='md'
              rows={5}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='teal'
              onClick={handleSubmit}
            >
              Notify me
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SettingsIntegrations
