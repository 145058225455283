import * as types from 'model/actionTypes'
import { DictT, TemplateT } from 'shared/types/model'

interface templatesActionT {
  type: string
  templates: DictT<TemplateT> | null
}

export function receiveTemplates (templates: DictT<TemplateT> | null): templatesActionT {
  return {
    type: types.RECEIVE_TEMPLATES,
    templates
  }
}
