import { FC, useMemo } from 'react'
import _ from 'lodash'
import { Flex, HStack, Text, Stack, Button, VStack } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { AppConfT, IndustryT, TemplateSectionsT } from 'shared/types/model'
import Loading from 'pages/LoadingPage'
import IndustryRow from 'pages/settingsPages/settingsOnboarding/IndustryRow'
import {
  dbDeleteIndustry,
  dbUpdateDefaultIndustry,
  dbUpdateIndustry
} from 'controllers/settings'
import { generateId } from 'controllers/db'

const SettingsOnboarding: FC = () => {
  const appConf: AppConfT | null = useSelector(state => state.appConf)
  const templates = useSelector(state => state.templates)
  const templatesSections: TemplateSectionsT = useSelector(
    state => state.templatesSections
  )

  const sortedIndustries = useMemo(() => {
    return _.sortBy(appConf?.industries, 'createdAt')
  }, [appConf])

  const renderHeader = () => {
    return (
      <Stack
        flex={1}
        direction='column'
        spacing='2'
        display={{ base: 'none', lg: 'block' }}
      >
        <Text fontSize='2xl' lineHeight='8' fontWeight='bold' color='gray.900'>
          Industries
        </Text>
        <Text fontSize='md' lineHeight='6' fontWeight='normal' color='gray.600'>
          Configure templates and industries
        </Text>
      </Stack>
    )
  }

  const onSelectTemplate = (industryId: string, templateId: string) => {
    const t = _.get(templates, templateId)
    if (t) {
      const icon = _.get(templatesSections, ['sections', t?.sectionId, 'icon'])
      const upd = {
        templateId,
        previewUrl: t.previewUrl,
        icon
      }
      if (industryId === 'default') {
        dbUpdateDefaultIndustry(upd)
      } else {
        dbUpdateIndustry(industryId, upd)
      }
    }
  }

  const onTitleChange = (industryId: string, title: string) => {
    if (industryId === 'default') {
      dbUpdateDefaultIndustry({ title })
    } else {
      dbUpdateIndustry(industryId, { title })
    }
  }

  const renderIndustries = () => {
    if (!_.isNil(appConf)) {
      return _.map(sortedIndustries, industry => {
        return (
          <IndustryRow
            key={industry.id}
            industry={industry}
            onTitleChange={onTitleChange}
            onSelectTemplate={onSelectTemplate}
            onDelete={dbDeleteIndustry}
          />
        )
      })
    }
  }

  const addIndustry = () => {
    const id = generateId()
    const ind: IndustryT = {
      id,
      createdAt: _.now()
    }
    dbUpdateIndustry(id, ind)
  }

  const renderAddIndustryRow = () => {
    return (
      <HStack justify={'flex-end'}>
        <Button
          variant={'ghost'}
          color='blue.500'
          onClick={addIndustry}
          size='sm'
        >
          Add industry
        </Button>
      </HStack>
    )
  }

  if (_.isNil(appConf)) {
    return <Loading />
  } else {
    return (
      <Flex
        w='full'
        px={{ lg: '8', base: '0' }}
        py={{ lg: '4', base: '0' }}
        pb={4}
        overflowY='hidden'
        bg={{ lg: 'gray.50', base: 'white' }}
      >
        <HStack
          w='full'
          bg='white'
          boxShadow={{ lg: 'base', base: 'none' }}
          borderRadius='2xl'
          p={{ lg: '8', base: '4' }}
          spacing={{ lg: '4', base: 0 }}
          align='flex-start'
          h='fit-content'
        >
          {renderHeader()}
          <Stack direction='column' spacing='4' flex={2}>
            <VStack spacing={4} w='full' align={'flex-start'}>
              <Text fontSize={'sm'} fontWeight='semibold'>
                The industries are buttons on the onboarding screen:
              </Text>
              {renderIndustries()}
            </VStack>
            {renderAddIndustryRow()}
            <IndustryRow
              industry={
                appConf.defaultIndustry || {
                  id: 'default',
                  createdAt: 0
                }
              }
              onTitleChange={onTitleChange}
              onSelectTemplate={onSelectTemplate}
            />
          </Stack>
        </HStack>
      </Flex>
    )
  }
}

export default SettingsOnboarding
