import * as types from 'model/actionTypes'
import { UserT } from 'shared/types/model'

interface userActionT {
  type: string
  user: UserT | null
}

export function receiveUser (user: UserT | null): userActionT {
  return {
    type: types.RECEIVE_USER,
    user
  }
}

export function clear () {
  return {
    type: types.CLEAR
  }
}
