import { FC, useState, useRef, useEffect, RefObject, ChangeEvent } from 'react'
import { Box, Text, Flex, Progress, Portal, useBreakpointValue } from '@chakra-ui/react'
import PlayButton from 'components/mediaPreview/PlayButton'
import MediaControls from 'components/mediaPreview/MediaControls'
import { useMeasure } from 'react-use'
import MediaDuration from 'components/mediaPreview/MediaDuration'

type Props = {
  mediaUrl: string
  transcription?: string
  autoplay?: boolean
  showControls?: boolean
  thumbnail?: string
  containerProps?: object
  mediaControlsContainerRef?: RefObject<HTMLDivElement>
  mediaDurationContainerRef?: RefObject<HTMLDivElement>
}

const VideoPlayer: FC<Props> = ({
  mediaUrl,
  showControls = false,
  thumbnail,
  transcription,
  autoplay = false,
  containerProps = {},
  mediaControlsContainerRef,
  mediaDurationContainerRef
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const [ref] = useMeasure<HTMLDivElement>()
  const playerRef = useRef<HTMLVideoElement>(null)
  const [playing, setPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0.001)
  const [currentTime, setCurrentTime] = useState(0)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [fullscreen, setFullscreen] = useState(false)
  const [subtitleEnabled, setSubtitleEnabled] = useState(true)
  const [fullSize, setFullSize] = useState(true)

  const isFullScreen = () => {
    const d = document as Document & {
      webkitFullscreenElement: boolean
      mozFullScreenElement: boolean
      msFullscreenElement: boolean
    }
    return (d.fullscreenElement ||
      d.webkitFullscreenElement ||
      d.mozFullScreenElement ||
      d.msFullscreenElement) as boolean
  }

  const onFullscreenChanged = () => {
    console.log('onFullScreenChanged')
    setFullscreen(isFullScreen())
  }

  const addFullscreenEventListener = (handler: () => void) => {
    document.addEventListener('fullscreenchange', handler)
    document.addEventListener('webkitfullscreenchange', handler)
    document.addEventListener('mozfullscreenchange', handler)
    document.addEventListener('MSFullscreenChange', handler)
  }

  const removeFullscreenEventListener = (handler: () => void) => {
    document.removeEventListener('fullscreenchange', handler)
    document.removeEventListener('webkitfullscreenchange', handler)
    document.removeEventListener('mozfullscreenchange', handler)
    document.removeEventListener('MSFullscreenChange', handler)
  }

  useEffect(() => {
    addFullscreenEventListener(onFullscreenChanged)
    return () => removeFullscreenEventListener(onFullscreenChanged)
  })

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.playbackRate = playbackRate
    }
  }, [playbackRate])

  const handleLoad = (e: ChangeEvent<HTMLVideoElement>) => {
    const current = e.target
    const height = current.videoHeight
    const width = current.videoWidth
    const res = width < height
    setFullSize(res)
  }

  const onPlayButtonClick = () => {
    console.log('onPlayButtonClick', mediaUrl)
    playerRef.current?.play()
  }

  const handlePlaying = () => {
    setPlaying(true)
  }

  const handleEnded = () => {
    setPlaying(false)
  }

  const onTimeUpdate = () => {
    setCurrentTime(playerRef.current?.currentTime || 0)
  }

  const onDurationChange = () => {
    if (playerRef.current?.duration && playerRef.current?.duration > 0) {
      setDuration(playerRef.current.duration)
    }
  }

  const toFullScreen = () => {
    const elm = document.getElementById('video_wrapper') as HTMLDivElement & {
      mozRequestFullScreen(): Promise<void>
      webkitRequestFullscreen(): Promise<void>
      msRequestFullscreen(): Promise<void>
    }
    if (elm) {
      if (elm.requestFullscreen) {
        elm.requestFullscreen()
      } else if (elm.webkitRequestFullscreen) {
        elm.webkitRequestFullscreen()
      } else if (elm.mozRequestFullScreen) {
        elm.mozRequestFullScreen()
      } else if (elm.msRequestFullscreen) {
        elm.msRequestFullscreen()
      }
    }
  }

  const exitFullscreen = () => {
    const d = document as Document & {
      webkitExitFullscreen(): Promise<void>
      mozCancelFullScreen(): Promise<void>
      msExitFullscreen(): Promise<void>
    }
    if (d.exitFullscreen) {
      d.exitFullscreen()
    } else if (d.webkitExitFullscreen) {
      d.webkitExitFullscreen()
    } else if (d.mozCancelFullScreen) {
      d.mozCancelFullScreen()
    } else if (d.msExitFullscreen) {
      d.msExitFullscreen()
    }
  }

  const renderMediaControls = () => {
    if (showControls) {
      return (
        <MediaControls
          playbackRate={playbackRate}
          setPlaybackRate={(v: number) => setPlaybackRate(v)}
          isFullScreen={fullscreen}
          toFullScreen={fullscreen ? exitFullscreen : toFullScreen}
          toggleSubtitles={() => setSubtitleEnabled(!subtitleEnabled)}
          subtitleEnabled={subtitleEnabled}
        />
      )
    }
  }

  return (
    <Box
      id='video_wrapper'
      ref={ref}
      w='full'
      h='full'
      bg='blackAlpha.500'
      backgroundSize='cover'
      position='absolute'
      overflow='hidden'
      rounded={{ base: '0', lg: '2xl' }}
      sx={{
        video: {
          objectFit: fullSize && !isMobile ? 'contain' : 'cover',
          height: fullSize && !isMobile ? '100%' : 'auto',
          width: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)'
        }
      }}
      {...containerProps}
    >
      <Box
        position='absolute'
        top='0'
        right='0'
        width='100%'
        height='100%'
        backgroundImage={thumbnail}
        backgroundSize='cover'
        filter='blur(15px)'
      />
      <video
        src={mediaUrl}
        ref={playerRef}
        onPlaying={handlePlaying}
        onEnded={handleEnded}
        // poster={!fullSize ? thumbnail : undefined}
        autoPlay={autoplay}
        onTimeUpdate={onTimeUpdate}
        onDurationChange={onDurationChange}
        controls={false}
        playsInline
        disablePictureInPicture
        onCanPlayThrough={handleLoad}
      />
      <Box position={'absolute'} top={0} left={0} right={0}>
        <Progress
          value={(currentTime / duration) * 100}
          zIndex={2}
          colorScheme='teal'
          bg='black'
        />
      </Box>
      {mediaControlsContainerRef ? (
        <Portal containerRef={mediaControlsContainerRef}>
          {renderMediaControls()}
        </Portal>
      ) : (
        renderMediaControls()
      )}
      {mediaDurationContainerRef && (
        <Portal containerRef={mediaDurationContainerRef}>
          <MediaDuration currentTime={currentTime} duration={duration} />
        </Portal>
      )}
      {subtitleEnabled && (
        <Flex
          w='full'
          position={'absolute'}
          left={0}
          right={0}
          bottom={4}
          align='center'
          justify={'center'}
        >
          <Text
            textAlign={'center'}
            fontSize={'sm'}
            color='white'
            bg='blackAlpha.500'
            px={4}
            rounded='base'
          >
            {transcription}
          </Text>
        </Flex>
      )}
      {!playing && <PlayButton onClick={onPlayButtonClick} />}
    </Box>
  )
}

export default VideoPlayer
