import { FC } from 'react'
import {
  Show,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  onReplace?: () => void
  onMoveLeft?: () => void
  onMoveRight?: () => void
  onRemove?: () => void
}

const CardMobileMenu: FC<Props> = ({
  onReplace,
  onMoveLeft,
  onMoveRight,
  onRemove
}) => {
  return (
    <Show below='lg'>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisH} />}
          variant={'ghost'}
          size='sm'
        />
        <MenuList>
          {onReplace && (
            <MenuItem onClick={onReplace} _focus={{ bg: 'transparent' }}>
              Replace question
            </MenuItem>
          )}
          {onMoveLeft && (
            <MenuItem onClick={onMoveLeft} _focus={{ bg: 'transparent' }}>
              Move up
            </MenuItem>
          )}
          {onMoveRight && (
            <MenuItem onClick={onMoveRight} _focus={{ bg: 'transparent' }}>
              Move down
            </MenuItem>
          )}
          {onRemove && (
            <MenuItem onClick={onRemove} _focus={{ bg: 'transparent' }}>
              Remove
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Show>
  )
}

export default CardMobileMenu
