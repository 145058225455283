import { FC, useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react'
import { faShare } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import ShareModalContent from 'modals/shareModal/ShareModalContent'

const ShareModal: FC<{ formId: string }> = ({ formId }) => {
  const [modalContentKey, setModalContentKey] = useState(_.now())
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = () => {
    setModalContentKey(_.now())
    onOpen()
  }

  return (
    <>
      <Button
        onClick={openModal}
        variant='solid'
        colorScheme='blue'
        leftIcon={<FontAwesomeIcon icon={faShare} size='xs' />}
      >
        Share
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ShareModalContent
              formId={formId}
              onClose={onClose}
              key={_.toString(modalContentKey)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShareModal
