import { FC } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

import AppRouter from 'navigation/AppRouter'
import store from 'model/store'
import { theme } from 'theme/theme'
import '@fontsource/inter'
import '@fontsource/inter/100.css'
import '@fontsource/inter/200.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'

const App: FC<{}> = () => {
  const isLocalHost =
    location.hostname === 'localhost' || location.hostname === '127.0.0.1'
  if (!isLocalHost) {
    LogRocket.init('exma3u/facefile')
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL)
      })
    })
  }
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
