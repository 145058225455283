import { FC, useEffect, useState, useRef } from 'react'
import {
  Box,
  Flex,
  Avatar,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Tooltip,
  useBreakpointValue,
  useToast,
  ToastId
} from '@chakra-ui/react'
import _ from 'lodash'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faLock } from '@fortawesome/pro-regular-svg-icons'

import { ResponseT } from 'shared/types/model'
import { fetchResponseAnswersUrls } from 'controllers/responses'

type Props = {
  r: ResponseT
  onClick: (responseId: string) => void
  isSelected: boolean
  formName?: string
  onDeleteResponse: (responseId: string) => void
  onViewContact: () => void
  hideMenu?: boolean
  onCreateContact: () => void
  isBlocked: boolean
}

const ResponseRow: FC<Props> = ({
  r,
  onClick,
  isSelected,
  formName,
  onDeleteResponse,
  onViewContact,
  hideMenu,
  onCreateContact,
  isBlocked
}) => {
  const [tooltipIsOpen, setTooltopIsOpen] = useState(false)
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()

  useEffect(() => {
    if (tooltipIsOpen) {
      setTimeout(() => setTooltopIsOpen(false), 2000)
    }
  }, [tooltipIsOpen])

  const onPreviewResponse = () => {
    window.open(`${window.location.origin}/r/${r?.id}`, '_blank')
  }

  const handleDownload = async () => {
    setIsLoading(true)
    closeToast()
    toastIdRef.current = toast({
      title: 'Preparing file for download',
      status: 'info',
      isClosable: false
    })
    const res = await fetchResponseAnswersUrls(r, formName)
    closeToast()
    setIsLoading(false)
    if (res) {
      toastIdRef.current = toast({
        title: 'Download started',
        status: 'success',
        isClosable: true
      })
    } else {
      toastIdRef.current = toast({
        title: 'Error while preparing file',
        status: 'error',
        isClosable: true
      })
    }
  }

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const renderMenu = () => {
    if (isBlocked) {
      return (
        <Box color='red.500' px={3}>
          <FontAwesomeIcon icon={faLock} />
        </Box>
      )
    } else if (!hideMenu) {
      return (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FontAwesomeIcon icon={faEllipsisV} />}
            variant='ghost'
            size='md'
          />
          <MenuList zIndex={101}>
            <MenuItem
              color='gray.500'
              onClick={_.isEmpty(r.email) ? onCreateContact : onViewContact}
            >
              <Text color='gray.700' fontWeight='semibold'>
                {_.isEmpty(r.email) ? 'Create contact' : 'View contact'}
              </Text>
            </MenuItem>
            <MenuItem
              color='gray.500'
              onClick={onPreviewResponse}
            >
              <Text color='gray.700' fontWeight='semibold'>
                Preview response
              </Text>
            </MenuItem>
            <MenuItem
              color='gray.500'
              onClick={handleDownload}
              isDisabled={isLoading}
            >
              <Text color='gray.700' fontWeight='semibold'>
                Download response
              </Text>
            </MenuItem>
            <MenuItem
              color='red.500'
              onClick={() => onDeleteResponse(r.id)}
            >
              <Text fontWeight='semibold'>
                Delete interaction
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }
  }

  const onRowClick = () => {
    if (!isBlocked) {
      onClick(r.id)
    } else if (!isDesktop && !tooltipIsOpen) {
      setTooltopIsOpen(true)
    }
  }

  return (
    <Tooltip
      label='Your team is over your plan limit. Upgrade to view this response.'
      // shouldWrapChildren
      hasArrow
      placement='top'
      isDisabled={!isBlocked}
      // closeDelay={1000}
      closeOnClick={false}
      isOpen={isDesktop ? undefined : tooltipIsOpen}
      // ref={}
    >
      <Box
        _hover={{
          background: isBlocked ? 'blackAlpha.200' : 'white',
          boxShadow: 'md'
        }}
        rounded='md'
        boxShadow={isSelected ? 'base' : 'none'}
        bg={isSelected ? 'white' : 'transparent'}
        h='84px'
        w='full'
        px={{ base: 4, lg: 0 }}
        flexShrink={0}
        scrollSnapAlign='start'
      >
        <Flex
          px={{ base: 0, lg: '7' }}
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Flex
            w='full'
            py='4'
            onClick={onRowClick}
            cursor={isBlocked ? 'default' : 'pointer'}
          >
            <Avatar w='48px' h='48px' src={r.thumbnail} />
            <Flex flexDirection='column' justifyContent='space-between' pl='4'>
              <Text
                textAlign='start'
                textColor='gray.800'
                fontSize='xl'
                lineHeight='7'
                fontWeight='semibold'
                noOfLines={1}
              >
                {r.name || 'Human'}
              </Text>
              <Text
                mt='1'
                textAlign='start'
                textColor='gray.600'
                fontSize='sm'
                lineHeight='5'
                fontWeight='normal'
                noOfLines={1}
              >
                {moment(r.createdAt).fromNow()} {formName && `via ${formName}`}
              </Text>
            </Flex>
          </Flex>
          {renderMenu()}
        </Flex>
      </Box>
    </Tooltip>
  )
}

export default ResponseRow
