import {
  collection,
  onSnapshot,
  doc,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import * as Sentry from '@sentry/react'

import { db, dbOmit } from 'controllers/db'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { DictT, NotificationsSettingsT, SendAtT } from 'shared/types/model'
import { receiveNotificationsSettings } from 'model/actions/notificationsSettingsAC'
import { getRequestHeaders } from 'controllers/headers'

const makeId = (accountId: string, userId: string) => `${userId}_${accountId}`

export const fetchNotificationsSettings = async (
  accountId: string,
  userId: string
) => {
  try {
    const q = doc(db, 'notificationsSettings', makeId(accountId, userId))
    const unsubscribe = onSnapshot(
      q,
      docSN => {
        console.log('fetchNotificationsSettings onSnapshot', docSN)
        const ns = docSN.data() as NotificationsSettingsT | undefined
        if (ns) store.dispatch(receiveNotificationsSettings(ns))
      },
      err => {
        console.log(`fetchNotificationsSettings error: ${err.message}`)
      }
    )
    addListener('notificationsSettings', unsubscribe)
  } catch (e) {
    console.log('fetchForms error', e)
    Sentry.captureException(e)
  }
}

export const dbCreateNotificationsSettings = async (
  accountId: string,
  userId: string,
  params?: Partial<NotificationsSettingsT>
) => {
  try {
    const id = makeId(accountId, userId)
    const ns: NotificationsSettingsT = {
      accountId,
      userId,
      mutedFormsIds: [],
      sendAt: SendAtT.IMMEDIATELY,
      ...dbOmit(params as DictT<any>)
    }
    const ref = doc(collection(db, 'notificationsSettings'), id)
    await setDoc(ref, ns, { merge: true })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateNotificationsSettings = async (
  accountId: string,
  userId: string,
  upd: Partial<NotificationsSettingsT>
) => {
  try {
    console.log('dbUpdateNotificationsSettings', accountId, userId, upd)
    const id = makeId(accountId, userId)
    const ref = doc(collection(db, 'notificationsSettings'), id)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.error('dbUpdateNotificationsSettings', e)
    Sentry.captureException(e)
  }
}

export const userRequestedIntegration = async (
  accountId: string,
  userId: string,
  integration: string,
  comment: string
) => {
  try {
    const url = `${process.env
      .REACT_APP_DYNAMIC_URL as string}/account/integration-request`
    const headers = await getRequestHeaders()
    await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        accountId,
        userId,
        integration,
        comment
      })
    })
  } catch (e) {
    console.error('userRequestedIntegration error', e)
    Sentry.captureException(e)
  }
}
