import * as types from 'model/actionTypes'
import { AuthUser } from 'shared/types/model'

interface authActionT {
  type: string
  authData: AuthUser | null
}

export function receiveAuthData (authData: AuthUser | null): authActionT {
  return {
    type: types.RECEIVE_AUTH_DATA,
    authData
  }
}

export function logoutUser (): { type: string } {
  return {
    type: types.LOGOUT
  }
}
