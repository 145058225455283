import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle
} from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  HStack,
  IconButton
} from '@chakra-ui/react'
import CreateQuestionForm from 'modals/selectQuestionModal/CreateQuestionForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackButtonMobile from 'components/BackButtonMobile'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'

export interface ICreateQuestionModal {
  open: () => void
}

type Props = {
  onCreateQuestion: (text: string) => void
}

const CreateQuestionModal: ForwardRefRenderFunction<
  ICreateQuestionModal,
  Props
> = ({ onCreateQuestion }, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  const onCreateQuestionClick = (text: string) => {
    onCreateQuestion(text)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='full'>
      <ModalOverlay />
      <ModalContent>
        <ModalBody pt='6' w='full' px={4} bg='gray.50'>
          <HStack w='full' justify={'space-between'}>
            <BackButtonMobile onClick={onClose} />
            <IconButton
              aria-label='close'
              icon={<FontAwesomeIcon icon={faXmark} />}
              onClick={onClose}
            />
          </HStack>
          <CreateQuestionForm
            onClose={onClose}
            onCreateQuestion={onCreateQuestionClick}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreateQuestionModal)
