import { FC } from 'react'
import { HStack, Switch, Text } from '@chakra-ui/react'

type Props = {
  title: string
  checked: boolean
  onChange: (v: boolean) => void
}

const ResponseTypeSwitch: FC<Props> = ({ title, checked, onChange }) => {
  return (
    <HStack justify={'space-between'} w={'full'}>
      <Text color='gray.500' fontWeight={'medium'}>
        {title}
      </Text>
      <Switch
        isChecked={checked}
        onChange={e => onChange(e.target.checked)}
        colorScheme='teal'
      />
    </HStack>
  )
}

export default ResponseTypeSwitch
