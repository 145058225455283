import { FC, useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import _ from 'lodash'

import { ResponseT, AnswerT, QuestionT } from 'shared/types/model'
import { fetchResponse } from 'controllers/responses'
import Loading from 'pages/LoadingPage'
import Answers from 'pages/form/Answers'

const ResponsePage: FC = () => {
  const { responseId } = useParams()
  const [data, setData] = useState<{
    response: ResponseT
    answers: AnswerT[]
    questions: QuestionT[]
  } | null>(null)
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>()
  const navigate = useNavigate()

  useEffect(() => {
    if (_.isNil(responseId)) {
      navigate('/')
    } else {
      getResponse()
    }
  }, [responseId])

  const getResponse = async () => {
    const res = await fetchResponse(responseId as string)
    if (!res) {
      navigate('/')
    } else {
      setSelectedAnswerId(res.response.answersIds[0])
      setData(res)
    }
  }

  const currentAnswerIndex = useMemo(() => {
    if (data?.response && data?.response.answersIds && selectedAnswerId) {
      return _.indexOf(data?.response.answersIds, selectedAnswerId)
    } else {
      return 0
    }
  }, [selectedAnswerId, data])

  const answer = useMemo(
    () => _.get(data, ['answers', currentAnswerIndex]),
    [data, selectedAnswerId]
  )

  const question: QuestionT | undefined =
    answer && _.get(data?.questions, answer.questionId)

  if (_.isNil(data)) {
    return <Loading />
  }
  return (
    <Flex flexGrow={1} p={{ lg: '2', base: 0 }}>
      <Answers
        selectedAnswerId={selectedAnswerId}
        response={data.response}
        answer={answer}
        currentAnswerIndex={currentAnswerIndex}
        answersAr={data.answers}
        question={question}
        setSelectedAnswerId={setSelectedAnswerId}
      />
    </Flex>
  )
}

export default ResponsePage
