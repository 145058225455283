import * as types from 'model/actionTypes'
import { DictT, QuestionT } from 'shared/types/model'

interface questionsActionT {
  type: string
  questions: DictT<QuestionT> | null
}

export function receiveQuestions (questions: DictT<QuestionT> | null): questionsActionT {
  return {
    type: types.RECEIVE_QUESTIONS,
    questions
  }
}
