import { FC } from 'react'
import { HStack, Text } from '@chakra-ui/react'
import numeral from 'numeral'

type Props = {
  currentTime: number
  duration: number
}

const MediaDuration: FC<Props> = ({ currentTime, duration }) => {
  return (
    <HStack color='white' spacing={1}>
      <Text fontSize={'xs'} textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'>
        {numeral(currentTime)
          .format('00:00')
          .substring(3)}
      </Text>
      <Text fontSize={'xs'} textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'>
        /
      </Text>
      <Text fontSize={'xs'} textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'>
        {numeral(duration)
          .format('00:00')
          .substring(3)}
      </Text>
    </HStack>
  )
}

export default MediaDuration
