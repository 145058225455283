import _ from 'lodash'
import { setDoc, doc, updateDoc } from 'firebase/firestore'
import {
  AuthError,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  applyActionCode
} from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { db, auth } from 'controllers/db'
import store from 'model/store'
import { clearListenersExceptUser } from 'controllers/listeners'
import { clear } from 'model/actions/userAC'
import { UserProfileT } from 'shared/types/model'
import {
  logSignInEmailFailed,
  logSignInEmailSuccess,
  logSignInGoogleFailed,
  logSignInGoogleSuccess,
  logSwitchAccount
} from 'controllers/analytics'

export const dbSignIn = async (
  email: string,
  password: string
): Promise<null | string> => {
  try {
    const authData = await signInWithEmailAndPassword(auth, email, password)
    if (authData && authData.user.uid) {
      logSignInEmailSuccess(authData.user.uid)
    }
    return null
  } catch (e) {
    const er = e as AuthError
    console.log('error', er.code, er.message)
    logSignInEmailFailed(er.message)
    return er.code
  }
}

export const dbSignUp = async (
  name: string,
  email: string,
  password: string
): Promise<null | string> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    const authUser = userCredential.user
    const userId = authUser.uid
    logSignInEmailSuccess(userId)
    // add currentAccountId if the user clicked an invitation
    const userProfile: UserProfileT = {
      id: userId,
      name,
      email
    }
    const ref = doc(db, `usersProfiles/${userId}`)
    await setDoc(ref, userProfile, { merge: true })
    console.log('userProfile saved', userProfile)
    return null
  } catch (e) {
    console.log('dbSignUp error', e)
    const er = e as AuthError
    console.log('err code:', er.code)
    console.log('err message:', er.message)
    logSignInEmailFailed(er.message)
    return er.code
  }
}

export const dbSignOut = async (navigate: NavigateFunction): Promise<void> => {
  try {
    if (!_.isNil(auth) && !_.isNil(auth.currentUser)) {
      await signOut(auth)
    }
    navigate('/auth/signin')
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
    Sentry.captureException(e)
  }
}

export const switchAccount = async (
  accId: string,
  navigate?: NavigateFunction
) => {
  console.log('SWITCH ACCOUNT', accId)
  if (_.isNil(accId)) {
    console.warn('cannot switch to account', accId)
    return null
  }
  try {
    const uid = _.get(auth, 'currentUser.uid')
    if (uid) {
      console.log(' ----> history replace ')
      store.dispatch(clear())
      clearListenersExceptUser()
      if (!_.isNil(navigate)) navigate('/', { replace: true })
      await updateDoc(doc(db, 'users', uid), { currentAccountId: accId })
      logSwitchAccount(accId)
    } else {
      console.log('switchAccount, no uid')
      // Sentry.captureMessage(`${accId} switchAccount, uid is undefined`, 'fatal')
    }
  } catch (e) {
    console.log('cant change account', e)
    Sentry.captureException(e)
  }
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  // console.log('custom paramenters', provider.getCustomParameters())
  try {
    const result = await signInWithPopup(auth, provider)
    // console.log('signInWithPopup result', result)
    // This gives you a Google Access Token. You can use it to access the Google API.
    GoogleAuthProvider.credentialFromResult(result)
    console.log('signInWithGoogle result user', result.user)
    const uid = _.get(result, 'user.uid')
    if (uid) {
      logSignInGoogleSuccess(uid)
    }
    // console.log('signInWithPopup credential', credential)
  } catch (error) {
    logSignInGoogleFailed(_.get(error, 'message', 'unknown'))
    // console.error('signInWithGoogle error', error)
  }
}

export const dbResetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (e) {
    console.warn('password reset email was not sent', e)
    Sentry.captureException(e)
    return false
  }
}

export const handleVerifyEmail = async (actionCode: string, uid: string): Promise<boolean> => {
  try {
    console.log('handleVerifyEmail started')
    await applyActionCode(auth, actionCode).then(async () => {
      await auth.currentUser?.reload()
      if (auth.currentUser?.emailVerified) {
        await updateDoc(doc(db, 'users', uid), { emailVerified: true })
        return true
      } else {
        return false
      }
    })
    return true
  } catch (e) {
    console.log('handleVerifyEmail error', e)
    Sentry.captureException(e)
    return false
  }
}
