import * as types from 'model/actionTypes'
import { QuestionT, DictT } from 'shared/types/model'

const initialState: DictT<QuestionT> | null = null

export default function (
  state = initialState,
  action: { type: string; questions: DictT<QuestionT> }
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_QUESTIONS:
      return action.questions
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
