import { AnyAction } from '@reduxjs/toolkit'
import * as types from 'model/actionTypes'
import { ProductT, DictT } from 'shared/types/model'

const initialState: DictT<ProductT> | null = null

export default function (
  state = initialState,
  action: AnyAction
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_PRODUCTS:
      return action.products
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
