import types from 'model/actionTypes'
import { AnyAction } from 'redux'
import { AuthUser } from 'shared/types/model'

const initialState: AuthUser | null = null

export default function (
  state: typeof initialState = initialState,
  action: AnyAction
): any {
  switch (action.type) {
    case types.RECEIVE_AUTH_DATA:
      return action.authData
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
