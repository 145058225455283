import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, IconButton, Button } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/mobile_logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import DrawerContext from 'contexts/DrawerContext'

const NavBarMobile: FC = () => {
  const { openDrawer } = useContext(DrawerContext)
  const navigate = useNavigate()

  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      px='4'
      py='4'
      backgroundColor='white'
      w='full'
    >
      <Button
        variant='unstyled'
        flexShrink='0'
        _focus={{ outline: '0' }}
        onClick={() => navigate('/')}
      >
        <Logo />
      </Button>
      <IconButton
        variant='ghost'
        size='lg'
        aria-label='menu'
        color='gray.600'
        icon={<FontAwesomeIcon icon={faBars} />}
        onClick={openDrawer}
      />
    </Flex>
  )
}

export default NavBarMobile
