import { ChangeEvent } from 'react'
import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteCreatable
} from '@choc-ui/chakra-autocomplete'
import { Stack, Skeleton, Text } from '@chakra-ui/react'
import _ from 'lodash'

const GROUP_LABEL_STYLES = {
  fontSize: 'xs',
  fontWeight: 'medium',
  letterSpacing: 'wider',
  color: 'gray.400'
}

const AutoCompleteComponent = ({
  autocompleteData,
  value,
  disabled,
  onChange,
  onSelect,
  hideOnType,
  autoFocus,
  loading
}: {
  autocompleteData: { [key: string]: string[] } | null
  value: string
  disabled: boolean
  onChange: (v: ChangeEvent<HTMLInputElement>) => void
  onSelect: (v: any, i: any) => void
  hideOnType?: boolean
  autoFocus?: boolean
  loading?: boolean
}) => {
  if (_.isNil(autocompleteData)) return null

  const renderList = (label: string, values: string[], lId: number) => {
    if (loading && label === 'suggested') {
      return (
        <Stack key={lId} my='1' mx='5' spacing='4' mt='4'>
          <Text {...GROUP_LABEL_STYLES}>Suggested</Text>
          <Skeleton height='20px' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>
      )
    }
    return (
      <AutoCompleteGroup key={lId} showDivider>
        <AutoCompleteGroupTitle textTransform='capitalize' {...GROUP_LABEL_STYLES}>
          {label}
        </AutoCompleteGroupTitle>
        {_.map(values, v => (
          <AutoCompleteItem
            key={v}
            value={v}
            fontWeight='normal'
            fontSize='sm'
            color='gray.800'
          >
            {v}
          </AutoCompleteItem>
        ))}
      </AutoCompleteGroup>
    )
  }
  return (
    <AutoComplete
      openOnFocus
      defaultValues={[value]}
      onChange={onSelect}
      shouldRenderSuggestions={() => !(!!hideOnType && _.size(value) > 0)}
      suggestWhenEmpty
    >
      <AutoCompleteInput disabled={disabled} onChange={onChange} value={value} autoFocus={autoFocus} />
      <AutoCompleteList>
        {_.map(_.entries(autocompleteData), ([label, values], lId) => renderList(label, values, lId))}
      <AutoCompleteCreatable />
      </AutoCompleteList>
    </AutoComplete>
  )
}

export default AutoCompleteComponent
