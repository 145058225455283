import { ChangeEvent, FC, useState } from 'react'
import {
  Button,
  HStack,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
  VStack,
  Image,
  Flex,
  Switch
} from '@chakra-ui/react'
import _ from 'lodash'
import { resizeFile } from 'utils/imageResizer'
import storage from 'controllers/storage'

import { WelcomeT } from 'shared/types/model'
import FilesPickerButton from 'components/FilesPickerButton'

import { FileT } from 'shared/types/storage'
import { generateId } from 'controllers/db'

type Props = {
  formId: string
  welcome: WelcomeT
  update: (w: WelcomeT) => void
}

const DEFAULT_IMAGE_URL =
  'https://firebasestorage.googleapis.com/v0/b/subscription-2f261.appspot.com/o/Frame%2015793.png?alt=media&token=bc331255-f959-41f5-beba-9e76f4fbb32c'

const WelcomePageForm: FC<Props> = ({ formId, welcome, update }) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const updateWelcome = (upd: Partial<WelcomeT>) => {
    update({ ...welcome, ...upd })
  }

  const onChange =
    (k: string) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const v = e.target.value
      updateWelcome({ [k]: v })
    }

  const onCoverUploaded = (files: FileT[]) => {
    console.log('onCoverUploaded', files)
    const f = _.first(files)
    if (f) {
      updateWelcome({ imageUrl: f.url })
    }
  }

  const renderBanner = () => {
    return (
      <HStack w='full' justify={'space-between'} maxW='full'>
        <Flex flex={1} w='full' maxH='24'>
          <Image
            src={welcome.imageUrl || DEFAULT_IMAGE_URL}
            objectFit={'cover'}
          />
        </Flex>
        <FilesPickerButton
          userId={''}
          storagePath={`/forms/${formId}/`}
          onComplete={onCoverUploaded}
          fileProcessor={resizeFile}
          hideProgress
          options={{
            accept: {
              'image/*': ['.png', '.jpeg', '.jpg']
            },
            multiple: false
          }}
          storage={storage}
          generateId={() => 'cover_' + generateId()}
        >
          <Button size='xs' variant={'outline'} colorScheme='gray'>
            Change cover
          </Button>
        </FilesPickerButton>
      </HStack>
    )
  }

  const renderDescription = () => {
    return (
      <VStack w='full' align='start'>
        <FormControl>
          <FormLabel color='gray.700' fontSize='md' fontWeight={'semibold'}>
            Description of this opportunity
          </FormLabel>
          <Textarea
            id='description'
            size='sm'
            onChange={onChange('description')}
            value={welcome.description || ''}
            background='white'
            placeholder='You are invited to this interview because you applied for the position of Customer Support Manager at Mango Support Inc.'
          />
          <FormHelperText>
            Include anything that would help applicants quickly understand what
            they are applying for.
          </FormHelperText>
        </FormControl>
      </VStack>
    )
  }

  const renderExplanation = () => {
    return (
      <FormControl w='full'>
        <HStack w='full' justify='space-between' align='center' spacing={0}>
          <FormLabel color='gray.700' fontSize='md' fontWeight={'semibold'}>
            Short explanation for the applicant
          </FormLabel>
          {!editMode && (
            <Button
              variant='link'
              colorScheme='blue'
              size='sm'
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          )}
        </HStack>
        {editMode ? (
          <Textarea
            id='explanation'
            background='white'
            size='sm'
            value={welcome.explanation || ''}
            onChange={onChange('explanation')}
            autoFocus
            onBlur={() => {
              setEditMode(false)
            }}
            w='100%'
          />
        ) : (
          <Text
            color='gray.500'
            fontSize='sm'
            onDoubleClick={() => setEditMode(true)}
          >
            <i>{welcome.explanation || ''}</i>
          </Text>
        )}
      </FormControl>
    )
  }

  const renderLearnMore = () => {
    return (
      <VStack w='full' align='start'>
        <FormControl>
          <FormLabel color='gray.700' fontSize='md' fontWeight={'semibold'}>
            Link to more information
          </FormLabel>
          <InputGroup size='sm'>
            <InputLeftAddon
              children='https://'
              rounded={'md'}
              fontWeight={'normal'}
              bg='gray.100'
            />
            <Input
              id='url'
              rounded={'md'}
              value={welcome.learnMoreUrl || ''}
              onChange={onChange('learnMoreUrl')}
              background='white'
            />
          </InputGroup>
          <FormHelperText>
            Provide the applicant a link to more information (optional)
          </FormHelperText>
        </FormControl>
      </VStack>
    )
  }

  const renderShareMessage = () => {
    return (
      <VStack w='full' align='start'>
        <FormControl>
          <HStack align='center' w='full' justify={'space-between'}>
            <FormLabel
              color={welcome.shareMessageEnabled ? 'gray.700' : 'gray.500'}
              fontSize='md'
              fontWeight={'semibold'}
            >
              Custom share message
            </FormLabel>
            <Switch
              colorScheme='teal'
              checked={welcome.shareMessageEnabled}
              onChange={e =>
                updateWelcome({ shareMessageEnabled: e.target.checked })
              }
            />
          </HStack>

          <Textarea
            id='description'
            size='sm'
            onChange={onChange('shareMessage')}
            value={welcome.shareMessage || ''}
            background='white'
            placeholder='Thank you for opening our application link! Please reach out to jimmy@example.com if you have an issues or questions.'
          />
          <FormHelperText>
            Default custom note or alert message shared with applicants
          </FormHelperText>
        </FormControl>
      </VStack>
    )
  }

  const renderCompanyContacts = () => {
    return (
      <VStack w='full' align='start'>
        <Text fontWeight={'bold'} color='gray.700'>
          Company contact info
        </Text>
        <HStack w='full'>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <InputGroup size='md'>
              <Input
                id='name'
                value={welcome.name || ''}
                onChange={onChange('name')}
                background='white'
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <InputGroup size='md'>
              <Input
                id='email'
                value={welcome.email || ''}
                onChange={onChange('email')}
                background='white'
              />
            </InputGroup>
          </FormControl>
        </HStack>
        <HStack w='full'>
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <InputGroup size='md'>
              <Input
                id='phone'
                value={welcome.phone || ''}
                onChange={onChange('phone')}
                background='white'
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Website</FormLabel>
            <InputGroup size='md'>
              <Input
                id='website'
                value={welcome.website || ''}
                onChange={onChange('website')}
                background='white'
              />
            </InputGroup>
          </FormControl>
        </HStack>
      </VStack>
    )
  }

  return (
    <VStack
      align='start'
      spacing='10'
      w='full'
      h='full'
      overflow='hidden'
      px={6}
      py={7}
      borderRightWidth={1}
      borderColor={'gray.100'}
    >
      <VStack align='start' spacing={7} w='full'>
        <Text size='md' color='gray.700' fontWeight={'semibold'}>
          Configure what information appears on the first page your applicants
          see
        </Text>
        {renderBanner()}
        {renderDescription()}
        {renderExplanation()}
        {renderLearnMore()}
        {renderShareMessage()}
        {renderCompanyContacts()}
      </VStack>
    </VStack>
  )
}

export default WelcomePageForm
