import {
  Box,
  Divider,
  HStack,
  IconButton,
  Text,
  VStack
} from '@chakra-ui/react'
import { faArrowLeft, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VideoPlayer from 'components/VideoPlayer'
import { FC } from 'react'
import { AnswerT, QuestionT } from 'shared/types/model'

type Props = {
  a: AnswerT
  q: QuestionT
  num: number
  onBack: () => void
}

const AnswerSidePanel: FC<Props> = ({ a, num, onBack, q }) => {
  const heading = (
    <HStack w='full' justify={'space-between'} pl='4' pr={4}>
      <HStack py={2}>
        <IconButton
          aria-label='back'
          variant={'ghost'}
          colorScheme='gray'
          color='gray.500'
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={onBack}
        />
        <Text color='black' fontWeight={'semibold'}>
          Question {num}
        </Text>
      </HStack>
      <IconButton
        aria-label='close'
        variant={'ghost'}
        colorScheme='gray'
        color='black'
        icon={<FontAwesomeIcon icon={faXmark} />}
        onClick={onBack}
      />
    </HStack>
  )

  const renderResponse = (
    <VStack w='full' align='flex-start' px={6} pt={3}>
      <Text color='gray.900' fontSize={'sm'} fontWeight={'medium'}>
        Response
      </Text>
      <Box w='full' position={'relative'} h='lg'>
        <VideoPlayer mediaUrl={a.mediaUrl} />
      </Box>
    </VStack>
  )

  const renderTranscript = () => {
    if (a.transcription) {
      return (
        <VStack w='full' align='flex-start' px={6} pt={3} spacing={'10px'}>
          <Text color='gray.900' fontSize={'sm'} fontWeight={'medium'}>
            Answer transcript
          </Text>
          <Text fontSize={'xs'} color='gray.600' fontStyle={'italic'}>
            {a.transcription}
          </Text>
        </VStack>
      )
    }
  }

  const renderAIAnalysis = () => {
    if (a.evaluation) {
      return (
        <VStack w='full' align='flex-start' px={6} pt={3} spacing={'10px'}>
          <Text color='gray.900' fontSize={'sm'} fontWeight={'medium'}>
            AI Analysis
          </Text>
          <Text fontSize={'xs'} color='gray.600' fontStyle={'italic'}>
            {a.evaluation}
          </Text>
        </VStack>
      )
    }
  }

  return (
    <VStack w='full' h='full' align='flex-start' spacing={0}>
      {heading}
      <Text px={6} color='gray.900' fontSize={'sm'} fontWeight={'medium'}>
        {q.text}
      </Text>
      <Divider color='gray.50' pt={5} />
      <VStack spacing={0} w='full' overflowY={'auto'} pb={4}>
        {renderResponse}
        <Divider color='gray.50' pt={5} />
        {renderTranscript()}
        {renderAIAnalysis()}
      </VStack>
    </VStack>
  )
}

export default AnswerSidePanel
