import { FC, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-regular-svg-icons/faPlay'
// import { faCircleUser } from '@fortawesome/pro-regular-svg-icons/faCircleUser'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faChevronsRight } from '@fortawesome/pro-regular-svg-icons/faChevronsRight'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
import strartsWith from 'lodash/startsWith'
import {
  ContactFormSettingsT,
  InteractionContactT,
  WelcomeT
} from 'shared/types/model'
import facefileLogo from 'shared/assets/logo_whitebg.svg'
import WelcomeContacts from 'shared/components/welcomePageContent/WelcomeContacts'
import ContactForm from 'shared/components/welcomePageContent/ContactForm'

type Props = {
  welcome?: WelcomeT
  onClose?: () => void
  onGo?: (c?: InteractionContactT) => void
  onStartAgain?: () => void
  completed: boolean
  percentage: number
  contactForm: ContactFormSettingsT | null
  showContactForm?: boolean
  interactionContact?: InteractionContactT
}

const WelcomePageContent: FC<Props> = ({
  onClose,
  welcome,
  onGo,
  onStartAgain,
  completed,
  percentage,
  contactForm,
  showContactForm,
  interactionContact
}) => {
  const [contact, setContact] = useState<InteractionContactT>({})

  useEffect(() => {
    setContact(interactionContact || {})
  }, [interactionContact])

  const renderImage = () => {
    if (welcome && welcome.imageUrl) {
      return <img src={welcome.imageUrl} className=' h-full object-contain' />
    } else {
      return <img src={facefileLogo} />
    }
  }

  const renderResume = () => {
    if (!onClose && onStartAgain && !showContactForm) {
      return (
        <>
          <div className='absolute top-0 bottom-0 lelft-0 right-0 w-full h-full bg-blackAlpha-700'></div>
          <div className='absolute bottom-0 l-0 w-full bg-white rounded-t-md'>
            <div className='px-6 py-4 flex flex-row items-center justify-between  w-full'>
              <div className='flex flex-row items-center'>
                <span className='text-gray-700 font-bold text-lg'>
                  {completed ? 'Finished' : 'Resume session'}
                </span>
                <span className='text-gray-500 pl-3 text-sm italic'>
                  {percentage}% complete
                </span>
              </div>
              <div>
                {!completed && (
                  <button
                    className='hover:bg-gray-50 rounded-md transition-colors text-gray-700'
                    onClick={() => onGo()}
                  >
                    <FontAwesomeIcon icon={faXmark} fontSize={'1.2em'} />
                  </button>
                )}
              </div>
            </div>
            <div className='px-6 py-2 w-full'>
              <span className='text-gray-700'>
                {completed
                  ? 'Thank you for answering all the questions.'
                  : 'Would you like to continue from where you left off?'}
              </span>
            </div>
            <div className='px-6 py-4 w-full'>
              {!completed && (
                <button
                  className='w-full bg-blue-500 hover:bg-blue-400 active:bg-blue-700 rounded-lg transition-colors h-16'
                  onClick={() => onGo()}
                >
                  <p className='text-white text-[20px] font-semibold'>
                    Continue
                  </p>
                </button>
              )}
              <button
                className='w-full hover:bg-gray-100 active:bg-gray-200 rounded-lg transition-colors h-16 mt-3 border-gray-300 border'
                onClick={onStartAgain}
              >
                <p className='text-gray-600 text-[20px] font-semibold'>
                  Start Again
                </p>
              </button>
            </div>
          </div>
        </>
      )
    }
  }

  const renderDescription = () => {
    let title = 'You have been invited to a one-way video interview'
    if (showContactForm) {
      title = 'Add your contact info'
    } else if (welcome && welcome.description) {
      title = welcome.description
    }
    return <p className='text-md text-gray-800 font-bold mb-4'>{title}</p>
  }

  const explanation = (
    <p className='text-md text-black text-opacity-40 font-normal mb-4'>
      {(welcome && welcome.explanation) ||
        'Answer the following video questions using your camera.'}
    </p>
  )

  const learnMore = welcome &&
    welcome.learnMoreUrl &&
    welcome.learnMoreUrl.length > 0 && (
      <div className='flex mb-4'>
        <a
          href={
            strartsWith(welcome.learnMoreUrl, 'http')
              ? welcome.learnMoreUrl
              : 'https://' + welcome.learnMoreUrl
          }
          className='flex flex-row gap-4'
          target='_blank'
        >
          <p className='text-md text-gray-600 font-normal'>Learn more</p>
          <div>
            <FontAwesomeIcon icon={faExternalLink} color='#4A5568' />
          </div>
        </a>
      </div>
    )

  const shareMessage = welcome &&
    welcome.shareMessageEnabled &&
    welcome.shareMessage && (
      <div className='border-l-4 border-blue-400'>
        <p className='text-md text-black font-medium pl-2'>
          {welcome.shareMessage}
        </p>
      </div>
    )

  const howItWorks = (
    <button className='w-full hover:bg-gray-50 rounded-md transition-colors'>
      <div className='flex gap-4 p-4 items-center'>
        <div className='w-4 h-4 flex items-center justify-center'>
          <FontAwesomeIcon icon={faPlay} />
        </div>
        <p>Watch how it works</p>
        <div className='ml-auto flex flex-row items-center gap-4'>
          <p className='italic'>30 sec</p>
          <div className='w-4 h-4 flex items-center justify-center'>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </button>
  )

  const renderButton = () => {
    if (onGo) {
      return (
        <div className='flex pt-4 pb-2 w-full'>
          <button
            className='w-full bg-teal-500 hover:bg-teal-600 active:bg-teal-700 rounded-lg transition-colors h-16'
            onClick={() => onGo(contact)}
          >
            <p className='text-white text-[20px] font-semibold'>
              {showContactForm ? 'Done' : "Let's go"}{' '}
            </p>
          </button>
        </div>
      )
    }
  }

  const renderContactForm = () => {
    if (showContactForm && contactForm) {
      return (
        <ContactForm
          contact={contact}
          contactForm={contactForm}
          setContact={setContact}
        />
      )
    }
  }

  const renderContent = () => {
    if (showContactForm && contactForm) {
      return (
        <>
          {renderDescription()}
          <ContactForm
            contact={contact}
            contactForm={contactForm}
            setContact={setContact}
          />
        </>
      )
    } else {
      return (
        <>
          {renderDescription()}
          {explanation}
          {learnMore}
          {shareMessage}
        </>
      )
    }
  }

  return (
    <div className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative'>
      <div>
        <div className='w-full h-28 bg-cover relative flex justify-center items-center relative shrink-0'>
          {renderImage()}
          {onClose && (
            <button
              onClick={() => onClose()}
              className='absolute top-3 left-3 text-[20px] w-8 h-8 rounded-md border bg-whiteAlpha-500 border-gray-600'
            >
              <FontAwesomeIcon icon={faChevronsRight} color='#193154' />
            </button>
          )}
        </div>
        <div className='w-full h-auto p-4'>{renderContent()}</div>
      </div>

      <div className='px-4 pb-2 text-gray-500 text-sm bg-yellow h-full flex-col flex justify-end'>
        {!showContactForm && howItWorks}
        {!showContactForm && welcome && welcome.name && (
          <WelcomeContacts welcome={welcome} />
        )}
        {renderButton()}
      </div>
      {renderResume()}
    </div>
  )
}

export default WelcomePageContent
