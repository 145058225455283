import {
  Ref,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle
} from 'react'
import {
  Box,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'

import PlanSelect from 'components/stripe/PlanSelect'

export interface IUpgradeModal {
  open: () => void
}

type Props = {}

const UpgradeModal: ForwardRefRenderFunction<IUpgradeModal, Props> = (
  props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Heading as='h3' fontSize='lg' color='gray.900' fontWeight={700}>
              Transform Your Application Process
            </Heading>
            <Text fontSize='sm' pt='2' color='gray.600' fontWeight={400}>
              Finding the right applicants is just a click away!
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton alignSelf='center' />
        <ModalBody>
          <PlanSelect />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(UpgradeModal)
