import _ from 'lodash'
import { useEffect } from 'react'
import { Box, VStack, Button } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'model/hooks'
import { useNavigate } from 'react-router-dom'
import { AppConfT, OnboardingT } from 'shared/types/model'
import { receiveOnboarding } from 'model/actions/onboardingAC'

const OnboardingVideoForm = () => {
  const appConf: AppConfT | null = useSelector(state => state.appConf)
  const onboarding: OnboardingT = useSelector(state => state.onboarding)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      (!_.isNil(appConf) && _.isNil(appConf.defaultIndustry)) ||
      !_.has(onboarding, 'industry.previewUrl')
    ) {
      navigate('/auth/signup')
    }
  }, [appConf])

  useEffect(() => {
    if (_.isNil(onboarding)) {
      navigate('/onboarding/contacts')
    }
  }, [onboarding])

  const handleEvent = (event: MessageEvent) => {
    // console.log('event', event)
    const { data, origin } = event
    if (origin === 'https://www.videoask.com') {
      console.log('data', data)
      if (_.has(data, 'contactId')) {
        dispatch(receiveOnboarding({ contactId: data.contactId }))
      }
      // console.log('origin', origin)
      if (data.type === 'videoask_submitted') {
        console.log('go next')
        navigate('/auth/signup')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleEvent, false)
    return function cleanup () {
      window.removeEventListener('message', handleEvent)
    }
  }, [])

  const onSkipClick = () => {
    dispatch(receiveOnboarding({ contactId: undefined, industry: undefined }))
    navigate('/auth/signup')
  }

  const previewUrl = _.get(onboarding, 'industry.previewUrl')

  return (
    <VStack w='full' h='full' overflow={'hidden'}>
      <Box position='fixed' top={6} left={4} zIndex={2}>
        <Button
          size='md'
          // px={12}
          bg='blackAlpha.600'
          color='white'
          onClick={onSkipClick}
        >
          Skip
        </Button>
      </Box>
      <Box w='full' h='full' overflow={'hidden'}>
        {previewUrl && (
          <iframe
            src={`${previewUrl}#contact_name=${onboarding?.name}&contact_email=${onboarding?.email}`}
            allow='camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;'
            width='100%'
            height='100%'
          />
        )}
      </Box>
    </VStack>
  )
}

export default OnboardingVideoForm
