import * as types from 'model/actionTypes'
import { DictT, PriceT } from 'shared/types/model'

interface PricesActionT {
  type: string
  prices: DictT<PriceT> | null
}

export function receivePrices (prices: DictT<PriceT> | null): PricesActionT {
  return {
    type: types.RECEIVE_PRICES,
    prices
  }
}
