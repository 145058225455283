import { FC } from 'react'
import { HStack, IconButton, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'

type Props = {
  num: number
  amount: number
  toPrev: () => void
  toNext: () => void
}

const QuestionNumber: FC<Props> = ({ num, amount, toPrev, toNext }) => {
  return (
    <HStack
      spacing={{ base: '3.5', lg: '2' }}
      sx={{
        svg: {
          filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))'
        }
      }}
    >
      <Text
        fontSize={'xs'}
        fontWeight='bold'
        color='gray.800'
        bg='gray.100'
        px={2}
        rounded='sm'
      >
        Question {num} of {amount}
      </Text>
      <HStack>
        <IconButton
          aria-label='prev_question'
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          variant='unstyled'
          color='gray.100'
          size='xs'
          isDisabled={num === 1}
          onClick={toPrev}
        />
        <IconButton
          aria-label='next_question'
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          variant='unstyled'
          color='gray.100'
          size='xs'
          isDisabled={num === amount}
          onClick={toNext}
        />
      </HStack>
    </HStack>
  )
}

export default QuestionNumber
