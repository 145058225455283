import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  Ref,
  useState,
  useMemo
} from 'react'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  HStack,
  Text,
  Button,
  IconButton,
  Box,
  VStack,
  Tag
} from '@chakra-ui/react'
import _ from 'lodash'
import { useSelector } from 'model/hooks'
import { QuestionT, DictT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faXmark } from '@fortawesome/pro-solid-svg-icons'
import VideoPlayer from 'components/VideoPlayer'
import DynamicWrap from 'shared/components/DynamicWrap'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { getAllQuestions } from 'model/selectors/questions'

export interface IViewQuestionMobile {
  open: (questionId: string) => void
}

type Props = {
  onAdd: (qId: string) => void
}

const ViewQuestionMobile: ForwardRefRenderFunction<
  IViewQuestionMobile,
  Props
> = ({ onAdd }: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [questionId, setQuestionId] = useState<string>()
  const questionsDict: DictT<QuestionT> = useSelector(getAllQuestions)
  const templatesTags = useSelector(state => state.templatesTags)

  useImperativeHandle(ref, () => ({
    open: (questionId: string) => {
      setQuestionId(questionId)
      onOpen()
    }
  }))

  const question = useMemo(() => {
    if (questionId) {
      return questionsDict[questionId]
    }
  }, [questionId, questionsDict])

  const onAddQuestionClick = () => {
    if (questionId) {
      onClose()
      onAdd(questionId)
    }
  }

  const renderHeader = () => {
    return (
      <VStack w='full'>
        <HStack w='full' justify={'space-between'} px={4} pt={6}>
          <Button
            size='sm'
            onClick={onClose}
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            flexShrink={0}
            variant='ghost'
            pl={0}
          >
            <Text color='gray.600'>Back</Text>
          </Button>
          <IconButton
            aria-label='close'
            icon={<FontAwesomeIcon icon={faXmark} />}
            onClick={onClose}
          />
        </HStack>
        <HStack
          w='full'
          zIndex={2}
          px={4}
          pt={2}
          justify='space-between'
          align='center'
        >
          <Button
            variant={'solid'}
            colorScheme='blue'
            size='lg'
            w='full'
            onClick={onAddQuestionClick}
          >
            Add question
          </Button>
        </HStack>
      </VStack>
    )
  }

  const renderVideo = () => {
    console.log('renderVideo', question)
    if (question && question.mediaUrl) {
      return (
        <Box position='absolute' top={0} left={0} w='full' h='full'>
          <VideoPlayer
            mediaUrl={question.mediaUrl}
            autoplay={true}
            showControls={false}
            thumbnail={question.thumbnail}
            key={question.mediaUrl}
          />
        </Box>
      )
    }
  }

  const renderTags = () => {
    const tags = question && question.tags
    if (!_.isEmpty(tags)) {
      return (
        <DynamicWrap
          wrapProps={{
            maxH: 16,
            w: 'full',
            spacingX: 2,
            spacingY: 2,
            align: 'flex-end'
          }}
          elements={_.map(tags, (tagId: string) => {
            const label = templatesTags[tagId]
            return (
              <Tag
                variant={'solid'}
                bg='gray.100'
                color='black'
                size='sm'
                minH={4}
                rounded='sm'
              >
                {label}
              </Tag>
            )
          })}
          iconMore={
            <Box color='white'>
              <FontAwesomeIcon icon={faEllipsis} size='sm' />
            </Box>
          }
        />
      )
    }
  }

  const renderQuestionsSwitch = () => {
    return (
      <VStack
        position={'absolute'}
        bottom={0}
        left={0}
        w='full'
        px={4}
        pt={6}
        pb={24}
        bgGradient='linear(to-t, blackAlpha.800, transparent)'
        spacing={6}
      >
        <VStack bg='blackAlpha.500' p={4} pt={6} spacing={6} w='full'>
          <Text
            color='white'
            fontWeight={'bold'}
            textShadow='md'
            textAlign={'left'}
            w='full'
          >
            {question?.text}
          </Text>
        </VStack>
        {renderTags()}
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='full' autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody w='full' h='full' p='0' overflow={'hidden'}>
          {renderVideo()}
          {renderHeader()}
          {renderQuestionsSwitch()}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ViewQuestionMobile)
