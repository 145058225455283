import * as types from 'model/actionTypes'
import { AppConfT } from 'shared/types/model'

interface AppConfActionT {
  type: string
  appConf: AppConfT
}

export function receiveAppConf (appConf: AppConfT): AppConfActionT {
  return {
    type: types.RECEIVE_APP_CONF,
    appConf
  }
}
