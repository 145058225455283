// import _ from 'lodash'
import * as Sentry from '@sentry/react'

// import { auth } from 'controllers/db'
import { getRequestHeaders } from 'controllers/headers'

export const callOpenaiChat = async (
  message: { role: string; content: string }[],
  model: string,
  temperature: number,
  maxTokens: number
) => {
  try {
    console.log('callOpenai', message)
    const url = `${
      process.env.REACT_APP_DYNAMIC_URL as string
    }/adm/test_analysis_chat`
    console.log('fetch url', url)
    const headers = await getRequestHeaders()
    const data = {
      message,
      model,
      temperature,
      maxTokens
    }
    const resRaw = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
    const res = await resRaw.json()
    console.log('callOpenai res', res)
    return res
  } catch (e) {
    console.log('callOpenai error', e)
    Sentry.captureException(e)
    return null
  }
}
