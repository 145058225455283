import {
  Ref,
  useState,
  useRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useMemo
} from 'react'
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  VStack,
  Text
} from '@chakra-ui/react'
// import _ from 'lodash'

import { useSelector } from 'model/hooks'
import _ from 'lodash'
import { dbArchiveQuestion } from 'controllers/questions'
import { dbRemoveQuestionFromTemplates } from 'controllers/templates'

export interface IArchiveQuestionAlert {
  open: (qId: string) => void
}

type Props = {}

const ArchiveQuestionAlert: ForwardRefRenderFunction<
  IArchiveQuestionAlert,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [questionId, setQuestionId] = useState<string>()
  const templates = useSelector(state => state.templates)
  const cancelRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: (qId: string) => {
      setQuestionId(qId)
      onOpen()
    }
  }))

  const usedInTemplates = useMemo(() => {
    return _.filter(templates, t => _.includes(t.questions, questionId))
  }, [questionId, templates])

  const removeFromTemplates = () => {
    if (questionId && !_.isEmpty(usedInTemplates)) {
      const templatesIds = _.map(usedInTemplates, t => t.id)
      dbRemoveQuestionFromTemplates(questionId, templatesIds)
    }
  }

  const renderUsedInTemplates = () => {
    if (!_.isEmpty(usedInTemplates)) {
      return (
        <VStack align='flex-start' spacing={1} pt={8}>
          <Text fontWeight={'semibold'} color='gray.600'>
            The question is used in {_.size(usedInTemplates)} templates:
          </Text>
          {_.map(usedInTemplates, t => {
            return (
              <Text key={t.id} fontSize='sm' color='gray.600'>
                - {t.title}
              </Text>
            )
          })}
          <Button
            colorScheme='red'
            variant={'link'}
            size='sm'
            onClick={removeFromTemplates}
          >
            Remove from templates
          </Button>
        </VStack>
      )
    }
  }

  const onArchive = () => {
    if (questionId) {
      dbArchiveQuestion(questionId)
      onClose()
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Archive question
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              The question will be not available to use in new templates
              creation and video form creation
            </Text>

            {renderUsedInTemplates()}
          </AlertDialogBody>

          <AlertDialogFooter pt={4}>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={onArchive} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default forwardRef(ArchiveQuestionAlert)
