import { FC } from 'react'
import { Text, Center, HStack, VStack } from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

type Props = {
  price: string
  isMonth: boolean
  isCustom: boolean
}

const features = [
  'Unlimited video forms',
  'Unlimited users',
  'Remove FaceFile branding',
  'Create your own questions',
  'Integrations',
  'Transcripts'
]

const PlanFeatures: FC<Props> = ({ price, isMonth, isCustom }) => {
  const period = isMonth ? 'mo' : 'yr'

  const renderRow = (title: string, i: number) => {
    return (
      <HStack key={i} spacing='2'>
        <Center rounded={'full'} bg='teal.100' boxSize={4} color={'teal.300'}>
          <FontAwesomeIcon icon={faCheck} fontSize='0.5em' />
        </Center>
        <Text fontWeight={'medium'} color='gray.800'>
          {title}
        </Text>
      </HStack>
    )
  }

  return (
    <VStack
      px={{ base: '4', lg: '0' }}
      pt={{ base: '9', lg: '0' }}
      w={{ base: 'full', lg: '65%' }}
      spacing={4}
      align='flex-start'
    >
      <Text fontWeight={'bold'} color='gray.800'>
        {isCustom ? 'Custom pricing' : `${price}/${period} plan includes`}
      </Text>
      {_.map(features, renderRow)}
    </VStack>
  )
}

export default PlanFeatures
