import {
  query,
  collection,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteField,
  deleteDoc,
  writeBatch
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db, dbOmit } from 'controllers/db'
import store from 'model/store'
import { receiveTemplates } from 'model/actions/templatesAC'
import { receiveTemplatesSections } from 'model/actions/templatesSectionsAC'
import { receiveTemplatesTags } from 'model/actions/templatesTagsAC'
import { addListener } from 'controllers/listeners'
import {
  TemplateSectionsT,
  TemplateSectionT,
  TemplateT,
  DictT
} from 'shared/types/model'

export const fetchTemplates = async (): Promise<any> => {
  try {
    const q = query(collection(db, 'templates'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveTemplates(res))
      },
      err => {
        console.log(`fetchTemplates error: ${err.message}`)
      }
    )
    addListener('templates', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchTemplates error', e)
  }
}

export const fetchTemplatesSections = async (): Promise<any> => {
  try {
    const ref = doc(db, 'settings', 'templatesSections')
    const unsubscribe = onSnapshot(
      ref,
      ttSN => {
        store.dispatch(
          receiveTemplatesSections(ttSN.data() as TemplateSectionsT)
        )
      },
      err => {
        console.log(`fetchTemplatesSections error: ${err.message}`)
        Sentry.captureException(err)
      }
    )
    addListener('templatesSections', unsubscribe)
  } catch (e) {
    console.log('fetchTemplatesSections error', e)
    Sentry.captureException(e)
  }
}

export const fetchTemplatesTags = async (): Promise<any> => {
  try {
    const ref = doc(db, 'settings', 'templatesTags')
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        store.dispatch(receiveTemplatesTags((sn.data() as DictT<string>) || {}))
      },
      err => {
        console.log(`fetchTemplatesTags error: ${err.message}`)
        Sentry.captureException(err)
      }
    )
    addListener('templatesTags', unsubscribe)
  } catch (e) {
    console.log('fetchTemplatesTags error', e)
    Sentry.captureException(e)
  }
}

export const dbCreateTemplateTag = async (id: string, value: string) => {
  try {
    const upd = {
      [id]: value
    }
    const ref = doc(db, 'settings', 'templatesTags')
    await setDoc(ref, upd, { merge: true })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplateTag = async (id: string, value: string) => {
  try {
    const upd = {
      [id]: value
    }
    const ref = doc(db, 'settings', 'templatesTags')
    await setDoc(ref, upd, { merge: true })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplate = async (
  templateId: string,
  upd: Partial<TemplateT>
) => {
  try {
    const ref = doc(collection(db, 'templates'), templateId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbCreateTemplate = async (t: TemplateT) => {
  try {
    const ref = doc(collection(db, 'templates'), t.id)
    await setDoc(ref, t)
    const sectionsRef = doc(db, 'settings', 'templatesSections')
    const upd = {
      [`sections.${t.sectionId}.templatesOrder`]: arrayUnion(t.id)
    }
    await updateDoc(sectionsRef, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbCreateTemplateSection = async (s: TemplateSectionT) => {
  try {
    const ref = doc(db, 'settings', 'templatesSections')
    const upd = {
      [`sections.${s.id}`]: s,
      order: arrayUnion(s.id)
    }
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplateSection = async (s: TemplateSectionT) => {
  try {
    const ref = doc(db, 'settings', 'templatesSections')
    const upd = {
      [`sections.${s.id}`]: s
    }
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbAddQuestionToTemplate = async (
  templateId: string,
  questionId: string
) => {
  try {
    const ref = doc(db, 'templates', templateId)
    await updateDoc(ref, { questions: arrayUnion(questionId) })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbRemoveQuestionFromTemplate = async (
  templateId: string,
  questionId: string
) => {
  try {
    const ref = doc(db, 'templates', templateId)
    await updateDoc(ref, { questions: arrayRemove(questionId) })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbRemoveQuestionFromTemplates = async (
  questionId: string,
  templatesIds: string[]
) => {
  try {
    const batch = writeBatch(db)
    _.forEach(templatesIds, templateId =>
      batch.update(doc(db, 'templates', templateId), {
        questions: arrayRemove(questionId)
      })
    )
    await batch.commit()
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplateQuestions = async (
  templateId: string,
  questions: string[]
) => {
  try {
    const ref = doc(db, 'templates', templateId)
    await updateDoc(ref, { questions })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbEnableTemplate = async (
  templateId: string,
  isEnabled: boolean
) => {
  try {
    const ref = doc(db, 'templates', templateId)
    await updateDoc(ref, { enabled: isEnabled })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateSectionsOrder = async (order: string[]) => {
  try {
    const ref = doc(db, 'settings', 'templatesSections')
    await updateDoc(ref, { order })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplatesOrder = async (
  sectionId: string,
  templatesOrder: string[]
) => {
  try {
    const ref = doc(db, 'settings', 'templatesSections')
    const upd = {
      [`sections.${sectionId}.templatesOrder`]: templatesOrder
    }
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbRemoveTemplate = async (
  sectionId: string,
  templateId: string
) => {
  try {
    const ref = doc(db, 'settings', 'templatesSections')
    const upd = {
      [`sections.${sectionId}.templatesOrder`]: arrayRemove(templateId)
    }
    await updateDoc(ref, upd)
    const templateRef = doc(collection(db, 'templates'), templateId)
    await deleteDoc(templateRef)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbRemoveTemplatesSection = async (s: TemplateSectionT) => {
  try {
    const batch = writeBatch(db)
    const ref = doc(db, 'settings', 'templatesSections')
    const upd = {
      [`sections.${s.id}`]: deleteField(),
      order: arrayRemove(s.id)
    }
    batch.update(ref, upd)
    _.forEach(s.templatesOrder, tId => batch.delete(doc(db, 'templates', tId)))
    await batch.commit()
  } catch (e) {
    Sentry.captureException(e)
  }
}
