import { FC } from 'react'
import { Box, VStack, Center } from '@chakra-ui/react'

const ContactFormSkeleton: FC = () => {
  return (
    <Center
      w='66px'
      h='71px'
      rounded='base'
      bg='gray.50'
      flexShrink={0}
      boxShadow='base'
    >
      <VStack align='flex-start' spacing={1}>
        <Box w={8} h={1} bg='gray.100' rounded={'full'} />
        <Box
          w={12}
          h={3}
          bg='white'
          borderColor={'gray.200'}
          borderWidth={1}
          rounded={'full'}
        />
        <Box w={8} h={1} bg='gray.100' rounded={'full'} />
        <Box
          w={'10'}
          h={3}
          bg='white'
          borderColor={'gray.200'}
          borderWidth={1}
          rounded={'full'}
        />
      </VStack>
    </Center>
  )
}

export default ContactFormSkeleton
