import { FC, ReactNode, useState, useRef, LegacyRef, useEffect } from 'react'
import { Wrap, WrapProps, Tooltip, VStack, Box } from '@chakra-ui/react'
import DynamicWrapItem from 'shared/components/dynamicWrap/DynamicWrapItem'
import * as _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  wrapProps: WrapProps
  elements: ReactNode[]
  iconMore?: ReactNode
}

const DynamicWrap: FC<Props> = ({ wrapProps, elements, iconMore }) => {
  const [index, setIndex] = useState<number | undefined>()
  const itemSizesRef = useRef<DOMRect[]>([])
  const ref = useRef(null)

  const onItemBoudingRect = (rect: DOMRect, i: number) => {
    // console.log('onItemBoudingRect: i', i, 'rect', rect)
    itemSizesRef.current[i] = rect
  }

  const onDotsRect = (rect: DOMRect) => {
    const wrapperRect: DOMRect = ref.current?.getBoundingClientRect()
    if (wrapperRect) {
      if (rect.bottom > wrapperRect.bottom) {
        setIndex(index > 0 ? index - 1 : index)
      }
    }
  }

  useEffect(() => {
    const wrapperRect: DOMRect = ref.current?.getBoundingClientRect()
    if (wrapperRect) {
      const newIndex = _.reduce(
        itemSizesRef.current,
        (res, rect, i) => {
          if (rect.bottom > wrapperRect.bottom && (_.isNil(res) || i < res)) {
            return i
          } else {
            return res
          }
        },
        undefined
      )
      if (!_.isNil(newIndex)) {
        setIndex(newIndex)
      }
    }
  }, [])

  return (
    <Wrap
      position={'relative'}
      {...wrapProps}
      ref={ref as LegacyRef<HTMLDivElement>}
    >
      {elements.map((elt: ReactNode, i: number) => {
        if (i >= index) return null
        return (
          <DynamicWrapItem
            onBoudingRect={(rect: DOMRect) => onItemBoudingRect(rect, i)}
            key={i}
            order={i}
          >
            {elt}
          </DynamicWrapItem>
        )
      })}
      {!_.isNil(index) && (
        <DynamicWrapItem
          key='more'
          order={index + 1}
          onBoudingRect={(rect: DOMRect) => onDotsRect(rect)}
          py={0}
          lineHeight={1}
        >
          <Tooltip
            label={
              <VStack align={'flex-start'} p={2}>
                {_.map(elements, (elt, i) => {
                  return i >= index ? <Box key={i}>{elt}</Box> : null
                })}
              </VStack>
            }
            shouldWrapChildren
            bg='white'
            boxShadow={'base'}
            rounded='base'
          >
            {!_.isNil(iconMore) ? (
              iconMore
            ) : (
              <FontAwesomeIcon icon={faEllipsis} size='sm' />
            )}
          </Tooltip>
        </DynamicWrapItem>
      )}
    </Wrap>
  )
}

export default DynamicWrap
