import { useEffect, useRef, useState, useMemo } from 'react'
import _ from 'lodash'
import TemplatesSideBar from 'components/templates/TemplatesSideBar'
import { HStack, Flex } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import CreateTemplateModal, {
  ICreateTemplateModal
} from 'modals/CreateTemplateModal'
import CreateTemplateSectionModal, {
  ICreateTemplateSectionModal
} from 'modals/CreateTemplateSectionModal'
import { TemplateSectionT, QuestionT, DictT } from 'shared/types/model'
import TemplateQuestions from 'components/templates/TemplateQuestions'
import TemplateQuestionPreview from 'components/templates/TemplateQuestionPreview'

const SettingsTemplates = () => {
  const isFacefileAdmin = useSelector(state => state.account.isFaceFileAdmin)
  const templates = useSelector(state => state.templates)
  const createTemplateModalRef = useRef<ICreateTemplateModal>(null)
  const templatesSections = useSelector(state => state.templatesSections)
  const createTemplateSectionModalRef = useRef<ICreateTemplateSectionModal>(
    null
  )
  const [currentTemplateId, setCurrentTemplateId] = useState<string>()
  const [currentQuestionId, setCurrentQuestionId] = useState<string>()
  const questionsDict: DictT<QuestionT> = useSelector(
    state => state.questionsLibrary
  )

  const currentQuestion = useMemo(
    () => (currentQuestionId ? questionsDict[currentQuestionId] : undefined),
    [questionsDict, currentQuestionId]
  )

  const onCreateTemplateClick = (sectionId: string) => {
    createTemplateModalRef.current?.open(sectionId)
  }

  useEffect(() => {
    const t = currentTemplateId && templates && templates[currentTemplateId]
    const needSwitchTemplate = _.isEmpty(currentTemplateId) || !t
    const needResetQuestion =
      currentQuestionId && t && !_.includes(t.questions, currentQuestionId)
    if (needResetQuestion) {
      setCurrentQuestionId(undefined)
    }
    if (needSwitchTemplate) {
      _.forEach(templatesSections.order, sectionId => {
        const section = templatesSections.sections[sectionId]
        if (section && !_.isEmpty(section.templatesOrder)) {
          setCurrentTemplateId(section.templatesOrder[0])
          setCurrentQuestionId(undefined)
          return false
        }
      })
    }
  }, [currentTemplateId, currentQuestionId, templatesSections, templates])

  const onEditTemplateClick = (
    sectionId: string,
    templateId: string,
    templateName: string
  ) => {
    createTemplateModalRef.current?.open(sectionId, templateId, templateName)
  }

  const onCreateTemplateSectionClick = () => {
    createTemplateSectionModalRef.current?.open()
  }

  const onEditSectionClick = (s: TemplateSectionT) => {
    createTemplateSectionModalRef.current?.open(s)
  }

  const onTemplateClick = (templateId: string) => {
    if (templates) {
      const t = templates[templateId]
      setCurrentQuestionId(_.get(t, ['questoins', 0]))
      setCurrentTemplateId(templateId)
    }
  }

  const onQuestionClick = (questionId: string) => {
    setCurrentQuestionId(questionId)
  }

  return (
    <HStack borderLeftWidth={1} w='full' spacing={0} align='start' h='full' overflowY='auto'>
      <TemplatesSideBar
        canEdit={isFacefileAdmin}
        onCreateTemplateClick={onCreateTemplateClick}
        onEditTemplateClick={onEditTemplateClick}
        onEditSectionClick={onEditSectionClick}
        onCreateTemplateSectionClick={onCreateTemplateSectionClick}
        onTemplateClick={onTemplateClick}
        currentTemplateId={currentTemplateId}
      />
      <TemplateQuestions
        templateId={currentTemplateId}
        canEdit={isFacefileAdmin}
        onQuestionClick={onQuestionClick}
      />
      <Flex w='30%' h='full'>
        <TemplateQuestionPreview
          text={currentQuestion?.text}
          mediaUrl={currentQuestion?.mediaUrl}
        />
      </Flex>
      <CreateTemplateModal ref={createTemplateModalRef} />
      <CreateTemplateSectionModal ref={createTemplateSectionModalRef} />
    </HStack>
  )
}

export default SettingsTemplates
