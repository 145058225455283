import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  setDoc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { User } from 'firebase/auth'

import { auth, db, generateId } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveCFQs } from 'model/actions/cfqsAC'
import { CFQT } from 'shared/types/model'

export const fetchCFQs = async (accountId: string): Promise<any> => {
  try {
    const q = query(
      collection(db, 'cfqs'),
      where('accountId', '==', accountId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveCFQs(res))
      },
      err => {
        console.log(`fetchCFQs error: ${err.message}`)
      }
    )
    addListener('cfqs', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchCFQs error', e)
  }
}

export const dbCreateCFQ = async (
  cfq: Partial<CFQT>
) => {
  try {
    const state = store.getState()
    const accountId = state.account.id
    const authUser: User | null = auth.currentUser
    if (authUser) {
      const createdBy = authUser.uid
      const dbCfq: CFQT = {
        id: cfq.id || generateId(),
        accountId,
        createdAt: _.now(),
        createdBy,
        deleted: 0,
        ..._.omitBy(cfq, _.isNil)
      }
      const ref = doc(collection(db, 'cfqs'), cfq.id)
      await setDoc(ref, dbCfq)
    } else {
      Sentry.captureException(
        new Error('cannot create cfq, auth.currentUser is undefined')
      )
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}
