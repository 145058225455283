import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Select,
  OptionBase,
  GroupBase,
  SingleValue,
  ChakraStylesConfig,
  Size
} from 'chakra-react-select'

export interface OptionT extends OptionBase {
  label: string
  value: string
}

type Props = {
  options: OptionT[]
  value?: string
  onSelect: (v: string) => void
  placeholder?: string
  chakraStyles?: ChakraStylesConfig<OptionT, false, GroupBase<OptionT>>
  size?: Size
}

const SingleSelect: FC<Props> = ({
  options,
  value,
  onSelect,
  placeholder,
  chakraStyles = {},
  size = 'md'
}) => {
  const valueOpt = useMemo(() => {
    return _.find(options, opt => opt.value === value)
  }, [options, value])

  const onChange = (opt: SingleValue<OptionT>) => {
    if (opt) {
      onSelect(opt.value)
    } else {
      // onSelect(null)
    }
  }

  return (
    <Select<OptionT, false, GroupBase<OptionT>>
      useBasicStyles
      colorScheme='white'
      closeMenuOnSelect
      selectedOptionStyle='check'
      hideSelectedOptions={false}
      value={valueOpt}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      focusBorderColor='gray.200'
      size={size}
      chakraStyles={{
        menu: provided => ({
          ...provided,
          zIndex: 1000,
          width: 'full',
          overflow: 'visible'
        }),
        container: provided => ({
          ...provided,
          bgColor: 'white',
          width: 'full',
          borderColor: 'gray.200'
        }),
        control: provided => ({
          ...provided,
          boxShadow: 'none !important'
        }),
        ...chakraStyles
      }}
    />
  )
}

export default SingleSelect
