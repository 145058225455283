import { FC, useRef } from 'react'
import {
  Avatar,
  Box,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Heading,
  Show,
  VStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faEdit,
  faEllipsisH
} from '@fortawesome/pro-light-svg-icons'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import EditContactModal, { IEditContactModal } from 'modals/EditContactModal'
import RemoveContactModal, {
  IRemoveContactModal
} from 'modals/RemoveContactModal'
import { ContactT } from 'shared/types/model'
import BackButtonMobile from 'components/BackButtonMobile'

type Props = {
  contact: ContactT
}

const ContactHeader: FC<Props> = ({ contact }) => {
  const editContactRef = useRef<IEditContactModal>(null)
  const removeContactRef = useRef<IRemoveContactModal>(null)
  const navigate = useNavigate()

  const onEditClick = () => {
    editContactRef.current?.open(contact.name, contact.email)
  }

  const onRemoveClick = (): void => {
    removeContactRef.current?.open(contact.email)
  }

  const goBack = () => {
    navigate('/contacts')
  }

  const menu = (
    <Menu>
      <MenuButton as={Button} variant='ghost' size={{ base: 'sm', lg: 'md' }}>
        <FontAwesomeIcon icon={faEllipsisH} />
      </MenuButton>
      <MenuList zIndex={1000}>
        <MenuItem onClick={onEditClick}>
          <Text color='gray.700' fontWeight='semibold'>
            Edit contact details
          </Text>
        </MenuItem>
        <MenuItem onClick={onRemoveClick}>
          <Text color='red.500' fontWeight='semibold'>
            Delete contact
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )

  return (
    <VStack w='full' bg='white' align='flex-start' py={{ base: 4, lg: 0 }}>
      <Show below='lg'>
        <HStack px={4} bg='white' justify={'space-between'} w='full'>
          <BackButtonMobile onClick={goBack} />
        </HStack>
      </Show>
      <HStack
        pl={{ base: 4, lg: 2 }}
        pr={4}
        bg='white'
        align='center'
        w='full'
        h={{ base: 'auto', lg: 28 }}
        borderLeftWidth={1}
        borderLeftColor='gray.50'
        flexShrink={0}
        spacing={4}
      >
        <HStack spacing={4} align='center' w={{ base: 'full', lg: 'auto' }}>
          <Show above='lg'>
            <Box>
              <Button variant='unstyled' onClick={goBack}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </Box>
          </Show>
          <Show above='lg'>
            <Avatar src={_.get(contact, 'avatar')} borderRadius='0.5rem' />
          </Show>
          <Box>
            <Heading size='md'>{_.get(contact, 'name', 'Human')}</Heading>
          </Box>
        </HStack>
        <HStack align='center' spacing={2}>
          <Button
            variant='outline'
            onClick={onEditClick}
            size={{ base: 'sm', lg: 'md' }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          {menu}
        </HStack>
        <EditContactModal ref={editContactRef} />
        <RemoveContactModal ref={removeContactRef} />
      </HStack>
    </VStack>
  )
}

export default ContactHeader
