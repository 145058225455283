import {
  query,
  collection,
  onSnapshot,
  where,
  getDocs,
  writeBatch,
  doc,
  updateDoc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import moment from 'moment'

import { db } from 'controllers/db'
import store from 'model/store'
import { receiveResponses } from 'model/actions/responsesAC'
import { addListener } from 'controllers/listeners'
import { AnswerT, DictT, ResponseT } from 'shared/types/model'
import { logResponseDeleted } from 'controllers/analytics'
import { getRequestHeaders } from 'controllers/headers'

export const fetchResponses = async (accountId: string): Promise<any> => {
  try {
    const q = query(
      collection(db, 'responses'),
      where('accountId', '==', accountId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveResponses(res))

        const isLocalHost =
          location.hostname === 'localhost' || location.hostname === '127.0.0.1'
        if (!isLocalHost) {
          const heap = _.get(window, 'heap')
          if (!_.isNil(heap)) {
            console.log('triggering heap')
            heap.addUserProperties({
              responsesAllTime: _.sum(_.map(res, (r: ResponseT) => _.size(r.answersIds)))
            })
          }
        }
      },
      err => {
        console.log(`fetchContacts error: ${err.message}`)
      }
    )
    addListener('responses', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchContacts error', e)
  }
}

export const dbFetchAnswers = async (responseId: string) => {
  try {
    console.log('dbFetchAnswers of responseId', responseId)
    const q = query(
      collection(db, 'answers'),
      where('responseId', '==', responseId)
    )
    const answersSN = await getDocs(q)
    const res: DictT<AnswerT> = {}
    answersSN.forEach(doc => {
      const a = doc.data() as AnswerT
      res[a.id] = a
    })
    console.log('answers received', res)
    return res
  } catch (e) {
    console.warn('cannot fetch answers', responseId, 'error', e)
    Sentry.captureException(e)
    return {}
  }
}

export const updateResponsesNameEmail = async (
  ids: string[],
  name: string,
  email: string
) => {
  try {
    const batch = writeBatch(db)
    _.forEach(ids, responseId => {
      const ref = doc(db, 'responses', responseId)
      const upd = { name, email }
      batch.update(ref, upd)
    })
    await batch.commit()
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteResponse = async (responseId: string) => {
  try {
    const ref = doc(db, 'responses', responseId)
    await updateDoc(ref, { deleted: _.now() })
    logResponseDeleted(responseId)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteResponses = async (responseIds: string[]) => {
  try {
    const batch = writeBatch(db)
    const tNow = _.now()
    _.forEach(responseIds, rId => {
      batch.update(doc(db, 'responses', rId), { deleted: tNow })
      logResponseDeleted(rId)
    })
    await batch.commit()
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchResponse = async (responseId: string) => {
  try {
    const url = `${process.env
      .REACT_APP_DYNAMIC_URL as string}/p/response/${responseId}`
    const res = await fetch(url, {
      method: 'GET'
    })
    if (res.ok) {
      return await res.json()
    } else {
      return null
    }
  } catch (e) {
    Sentry.captureException(e)
    return null
  }
}

export const fetchResponseAnswersUrls = async (response: ResponseT, formName?: string) => {
  try {
    const responseId = _.get(response, 'id')
    const answers = await dbFetchAnswers(responseId)
    const answersIds = _.get(response, 'answersIds')
    const answersUrls = _.map(answersIds, id => _.get(answers, [id, 'mediaUrl']))
    const url = `${process.env.REACT_APP_DYNAMIC_URL as string}/account/get-responses-combined`
    const headers = await getRequestHeaders()
    const result = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        answersUrls,
        responseId
      })
    })
    if (result.status === 200) {
      const data = await result.blob()
      const blob = new Blob([data], { type: 'video/mp4' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${formName ? `${formName}-` : 'facefile-responses-'}${moment().format()}.mp4`
      link.click()
      return true
    } else {
      return false
    }
  } catch (e) {
    Sentry.captureException(e)
    return false
  }
}
