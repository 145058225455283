import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useMemo,
  useEffect
} from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import _ from 'lodash'

import { useSelector } from 'model/hooks'
import AddQuestionModal, { IAddQuestionModal } from 'modals/AddQuestionModal'
import { CFQT, DictT } from 'shared/types/model'
import { generateId } from 'controllers/db'
import { dbCreateCFQ } from 'controllers/cfqs'
import { dbCreateForm } from 'controllers/forms'
import Questions from 'modals/questionsAndCFQ/Questions'
import CFQs from 'modals/questionsAndCFQ/CFQs'
import CreateQuestionModal, {
  ICreateQuestionModal
} from 'modals/CreateQuestionModal'
import { dbCreateQuestion } from 'controllers/questions'
import {
  logCreateCustomQuestion
} from 'controllers/analytics'
import { getAllQuestions } from 'model/selectors/questions'

export interface IQuestionsAndCFQModal {
  open: ({ numberOfCfqs }: { numberOfCfqs: number }) => void
}

type Props = {}

const QuestionsAndCFQModal: ForwardRefRenderFunction<
  IQuestionsAndCFQModal,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const questions = useSelector(getAllQuestions)
  const accountId = useSelector(state => state.account.id)
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])
  const addQuestionModalRef = useRef<IAddQuestionModal>(null)
  const createQuestionModalRef = useRef<ICreateQuestionModal>(null)
  const dbCfqs = useSelector(state => state.cfqs)
  const [cfqsIds, setCfqsIds] = useState<string[]>([])
  const [cfqsLibrary, setCfqsLibrary] = useState<DictT<Partial<CFQT>>>({})
  const [loading, setLoading] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    open: ({ numberOfCfqs }: { numberOfCfqs: number }) => {
      const newCfqs: string[] = []
      _.times(numberOfCfqs, () => {
        const id = generateId()
        newCfqs.push(id)
      })
      setCfqsIds(newCfqs)
      onOpen()
    }
  }))

  useEffect(() => {
    setCfqsLibrary({ ...cfqsLibrary, ...dbCfqs })
  }, [dbCfqs])

  const cfqsWithData = useMemo(() => {
    return _.reduce(cfqsIds, (res, v) => {
      return { ...res, [v]: _.get(cfqsLibrary, v, { id: v }) }
    }, {})
  }, [cfqsLibrary, cfqsIds])

  const onSubmit = async () => {
    const id = generateId()
    setLoading(true)
    await Promise.all([
      ..._.map(cfqsIds, async id => {
        if (!_.has(dbCfqs, id)) {
          const cfq = _.get(cfqsLibrary, id)
          await dbCreateCFQ(cfq)
        }
      }),
      dbCreateForm(id, { questions: selectedQuestions, cfqsIds })
    ])
    setLoading(false)
    handleClose()
  }

  const onQuestionSelect = (qIds: string[]) => {
    setSelectedQuestions(qIds)
  }

  const onRemoveQuestion = (qId?: string) => {
    if (qId) setSelectedQuestions([..._.pull(selectedQuestions, qId)])
  }

  const openAddQuestionModal = () => {
    addQuestionModalRef.current?.open(selectedQuestions)
  }

  const openCreateQuestionModal = () => {
    createQuestionModalRef.current?.open()
  }

  const addCfq = () => {
    const id = generateId()
    setCfqsIds([...cfqsIds, id])
  }

  const updateCfq = (id: string, cfq: Partial<CFQT>) => {
    if (id && !_.isNil(cfq)) setCfqsLibrary({ ...cfqsLibrary, [id]: { ...cfq } })
  }

  const removeCfq = (id?: string) => {
    if (id) {
      setCfqsIds([..._.pull(cfqsIds, id)])
    }
  }

  const handleClose = () => {
    setCfqsIds([])
    setSelectedQuestions([])
    onClose()
  }

  const onCreateQuestion = async (text: string) => {
    console.log('onCreateQuestion', text)
    const qId = await dbCreateQuestion(text, accountId)
    console.log(qId)
    if (!_.isNil(qId)) {
      addQuestionModalRef.current?.update([...selectedQuestions, qId])
      logCreateCustomQuestion(qId)
    }
  }

  const submitDisabled = useMemo(
    () =>
      _.isEmpty(selectedQuestions) ||
      _.some(cfqsIds, c => _.isEmpty(_.get(cfqsLibrary, [c, 'condition'])) || _.isEmpty(_.get(cfqsLibrary, [c, 'question']))) ||
      loading,
    [selectedQuestions, cfqsWithData, loading]
  )

  const questionsText = useMemo(() => _.map(selectedQuestions, id => _.get(questions, id)), [selectedQuestions, questions])

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size='5xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalBody pt='0' px={4}>
            <Questions
              questions={questions}
              selectedQuestions={selectedQuestions}
              onRemoveQuestion={onRemoveQuestion}
              openAddQuestionModal={openAddQuestionModal}
              openCreateQuestionModal={openCreateQuestionModal}
            />
            <CFQs
              questions={questionsText}
              cfqs={cfqsWithData}
              addCfq={addCfq}
              updateCfq={updateCfq}
              removeCfq={removeCfq}
            />
          </ModalBody>
          <ModalFooter justifyContent='flex-start' pt={8}>
            <HStack justify='flex-end' w='full'>
              <Button
                variant='ghost'
                fontSize='md'
                lineHeight='6'
                fontWeight='semibold'
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme='teal'
                onClick={onSubmit}
                disabled={submitDisabled}
                isLoading={loading}
              >
                Create form
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddQuestionModal
        ref={addQuestionModalRef}
        onSelect={onQuestionSelect}
        openCreateQuestionModal={openCreateQuestionModal}
       />
      <CreateQuestionModal
        onCreateQuestion={onCreateQuestion}
        ref={createQuestionModalRef}
      />
    </>
  )
}

export default forwardRef(QuestionsAndCFQModal)
