import { FC, SyntheticEvent } from 'react'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Tooltip,
  HStack,
  Image,
  VStack,
  Tag,
  Show,
  useBreakpointValue,
  Stack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faTrashCan,
  faArrowRightArrowLeft,
  faClock,
  faArrowUp,
  faArrowDown
} from '@fortawesome/pro-regular-svg-icons'
import PlayButton from 'components/mediaPreview/PlayButton'
import { useSelector } from 'model/hooks'
import _ from 'lodash'
import DynamicWrap from 'shared/components/DynamicWrap'
import CardMobileMenu from 'components/card/CardMobileMenu'

type Props = {
  onPlay: () => void
  onRemove?: () => void
  onMoveLeft?: () => void
  onMoveRight?: () => void
  onReplace?: () => void
  name: string
  thumbnail?: string
  disableMoveLeft?: boolean
  disableMoveRight?: boolean
  hideButtons?: boolean
  isSelected: boolean
  canSelect: boolean
  tags?: string[]
  stepNumber?: number
  stepsAmount?: number
}

const Card: FC<Props> = ({
  onPlay,
  onRemove = () => null,
  onMoveLeft = () => null,
  onMoveRight = () => null,
  onReplace = () => null,
  name,
  thumbnail,
  disableMoveLeft = false,
  disableMoveRight = false,
  hideButtons = false,
  isSelected,
  canSelect,
  tags,
  stepNumber,
  stepsAmount
}) => {
  const templatesTags = useSelector(state => state.templatesTags)
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const onCardClick = (e: SyntheticEvent) => {
    console.log('onCardClick')
    if (canSelect) {
      e.stopPropagation()
      onPlay()
    }
  }

  const renderButtons = () => {
    return (
      <Flex
        w={{ base: 8, lg: 'full' }}
        align='center'
        justify='space-between'
        px={{ lg: '2' }}
        pb={{ base: 0, lg: 2 }}
        position={{ base: 'relative', lg: 'absolute' }}
        bottom={0}
        left={0}
        color={{ base: 'gray.800', lg: 'gray.500' }}
      >
        <Stack spacing={0} direction={{ base: 'column', lg: 'row' }}>
          {!disableMoveLeft && (
            <Tooltip hasArrow label={'Move question left'} placement='top'>
              <IconButton
                variant='ghost'
                size='sm'
                aria-label='Move left'
                icon={
                  <FontAwesomeIcon icon={isDesktop ? faArrowLeft : faArrowUp} />
                }
                visibility={{
                  base: 'visible',
                  lg: 'hidden'
                }}
                _groupHover={{ visibility: 'visible' }}
                isDisabled={disableMoveLeft}
                onClick={onMoveLeft}
              />
            </Tooltip>
          )}
          {!disableMoveRight && (
            <Tooltip hasArrow label={'Move question right'} placement='top'>
              <IconButton
                variant='ghost'
                size='sm'
                aria-label='Move right'
                icon={
                  <FontAwesomeIcon
                    icon={isDesktop ? faArrowRight : faArrowDown}
                  />
                }
                visibility={{
                  base: 'visible',
                  lg: 'hidden'
                }}
                _groupHover={{ visibility: 'visible' }}
                isDisabled={disableMoveRight}
                onClick={onMoveRight}
              />
            </Tooltip>
          )}
        </Stack>
        <Show above='lg'>
          <HStack spacing={0}>
            <Tooltip hasArrow label={'Replace question'} placement='top'>
              <IconButton
                variant='ghost'
                size='sm'
                aria-label='Replace question'
                icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
                visibility={{
                  base: 'visible',
                  lg: 'hidden'
                }}
                _groupHover={{ visibility: 'visible' }}
                onClick={onReplace}
              />
            </Tooltip>

            <Tooltip hasArrow label={'Remove question'} placement='top'>
              <IconButton
                variant='ghost'
                size='sm'
                aria-label='Remove question'
                icon={<FontAwesomeIcon icon={faTrashCan} />}
                visibility='hidden'
                _groupHover={{ visibility: 'visible' }}
                onClick={onRemove}
              />
            </Tooltip>
          </HStack>
        </Show>
      </Flex>
    )
  }

  const renderImage = () => {
    if (thumbnail) {
      return (
        <Box w='full' h='110px' as='button' onClick={onCardClick}>
          <Image
            src={thumbnail}
            w='full'
            h='110px'
            borderTopRadius={'lg'}
            objectFit='cover'
          />
        </Box>
      )
    } else {
      return (
        <VStack
          w='full'
          h='110px'
          color='gray.300'
          flexShrink={0}
          align='center'
          justify={'center'}
        >
          <FontAwesomeIcon icon={faClock} fontSize='2rem' />
          <Text
            fontSize='xs'
            fontWeight='light'
            fontStyle='italic'
            textAlign='center'
            color='gray.500'
            mt='7'
          >
            Your video is being rendered, you will receive a notification when
            it is ready for use.
          </Text>
        </VStack>
      )
    }
  }

  const renderPlayButton = () => {
    return (
      <Box
        w='full'
        position={'absolute'}
        top={'28'}
        visibility='hidden'
        _groupHover={{ visibility: isSelected ? 'hidden' : 'visible' }}
      >
        <PlayButton onClick={onCardClick} />
      </Box>
    )
  }

  const renderTags = () => {
    if (tags && !_.isEmpty(tags)) {
      return (
        <DynamicWrap
          wrapProps={{
            position: 'absolute',
            maxH: 10,
            bottom: 0,
            left: 0,
            w: 'full',
            px: 4,
            pb: 2,
            spacingX: 2,
            spacingY: 1,
            align: 'flex-end'
          }}
          elements={_.map(tags, (tagId: string) => {
            const label = templatesTags[tagId]
            return (
              <Tag colorScheme={'blackAlpha'} size='sm' minH={4} rounded='sm'>
                {label}
              </Tag>
            )
          })}
        />
      )
    }
  }

  const renderMobileMenu = () => {
    return (
      <Show below='lg'>
        <CardMobileMenu
          onReplace={onReplace}
          onMoveLeft={disableMoveLeft ? undefined : onMoveLeft}
          onMoveRight={disableMoveRight ? undefined : onMoveRight}
          onRemove={onRemove}
        />
      </Show>
    )
  }

  return (
    <Stack
      spacing={'0'}
      py={{ base: 2, lg: 0 }}
      w={{ base: 'full', lg: '310px' }}
      h={{ base: undefined, lg: '270px' }}
      borderRadius={{ base: undefined, lg: 'lg' }}
      boxShadow={{ base: undefined, lg: 'base' }}
      // bg='white'
      _hover={{ bg: { base: undefined, lg: 'gray.100' } }}
      role='group'
      position='relative'
      className='question_card'
      direction={{ base: 'row', lg: 'column' }}
      align='center'
      borderTopWidth={{ base: 1, lg: 0 }}
      justify={{ base: 'space-between', lg: 'center' }}
    >
      <Stack
        spacing={0}
        direction={{ base: 'row', lg: 'column' }}
        w='full'
        h='full'
      >
        <Show above='lg'>{renderImage()}</Show>
        {!hideButtons && renderButtons()}
        <Flex
          px={{ base: 3, lg: '6' }}
          py='2'
          h='full'
          borderBottomRadius='lg'
          as={canSelect && isDesktop ? 'button' : undefined}
          onClick={isDesktop ? onCardClick : undefined}
          borderTopWidth={{ base: 0, lg: 1 }}
          borderTopColor={'gray.100'}
          align='center'
          // bg='yellow'
        >
          <VStack spacing={0} w='full' align='flex-start'>
            <Text
              fontSize={{ base: 'sm', lg: 'lg' }}
              fontWeight={{ base: 'medium', lg: 'semibold' }}
              textAlign={{ base: 'left', lg: 'center' }}
              noOfLines={{ base: undefined, lg: 3 }}
              color={thumbnail ? 'gray.800' : 'gray.500'}
              lineHeight={1.4}
              fontStyle={thumbnail ? 'normal' : 'italic'}
            >
              {name}
            </Text>
            <Show below='lg'>
              <Text pt={2} color='gray.500' fontSize={'xs'}>
                Step {stepNumber} of {stepsAmount}
              </Text>
            </Show>
          </VStack>
        </Flex>
      </Stack>
      <Show above='lg'>{thumbnail && renderPlayButton()}</Show>
      {renderMobileMenu()}
      {renderTags()}
    </Stack>
  )
}

export default Card
