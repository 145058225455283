import {
  ForwardRefRenderFunction,
  useState,
  forwardRef,
  useImperativeHandle,
  Ref,
  useMemo,
  useEffect
} from 'react'
import _ from 'lodash'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  VStack,
  Flex,
  HStack,
  IconButton,
  Box,
  Wrap,
  WrapItem,
  ModalFooter,
  Button,
  Divider
} from '@chakra-ui/react'
import SingleSelect, { OptionT } from 'components/SingleSelect'

import { useSelector } from 'model/hooks'
import { dbAddQuestionToTemplate } from 'controllers/templates'
import { QuestionT } from 'shared/types/model'
import VideoPlayer from 'components/VideoPlayer'
import Card from 'components/card/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons'
import QuestionsFilterTags from 'components/QuestionsFilterTags'

export interface ISelectPublicQuestionModal {
  open: (templateId?: string) => void
}

type Props = {}

const SelectPublicQuestionModal: ForwardRefRenderFunction<
  ISelectPublicQuestionModal,
  Props
> = (_props: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tId, setTId] = useState<string | null>(null)
  const [sortByField, setSortByField] = useState('createdAt')
  const [order, setOrder] = useState<string>('desc')
  const allQuestions = useSelector(state => state.questionsLibrary)
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(
    null
  )
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const templates = useSelector(state => state.templates)

  useEffect(() => {
    if (tId && templates) {
      const t = templates[tId]
      if (t) {
        const tags = t.tags || []
        setSelectedTags(tags)
      }
    }
  }, [tId, templates])

  useImperativeHandle(ref, () => ({
    open: (templateId: string) => {
      setTId(templateId)
      onOpen()
    }
  }))

  const questions = useMemo(() => {
    const res = _.filter(allQuestions, q => {
      const filtersPassed =
        _.isEmpty(selectedTags) ||
        (!_.isEmpty(q.tags) &&
          _.size(_.intersection(q.tags, selectedTags)) === _.size(selectedTags))
      return !q.deleted && filtersPassed
    })
    return _.orderBy(res, [sortByField], [order as 'asc' | 'desc'])
  }, [allQuestions, sortByField, order, selectedTags])

  const currentQuestion: QuestionT | undefined =
    currentQuestionId && allQuestions[currentQuestionId]

  const onDone = () => {
    if (currentQuestionId && tId) {
      dbAddQuestionToTemplate(tId, currentQuestionId)
      onClose()
    }
    console.log('onDone')
  }

  const changeOrder = () => {
    setOrder(order => (order === 'asc' ? 'desc' : 'asc'))
  }

  const renderHeader = () => {
    const sortByOptions: OptionT[] = [
      { value: 'createdAt', label: 'Date created' },
      { value: 'lastUsedAt', label: 'Last used' },
      { value: 'length', label: 'Length' },
      { value: 'createdBy', label: 'Created by' }
    ]
    return (
      <HStack pr='6' w='full' justify='space-between' flexGrow={0}>
        <HStack align='center' spacing={0} justify='flex-start' pl={2}>
          <IconButton
            variant={'unstyled'}
            color='gray.400'
            aria-label='sorting order'
            icon={
              <FontAwesomeIcon
                icon={order === 'asc' ? faArrowUp : faArrowDown}
                onClick={changeOrder}
              />
            }
          />
          <Box w={40}>
            <SingleSelect
              size='sm'
              options={sortByOptions}
              value={sortByField}
              onSelect={v => setSortByField(v)}
              chakraStyles={{
                singleValue: provided => ({
                  ...provided,
                  color: 'blackAlpha.600'
                }),
                downChevron: provided => ({
                  ...provided,
                  color: 'gray.400'
                }),
                control: provided => ({
                  ...provided,
                  borderColor: 'gray.200',
                  rounded: 'md'
                })
              }}
            />
          </Box>
          <HStack pl={4} spacing={4} zIndex={102}>
            <Divider h={6} orientation='vertical' color={'gray.50'} />
            <QuestionsFilterTags
              selectedTags={selectedTags}
              setSelectedTags={tagsIds => setSelectedTags(tagsIds)}
            />
          </HStack>
        </HStack>
      </HStack>
    )
  }

  const handleClick = (id: string | null) => {
    setCurrentQuestionId(id)
  }

  const renderQuestions = (
    <Wrap
      w='100%'
      maxH='682px'
      overflowY='auto'
      pt={4}
      px='5'
      spacing='18px'
      sx={{
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      {_.map(questions, (q: QuestionT) => {
        return (
          <WrapItem
            key={q.id}
            display='flex'
            borderRadius='lg'
            borderWidth={3}
            borderColor={
              q.id === currentQuestionId ? 'blue.400' : 'transparent'
            }
          >
            <Card
              onPlay={() => handleClick(q.id)}
              name={q.text}
              thumbnail={q.thumbnail}
              hideButtons
              isSelected={q.id === currentQuestionId}
              canSelect
              tags={q.tags}
            />
          </WrapItem>
        )
      })}
    </Wrap>
  )

  const renderMedaiPreview = () => {
    if (currentQuestion && currentQuestion.mediaUrl) {
      return (
        <Box w='full' h='full' position={'relative'}>
          <VideoPlayer
            mediaUrl={currentQuestion.mediaUrl}
            key={currentQuestion.mediaUrl}
          />
        </Box>
      )
    } else {
      return (
        <Box
          w='full'
          h='full'
          border='1px solid'
          borderStyle='dashed'
          borderRadius='10'
          borderColor='gray.300'
        />
      )
    }
  }

  const renderBody = () => {
    if (tId) {
      return (
        <ModalBody p='0' w='full' h='full%'>
          <Flex direction='row' w='full' h='full' borderTopRadius={'lg'}>
            <VStack
              bg='white'
              w='full'
              h='full'
              pt='4'
              borderTopRadius={'lg'}
              // borderRight='2px solid'
              // borderColor='gray.100'
            >
              {renderHeader()}
              {renderQuestions}
            </VStack>
            <Flex
              bg='gray.50'
              w={'48%'}
              h='full'
              px={10}
              pt={90}
              pb={154}
              borderTopRadius={'lg'}
            >
              <Box w='100%' h='full'>
                {renderMedaiPreview()}
              </Box>
            </Flex>
          </Flex>
        </ModalBody>
      )
    }
  }

  const footer = (
    <ModalFooter
      px='19'
      py='16px'
      bg='white'
      justifyContent='space-between'
      borderTop='2px solid'
      borderColor='gray.100'
      borderBottomRadius={'lg'}
    >
      <Flex gap='4' alignItems='center'>
        <Button
          fontSize='md'
          lineHeight='6'
          fontWeight='semibold'
          color='gray.700'
          variant='ghost'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          fontSize='lg'
          size='lg'
          lineHeight='7'
          fontWeight='semibold'
          colorScheme='blue'
          disabled={_.isNil(currentQuestionId)}
          onClick={onDone}
        >
          Add question
        </Button>
      </Flex>
    </ModalFooter>
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl' autoFocus={false}>
      <ModalOverlay />
      <ModalContent h='80%' maxH='800px'>
        {renderBody()}
        {footer}
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(SelectPublicQuestionModal)
