import { FC } from 'react'
import {
  VStack,
  Heading,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react'
import AIModelSelect from 'components/settings/AIModelSelect'

type Props = {
  model: string
  setModel: (model: string) => void
  temperature: string
  setTemperature: (t: string) => void
  maxTokens: string
  setMaxTokens: (v: string) => void
}

const AISettings: FC<Props> = ({
  model,
  setModel,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens
}) => {
  return (
    <VStack w='full' align='flex-start'>
      <Heading>AI Settings</Heading>
      <AIModelSelect model={model} setModel={setModel} />
      <FormControl>
        <FormLabel>Temperature</FormLabel>
        <NumberInput
          value={temperature}
          onChange={str => setTemperature(str)}
          min={0}
          max={1}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
      <FormControl>
        <FormLabel>Max tokens</FormLabel>
        <NumberInput
          value={maxTokens}
          onChange={str => setMaxTokens(str)}
          min={0}
          max={2048}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>
    </VStack>
  )
}

export default AISettings
