import {
  Image,
  HStack,
  Text,
  VStack,
  Box,
  IconButton,
  Button,
  Collapse
} from '@chakra-ui/react'
import { faCaretRight, faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useState } from 'react'
import { AnswerT, QuestionT } from 'shared/types/model'

type Props = {
  a: AnswerT
  q: QuestionT
  num: number
  onSelect: (answerId: string) => void
  isSelected: boolean
}

const AnswerRow: FC<Props> = ({ a, q, num, onSelect, isSelected }) => {
  const [analisysOpen, setAnalysisOpen] = useState(false)

  const secondsToString = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const thumbnail = (
    <Box
      w='28'
      h='36'
      rounded='base'
      position={'relative'}
      as='button'
      onClick={() => onSelect(a.id)}
    >
      <Image
        w='28'
        h='36'
        rounded='base'
        src={a.thumbnail}
        objectFit={'cover'}
      />
      <VStack
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
        // justify={'space-between'}
      >
        <HStack
          justify={'flex-end'}
          w='full'
          pt={1}
          pr={2}
          flex={1}
          align={'flex-start'}
        >
          <Text
            fontSize='xs'
            // color='whiteAlpha.700'
            color='white'
            textShadow='rgba(0, 0, 0, 0.9) 0px 0px 2px'
          >
            {secondsToString(Math.floor(a.duration / 100))}
          </Text>
        </HStack>
        <HStack flex={1}>
          <IconButton
            rounded={'full'}
            aria-label='play'
            opacity={0.8}
            bg='gray.50'
            color='gray.700'
            icon={<FontAwesomeIcon icon={faPlay} />}
          />
        </HStack>
        <HStack pb={2} flex={1} align={'flex-end'}>
          <Box
            bg='whiteAlpha.500'
            borderColor={'blackAlpha.500'}
            rounded={'full'}
            borderWidth={1}
            px={3}
            py={'2px'}
          >
            <Text
              color='blackAlpha.700'
              fontWeight={'medium'}
              fontSize={'10px'}
            >
              Question {num}
            </Text>
          </Box>
        </HStack>
      </VStack>
    </Box>
  )

  const qa = (
    <VStack pl={5} align='flex-start' spacing={3} w='full'>
      <Text color='gray.900' fontWeight={'medium'} fontSize={'sm'}>
        {q.text}
      </Text>
      <Text
        color='gray.500'
        fontWeight={'normal'}
        fontSize={'xs'}
        fontStyle={'italic'}
      >
        {a.transcription}
      </Text>
      {a.evaluation && (
        <VStack
          align={'flex-start'}
          bg={analisysOpen ? 'gray.100' : 'transparent'}
          w='full'
          rounded={'lg'}
          spacing={0}
        >
          <Button
            variant={'ghost'}
            size='sm'
            fontWeight={'medium'}
            px={2}
            sx={{
              svg: {
                transitionProperty: 'transform',
                transitionDuration: '200ms'
              }
            }}
            leftIcon={
              <FontAwesomeIcon
                icon={faCaretRight}
                fontSize={'1.4em'}
                rotation={analisysOpen ? 90 : undefined}
              />
            }
            onClick={() => setAnalysisOpen(open => !open)}
          >
            AI Analysis
          </Button>
          <Collapse in={analisysOpen} animateOpacity>
            <Box px='7' py={2}>
              <Text color='gray.500' fontSize={'xs'} fontStyle={'italic'}>
                {a.evaluation}
              </Text>
            </Box>
          </Collapse>
        </VStack>
      )}
    </VStack>
  )

  return (
    <HStack
      w='full'
      align='flex-start'
      pl={'10'}
      bg={isSelected ? 'gray.100' : 'white'}
    >
      {thumbnail}
      {qa}
    </HStack>
  )
}

export default AnswerRow
