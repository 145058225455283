import { ForwardRefRenderFunction, forwardRef, Ref, useImperativeHandle, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import SettingsSidebar from 'modals/settingsModal/SettingsSidebar'
import SettingsBody from 'modals/settingsModal/SettingsBody'

export interface ISettingsModal {
  open: () => void
}

type Props = {}

const SettingsModal: ForwardRefRenderFunction<ISettingsModal, Props> = (_, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTab, setSelectedTab] = useState(0)
  useImperativeHandle(ref, () => ({
    open: () => {
      handleOpen()
    }
  }))

  const handleOpen = () => {
    onOpen()
  }

  const handleClose = () => {
    setSelectedTab(0)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='6xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalBody h='100%' p='0' rounded='lg'>
          <HStack h='fit-content' w='full'>
            <SettingsSidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <SettingsBody selectedTab={selectedTab} />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(SettingsModal)
