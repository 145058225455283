import { createSelector } from '@reduxjs/toolkit'
import { getResponses, getSubscription } from 'model/selectors/base'
import _ from 'lodash'
import { ContactT, DictT, ResponseT } from 'shared/types/model'

export const getAllResponses = createSelector([getResponses], responses => {
  const filteredResponses = _.filter(responses, r => r.deleted === 0)
  return _.orderBy(filteredResponses, 'createdAt', 'desc')
})

export const getResponsesByEmail = createSelector(
  [getAllResponses],
  responses => {
    const res: DictT<ResponseT[]> = {}
    const responsesGroups = _.groupBy(responses, 'email')
    _.forEach(responsesGroups, (respAr: ResponseT[], email: string) => {
      res[email] = _.orderBy(respAr, 'createdAt', 'desc')
    })
    return res
  }
)

export const getResponsesByEmailWithDeleted = createSelector(
  [getResponses],
  responses => {
    const res: DictT<ResponseT[]> = {}
    const responsesGroups = _.groupBy(_.values(responses), 'email')
    _.forEach(responsesGroups, (respAr: ResponseT[], email: string) => {
      res[email] = _.orderBy(respAr, 'createdAt', 'desc')
    })
    return res
  }
)

export const getContacts = createSelector(
  [getResponsesByEmail],
  responsesByEmail => {
    const res: ContactT[] = []
    _.forEach(responsesByEmail, (respAr, email: string) => {
      const lastResponse = _.last(respAr)
      const firstResponse = _.first(respAr)
      if (firstResponse && lastResponse && email !== 'undefined') {
        const c: ContactT = {
          name: _.isEmpty(lastResponse.name) ? 'Human' : lastResponse.name,
          email: email === 'undefined' ? '' : email,
          phone: firstResponse.phone,
          thumbnail: firstResponse.thumbnail,
          lastResponseId: lastResponse.id
        }
        res.push(c)
      }
    })
    console.log('getContacts res', res)
    return _.orderBy(res, 'name')
  }
)

export const getContactsByEmail = createSelector([getContacts], contactsAr => {
  return _.keyBy(contactsAr, 'email')
})

export const getResponsesByFormId = createSelector(
  [getResponses],
  responses => {
    const res: DictT<ResponseT[]> = {}
    const responsesGroups = _.groupBy(_.values(responses), 'formId')
    _.forEach(responsesGroups, (respAr: ResponseT[], email: string) => {
      res[email] = _.orderBy(respAr, 'createdAt', 'desc')
    })
    return res
  }
)

export const getAllAnswersAmount = createSelector([getResponses], responses => {
  return _.sum(_.map(responses, r => _.size(r.answersIds)))
})

export const getAnswersUsed = createSelector(
  [getResponses, getSubscription, getAllAnswersAmount],
  (responses, subscription, allAnswersAmount) => {
    if (_.isNil(subscription)) {
      return allAnswersAmount
    } else {
      return _.reduce(
        responses,
        (res, r) => {
          if (r.createdAt > subscription.currentPeriodStart) {
            return res + _.size(r.answersIds)
          } else {
            return res
          }
        },
        0
      )
    }
  }
)
