import * as types from 'model/actionTypes'
import { AIConfT } from 'shared/types/model'

interface AIConfActionT {
  type: string
  aiConf: AIConfT
}

export function receiveAIConf (aiConf: AIConfT): AIConfActionT {
  return {
    type: types.RECEIVE_AI_CONF,
    aiConf
  }
}
