import { FC, useMemo } from 'react'
import _ from 'lodash'
import { TemplateSectionT, TemplateT, QuestionT } from 'shared/types/model'
import {
  useDisclosure,
  VStack,
  Collapse,
  Text,
  HStack,
  Button,
  Image,
  Box,
  IconButton
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, fas } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'model/hooks'
import { getQuestionsLibrary } from 'model/selectors/base'
import { faPlay } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  t: TemplateT
  section: TemplateSectionT
  onPlayClick: (templateId: string, index: number) => void
  onUseTemplateClick: () => void
}

const TemplateRow: FC<Props> = ({
  t,
  section,
  onPlayClick,
  onUseTemplateClick
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const questions = useSelector(getQuestionsLibrary)

  console.log('t', t, section)
  const questionsAmount = _.size(t.questions)

  const contactFormIndex = useMemo(() => {
    if (t && t.questions && t.contactFormQuestionId) {
      return _.indexOf(t.questions, t.contactFormQuestionId)
    }
  }, [t])

  const renderQuestion = (q: QuestionT, index: number, amount: number) => {
    return (
      <HStack
        px={2}
        pb={4}
        pt={1}
        justify='space-between'
        w='full'
        align='flex-start'
        borderBottomWidth={1}
        borderColor='gray.200'
        spacing={4}
      >
        <Image
          w='10'
          h='12'
          objectFit='cover'
          src={_.get(q, 'thumbnail', '')}
          alt='question thumbnail'
          rounded='sm'
          fallback={<Box w='10' h='12' bgColor='blackAlpha.700' rounded='sm' />}
        />
        <HStack align='center' w='full' justify={'space-between'} spacing={4}>
          <VStack align={'flex-start'}>
            <Text fontSize='sm' color='gray.800'>
              {q.text}
            </Text>
            <Text fontSize={'xs'} color='gray.500'>
              Step {index + 1} of {amount}
            </Text>
          </VStack>
          <IconButton
            size='sm'
            variant={'outline'}
            aria-label='play question'
            icon={<FontAwesomeIcon icon={faPlay} />}
            onClick={() => onPlayClick(t.id, index)}
          />
        </HStack>
      </HStack>
    )
  }

  const renderContactForm = (index: number, amount: number) => {
    return (
      <HStack
        pl={16}
        pb={4}
        pt={1}
        justify='space-between'
        w='full'
        align='flex-start'
        borderBottomWidth={1}
        borderColor='gray.200'
        spacing={4}
      >
        <VStack align={'flex-start'}>
          <Text fontSize='sm' color='blue.500' fontWeight={'semibold'}>
            Contact form
          </Text>
          <Text fontSize={'xs'} color='gray.500'>
            Step {index + 1} of {amount}
          </Text>
        </VStack>
      </HStack>
    )
  }

  const renderQuestionsList = () => {
    if (t && questions) {
      const amount = t.contactFormQuestionId
        ? _.size(t.questions) + 1
        : _.size(t.questions)
      return _.map(t.questions, (qId, index) => {
        const i =
          !_.isNil(contactFormIndex) && index > contactFormIndex
            ? index + 1
            : index
        const q = questions[qId]
        if (q) {
          if (q.id === t.contactFormQuestionId) {
            return [
              renderQuestion(q, i, amount),
              renderContactForm(i + 1, amount)
            ]
          } else {
            return renderQuestion(q, i, amount)
          }
        } else {
          return null
        }
      })
    }
  }

  const renderContent = () => {
    return (
      <VStack w='full' px={2} pt={4}>
        <Button
          variant='solid'
          colorScheme='teal'
          w='full'
          mb={2}
          onClick={onUseTemplateClick}
        >
          Use this template
        </Button>
        {renderQuestionsList()}
      </VStack>
    )
  }

  return (
    <VStack bg='white' w='full' rounded={'lg'} align='flex-start' py={4}>
      <HStack
        as='button'
        onClick={onToggle}
        px={6}
        color='gray.700'
        justify={'space-between'}
        w='full'
        sx={{ svg: { transitionDuration: '300ms' } }}
      >
        <HStack spacing={4}>
          <FontAwesomeIcon
            icon={faChevronRight}
            rotation={isOpen ? 90 : undefined}
          />
          <VStack align='flex-start' spacing={1}>
            <Text fontSize={'md'} fontWeight='semibold' color='gray.800'>
              {t.title}
            </Text>
            <Text fontSize='xs' color='gray.600'>{`${questionsAmount} question${
              questionsAmount !== 1 ? 's' : ''
            }`}</Text>
          </VStack>
        </HStack>
        <FontAwesomeIcon
          icon={fas[section.icon || 'faSquareList']}
          fontSize={'1.2em'}
        />
      </HStack>

      <Collapse in={isOpen} animateOpacity style={{ width: '100%' }}>
        {renderContent()}
      </Collapse>
    </VStack>
  )
}

export default TemplateRow
