import { Heading, VStack, Text, Button } from '@chakra-ui/react'
import { faCubes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

type Props = {
  onBrowseTemplatesClick: () => void
}

const CreateFormMobileEmptyState: FC<Props> = ({ onBrowseTemplatesClick }) => {
  return (
    <VStack px={8} align='flex-start' w='full' spacing={4} pt={8}>
      <Heading size='md' color='gray.800'>
        Create interview form
      </Heading>
      <Text color='gray.600' pb={4}>
        Create your first form from a premade template.
      </Text>
      <Button
        variant='solid'
        colorScheme={'teal'}
        size='lg'
        w='full'
        leftIcon={<FontAwesomeIcon icon={faCubes} />}
        onClick={onBrowseTemplatesClick}
      >
        Choose a template
      </Button>
      <Text fontSize='xs' color='gray.600' pt={4}>
        You can customize after choosing your template
      </Text>
    </VStack>
  )
}

export default CreateFormMobileEmptyState
