import {
  getDocs,
  where,
  collection,
  doc,
  onSnapshot,
  query
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { auth, db } from 'controllers/db'
import { AccountT, UserT } from 'shared/types/model'
import { receiveAccount } from 'model/actions/accountAC'
import { receiveUserAccounts } from 'model/actions/userAccountsAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { getRequestHeaders } from 'controllers/headers'

export const fetchAccount = async (accountId: string) => {
  console.log('fetch account', accountId)
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'accounts', accountId),
      accountSN => {
        if (accountSN.exists()) {
          const account = accountSN.data() as AccountT
          store.dispatch(receiveAccount(account))
          const isLocalHost = _.get(location, 'hostname') === 'localhost' || _.get(location, 'hostname') === '127.0.0.1'
          if (!isLocalHost) {
            const heap = _.get(window, 'heap')
            if (!_.isNil(heap)) {
              heap.addUserProperties({
                isFaceFileAdmin: _.toString(_.get(account, 'isFaceFileAdmin', false))
              })
            }
          }
        } else {
          store.dispatch(receiveAccount(null))
        }
      },
      err => {
        console.log('fetch account error', err)
        Sentry.captureMessage('fetch account error')
        Sentry.captureException(err)
      }
    )
    addListener('account', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch account error', e)
  }
}

export const fetchUserAccounts = async (user: UserT): Promise<void> => {
  try {
    if (!_.isNil(user)) {
      const members = _.get(user, 'adminOfAccounts')
      const chunks = _.chunk(members, 10)
      const qAr = await Promise.all(
        _.map(chunks, async ids => {
          const qSN = await getDocs(
            query(collection(db, 'accountsProfiles'), where('id', 'in', ids))
          )
          return _.map(qSN.docs, doc => {
            const p = doc.data() as UserT
            return p
          })
        })
      )
      const res = _.keyBy(_.flatten(qAr), 'id')
      store.dispatch(receiveUserAccounts(res))
    } else {
      console.log('fetchUserAccounts error: adminsOfAccounts is missing')
      Sentry.captureMessage(
        'fetchUserAccounts error: adminsOfAccounts is missing'
      )
    }
  } catch (e) {
    console.log('fetchUserAccounts error', e)
    Sentry.captureException(e)
  }
}

export const trackFormCreated = async (byGuide: boolean): Promise<void> => {
  try {
    const url = `${process.env
      .REACT_APP_DYNAMIC_URL as string}/stonly/track-form-created`
    const state = store.getState()
    const userId = _.get(state, 'user.id')
    const idToken = await auth.currentUser?.getIdToken()
    if (!_.isNil(idToken)) {
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: idToken
        },
        body: JSON.stringify({
          userId,
          byGuide
        })
      })
    }
  } catch (e) {
    console.log('trackFormCreated error', e)
    Sentry.captureException(e)
  }
}

export const resendVerificationEmail = async (): Promise<boolean> => {
  try {
    const url = `${process.env
      .REACT_APP_DYNAMIC_URL as string}/account/send-verification-email`
    const headers = await getRequestHeaders()
    const req = await fetch(url, {
      method: 'GET',
      headers
    })
    if (req.status >= 300 || req.status < 200) {
      Sentry.captureException(new Error('resendVerificationEmail is not sent'))
      return false
    } else {
      return true
    }
  } catch (e) {
    console.log('resendVerificationEmail error', e)
    Sentry.captureException(e)
    return false
  }
}
