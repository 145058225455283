import { Button } from '@chakra-ui/react'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

type Props = {
  onClick: () => void
}

const BackButtonMobile: FC<Props> = ({ onClick }) => (
  <Button
    leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
    variant='outline'
    size='sm'
    onClick={onClick}
  >
    Back
  </Button>
)
export default BackButtonMobile
