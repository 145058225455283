import * as types from 'model/actionTypes'
import { DictT, UserT } from 'shared/types/model'

interface userAccountsActionT {
  type: string
  userAccounts: DictT<UserT> | null
}

export function receiveUserAccounts (userAccounts: DictT<UserT> | null): userAccountsActionT {
  return {
    type: types.RECEIVE_USER_ACCOUNTS,
    userAccounts
  }
}

export function clear () {
  return {
    type: types.CLEAR
  }
}
