import { FC } from 'react'
import {
  Text,
  Button,
  Center,
  VStack,
  useClipboard,
  Tooltip,
  Box,
  HStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpFromSquare,
  faArrowUpRightFromSquare,
  faCamera,
  faGear,
  faLinkSimple
} from '@fortawesome/pro-regular-svg-icons'
import { logFormLinkCopied } from 'controllers/analytics'

type Props = {
  formId: string
}

const FormEmptyState: FC<Props> = ({ formId }) => {
  const { onCopy, hasCopied, value } = useClipboard(
    `${process.env.REACT_APP_VIEW_FORM_URL}/?form=${formId}`
  )

  const copyLinkToClipboard = () => {
    logFormLinkCopied(formId)
    onCopy()
  }

  const openPreview = () => {
    window.open(`${process.env.REACT_APP_VIEW_FORM_URL}/?form=${formId}`, '_blank')
  }

  return (
    <Center w='full' h='full' pb={{ base: 12, lg: 0 }} bg='white'>
      <VStack spacing={6}>
        <Box color='black'>
          <FontAwesomeIcon icon={faArrowUpFromSquare} size='2x' />
        </Box>
        <Text
          color='black'
          fontWeight={'semibold'}
          fontSize={'lg'}
          borderColor={'gray.500'}
        >
          To collect responses, share this link with your target audience
        </Text>

        <HStack spacing={8} py={2}>
          <Text color='gray.800' fontWeight={'medium'} fontSize={'lg'}>
            {value}
          </Text>
          <Tooltip
            label={hasCopied ? 'Link copied!' : 'Copy link'}
            closeDelay={1000}
          >
            <Button
              size='sm'
              variant={'outline'}
              colorScheme='gray'
              color='gray.600'
              leftIcon={<FontAwesomeIcon icon={faLinkSimple} color='black' />}
              onClick={copyLinkToClipboard}
            >
              Copy Link
            </Button>
          </Tooltip>
        </HStack>

        <Button
          variant={'solid'}
          colorScheme='teal'
          size='lg'
          w='full'
          maxW='80'
          bg='teal.400'
          leftIcon={<FontAwesomeIcon icon={faArrowUpFromSquare} />}
        >
          Share
        </Button>

        <VStack spacing='10px' w='full' maxW={80}>
          <Text color='gray.600' fontSize={'sm'} fontWeight={'medium'}>
            MORE OPTIONS
          </Text>
          <Button
            variant={'outline'}
            colorScheme='gray'
            w='full'
            size='lg'
            color='gray.500'
            fontSize='md'
            fontWeight={'medium'}
            leftIcon={<FontAwesomeIcon icon={faCamera} />}
          >
            Record a test response
          </Button>
          <Button
            variant={'outline'}
            colorScheme='gray'
            w='full'
            size='lg'
            color='gray.500'
            fontSize='md'
            fontWeight={'medium'}
            leftIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            onClick={openPreview}
          >
            Preview
          </Button>
          <Button
            variant={'outline'}
            colorScheme='gray'
            w='full'
            size='lg'
            color='gray.500'
            fontSize='md'
            fontWeight={'medium'}
            leftIcon={<FontAwesomeIcon icon={faGear} />}
          >
            Configure
          </Button>
        </VStack>
      </VStack>
    </Center>
  )
}

export default FormEmptyState
