import {
  doc,
  onSnapshot,
  query,
  collection,
  where,
  updateDoc
} from 'firebase/firestore'
import { db, dbOmit } from 'controllers/db'
import _ from 'lodash'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

import { AccountProfileT, UserProfileT } from 'shared/types/model'
import { receiveAccountProfile } from 'model/actions/accountProfileAC'
import { addListener } from 'controllers/listeners'
import store from 'model/store'
import { receiveUsersProfiles } from 'model/actions/profilesAC'

export const fetchAccountProfile = async (accountId: string) => {
  console.log('fetchAccountProfile', accountId)
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'accountsProfiles', accountId),
      accountProfilesSN => {
        if (accountProfilesSN.exists()) {
          const accountProfile = accountProfilesSN.data() as AccountProfileT
          store.dispatch(receiveAccountProfile(accountProfile))

          const isLocalHost =
          location.hostname === 'localhost' || location.hostname === '127.0.0.1'
          if (!isLocalHost) {
            const heap = _.get(window, 'heap')
            if (!_.isNil(heap)) {
              console.log('triggering heap')
              heap.addUserProperties({
                companyName: _.get(accountProfile, 'name')
              })
            }
          }
        } else {
          store.dispatch(receiveAccountProfile(null))
        }
      },
      err => {
        console.log('fetchAccountProfile error', err)
        Sentry.captureMessage('fetch user error')
        Sentry.captureException(err)
      }
    )
    addListener('accountProfile', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchAccountProfile error', e)
  }
}

export const fetchUserProfiles = async (accountId: string, userId: string) => {
  console.log('fetchUserProfiles', accountId)
  try {
    const q = query(
      collection(db, 'usersProfiles'),
      where('accounts', 'array-contains', accountId)
    )
    const unsubscribe = onSnapshot(
      q,
      profilesSN => {
        const profiles = _.keyBy(
          _.map(profilesSN.docs, doc => doc.data() as UserProfileT),
          'id'
        )
        const userProfile = profiles[userId]
        const isLocalHost =
          location.hostname === 'localhost' || location.hostname === '127.0.0.1'

        if (!_.isNil(userProfile)) {
          if (!isLocalHost) {
            const name = _.get(userProfile, 'name')
            const email = _.get(userProfile, 'email')
            LogRocket.identify(userId, { name, email })

            const heap = _.get(window, 'heap')
            if (!_.isNil(heap)) {
              console.log('triggering heap')
              heap.identify(`${name} (${email})`)
              heap.addUserProperties({
                name,
                email
              })
            }
          }
        }
        Sentry.setUser({
          id: userId,
          email: _.get(userProfile, 'email', ''),
          username: _.get(userProfile, 'name', ''),
          accountId
        })
        store.dispatch(receiveUsersProfiles(profiles))
      },
      err => {
        console.log('fetchUserProfiles', err)
        Sentry.captureMessage('fetchUserProfiles')
        Sentry.captureException(err)
      }
    )
    addListener('profiles', unsubscribe)
  } catch (e) {
    console.log('fetch user error', e)
    Sentry.captureException(e)
  }
}

export const dbUpdateAccountProfile = async (
  accountId: string,
  upd: Partial<AccountProfileT>
) => {
  try {
    const ref = doc(collection(db, 'accountsProfiles'), accountId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateUserProfile = async (
  userId: string,
  upd: Partial<UserProfileT>
) => {
  try {
    const ref = doc(collection(db, 'usersProfiles'), userId)
    await updateDoc(ref, upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}
