import * as types from 'model/actionTypes'
import { UserT } from 'shared/types/model'

const initialState: UserT | null = null

export default function user (
  state = initialState,
  action: {
    type: string
    user: typeof initialState
  }): typeof initialState | UserT {
  switch (action.type) {
    case types.RECEIVE_USER:
      return action.user
    case types.LOGOUT:
      return initialState
    default :
      return state
  }
}
