import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Text,
  Heading,
  Textarea,
  VStack,
  HStack,
  useToast
} from '@chakra-ui/react'
import _ from 'lodash'
import { callOpenaiChat } from 'controllers/admin'
import QuestionsAnswersTestCase from 'components/settings/QuestionsAnswersTestCase'
import { AIPromptConfT, AIConfT, QA } from 'shared/types/model'
import { generateId } from 'controllers/db'
import { dbUpdateAIAnswerAnalysis, fetchAIConf } from 'controllers/settings'
import { useSelector } from 'model/hooks'
import AISettings from 'components/settings/AISettings'

const SettingsAnalysisPrompt = () => {
  const [prompt, setPrompt] = useState('')
  const [model, setModel] = useState('gpt-3.5-turbo')
  const [temperature, setTemperature] = useState('0')
  const [maxTokens, setMaxTokens] = useState('1024')
  const [loading, setLoading] = useState(false)
  const aiConf: AIConfT | null = useSelector(state => state.aiConf)
  const toast = useToast()
  const [qas, setQas] = useState<QA[]>([
    { id: generateId(), q: '', a: '', ai: '' }
  ])

  useEffect(() => {
    fetchAIConf()
  }, [])

  useEffect(() => {
    if (aiConf) {
      setPrompt(aiConf.answerAnalysis.prompt)
      setModel(aiConf.answerAnalysis.model)
      setTemperature(_.toString(aiConf.answerAnalysis.temperature))
      setMaxTokens(_.toString(aiConf.answerAnalysis.maxTokens))
    }
  }, [aiConf])

  const interview_ = useMemo(() => {
    const r: string[] = []
    _.forEach(qas, (qa, i) => {
      r.push(`Question ${i + 1}: ${qa.q}`)
      r.push(`Answer ${i + 1}: ${qa.a}`)
    })
    return _.join(r, '/n')
  }, [qas])

  const makePrompt = () => {
    return _.replace(prompt, '$INTERVIEW', interview_)
  }

  const saveSettings = () => {
    const c: AIPromptConfT = {
      prompt,
      temperature: Number(temperature),
      model,
      maxTokens: Number(maxTokens)
    }
    dbUpdateAIAnswerAnalysis(c)
  }

  const send = async () => {
    setLoading(true)
    console.log('send', prompt)
    // callOpenai(prompt)
    const message = [
      // { role: 'system', content: prompt },
      { role: 'user', content: makePrompt() }
    ]
    const apires = await callOpenaiChat(
      message,
      model,
      Number(temperature),
      Number(maxTokens)
    )
    console.log('apires', apires)
    const resM = _.get(apires, ['choices', 0, 'message', 'content'])
    if (resM) {
      try {
        const d = JSON.parse(resM)
        if (_.isArray(d)) {
          const newQas = _.map(qas, (qa, i) => {
            const ai = _.get(d, [i, 'evaluation'])
            if (ai) {
              return { ...qa, ai }
            } else {
              return qa
            }
          })
          setQas(newQas)
        }
      } catch (e) {
        console.warn(e)
        toast({
          title: 'Error',
          description: _.get(e, 'message'),
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      }
    }
    setLoading(false)
  }

  const renderTestCase = () => {
    return <QuestionsAnswersTestCase qas={qas} setQas={setQas} />
  }

  const renderNavBar = () => {
    return (
      <HStack justify={'flex-end'} w='full' py='4'>
        <HStack>
          <Button onClick={send} colorScheme='blue' isLoading={loading}>
            Test configuration
          </Button>
          <Button colorScheme='blue' onClick={saveSettings}>
            Save settings
          </Button>
        </HStack>
      </HStack>
    )
  }

  const renderVariables = () => {
    return (
      <VStack w='full' align='flex-start'>
        <Heading>Variables</Heading>
        <Text fontSize={'sm'}>
          <strong>$INTERVIEW</strong> - will be replaced with all questions and
          answers
        </Text>
      </VStack>
    )
  }

  return (
    <VStack w='full' px={8} mb={8}>
      {renderNavBar()}
      {renderTestCase()}
      <HStack align='flex-start' spacing={16} w='full'>
        <VStack w='full' align={'flex-start'}>
          <Heading>Prompt</Heading>
          <Textarea
            value={prompt}
            size='sm'
            onChange={e => setPrompt(e.target.value)}
            background={'white'}
            minH='80'
          />
        </VStack>
        <VStack w='full' spacing={6}>
          {renderVariables()}
          <AISettings
            model={model}
            setModel={setModel}
            temperature={temperature}
            setTemperature={setTemperature}
            maxTokens={maxTokens}
            setMaxTokens={setMaxTokens}
          />
        </VStack>
      </HStack>
    </VStack>
  )
}

export default SettingsAnalysisPrompt
