import * as types from 'model/actionTypes'
import { TemplateT, DictT } from 'shared/types/model'

const initialState: DictT<TemplateT> | null = null

export default function (
  state = initialState,
  action: { type: string; templates: DictT<TemplateT> }
): typeof initialState {
  switch (action.type) {
    case types.RECEIVE_TEMPLATES:
      return action.templates
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
