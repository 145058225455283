import { FC } from 'react'
import { Text, VStack, Box, Flex } from '@chakra-ui/react'
// import _ from 'lodash'
import WelcomePageContent from 'shared/components/WelcomePageContent'

import { WelcomeT } from 'shared/types/model'

type Props = {
  welcome: WelcomeT
}

const WelcomePagePreview: FC<Props> = ({ welcome }) => {
  const renderNavBar = () => {
    return (
      <Box flex={1} px={5} py={7} w='full'>
        <Text fontWeight={'semibold'}>Preview</Text>
      </Box>
    )
  }

  return (
    <VStack h='full' spacing={0} w='full' overflow={'hidden'}>
      {renderNavBar()}
      <Flex h='full' w='full' px={10} pb={6}>
        <Flex
          h='full'
          w='full'
          borderWidth={1}
          rounded={'lg'}
          boxShadow={
            '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)'
          }
        >
          <WelcomePageContent welcome={welcome} onGo={() => null} />
        </Flex>
      </Flex>
    </VStack>
  )
}

export default WelcomePagePreview
