import {
  doc,
  getDoc,
  setDoc,
  query,
  collection,
  where,
  onSnapshot,
  updateDoc,
  deleteDoc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db, generateId } from 'controllers/db'
import { InvitationT } from 'shared/types/model'
import store from 'model/store'
import { receiveInvitations } from 'model/actions/inviationsAC'
import { addListener } from 'controllers/listeners'
import { switchAccount } from 'controllers/auth'
import { logTeammateInvited } from 'controllers/analytics'
import { getRequestHeaders } from 'controllers/headers'

export const fetchInvitations = async (accountId: string) => {
  try {
    if (!_.isNil(accountId)) {
      const q = query(
        collection(db, 'invitations'),
        where('accountId', '==', accountId)
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const res = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.set(res, doc.id, p)
          })
          store.dispatch(receiveInvitations(res))
        },
        err => {
          console.log(`fetchInvitations error: ${err.message}`)
        }
      )
      addListener('invitations', unsubscribe)
    } else {
      console.log('fetchInvitations error: accountId missing')
    }
  } catch (e) {
    console.log('fetchInvitations error', e)
    Sentry.captureException(e)
  }
}

export const sendInvitation = async (email: string): Promise<void> => {
  try {
    const state = store.getState()
    const invitationId = generateId()
    const userId = _.get(state, 'user.id')
    const invitation: InvitationT = {
      email,
      id: invitationId,
      createdAt: _.now(),
      createdBy: _.get(state, ['profiles', userId, 'email']),
      accountId: _.get(state, 'account.id'),
      accountName: _.get(state, 'accountProfile.name'),
      status: 'pending'
    }
    await setDoc(
      doc(db, 'invitations', invitationId),
      _.omitBy(invitation, _.isNil)
    )
    logTeammateInvited(email)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchInvitation = async (
  invitationId: string
): Promise<InvitationT | null> => {
  try {
    const docRef = doc(db, 'invitations', invitationId)
    const docSn = await getDoc(docRef)
    const invitation = docSn.data() as InvitationT
    return invitation
  } catch (e) {
    console.log('fetchInvitation error', e)
    Sentry.captureException(e)
    return null
  }
}

export const acceptInvitation = async (inviteId: string): Promise<void> => {
  try {
    const url = `${process.env.REACT_APP_DYNAMIC_URL as string}/account/accept`
    const headers = await getRequestHeaders()
    const res = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        inviteId
      })
    })
    if (res.status === 200) {
      const { accountId } = await res.json()
      switchAccount(accountId)
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('acceptInvitation error', e)
  }
}

export const declineInvitation = async (
  invitationId: string
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'invitations', invitationId), {
      status: 'declined'
    })
  } catch (e) {
    Sentry.captureException(e)
    console.log('declineInvitation error', e)
  }
}

export const removeInvitation = async (invitationId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'invitations', invitationId))
  } catch (e) {
    Sentry.captureException(e)
    console.log('removeInvitation error', e)
  }
}
